import React, { useCallback, useEffect, useRef } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { filter, isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { search as dropdownsSearch } from 'api/dropdowns';
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/carpool_permits/index'
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import withFetching from 'components/modules/with_fetching';

const initialValues = { 
  permit_type_name: 'car_pool', 
  expiring_permit: true, 
  hourly_rate: '0.0'
}

const singleFieldProps = {
  lSize: 2,
  iSize: 10
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

const attrs = {
  customLabelClass: `${styles.inputLabel}`,
  customInputClass: `${styles.input}`,
}

const expiringFields = ['commuters_per_vehicle', 'code', 'permit_type_name', 'cost']

const validateValues = (formState) => {
  const errors = {}
  const { parking_hour_from: from, parking_hour_to: to, hourly_rate, payment_type, commuters_per_vehicle, validity, category, parking_lot_ids, period, allow_cancellation, status, expiring_permit, cost } = formState;
  if(!commuters_per_vehicle) { errors['commuters_per_vehicle'] = ['Commuters can\'t be blank']}
  if(!category && expiring_permit) { errors.category = ['Category can\'t be blank']}
  if(!validity && expiring_permit) { errors['validity'] = ['Validity can\'t be blank']}
  if(payment_type === 'paid' && parseFloat(cost) <= 0 && !expiring_permit) { errors['cost'] = ['Cost must be greater than 0']}
  if(!allow_cancellation) { errors.allow_cancellation = ['Allow cancellation can\'t be blank']}
  if(!status) { errors['status'] = ['Status can\'t be blank']}
  if(!payment_type) { errors['payment_type'] = ['Payment type can\'t be blank']}
  if(isEmpty(period) && expiring_permit) { errors['period'] = ['Period can\'t be blank']}
  if(isEmpty(parking_lot_ids)) { errors.parking_lot_ids = ['Please select at least 1 parking lot']}
  if(payment_type === 'paid' && parseFloat(hourly_rate) <= 0) { errors.hourly_rate = ['Hourly rate must be greater than 0'] }
  if(!to) { errors.parking_hour_to = ["Time to can't be blank"] }
  if(!from) { errors.parking_hour_from = ["Time from can't be blank"] }
  if(!!to && !!from && to === from) { errors.parking_hour_to = ["Parking hours cannot have the same value"] }
  if(!!to && !!from && to < from) { errors.parking_hour_from = ["Starting Parking Hour cannot be greater than Final Parking Hour"] }
  return errors
}

const Create = (props) => {
  const  { setState, createCarpoolPermit, closeModal, errors, isSaving, startFetching, parkingLots, codes, dropdownsFetched } = props;
  const formApiRef = useRef();
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  
  const updateHourlyRate = (e) => {
    
    if(e?.value === 'free' && e?.label === 'Free') {
      formApiRef.current.setValues({...formApiRef.current.getState(), 'hourly_rate': '0.0', 'cost': '0.0'})
    }
  }
  
  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {updateHourlyRate(e)}
    }
  }
  const save = useCallback(() => {
    setState('errors', {})
    const {values} = formApiRef.current.getState();

    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setState('errors', inputErrors)
      return
    };

    values.parking_lots_array = values.parking_lot_ids?.map(id => ({id: parseInt(id)})).filter(lot => lot.id !== 0) || [];
    values.permit_type_name = 'car_pool'
    values.name = 'Carpoot Permit'

    createCarpoolPermit(values);
  },[setState, createCarpoolPermit]);

  useEffect(() => {
    setState('errors', {})
    const fetchDropdowns = async () => {
      Promise.all([
        startFetching(dropdownsSearch('permit_type_code_list'))
          .then(response => setState('dropdowns', { 'codes': response.data })),
        startFetching(dropdownsSearch('parking_lot_list'))
          .then(response => setState('dropdowns', { 'parkingLots': response.data, 'dropdownsFetched': true  }))
      ]);
    }
    if (!dropdownsFetched) fetchDropdowns()
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])
  
  const getFields = (validity, payment_type) => {
    return fieldsNew({mute: false, parkingLots, codes, validity, payment_type, ...attrs})
  }
  
  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}>Create Carpool Permit Type</p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >

        {({ formState }) => {
            const { expiring_permit, validity, payment_type } = formState.values;
            const newFields = getFields(validity, payment_type)
            if(!expiring_permit) {
              newFields[1] = filter(newFields[1], field => expiringFields.includes(field.name))
              newFields[3] = filter(newFields[3], field => field.name !== 'hourly_rate')
            } else {
              newFields[3] = filter(newFields[3], field => field.name !== 'cost')
            }

            if(errors.parking_lots_array) {errors.parking_lot_ids = errors.parking_lots_array}

            return (
              <Row className='mx-auto w-100'>
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0`} xs={12}>
                      { renderField(field, { ...singleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                <div className='w-100 d-flex flex-wrap'>
                  {
                    expiring_permit === true &&
                      <>
                        <div className={`m-0 ${styles.fieldset}`}>
                          { renderFields(newFields[1].slice(0,3), { ...doubleFieldProps, errors} )}
                        </div>
            
                        <div className={`m-0 ${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
                          { renderFields(newFields[1].slice(3), { ...doubleFieldProps, errors} )}
                        </div>
                      </>
                  }
                      
                  { expiring_permit === false &&
                    <>
                      <Col className={`m-0 p-0`} xs={12}>
                        { renderField(newFields[1][1], { ...singleFieldProps, errors} )}
                      </Col>
                      <div className={`m-0 ${styles.fieldset}`}>
                        { renderFields(newFields[1].slice(0,1), { ...doubleFieldProps, errors} )}
                      </div>
                      <div className={`m-0 ${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
                        { renderFields(newFields[1].slice(2), { ...doubleFieldProps, errors} )}
                      </div>
                    </>
                  }
                </div>
                <Col className={`m-0 p-0`} xs={12}>
                  { renderFields(newFields[2], { ...singleFieldProps, errors} )}
                </Col>
     
                <div className='w-100 d-flex'>
                  <div className={`m-0 ${styles.fieldset}`}>
                    { renderFields(newFields[3].slice(0,3), { ...doubleFieldProps, errors} )}
                  </div>
            
                  <div className={`m-0 ${styles.fieldset} ${styles['fieldset-right-label']} pl-3`}>
                  { map(newFields[3].slice(3), (field, idx) => {
                    if(idx === 1) {
                      field = {
                        ...field,
                        icon: dollarIcon(), 
                        customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                      }
                    }

                    if(idx === 2) {
                      field = {...field, options: map(field.options, ({value, label}) => ({
                        value,
                        label: <>
                          <FontAwesomeIcon 
                            className='mr-2' 
                            icon={faCircle}
                            style={{ 
                              color: `${value === 'active' ? 'green' : 'red'}`,
                              width: 10
                            }}
                            /> 
                          {label}
                        </>
                      }))}
                    }
                    return <React.Fragment key={idx}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </React.Fragment>
                  }) }
                  </div>
                </div>

                <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={`${styles.button}`}  isLoading={isSaving}>
                    Create
                  </Button>
                </Col>
              </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);
