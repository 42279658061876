import { FieldType } from 'components/helpers/form_fields'
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const searchFields = (attrs) => {
  const { customLabelClass, customInputClass} = attrs
  return [
    { 
      name: 'user_id', 
      label: 'User ID',
      customInputClass,
      customLabelClass,
    },
    { 
      name: 'full_name', 
      label: 'Name', 
      customInputClass,
      customLabelClass,
    },
    { 
      name: 'email', 
      label: 'Email Address', 
      customInputClass,
      customLabelClass,
    },
  ];
};

const filterFields = (attrs) => {
  const { parkingLots, permitTypes, customInputClass, customLabelClass } = attrs
  return [
    {
      name: 'parking_lot_ids',
      label: 'Parking Lots:',
      type: FieldType.MULTISELECT_FIELD,
      showToolTip: false,
      options: [{value: 0, label: 'All Parking Lots'}].concat(parkingLots),
      placeholder: 'Choose a Parking Lot',
      customInputClass,
      customLabelClass,
      showCount: false,
    },
    {
      name: 'permit_type_ids',
      label: 'Permit Type:',
      type: FieldType.MULTISELECT_FIELD,
      showToolTip: false,
      options: [{value: 0, label: 'All Permit Types'}].concat(permitTypes),
      placeholder: 'Choose a Permit Type',
      customLabelClass,
      customInputClass,
      showCount: false,
    },
  ]
}

const movePositionFields = (attrs) => {
  const { customLabelClass='', customInputClass=''} = attrs
  return ([
    [
      {
        name: 'waiting_list_number',
        label: 'Current Position',
        customInputClass,
        customLabelClass,
        disabled: true,
      }
    ],
    [
      {
        name: 'new_position',
        label: 'New Position',
        placeholder:"Enter the new position",
        type: FieldType.NUMBER_FIELD,
        mandatory: true,
        customInputClass,
        customLabelClass,
        min: 1,
      },
    ]
  ]);
} 

const configureFields = (attrs) => {
  const { customInputClass='', customLabelClass='', disabled, name, id, customInputClass1='', accountTypes=[], customInputClass2='', customLabelClass2=''} = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      disabled: 'red',
      enabled: 'green'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }
  
  return [
    {
      name: 'status',
      label: 'Waitlist Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{ value: 'disabled', label: 'Disabled' }, { value: 'enabled', label: 'Enabled' }].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass,
      customLabelClass,
    },
    {
      name: 'waitlist_type',
      label: 'Waitlist Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{ value: 'default', label: 'Default' },
                { value: 'priority', label: 'Priority' },
                { value: 'lottery', label: 'Lottery' }],
      customInputClass,
      customLabelClass,
      tooltip: 'Priority allocates waitlist positions according to order of joining. Lottery allocates positions randomly.',
      disabled: disabled
    },
    {
      name: 'max_users',
      label: 'Max Users',
      placeholder: 'Max Users',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      min: 1,
      tooltip: 'The maximum number of users allowed on the lottery waitlist.',
    },
    { 
      name: `priority[${id}-${name}][min]`, 
      label: `${name?.split('_').join(' ').replace(/(\b[a-z](?!\s))/, (x) => (x.toUpperCase()))}`,
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Min.',
      min: 1,
      customInputClass: customInputClass1,
      customLabelClass,
    },
    { 
      name: `priority[${id}-${name}][max]`, 
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Max.',
      min: 2,
      customInputClass,
      customLabelClass,
    },
    { 
      name: `priority_criteria[account_type]`, 
      label: 'Account Type',
      type: FieldType.MULTISELECT_FIELD,
      options: accountTypes,
      placeholder: 'Please select',
      customInputClass: customInputClass2,
      customLabelClass: customLabelClass2,
    },
    { 
      name: `priority[parking_sessions_completed][max]`, 
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Max.',
      min: 2,
      customInputClass,
      customLabelClass,
    },
    { 
      name: `priority[account_type][min]`, 
      label: 'Account type',
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Min.',
      min: 1,
      customInputClass,
      customLabelClass,
    },
    { 
      name: `priority[account_type][max]`, 
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Max.',
      customInputClass,
      min: 2,
      customLabelClass,
    },
    { 
      name: `priority[violations_committed][min]`, 
      label: 'Violations committed',
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Min.',
      customInputClass,
      min: 1,
      customLabelClass,
    },
    { 
      name: `priority[violations_committed][max]`, 
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Max.',
      customInputClass,
      min: 2,
      customLabelClass,
    },
    { 
      name: `priority[user_age][min]`, 
      label: 'User age',
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Min.',
      min: 1,
      customInputClass,
      customLabelClass,
    },
    { 
      name: `priority[user_age][max]`, 
      type: FieldType.NUMBER_FIELD,
      placeholder: 'Max.',
      min: 2,
      customInputClass,
      customLabelClass,
    },
  ]
}


export {searchFields, filterFields, movePositionFields, configureFields};