import React, { useState, useEffect } from "react";

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx, disableHighlight } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
      onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) setShowText(false) }}
      className={`d-flex align-items-center justify-content-center ${idx !== 0 ? 'border-left' : ''}`}
      role='button'
      style={{cursor: disableHighlight && 'not-allowed'}}
    >
      { showText
        ? <span 
        className={showText ? `d-inline-flex justify-content-center align-items-center ${highlightedClass}` : 'd-none'}
            style={{padding: '5px 10px', borderRadius: '15px' }}
          >
            {text}
          </span>
        : <span className={showText ? 'd-none' : 'd-inline-flex justify-content-center align-items-center'}>{icon}</span>
      }
    </div>
  )
}

export default Action;