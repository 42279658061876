import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { AlertMessages } from 'components/helpers/alert_messages';
import ValetLogin from 'routes/valet/login';
import PrivateRoute from 'routes/private_route';
import ValetHomePageRoute from 'routes/valet/home'

function ValetAppContent(props) {
  const { match } = props;
  return (
    <div className="position-relative">
      <AlertMessages>
        <PrivateRoute path={`${match.path}`} component={ValetLogin} />
        <PrivateRoute path={`${match.path}/home`} component={ValetHomePageRoute} />
      </AlertMessages>
    </div>
  );
}

ValetAppContent.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ValetAppContent);
