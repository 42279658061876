import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import RejectApplication from '../reject_application';
import ApproveApplication from '../approve_application';
import RejectApplicationReason from '../reject_reason';
import UnderReviewApplication from '../under_review_application';
import { connect } from 'react-redux';
import Show from '../show';
/* Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/* API */
import { approveApplication, putUnderReview, rejectApplication } from 'api/permit_applications';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import Preview from 'components/pages/vehicles/preview';

const initialValues = {
  isSaving: false,
  errors: {},
}

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'innerModal':
      return { ...state, innerModal: payload }  
    default:
      return { ...state };
  }
}

const ApplicationModal = (props) => {
  const [values, dispatch] = useReducer(reducer, initialValues)
  const [image, setImage] = useState()
  const [modalShown, setModalShown] = useState(false);
  
  const state = useMemo(() => values,[values])
  
  const setState = useCallback((type, payload) => {
    dispatch({ type , payload })
  },[dispatch])

  const { addAlertMessages } = useContext(AlertMessagesContext)
  
  const { setListElement, modalType, openModal, record } = props

  const closeModal = useCallback(() => {
    openModal()
  }, [openModal])

  const rejectPermitApplication = useCallback(async (values) => {
    setState('isSaving', true)
    try {
      const response = await rejectApplication({...values, id: record.id })
      if(response.status === 200){
        setListElement(response.data)
        closeModal();
        addAlertMessages([{
            type: 'danger',
            text: 'Permit application was successfully rejected',
            onlyMessage: true
          }], 'center')
        }
    } catch (error) {
      setState('errors', error.response.data.errors)
    }
    setState('isSaving', false)
  },[setListElement, setState, addAlertMessages, record.id, closeModal])

  const approvePermitApplication = useCallback(async (values) => {
    setState('isSaving', true)
    try {
      const response = await approveApplication({...values })
      if(response.status === 200){
        setListElement(response.data)
        addAlertMessages([{
          type: 'primary',
          text: 'Permit Application was successfully approved',
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      addAlertMessages([{
        type: 'danger',
        text: Object?.values(error?.response?.data?.errors)[0],
        onlyMessage: true,
      }], 'center')
    }
    setState('isSaving', false)
  },[setState, setListElement, addAlertMessages])

  const putApplicationOnReview = useCallback(async (values) => {
    setState('isSaving', true)
    try {
      const response = await putUnderReview({...values })
      if(response.status === 200){
        setListElement({...record, status: response.data.status})
        addAlertMessages([{
          type: 'notice',
          text: 'Permit application was successfully put under review',
          onlyMessage: true
        }], 'center')
      }
    } catch (_error) {
    }finally {
      setState('isSaving', false)
    }
  },[setState, setListElement, addAlertMessages, record])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const modalProps = useCallback(() => {
    const width = '480px'
    switch (modalType) {
      case 'show':
        return {width: '689px'}
      case 'approve':
        return {width: '689px'}
      default:
        return { width};
    }
  },[modalType])

  const innerModalProps = useCallback(() => {
    const width = '480px'
    switch (state.innerModal) {
      case 'show_session':
        return { width: '680px' };
      case 'raise_dispute':
        return { width: '680px' };
      default:
        return { width };
    }
  },[state.innerModal])
  

  return (
    <Modal
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.applicationModal} 
      style={{maxWidth: modalProps().width, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        {
          modalType === 'under_review' &&
            <UnderReviewApplication
              record={record}
              putApplicationOnReview={putApplicationOnReview}
              closeModal={closeModal}
              state={state}
            />
        }
        { modalType === 'show' && <Show record={record} closeModal={closeModal} /> }
        {
          modalType === 'reject' && 
          <RejectApplication
            rejectPermitApplication={rejectPermitApplication}
            record={record}
            closeModal={closeModal}
            state={state}
            setState={setState}
            errors={state.errors}
          />
        }
        {
          modalType === 'approve' && 
          <ApproveApplication
            approvePermitApplication={approvePermitApplication}
            record={record}
            closeModal={closeModal}
            state={state}
            setState={setState}
            image={image}
            setImage={setImage}
            openPreviewModal={() => setModalShown(!modalShown)}
          />
        }
        { modalType === 'reject_reason' && <RejectApplicationReason record={record} closeModal={closeModal} /> }
      </ModalBody>
      {modalShown &&
        <Modal
          isOpen={modalShown}
          close={() => {setModalShown(false)}}
          centered
          style={{maxWidth: innerModalProps().width, width: '100%'}}
          contentClassName={styles.previewModal}
          backdropClassName={styles.backdropC}
        >
          <ModalBody>
            <Preview
              close={() => {setModalShown(false)}}
              image={image}
            />
          </ModalBody>
        </Modal>
      }
    </Modal>
  )
}

const  mapState = (state) => {
  return {
    record: state.permit_application.records.record
  }
}
export default connect(mapState, null)(ApplicationModal);

