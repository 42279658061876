import env from '.env';
import axios from 'axios';
import fetchApi from 'components/modules/fetch_api';

const auth = (username, password) => {
  return axios.post(`${env.backend_url}/v1/valet_staff/auth/sign_in`, {valet_staff: {username, password}});
};

const checkValet = () => {
  const critical = true;
  return fetchApi(`v1/valet_staff/auth/me `, { method: 'GET' }, critical);
};

const sendResetPasswordInstructionsRequestValet = (username) => {
  return axios.post(`${env.backend_url}/v1/valet_staff/auth/send_reset_password_email`, { valet_staff: { username } });
};

const resetPasswordRequest = (data) => {
  return axios.put(`${env.backend_url}/v1/valet_staff/auth/change_password`, { valet_staff: data });
};

const checkPasswordToken = (data) => {
  return axios.post(`${env.backend_url}/v1/valet_staff/auth/check_reset_password_code`, { valet_staff: data });
};


export {
  auth,
  checkValet,
  sendResetPasswordInstructionsRequestValet,
  resetPasswordRequest,
  checkPasswordToken,
};
