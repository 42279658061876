import React, { useCallback, useRef } from 'react'
/* Helpers */
import { displayDate } from 'components/helpers'
import { searchFields } from 'components/helpers/fields/permits/index'
/* Base */
import Button from 'components/base/button'
/* API */
import { filterFetcher } from "api/permits";
/* Components */
import { Form } from 'informed'
import { Col } from 'reactstrap'
/* Assets */
import styles from './index.module.sass'
import { list as selectList } from 'selectors/list'
import { renderFieldsWithGrid } from 'components/base/forms/common_form';


const FilterForm = (props) => {
  const {parkingLots, permitTypes, permitTypeCategory, setFilters, match, setList, startFetching, setListType } = props;

  const attrs = {parkingLots, permitTypes, permitTypeCategory, customInputClass: styles.formInput, customLabelClass: styles.formLabel }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const filterPermits = useCallback(async (filters={}) => {
    const { application_date, permit_valid } = filters
    if(application_date && !application_date?.to) { delete application_date.to}
    if(application_date && !application_date?.from) { delete application_date.from}
    if(permit_valid && !permit_valid?.to) { delete permit_valid.to}
    if(permit_valid && !permit_valid?.from) { delete permit_valid.from}
    if(filters.issue_date === '') { delete filters?.issue_date}
  
    startFetching(filterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
      // eslint-disable-next-line
  },[setList, startFetching, match?.params])

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const filters = {
      ...filterValues,
      application_date: {
        from: displayDate(filterValues.application_date?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.application_date?.to, 'yyyy-mm-dd')
      },
      permit_valid: {
        from: displayDate(filterValues.permit_valid?.from, 'yyyy-mm-dd'),
        to: displayDate(filterValues.permit_valid?.to, 'yyyy-mm-dd')
      },
      users: { 
        first_name: filterValues?.vehicle_owner_name?.split(" ")[0],
        last_name: filterValues?.vehicle_owner_name?.split(" ")[1]
      },
      issue_date: displayDate(filterValues?.issue_date, 'yyyy-mm-dd')
    }
    setFilters(filters);
    setListType('permitted');
    filterPermits(filters);
  }

  const resetFilters = () => {
    formApiRef.current.setValues({
      application_date: { from: '', to: ''},
      permit_valid: { from: '', to: ''}, 
      permit_number: '', 
      vehicle: {plate_number: '', model: ''},
      permit_types: {parking_lot_ids: ''}, 
      permit_type: {parking_lot_ids: '', name: '', category: ''}, 
      status:'',
      issue_date: '',
      vehicle_owner_name:''
    })
    setFilters({})
    filterPermits({})
    setListType('permitted')
  }

  const getFields = (applicationFromDate, applicationToDate, permitFromDate, permitToDate) => searchFields({ ...attrs, applicationFromDate, applicationToDate, permitFromDate, permitToDate})

  return (
    <Form initialValues={'initialValues'} getApi={setFormApi} className={`${styles.form}`} onSubmit={save}>
      {({formState}) => {
        const applicationFromDate = formState.values.application_date?.from || ''
        const applicationToDate = formState.values.application_date?.to || ''
        const permitFromDate = formState.values.permit_valid?.from || ''
        const permitToDate = formState.values.permit_valid?.to || ''
        const fields = getFields( applicationFromDate, applicationToDate, permitFromDate, permitToDate)
        return <React.Fragment>
        <div lg={12} className=''>
        {
          renderFieldsWithGrid(
            fields,
            3, 4, { lSize: 5, iSize: 7 }
          )
        }
        </div>
        <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
          <Button
            className={`${styles.btn} ${styles['background-primary']} mr-5`}
            type='submit'
          >
            Search
          </Button>
          <Button
            type='button'
            onClick={resetFilters}
            className={`${styles.btn} ${styles['background-secondary']}`}
          >
            Reset
          </Button>
        </Col>
        </React.Fragment>
      }}
    </Form>
  )
}

export default FilterForm;
