import { FieldType } from 'components/helpers/form_fields'

const schedulerFields = (attrs) => {
  const { customInputClass, customLabelClass, emails=[], customDropdownInputClass='', customLabel1Class='', id, mute, customInput1Class=''} = attrs

  return [
    {
      name: `schedulers_attributes[${id}][email]`,
      label: 'Email to',
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: emails,
      customInputClass: customDropdownInputClass,
      customLabelClass,
      placeholder: 'Select Admin User',
      disabled: mute
    },
    {
      name: `schedulers_attributes[${id}][file_format]`,
      label: 'Format',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: "pdf", label: "PDF" },
        { value: "csv", label: "CSV" },
      ],
      customInputClass, 
      customLabelClass
    },
    {
      name: `schedulers_attributes[${id}][schedule_type]`,
      label: 'Schedule',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: "day", label: "Daily" },
        { value: "week", label: "End of Week" },
        { value: "month", label: "End of Month" },
      ],
      customInputClass, 
      customLabelClass
    },
    {
      name: `schedulers_attributes[${id}][schedule_time]`,
      label: 'at',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass: customInput1Class,
      customLabelClass: customLabel1Class
    },
  ];
};

export { schedulerFields }