import { FieldType } from 'components/helpers/form_fields'

const permitFilterFields = (attrs) => {
  const {permitTypeCategory=[], parkingLots=[], permitTypes=[], customInputClass, customLabelClass} = attrs

  return [
    {
      name: 'permit_type_name',
      label: 'Permit Type',
      placeholder: 'Choose a Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      customLabelClass,
      customInputClass,
      options: permitTypes
    },
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a status',
      customLabelClass,
      customInputClass,
      options: [
        {label: 'Pending', value: 'pending'},
        {label: 'Active', value: 'active'},
        {label: 'Revoked', value: 'revoked'},
        {label: 'Expired', value: 'expired'},
      ]
    },
    {
      name: 'parking_lot_ids',
      label: 'Parking Lots',
      type: FieldType.MULTISELECT_FIELD,
      showToolTip: false,
      options: [{value: 0, label: 'All Parking Lots'}].concat(parkingLots),
      placeholder: 'Choose Lots',
      customInputClass,
      customLabelClass,
      showCount: false,
    },
    {
      name: 'permit_type_category',
      label: 'Permit Category',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a Category',
      customLabelClass,
      customInputClass,
      options: permitTypeCategory
    },
  ];
};

const permitTypeFilterFields = (attrs) => {
  const { customInputClass, customLabelClass} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a status',
      customLabelClass,
      customInputClass,
      options: [
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' },
      ]
    },
    {
      name: 'category',
      label: 'Permit Category',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a Category',
      customLabelClass,
      customInputClass,
      options: [
        { value: 'permanent', label: 'Permanent' },
        { value: 'temporary', label: 'Temporary' },
      ]
    },
  ];
};

const parkingSessionFilterFields = (attrs) => {
  const { customInputClass, customLabelClass, parkingSessionStatuses=[], parkingLots=[]} = attrs
  return [
    {
      name: 'statuses',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a status',
      customLabelClass,
      customInputClass,
      options: parkingSessionStatuses
    },
    {
      name: 'parking_lot_id',
      label: 'Parking Lots',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      showToolTip: false,
      options: parkingLots,
      placeholder: 'Choose a Lot',
      customInputClass,
      customLabelClass,
      showCount: false,
    }
  ];
};

const violationFilterFields = (attrs) => {
  const { customInputClass, customLabelClass, parkingLots=[], ticketStatuses=[], violationTypes=[]} = attrs
  return [
    {
      name: 'ticket_status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a status',
      customLabelClass,
      customInputClass,
      options: ticketStatuses
    },
    {
      name: 'parking_lot_id',
      label: 'Parking Lots',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      showToolTip: false,
      options: parkingLots,
      placeholder: 'Choose a Lot',
      customInputClass,
      customLabelClass,
      showCount: false,
    },
    {
      name: 'violation_type',
      label: 'Violation Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a type',
      options: violationTypes,
      customInputClass,
      customLabelClass,
    },
  ];
};

const citationFilterFields = (attrs) => {
  const { customInputClass, customLabelClass, citationStatuses=[], violationTypes=[]} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a status',
      customLabelClass,
      customInputClass,
      options: citationStatuses
    },
    {
      name: 'violation_type_id',
      label: 'Violation Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a type',
      options: violationTypes,
      customInputClass,
      customLabelClass,
    },
  ];
};

const disputeFilterFields = (attrs) => {
  const { customInputClass, customLabelClass, disputeStatuses= [], disputeTypes= []} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a Status',
      options: disputeStatuses,
      customInputClass,
      customLabelClass,
    },
    {
      name: 'dispute_type',
      label: 'Dispute Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a type',
      options: disputeTypes,
      customInputClass,
      customLabelClass,
    },
  ];
};

const paymentFilterFields = (attrs) => {
  const { customInputClass, customLabelClass} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a status',
      customInputClass,
      customLabelClass,
      options: [
        { value: 'pending', label: 'Pending' },
        { value: 'success', label: 'Successful' },
        { value: 'failed', label: 'Failed' },
      ],
    },
    {
      name: 'payment_method',
      label: 'Payment Method',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a method',
      customInputClass,
      customLabelClass,
      options: [
        { value: 'cash', label: 'Cash' },
        { value: 'credit_card', label: 'Credit Card' },
        { value: 'free_pay', label: 'Free Pay' },
        { value: 'wallet', label: 'Wallet' }
      ],
    },
  ];
};

const userFilterFields = (attrs) => {
  const { customInputClass, customLabelClass} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a status',
      customInputClass,
      customLabelClass,
      options: [
        {label: 'Active', value: 'active'},
        {label: 'Deactivated', value: 'deactivated'},
        {label: 'On Hold', value: 'on_hold'}
      ], 
    },
    {
      name: 'query[users[email]]',
      label: 'Email Address',
      placeholder: 'Enter Email Address',
      customInputClass,
      customLabelClass
    },
    {
      name: 'plate_number',
      label: 'License Plate Number',
      placeholder: 'Enter Plate Number',
      customInputClass,
      customLabelClass
    },
  ];
};

const vehicleFilterFields = (attrs) => {
  const { customInputClass, customLabelClass} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a Status',
      options: [
        { value: 'pending', label: 'Pending' },
        { value: 'active', label: 'Active' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'inactive', label: 'Inactive' }
      ],
      customInputClass,
      customLabelClass,
    },
    {
      name: 'manufacturer_name',
      label: 'Vehicle Manufacturer',
      placeholder: 'Enter Vehicle manufacturer',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'category',
      label: 'Category',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: 'personal', label: 'Personal' },
        { value: 'government', label: 'Government' },
        { value: 'agency', label: 'Agency' }
      ],
      placeholder: 'Choose a category',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'vehicle_type',
      label: 'Vehicle Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: 'gasoline', label: 'Gasoline' },
        { value: 'electric', label: 'Electric' }
      ],
      placeholder: 'Choose a type',
      customInputClass,
      customLabelClass,
    },
  ];
};

const parkingLotFilterFields = (attrs) => {
  const { customInputClass, customLabelClass, townManagers= [], parkingAdmins= []} = attrs
  return [
    {
      name: 'status',
      label: 'Lot Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder:'Choose a status',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'suspended', label: 'Suspended' }
      ],
      customInputClass, 
      customLabelClass
    },
    {
      name: 'parking_lot_type',
      label: 'Parking Lot Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder:'Choose a status',
      options: [
        { value: 'gated', label: 'Gated' },
        { value: 'non_gated', label: 'Non Gated' }
      ],
      customInputClass, 
      customLabelClass
    },
    {
      name: 'town_managers',
      label: 'Town Manager',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Choose a town manager',
      options: townManagers,
      customInputClass, 
      customLabelClass
    },
    {
      name: 'parking_admins',
      label: 'Parking Operator',
      placeholder:'Choose a parking operator',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: parkingAdmins,
      customInputClass, 
      customLabelClass
    },
  ];
};

const eventFilterFields = (attrs) => {
  const { customInputClass, customLabelClass} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder:'Choose a status',
      options: [
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Approved' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'expired', label: 'Expired' },
        { value: 'cancelled', label: 'Cancelled' }
      ],
      customInputClass, 
      customLabelClass
    },
    {
      name: 'event_type',
      label: 'Event Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder:'Choose a type',
      options: [
        { value: 'city', label: 'City' },
        { value: 'pvt', label: 'Private' }
      ],
      customInputClass, 
      customLabelClass
    },
    { 
      name: 'event_days', 
      label: 'Event Days',
      type: FieldType.NUMBER_FIELD,
      placeholder:'Enter Number of Event Days',
      min: 1,
      customLabelClass, 
      customInputClass,
    },
    { 
      name: 'email', 
      label: 'Email', 
      placeholder: "Enter Email",
      customLabelClass, 
      customInputClass 
    },
  ];
};

const bootingTowingFilterFields = (attrs) => {
  const { customInputClass, customLabelClass} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder:'Choose a status',
      options: [
        { value: 'booted', label: 'Booted' },
        { value: 'impounded', label: 'Impounded' }
      ],
      customInputClass, 
      customLabelClass
    },
    {
      name: 'manufacturer_name',
      label: 'Vehicle Manufacturer',
      placeholder: 'Enter Vehicle manufacturer',
      customInputClass,
      customLabelClass,
    }
  ];
};

const agencyFilterFields = (attrs) => {
  const { customInputClass, customLabelClass, agencyTypes= []} = attrs
  return [
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder:'Choose a status',
      options: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
      ],
      customInputClass, 
      customLabelClass
    },
    {
      name: 'agency_type_id',
      label: 'Agency Type',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder:'Choose a type',
      options: agencyTypes,
      customInputClass, 
      customLabelClass
    }
  ];
};

export { permitFilterFields, permitTypeFilterFields, parkingSessionFilterFields,
         violationFilterFields, citationFilterFields, disputeFilterFields,
         paymentFilterFields, userFilterFields, vehicleFilterFields,
         parkingLotFilterFields, eventFilterFields, bootingTowingFilterFields,
         agencyFilterFields };