import { FieldType } from "components/helpers/form_fields";
import periodOptions from "./periods";

const fieldsNew = (attrs) => {
  const { 
    mute, parkingLots=[], validity='', customLabelClass='', customInputClass='', payment_type, expiring_permit, permitTypes=[],renderLocationModal
  ,disable, name} = attrs

  return ([
    [
      {
        name: 'expiring_permit',
        label: 'Expiration',
        customInputClass,
        customLabelClass,
        type: FieldType.RADIO_BUTTON_FIELD,
        mandatory: true,
        disabled: mute,
        list: [
          { label: 'Expiring Permit', value: true },
          { label: 'Non-Expiring Permit', value: false }
        ]
      },
      {
        name: 'name',
        label: 'Permit Type',
        placeholder: 'Please select',
        customInputClass,
        customLabelClass,
        type: FieldType.DROPDOWN_SELECT_FIELD,
        mandatory: true,
        options: expiring_permit === false ? permitTypes.filter(item => item.label !== "Visitor Permit")  : permitTypes,
        disabled: mute
      },
      {
        name: 'optional_name',
        label: 'Optional Name',
        iSize: '3',
        customInputClass,
        customLabelClass,
        mandatory: false,
      },
      {
        name: 'lot_find_by',
        label: 'Residential Zone',
        customInputClass,
        customLabelClass,
        type: FieldType.RADIO_BUTTON_FIELD,
        mandatory: true,
        disabled: mute,
        list: [
          { label: 'Zip Code', value: 'zip_code' },
          { label: 'Location Perimeter', value: 'location_perimeter' }
        ]
      },
      {
        name: 'location',
        label: 'Location',
        mandatory: true,
        render: renderLocationModal,
        disabled: mute
      },
    ],
    [
      {
        name: 'country',
        label: 'Country',
        iSize: '3',
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute
      },
      {
        name: 'state',
        label: 'State',
        iSize: '3',
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute

      },
      {
        name: 'city',
        label: 'City',
        iSize: '3',
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute

      },
      {
        name: 'zipcode',
        label: 'Zipcode',
        iSize: '3',
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute
      },
      {
        name: 'latitude',
        label: 'Latitude',
        iSize: '3',
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute
      },
      {
        name: 'longitude',
        label: 'Longitude',
        iSize: '3',
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: mute
      },
     
      {
        name: 'distance',
        label: 'Perimeter Radius',
        iSize: '3',
        customInputClass,
        type: FieldType.SELECT_FIELD,
        options: [1,2,3,4,5,6,7,8,9,10].map((val) => ({label: val === 1 ? `${val} Mile`: `${val} Miles` , value: val})),
        customLabelClass,
        mandatory: true,
      },
      {

        name: '',
        label: 'Distance',
        iSize: '3',
        customInputClass,
        type: FieldType.SELECT_FIELD,
        options: [1,2,3,4,5,6,7,8,9,10].map((val) => ({label: val === 1 ? `${val} Mile`: `${val} Miles` , value: val})),
        customLabelClass,
        mandatory: true,

      },
      {
        name: 'category',
        label: 'Category',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'permanent', label: 'Permanent' },
          { value: 'temporary', label: 'Temporary' },
        ],
        disabled: name === 'Visitor Permit' ? true : mute? mute : disable,
        mandatory: true,
        tooltipWidth: '25px',
        tooltipHeight: '25px',
        tooltipClass: '',
        tooltipEnd: 'Permanent permit types are renewable and extendable by subscribers. Temporary permits can only be renewed or extended by an admin.',
        customInputClass,
        customLabelClass,
      },
      {
        name: 'commuters_per_vehicle',
        label: 'Commuters',
        type: FieldType.SELECT_FIELD,
        options: [1,2,3,4,5,6,7,8,9,10].map((val) => ({label: val, value: val})),
        mandatory: true,
        disabled: false,
        customInputClass,
        customLabelClass,
      }
    ],
    [
      {
        name: 'validity',
        label: 'Validity',
        type: name === 'Visitor Permit' ? FieldType.MULTISELECT_FIELD : FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'days', label: 'Days' },
          { value: 'weeks', label: 'Weeks' },
          { value: 'months', label: 'Months' },
        ],
        mandatory: expiring_permit,
        disabled: disable,
        customInputClass,
        customLabelClass,
        showToolTip: false,
      },
      {
        name: 'period',
        label: 'Period',
        type: FieldType.MULTISELECT_FIELD,
        placeholder: 'Please select',
        options: periodOptions(validity, [1,3,6]),
        mandatory: expiring_permit,
        showToolTip: false,
        disabled: disable,
        customInputClass,
        customLabelClass,
        showCount: false,
      },
      {
        name: 'minimum_chargeable_time',
        label: 'Minimum Chargeable Time',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [10,15,20,25,30,35,40,45,50,55,60].map(val => ({value: val, label: `${val} minutes`})),
        mandatory: expiring_permit && name === 'Visitor Permit',
        showToolTip: false,
        disabled: disable,
        customInputClass,
        customLabelClass,
        showCount: false,
      },
    ],
    [
      {
        name: 'parking_lot_ids',
        label: 'Parking Lots',
        type: FieldType.MULTISELECT_FIELD,
        showToolTip: false,
        options: [{value: 0, label: 'All Parking Lots'}].concat(parkingLots),
        placeholder: 'Choose a Parking Lot',
        customInputClass,
        customLabelClass,
        mandatory: true,
        showCount: false,
      },
    ],
    [
      {
        name: 'parking_hour_from',
        label: 'Time from',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'parking_hour_to',
        label: 'Time To',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'payment_type',
        label: 'Paid/Free',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'free', label: 'Free' },
          { value: 'paid', label: 'Paid' },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      
      {
      name: 'allow_cancellation',
      label: 'Allow Cancellation',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Please select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      customInputClass,
      customLabelClass,
      mandatory: true,
      disabled: disable,
    },
    {
        name: 'status',
        label: 'Status',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' },
        ],
        customInputClass,
        customLabelClass,
        mandatory: true
      }
    ],
    [
      {
        name: 'hourly_rate',
        label: 'Hourly Rate',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: payment_type === 'paid',
        min: 0.01,
        disabled: payment_type === 'free',
      }
    ],
    [
      {
        name: 'cost',
        label: `${name === 'Visitor Permit' ? 'Minimum' : ''} Cost`,
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: payment_type === 'paid',
        min: 0.01,
        disabled: payment_type === 'free',
      }
    ],
    [
      {
        name: 'cost_per_day',
        label: 'Cost Per Day',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        min: 0.01,
        disabled: payment_type === 'free',
      },
      {
        name: 'cost_per_week',
        label: 'Cost Per Week',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        min: 0.01,
        disabled: payment_type === 'free',
      },
      {
        label: 'Cost Per Month',
        name: 'cost_per_month',
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        min: 0.01,
        disabled: payment_type === 'free',
      },
    ],
    [
      {
        name: 'cost_type',
        label: 'Cost Type',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: [
          {value: 'hourly_rate', label: "Hourly Rate"},
          {value: 'fixed_cost', label: "Fixed Cost"}
        ],
        mandatory: true,
        defaultValue: "hourly_rate",
        showToolTip: false,
        disabled: disable ||  payment_type === 'free',
        customInputClass,
        customLabelClass,
        showCount: false,
      }
    ],
    
  ]);
} 




export default fieldsNew;