import React from 'react';
import PropTypes from 'prop-types';
import DashboardLogin from './dashboard/login';
import SubscriberLayout from './subscriber_layout';
import ValetLayout from './valet_layout';
import Login from './login';
import ValetLogin from './valet_login';
import ValetResetPassword from './valet_reset_password';
import SignUp from './signup';
import LandingPage from './landingpage';
import FindParking from './findparking';
import SendResetPasswordInstructions from './send_reset_password_instructions';
import ConfirmUserAccount from './confirm_user_account'
import AccountConfirmation from './account_confirmation'
import ResetPassword from './reset_password';
import Dashboard from './frame';
import PrivateRoute from 'routes/private_route';
import Layout from 'components/base/layout';
import Cookies from 'js-cookie';
import CompleteRegistration from './login/complete_registration';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AlertMessages } from 'components/helpers/alert_messages';
import { ActionCableProvider } from 'react-actioncable-provider';
import env from '.env';
/* Actions */
import { logOut, subLogOut,valetLogOut, setDashboardToken, setSubscriberToken, setCurrentUserData, setCurrentSubData, setValetToken, setCurrentValetData } from 'actions/users';
import PrivacyPolicyModal from './privacy_policy';
import { logout } from 'api/webapp/subscriber';

class App extends React.Component {
  componentWillMount() {
    const tokenFromCookies = Cookies.get('_session_auth_token');

    if (tokenFromCookies) {
      this.props.setDashboardToken(tokenFromCookies);
      this.props.setSubscriberToken(tokenFromCookies);
      this.props.setValetToken(tokenFromCookies);
      this.props.setCurrentUserData();
      this.props.setCurrentSubData();
      this.props.setCurrentValetData();
    }
  }

  componentDidMount () {
    const { location, history } = this.props;

    if (location.pathname === '/') {
      history.push('/homepage');
    }
  }

  render () {
    const { serverError, serverErrorCritical, history } = this.props;
    if (serverError && serverErrorCritical) {
      if (
        serverError?.response?.data?.error ===
        "User must accept the updated privacy policy to continue."
      ) {
        return <PrivacyPolicyModal history={history} />;
      } else {
        throw new Error(serverError.message);
      }
    }

    return (
      <React.Fragment>
        <ActionCableProvider url={env.backend_cable}>
            <Layout>
              <Route path='/sign_out' render={() => {
                RemoveData.call(this);
                return <Redirect to='/dashboard/login' />;
              }} />
              <Route path='/subscriber/sign_out' render={() => {
                logout();
                RemoveSubData.call(this);
                return <Redirect to='/homepage' />;
              }} />
              <Route path='/valet/sign_out' render={() => {
                RemoveValetData.call(this);
                return <Redirect to='/valet_login' />;
              }} />
              <PrivateRoute path='/dashboard' component={Dashboard} />
              <PrivateRoute path='/subscriber' component={SubscriberLayout} />
              <PrivateRoute path='/valet' component={ValetLayout} />
            </Layout>
          <AlertMessages>
            <Route path="/dashboard/login" component={DashboardLogin}/>
            <Route path="/login" component={Login}/>
            <Route path="/valet_login" component={ValetLogin}/>
            <Route path="/valet_reset_password" component={ValetResetPassword}/>
            <Route path="/complete_registration" component={CompleteRegistration}/>
            <Route path="/signup" component={SignUp}/>
            <Route path="/findparking" component={FindParking}/>
            <Route path="/homepage" component={LandingPage}/>
            <Route path="/forgot_password" component={SendResetPasswordInstructions}/>
            <Route path="/confirm_user_account" component={ConfirmUserAccount}/>
            <Route path="/reset_password/:reset_password_token" component={ResetPassword}/>
            <Route path="/account_confirmation" component={AccountConfirmation}/>
          </AlertMessages>
        </ActionCableProvider>
      </React.Fragment>
    );
  }
}

function RemoveData() {
  removeFilters()
  this.props.logOut();
}

function RemoveSubData() {
  removeFilters()
  this.props.subLogOut();
}

function RemoveValetData() {
  removeFilters()
  this.props.valetLogOut();
}

const removeFilters = () => {
  Object.keys(localStorage).forEach(function (key) {
    if (/^FILTERS_/.test(key)) {
      localStorage.removeItem(key);
    }
  });
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  serverError: PropTypes.object,
  serverErrorCritical: PropTypes.bool,
  setDashboardToken: PropTypes.func.isRequired,
  setCurrentUserData: PropTypes.func.isRequired,
  setSubscriberToken:  PropTypes.func.isRequired,
  setCurrentSubData: PropTypes.func.isRequired,
  setValetToken: PropTypes.func.isRequired,
  setCurrentValetData: PropTypes.func.isRequired,
};

const mapState = state => {
  const { server } = state;
  const { error = {} } = server;
  return { serverError: error.payload, serverErrorCritical: error.critical };
};

function mapDispatch (dispatch) {
  return { ...bindActionCreators({ logOut, subLogOut, valetLogOut, setSubscriberToken, setDashboardToken, setCurrentUserData, setCurrentSubData, setValetToken, setCurrentValetData }, dispatch) };
}

export default connect(mapState, mapDispatch)(App);
