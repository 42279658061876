import Button from 'components/base/button';
import React from 'react'
import { Col } from 'reactstrap';
import styles from './index.module.sass';
import { map } from 'underscore';

const Confirm = (props) => {

  const { deleteScheduleReports, closeModals, deletedEmails } = props;
  
  return (
    <Col className={styles.body}>
      <div className={styles.content}>
        <Col className='mt-3'>
          <span className='ml-4'>Schedulers for below emails will be deleted.</span>
          {map(deletedEmails, (item) => (
            <div className={styles.bulletContent}>
              <Col className='pr-3' xs={1}>{`\u25CF`}</Col>
              <Col className='p-0' style={{textAlign: 'left'}} xs={11}>
                <span className={styles.boldText}>{item}</span>
              </Col>
            </div>
          ))}
        </Col>
      </div>
      <Col className="d-flex justify-content-center my-3">
        <Button onClick={() => closeModals()} type='button' className={`${styles.cancelButton} mr-4`}>
          Cancel
        </Button>
        <Button type='button' onClick={()=>  deleteScheduleReports()} className={styles.submitButton}>
          Confirm
        </Button>
      </Col>
    </Col>
  )
}

export default Confirm;