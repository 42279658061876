import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { update, create } from 'api/clients';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Show from '../show';
import Create from '../create';
import Delete from '../delete';
import Setting from '../settings';

const initState = { errors: {}, isSaving: false, parkingLots: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }  
    default:
      return { ...state };
  }
}

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const ClientModal = (props) => {
  const { openModal, modalType, setActiveTab, selectedRecordId, startFetching, addListElement } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})
  const [updateData, setUpdateData] = useState(null)

  const closeModal = useCallback( () => {
    setState('innerModal', null)
    openModal()
    setErrors({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const innerModalProps = useCallback(() => {
    const width = '480px'
    switch (state.innerModal) {
      case 'delete':
        return {title: 'Delete Client', width : '400px'}
      default:
        return { width };
    }
  },[state.innerModal])

  const updateClient = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({data: data, id: selectedRecordId.id })
      if(response.status === 200 || response.status === 201 )
      {
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: `Changes on ${response?.data?.username} client were successfully saved`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, setErrors, addAlertMessages, closeModal, selectedRecordId])

  const createClient = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data: {admin: data}})
      if(response.status === 200 || response.status === 201 )
      {
        addListElement({setList: setList, data: response.data})
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: `${response?.data?.name} account was successfully created`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
  },[setState, setErrors, addListElement, addAlertMessages, closeModal])

  const modalProps = useCallback(() => {
    let width = '500px'
    switch (modalType) {
      case 'show':
        return {maxWidth : '680px'}
      case 'create':
        return {maxWidth : '600px'}
      case 'delete':
        return {title: 'Delete Account Type', maxWidth : '480px'}
      case 'setting':
        return {title: 'Module Customization', maxWidth : '680px'}
      default:
        break;
    }
    return { width }
  },[modalType])

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null)}}
    />
  },[setState]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.vehicleModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'show' && 
          <Show
            {...props}
            selectedRecordId={selectedRecordId}
            state={state}
            setState={setState}
            closeModal={closeModal}
            updateClient={updateClient}
            setErrors={setErrors}
            errors={errors}
            startFetching={startFetching}
            openInnerModal={() => setState('innerModal', 'confirm')}
            openDeleteModal={() => setState('innerModal', 'delete')}
            setUpdateData={setUpdateData}
            updateData={updateData}
          />
        }
        {
          modalType === 'create' && 
          <Create
            {...props}
            state={state}
            setState={setState}
            closeModal={closeModal}
            createClient={createClient}
            setErrors={setErrors}
            errors={errors}
            startFetching={startFetching}
          />
        }
        {
          modalType === 'setting' && 
          <Setting
            {...props}
            selectedRecordId={selectedRecordId}
            state={state}
            setState={setState}
            closeModal={closeModal}
            updateClient={updateClient}
            setErrors={setErrors}
            errors={errors}
            startFetching={startFetching}
            openInnerModal={() => setState('innerModal', 'confirm')}
            openDeleteModal={() => setState('innerModal', 'delete')}
            setUpdateData={setUpdateData}
            updateData={updateData}
          />
        }
      </ModalBody>
      {!!state.innerModal &&
        <Modal
          isOpen={!!state.innerModal}
          centered
          style={{maxWidth: innerModalProps().width, width: '100%'}}
          contentClassName={styles.modal}
          backdropClassName={styles.backdropC}
        >
          { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
          <ModalBody>
          <p className={`${styles.modalTitle} m-0 p-0`}>{innerModalProps().title}</p>
            {
              state.innerModal === 'delete' && 
              <Delete
                {...props}
                selectedRecordId={selectedRecordId}
                state={state}
                closeModal={closeModal}
                updateClient={updateClient}
              />
            }
          </ModalBody>
        </Modal>
      }
    </Modal>
  )
}

export default ClientModal;
