import React, {useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map, filter } from 'underscore';
/* Base */
import { renderField } from 'components/base/forms/common_form'
import Button from 'components/base/button';
import FormParkingLots from '../formparkingLots';
/* API */
import { search as dropdownSearch } from 'api/dropdowns'
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/permit_types/index'
/* Styles/Assets */
import styles from '../edit/index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import LocationForm from '../location/form/index'
import withFetching from 'components/modules/with_fetching';
import { getParkingLotsByZip, getParkingLotsByLatLong } from 'api/permit_types';
import {exampleData} from 'components/helpers/fields/permit_types/index'
import { capitalize } from 'components/helpers';
import Loader from 'components/helpers/loader';
import { index as parkingLotIndex } from 'api/parking_lots';
import TooltipInfo from 'components/helpers/tooltip_info';

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

const attrs = {
  customLabelClass: `${styles.formLabel}`,
  customInputClass: `${styles.formInput}`,
}

const Edit = (props) => {
  const  { setState, updateRecord, record ,closeModal, errors, setErrors ,isSaving, startFetching, setSpecialParkingLots, specialParkingLots  } = props;
  const formApiRef = useRef();
  const [parkingLots, setParkingLots] = useState()
  const [permitTypes, setPermitTypes] = useState()
  const [currentLocation, setCurrentLocation] = useState(exampleData)
  const [loc, setLoc] = useState(false)
  const [isFetchingLot, setIsFetchingLot] = useState(false)
  const [isFetchingLotUpdated, setIsFetchingLotUpdated] = useState(false)
  const [share, setShare] = useState(record?.sharing)
  const [purposeOfVisit, setPurposeOfVisit] = useState(record?.purpose_of_visit)
  const [residentDetails, setResidentDetails] = useState(record?.details_of_resident)
  const [showHourlyRate, setShowHourlyRate] = useState(record?.hourly_rate ? true : false)

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const singleFieldProps = {
    lSize: 2,
    iSize: 10,
  }

  
  const locationFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {handleLocationChange(e)}
    }

  }
  
  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {handleInutChange(e)}
    }
  }


  const zipFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {fetchParkingLotsByZip()}
    }
  }

  const latlongFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {fetchParkingLotsByLatLong()}
    }
  }


  const costTypeFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {handleCostTypeChange(e); setErrors({})}
    }
  }

  const handleCostTypeChange = (e) => {
    if(e.value === 'hourly_rate'){
      setShowHourlyRate(true)
    }else if (e.value === 'fixed_cost'){
      setShowHourlyRate(false)
    }
  }



  const handleLocationChange = (e) => {
    if(formApiRef.current.getValue('lot_find_by') === 'zip_code'){formApiRef.current.setValue('country', currentLocation.country)}
  }


  const handleInutChange = (e) => {
    if(e?.value === 'free' && e?.label === 'Free') {
      if (formApiRef?.current?.getValue('name') === 'Visitor Permit') {
        if (formApiRef?.current?.getValue('validity').includes('days')) {formApiRef.current.getValue('cost_per_day', '0.0')}
        if (formApiRef?.current?.getValue('validity').includes('weeks')) {formApiRef.current.getValue('cost_per_week', '0.0')}
        if (formApiRef?.current?.getValue('validity').includes('months')) {formApiRef.current.getValue('cost_per_month', '0.0')}
        formApiRef.current.setValue('cost', '0.0')
      } else {
        if(formApiRef?.current?.getValue('expiring_permit') === true){formApiRef.current.setValue('hourly_rate', '0.0')}
        if(formApiRef?.current?.getValue('expiring_permit') === false){formApiRef.current.setValue('cost', '0.0')}
      }
    }
  }
    
  const verifyTimeValues = (formState) => {
    const errors = {}
    const { parking_hour_from: from, parking_hour_to: to, hourly_rate, payment_type, name, parking_lot_ids,validity } = formState;

    if(!name) { errors.name = ['Name can\'t be blank']}
    if(isEmpty(parking_lot_ids)) { errors.parking_lot_ids = ['Please select at least 1 parking lot']}
    if((name==='Visitor Permit' && validity?.includes('days') && !to) ||(name!=='Visitor Permit' && !to)) errors.parking_hour_to = ["Time to can't be blank"]
    if((name==='Visitor Permit' && validity?.includes('days') && !from) ||(name!=='Visitor Permit' && !from)) errors.parking_hour_from = ["Time from can't be blank"]
    if(!!to && !!from && to === from) errors.parking_hour_to = ["Parking hours cannot have the same value"]
    if(!!to && !!from && to < from) errors.parking_hour_from = ["Starting Parking Hour cannot be greater than Final Parking Hour"]
    if(to && !to.match(/^\d{1,2}:\d{1,2}$/)){errors.parking_hour_to = ['Time is invalid']}
    if(from && !from.match(/^\d{1,2}:\d{1,2}$/)){errors.parking_hour_from = ['Time is invalid']}
    if(!!to && !!from && to === from) { errors.parking_hour_to = ["Parking hours cannot have the same value"] }
    if(!!to && !!from && to < from) { errors.parking_hour_from = ["Starting Parking Hour cannot be greater than Final Parking Hour"] }
    if(payment_type === 'paid' && parseFloat(hourly_rate) <= 0) { errors.hourly_rate = ['Hourly rate must be greater than 0'] }
    return errors
  }


  const save = () => {
    setErrors({})
    const {values: formValues} = formApiRef.current.getState();
    const permitTypeName= formApiRef.current.getValue('name')
    const inputErrors = verifyTimeValues(formValues)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    formValues.parking_lots_array = formValues.parking_lot_ids?.map(id => ({id: parseInt(id)})) || [];
    formValues.hourly_rate = `${formValues.hourly_rate}`
    if(["Special Permit", "Disabled Permit", "Government Permit", "Military Permit", "Medical Assistance Permit"].includes(permitTypeName)){
      const parking_lots_array = map(formValues.lots, lot => {
        const { id, allocated_spaces} = lot
        return { id, reserved_spaces: allocated_spaces }
    })
    formValues.parking_lots_array = parking_lots_array;
    }
    formValues.period = formValues?.period
    const updatedValues = {
      ...formValues,
      
      cost: formValues.cost === undefined ? null : `${formValues.cost}`,
      hourly_rate: formValues.hourly_rate === undefined ? null : `${formValues.hourly_rate}`,
      sharing: share ? 'true' : 'false', // Add or update attributes here
    };

    console.log(updatedValues, 'updatedValues')
    updateRecord(updatedValues)
  }

  
  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        setLoc={setLoc}
        loc={loc}
        fetchLocationValuesByZipcode={fetchLocationValuesByZipcode}
        record={record}
      />
    );
  }

  const getFields = (validity, payment_type) => {
    return fieldsNew({mute: true, parkingLots, validity, payment_type,permitTypes,renderLocationModal: renderLocationModal,disable: true, name: record?.name, ...attrs})
  }
  
  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchParkingLotsByZip = async() => {
    const zip = formApiRef.current.getValue('zipcode')
    try {
      const response =  await getParkingLotsByZip(zip)
      if(response.data){setParkingLots(response.data.map((lot, idx) => {return {value: lot?.id , label: capitalize(lot.name)}}))}else{setParkingLots([])}
    } catch (error) {
      setState('errors', error.response.data.errors)
    } finally {
    }  
  }

  const fetchParkingLotsByLatLong = async() => {
    const lat = formApiRef.current.getValue('latitude')
    const long = formApiRef.current.getValue('longitude')
    const distance = formApiRef.current.getValue('distance')
    try {
      const response =  await getParkingLotsByLatLong({ ltd: lat, lng: long , distance: distance})
      if(response.data){setParkingLots(response.data.map((lot, idx) => {return {value: lot?.id , label: capitalize(lot.name)}}))}else{setParkingLots([])}
    } catch (error) {
      setState('errors', error?.response?.data?.errors)
    } finally {
    }  
  }




  const fetchLocationValuesByZipcode = (values) => {
    const findBy = formApiRef.current.getValue('lot_find_by')
    if(findBy === 'zip_code'){formApiRef.current.setValue('country', values.country)
      formApiRef.current.setValue('state', values.state)
      formApiRef.current.setValue('city', values.city)
      formApiRef.current.setValue('zipcode', values.zip)
      fetchParkingLotsByZip()
    }
    if(findBy === 'location_perimeter'){
      formApiRef.current.setValue('latitude', values.ltd)
      formApiRef.current.setValue('longitude', values.lng)
      fetchParkingLotsByLatLong()
    }
  }

  
  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await startFetching(dropdownSearch('permit_type_list'))
        setPermitTypes(permitTypesList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(permitTypes)) fetchPermitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  
  useEffect(() => {
    const fetchParkingLots = async () => {
      try {
        const parkingLotsList = await startFetching(dropdownSearch('permit_parking_lot_list'))
        setParkingLots(parkingLotsList.data)
      } catch (_error) {
      }
    }
   fetchParkingLots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    setState('errors', {})
    const fetchSpecialParkingLots = async (e) => {
      setIsFetchingLot(true)
      if(["Special Permit", "Disabled Permit", "Government Permit", "Military Permit", "Medical Assistance Permit"].includes(record?.name)){
        try {
          const response = await parkingLotIndex({reserved_for: 'special_permits'});
          setSpecialParkingLots( 
            map( 
              filter(response.data, pl => pl.reserved_spaces_count.special_permits >= 1), 
              ({id, reserved_spaces_count, name}) => ({id, reserved_spaces: reserved_spaces_count, name}))
          )
        } catch (_error) {
        }finally {
          setIsFetchingLot(false)
        }
      }
    }
    if(isEmpty(parkingLots)){
      fetchSpecialParkingLots()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    

    record.parking_lot_ids = map(record.permit_parking_lots, 
      (ppl) => (ppl?.parking_lot?.id) 
    );

    const refinedAttrs = () => ({
      allow_cancellation: record.allow_cancellation?.toString(),
      expiring_permit: record.expiring_permit === 'YES' ? true : false,
      name: record?.name,
      hourly_rate: record?.hourly_rate,
      lot_find_by: record?.lot_find_by,
      location: record?.location?.full_address,
      country: record?.location?.country,
      state: record?.location?.state,
      city: record?.location?.city,
      zipcode: record?.location?.zip,
      latitude: record?.location?.ltd,
      longitude: record?.location?.lng,
      cost_type: record?.hourly_rate? 'hourly_rate' : 'fixed_cost',
      minimum_chargeable_time: parseInt(record?.minimum_chargeable_time)
    })
    const { parking_hour_to, parking_hour_from } = record
    record.parking_hour_to = parking_hour_to?.match(/^\d{1,2}:\d{1,2}$/) ? parking_hour_to : ''
    record.parking_hour_from = parking_hour_from?.match(/^\d{1,2}:\d{1,2}$/) ? parking_hour_from : ''
    if(errors.parking_lots_array) {errors.parking_lot_ids = errors.parking_lots_array}

  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={{ ...record, ...refinedAttrs(), validity: record?.name !== 'Visitor Permit' ? !isEmpty(record?.validity)? record?.validity[0] : '' : record?.validity }} onSubmit={save} >

        {({ formState }) => {
            const { validity, payment_type, expiring_permit, name, lot_find_by, parking_lot_ids } = formState.values;
            const newFields = getFields(validity, payment_type, expiring_permit)
            return (
                    <Row className='mx-auto w-100'>
                      { map(newFields[0], (field, idx) => {
                        if(idx === 3) {
                          field = {
                            ...field,
                          }
                        }
                        return (
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                            {idx=== 0 &&
                              renderField(field, { ...singleFieldProps, errors} )}
                            {idx=== 1 &&
                              renderField(field, { ...singleFieldProps, errors} )}
                            {idx=== 2 &&
                              renderField(field, { ...singleFieldProps, errors} )}
                            {(idx === 3) &&
                            <div className={name ==='Residential Permit' ? '' : 'd-none'}>
                              {renderField(field, { ...singleFieldProps, errors} )}
                            </div>
                            }
                            {name ==='Residential Permit' && (idx === 4) &&
                              <React.Fragment>
                               <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']} pl-2`} xs={12}>
                                 {renderField(field, { ...locationFieldProps, errors} )}  
                               </Col>
                              </React.Fragment>
                            }
      
                          </Col>
                          
                        )
                      }) }

                      { map(newFields[1], (field, idx) => {
                        if(idx === 1) {
                          field = {
                            ...field,
                          }
                        }
                        return (
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                           {name ==='Residential Permit' && lot_find_by === 'zip_code' && (idx === 0 || idx === 1 || idx === 2 ) &&
                              renderField(field, { ...doubleFieldProps, errors} )
                            }
                            {name ==='Residential Permit' && lot_find_by === 'zip_code' && (idx === 3 ) && <React.Fragment>
                              {renderField(field, { ...zipFieldProps, errors} )}
                              </React.Fragment>
                            }
                            {name ==='Residential Permit' && lot_find_by === 'location_perimeter' && (idx === 4 || idx === 5 ) &&
                              renderField(field, { ...latlongFieldProps, errors} )
                            }
                            {name ==='Residential Permit' && lot_find_by === 'location_perimeter' && (idx === 6 ) && <React.Fragment>
                              {renderField(field, { ...latlongFieldProps, errors} )}
                              </React.Fragment>
                            }

                            {idx=== 8 && expiring_permit &&
                              renderField(field, { ...doubleFieldProps, errors} )}
                            {name==='Carpool Permit' && idx===9 &&
                              renderField(field, { ...doubleFieldProps, errors} )
                            }
                          </Col>
                        )
                      }) }
      
                      {
                        expiring_permit && <React.Fragment>
                        { map(newFields[2], (field, idx) => {
                        return (
                          <React.Fragment>
                          {idx === 0 && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                             {renderField(field, { ...doubleFieldProps, errors} )}
                          </Col>}
                          {idx === 1 && name !== 'Visitor Permit' && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                            {renderField(field, { ...doubleFieldProps, errors} )}
                          </Col>}
                          {idx === 2 && name === 'Visitor Permit' && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                            {renderField(field, { ...doubleFieldProps, errors} )}
                          </Col>}
                          </React.Fragment>
                        )
                      }) }
                        </React.Fragment>
                      }
                      { map(newFields[3], (field, idx) => {
                        return (
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                            { renderField(field, { ...singleFieldProps, errors} )}
                          </Col>
                        )
                      }) }

                     {["Special Permit", "Disabled Permit", "Government Permit", "Military Permit", "Medical Assistance Permit"].includes(name) && <React.Fragment>
                      <Col className='mb-3 p-0' xs={12}>
                        {isFetchingLot && !isEmpty(parking_lot_ids)  
                          ? <Loader />
                          : <FormParkingLots 
                              selectedLots={parking_lot_ids} 
                              parkingLots={specialParkingLots}
                              formState={formState}
                              formApiRef={formApiRef}
                              permitParkingLots={record?.permit_parking_lots}
                              setParkingLots={setParkingLots}
                              setIsFetchingLotUpdated={setIsFetchingLotUpdated}
                              isFetchingLotUpdated={isFetchingLotUpdated}
                            /> 
                        }
                      </Col>
                        </React.Fragment>
                      }
      
                      { map(newFields[4], (field, idx) => {
                        
                        if(idx === 4) {
                          field = {...field, options: map(field.options, ({value, label}) => ({
                            value,
                            label: <React.Fragment>
                              <FontAwesomeIcon 
                                className='mr-2' 
                                icon={faCircle}
                                style={{ 
                                  color: `${value === 'active' ? 'green' : 'red'}`,
                                  width: 10
                                }}
                               /> 
                              {label}
                            </React.Fragment>
                          }))}
                        }
                        return (
                          <React.Fragment>
                            {name === 'Visitor Permit' && validity?.includes('days') && (idx === 0 || idx === 1) && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {name !== 'Visitor Permit' && (idx === 0 || idx === 1) && typeof(validity) === 'string' && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {(idx === 2 || idx === 3 || idx === 4) && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }

                {name !== 'Visitor Permit' && <React.Fragment>{map(newFields[8], (field, idx) => {
                  return (
                    <React.Fragment>
                      {<Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                        {renderField(field, { ...costTypeFieldProps, errors })}
                      </Col>}
                    </React.Fragment>
                  )
                })}</React.Fragment>}


                      {expiring_permit && showHourlyRate && <React.Fragment>{ map(newFields[5], (field, idx) => {
                        if(idx === 0) {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                        return (
                          <React.Fragment>
                            {name !== 'Visitor Permit' && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }</React.Fragment>}
                      {<React.Fragment>{ map(newFields[6], (field, idx) => {
                        if(idx === 0) {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                        return (
                          <React.Fragment>
                            {(!showHourlyRate || name === 'Visitor Permit') && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }</React.Fragment>}
                      {
                        expiring_permit && <React.Fragment>
                        {name === 'Visitor Permit'&&  map(newFields[7], (field, idx) => {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        return (
                          <React.Fragment>
                            {idx === 0 && validity?.includes('days') && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {idx === 1 && validity?.includes('weeks') && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {idx === 2 && validity?.includes('months') && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }
                        </React.Fragment>
                      }
                      {/* Below code would be used in future */}
                      {/* {!expiring_permit && <React.Fragment>{ map(newFields[6], (field, idx) => {
                        if(idx === 0) {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                        return (
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                            { renderField(field, { ...doubleFieldProps, errors} )}
                          </Col>
                        )
                      }) }</React.Fragment>} */}
                      <Row style={{ display: "flex", width: "100%" }}>
                        <label className={styles.checkboxText}>Space Sharing</label>
                        <input
                          type="checkbox"
                          checked={share}
                          onChange={() => setShare(!share)}
                          className={styles.customCheckStyle}
                        />
                        <span className={styles.checkboxDescription} style={{color: (share ? 'green' : 'red')}}>
                          * Parking space shared is
                        </span>
                        <span className={styles.checkboxDescription1} style={{color: (share ? 'green' : 'red')}}>
                          {share ? 'enabled' : 'disabled'}
                        </span>
                        <TooltipInfo 
                          className={styles.toolTip}
                          text='Enabling this will allow permit holders to share their parking spaces when not using them.'
                          target='share'
                        />
                      </Row>
                      {name === 'Visitor Permit' && <Col className='p-0' style={{ marginTop: '0.5rem' }} xs={12}>
                        <Row style={{ display: "flex", width: "100%" }}>
                          <label className={styles.checkboxText1}>Additional details to ask the applicant</label>
                        </Row>
                        <Row style={{ display: "flex", width: "100%", marginLeft: '1.5rem' }}>
                          <input
                            type="checkbox"
                            checked={purposeOfVisit}
                            onChange={() => setPurposeOfVisit(!purposeOfVisit)}
                            className={styles.customCheckStyle}
                          />
                          <span className={styles.checkboxDescription2}>
                            Purpose of the visit
                          </span>
                        </Row>
                        <Row style={{ display: "flex", width: "100%", marginLeft: '1.5rem', marginTop: '0.5rem' }}>
                          <input
                            type="checkbox"
                            checked={residentDetails}
                            onChange={() => setResidentDetails(!residentDetails)}
                            className={styles.customCheckStyle}
                          />
                          <span className={styles.checkboxDescription2} >
                            Details of the resident the applicant is visiting
                          </span>
                        </Row>
                      </Col>}
                    <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                      <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                        Cancel
                      </Button>
                      <Button type='submit' className={`${styles.button}`}   isLoading={isSaving}>
                        Save
                      </Button>
                    </Col>
                  </Row>
              )
            }
          }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Edit);
