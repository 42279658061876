import React, { useCallback, useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Delete from '../delete';
import { useDispatch, useSelector } from 'react-redux';
import ParkingLots from '../parkingLots';
import Create from '../create';
import Edit from '../edit';
/* Actions */
import { ADD_LIST_ELEMENT, POP_LIST_ELEMENT, SET_LIST_ELEMENT } from 'actions/violation_types';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
/* API */
import { create, destroy, update } from 'api/violation_types';
/* Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const ViolationTypeModal = (props) => {
  const { modalType, openModal  } = props
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState({})

  const record = useSelector(state => state.violation_type.records.record)
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    openModal(null)
  },[openModal])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const popListElement = useCallback((payload) => {
    dispatch({type: POP_LIST_ELEMENT, payload});
  }, [dispatch])

  const addListElement = useCallback((payload) => {
    dispatch({type: ADD_LIST_ELEMENT, payload});
  }, [dispatch]);

  const setListElement = useCallback((payload) => {
    dispatch({type: SET_LIST_ELEMENT, payload});
  }, [dispatch]);


  const deleteViolationType = useCallback(async () => {
    if(record.status === 'active') return 
    setIsDeleting(true)
    try {
      await destroy({id: record.id}) 
      popListElement({id: record.id})
      closeModal()
      addAlertMessages([{
        type: 'danger',
        text: `${record.display_name} violation type was successfully deleted`,
        onlyMessage: true,
      }], 'center')
    } catch (error) {
      console.log(error)
    }finally {
      setIsDeleting(false)
    }

  },[setIsDeleting, popListElement, addAlertMessages, closeModal, record])

  const createViolationType = useCallback(async (data) => {
    setIsSaving(true)
    try {
      const response = await create({data: {violation_type: data}}) 
      addListElement({setList: setList, data: response.data})
      closeModal()
      addAlertMessages([{
        type: 'primary',
        text: `${response.data.display_name} violation type was successfully created`,
        onlyMessage: true,
      }], 'center')
    } catch (error) {
      setErrors(error.response?.data?.errors)
    }finally {
      setIsSaving(false)
    }
  },[setErrors,addListElement, setIsSaving, addAlertMessages, closeModal])

  const editViolationType = useCallback(async (data) => {
    setIsSaving(true)
    try {
      const response = await update({ id: record.id, data: { violation_type: data }}) 
      setListElement(response.data)
      closeModal()
      addAlertMessages([{
        type: 'primary',
        text: `${response.data.display_name} violation type was successfully saved`,
        onlyMessage: true,
      }], 'center')
    } catch (error) {
      setErrors(error.response?.data?.errors)
    }finally {
      setIsSaving(false)
    }
  },[setErrors, record.id, setListElement, setIsSaving, addAlertMessages, closeModal])

  return (
    <Modal 
      isOpen={!!modalType} 
      centered
      className={styles.violationModal}
      style={{maxWidth: 480, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody >
        { modalType === 'delete'
            && <Delete 
              closeModal={closeModal} 
              deleteViolationType={deleteViolationType} 
              violationType={record.display_name} 
              isDeleting={isDeleting}
            />}
        { modalType === 'create'
          && <Create
            closeModal={closeModal} 
            createViolationType={createViolationType} 
            isSaving={isSaving}
            errors={errors}
            setErrors={setErrors}
          />}
        { modalType === 'edit'
          && <Edit
            closeModal={closeModal} 
            editViolationType={editViolationType} 
            isSaving={isSaving}
            errors={errors}
            setErrors={setErrors}
            record={record}
          />}
        { modalType === 'lots' &&
            <ParkingLots 
              lots={record.parking_lots} 
              violationType={record.display_name} 
              closeModal={closeModal}
            />
    
        }
      </ModalBody>
    </Modal>
  )
}

export default ViolationTypeModal;
