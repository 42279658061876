import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Col, Container, Label } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/admins";
import Loader from 'components/helpers/loader';
import { showFields } from 'components/helpers/fields/admins';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { search as dropdownsSearch } from "api/dropdowns";
import { ReactComponent as CloudDownloadIcon } from 'assets/cloud-download.svg'
import { Link } from 'react-router-dom';
import { ReactComponent as TrashIcon } from 'assets/trash_icon.svg';

const Show = (props) => {
  const { startFetching, selectedRecordId, isSaving, updateAdmin, openInnerModal, setUpdateData, errors, setErrors, openDeleteModal, currentUser} = props
  const [isFetching, setIsFetching] = useState()
  const [admin, setAdmin] = useState({})
  const [dropdowns, setDropdowns] = useState()
  const [fileName, setFileName] = useState();
  const fileRef = useRef()
  const [file, setFile] = useState()
  const [image, setImage] = useState(null)

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(
          dropdownsSearch(`role_id?admin_id=${currentUser.id}`)
        ).then((response) => setDropdowns(response.data))
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
    roles: dropdowns
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const applicationData = useCallback((data) => {
    const { id, name, username, phone, email, password, role={}, status,  } = data
    
    return ({  
      id: id,
      name: name,
      username: username,
      phone: phone,
      email: email,
      password: password,
      role_id: role?.id,
      status: status
    })
  },[])

  useEffect(() => {
    if(!isEmpty(selectedRecordId?.avatar)){
      setImage(selectedRecordId.avatar)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordId, setFile, setFileName, setImage, admin])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!selectedRecordId)  return;
      try {
        const response = await show({id: selectedRecordId?.id})
        if(response.status === 200) {
          const { data } = response;
          setAdmin(applicationData(data));
          setImage(response.data.avatar)
        }
      } catch(error) {
      } finally {
        setIsFetching(false)
      }
    };
    fetchCitationTicket();
  }, [selectedRecordId, applicationData, startFetching])

  const validateValues = (formState) => {
    const errors = {}
    const { name, username, email, role_id, status } = formState;
    if(!name) { errors['name'] = ['Name is required']}
    if(!username) { errors['username'] = ['User Name is required']}
    if(!email) { errors['email'] = ['Email is required']}
    if(!role_id) { errors['role_id'] = ['Role is required']}
    if(!status) { errors['status'] = ['Status is required']}
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash = file ? {
      ...values,
      avatar: file,
    } : {
      ...values
    }
    setUpdateData(updatedHash)
    if(values?.password !== undefined){
      openInnerModal()
    }
    else{
      updateAdmin(updatedHash)
    }
  }

  const getBase64= file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        setFile(reader.result);
        resolve(baseURL);
      };
    });
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    }else {
      setFileName(e.target.files[0]?.name)
      setImage(URL.createObjectURL(e.target.files[0]));
      getBase64(e.target.files[0])
    }
  }
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];
      if (validFileTypes.includes(e.dataTransfer.files[0].type)) {
        setFileName(e.dataTransfer.files[0].name)
        setImage(URL.createObjectURL(e.dataTransfer.files[0]));
        getBase64(e.dataTransfer.files[0])
      }
    }
  };

  const fields = () => {
    return showFields({...fieldAttrs})
  }

  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{admin?.username}</p>
      <Form initialValues={admin} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col>
              <div >
                {renderFields(newFields.slice(0, 8), {...singleFieldProps, errors})}
                <div onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} className={styles.uploadField}>
                  <span className={styles.inputLabel} style={{marginLeft: '-628px'}}>
                    Account Picture
                  </span>
                  <span className={styles.uploadContainer} onDragEnter={handleDrag} onDrop={handleDrop} onDragLeave={handleDrag} onDragOver={handleDrag} >
                    <input ref={fileRef} name='id_proof' className='d-none' id='id-proof-file-upload' type='file' accept='.jpg, .png, .jpeg' onChange={showFileName}/>
                    {!image && <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '0.5rem', width: '80%'}}>
                      <span className='mb-2'>
                        {image ? <img className={styles.img} alt="" src={image}/>
                          : <CloudDownloadIcon />}
                      </span>
                      <span>
                        <span className={styles.boldText1}>
                          Add Picture
                        </span>
                        <span className='ml-1'>
                          or drag it here
                        </span>
                      </span>
                      <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
                    </Label>
                    }
                    {image && (<div className='d-flex'>
                    <Label htmlFor='id-proof-file-upload' className={`${styles.dragDropLabel1} d-flex flex-column h-95  text-center p-3`} style={{marginLeft: '0.5rem'}}>
                      <span className='mb-2 mt-2'>
                        <img className={fileName ? styles.img : styles.img1} alt="avatar" src={image}/>
                      </span>
                      <span className={styles.uploadText}>
                        <span className={styles.boldText2}>
                        {fileName}
                        </span>
                      </span>
                    </Label>  
                    <div className={styles.deleteField}>
                      <TrashIcon className={styles.icon} onClick={() =>  {setFileName(); setImage(); setFile()}}/>
                    </div>
                    </div>)
                    }
                  </span>
                </div>
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <Button 
                type='button'
                onClick={()=>  openDeleteModal()}
                className={`${styles.deleteButton} mb-3`}
              >
                Delete
              </Button>
              
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Save
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Show);