import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import Index from 'components/pages/subscribers/index';
import Show from 'components/pages/subscribers/show';
import Rewards from 'components/pages/subscribers/show/rewards/index'
import UserCoupons from 'components/pages/subscribers/show/coupons/index/index'
import UserChargingSessions from 'components/pages/subscribers/show/charging_sessions/index'
import UserPermits from 'components/pages/subscribers/show/permits/index/index'
import vehiclesShow from 'components/pages/subscribers/vehicles/index';
import renderWithBackPath from 'components/modules/render_with_back_path';
import renderWithParentPath from 'components/modules/render_with_parent_path';

const Routing = (props) => {
  const { match } = props;
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Index}/>
      <Switch>
        <Route path={`${match.path}/:id`} render={(props) => (
          <React.Fragment>
            <Route exact path={`${props.match.path}`} component={renderWithBackPath(Show, `${match.url}/`)} />
            <Route exact path={`${props.match.path}/rewards`} render={renderWithParentPath(renderWithBackPath(Rewards, `${match.url}/`), props.match.url)}/>
            <Route exact path={`${props.match.path}/coupons`} render={renderWithParentPath(renderWithBackPath(UserCoupons, `${match.url}/`), props.match.url)}/>
            <Route exact path={`${props.match.path}/charging_sessions`} render={renderWithParentPath(renderWithBackPath(UserChargingSessions, `${match.url}/`), props.match.url)}/>
            <Route exact path={`${props.match.path}/vehicles`} render={renderWithParentPath(renderWithBackPath(vehiclesShow, `${match.url}/`), props.match.url)}/>
            <Route exact path={`${props.match.path}/permits`} render={renderWithParentPath(renderWithBackPath(UserPermits, `${match.url}/`), props.match.url)}/>
          </React.Fragment>
        )} />
      </Switch>
      <Route exact path={`${match.path}/:subscriberId/vehicles/:id`} component={renderWithBackPath(vehiclesShow, match.path)}/>
    </React.Fragment>
  );
};

Routing.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Routing);
