import React, { useCallback, useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filterFetcher } from 'api/parking_sessions';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD  } from 'actions/parking_sessions';
import { invoke } from 'actions';
import AccessControl from 'components/helpers/access-control';
import ToolBar from './toolbar';
import TableData from './tableData';
import { search as dropdownsSearch } from "api/dropdowns";

const resource = 'parking_session';

const Index = (props) => {
  const { currentUser, startFetching } = props
  const [modalType, setModalType] = useState()
  const [activeTab, setActiveTab] = useState()

  const [parkingAdmins, setParkingAdmins] = useState([])
  const [townManagers, setTownManagers] = useState([])
  // eslint-disable-next-line
  const [agencies, setAgencies] = useState([])

  useEffect(() => {
    const fetch = () => {
        Promise.all([
            startFetching(dropdownsSearch('admins_by_role-parking_admin', { admin_id: currentUser.id }))
              .then(response => setParkingAdmins(response.data)),
            startFetching(dropdownsSearch('admins_by_role-town_manager'))
              .then(response => setTownManagers(response.data)),
            startFetching(dropdownsSearch('agency_list'))
              .then(response => setAgencies(response.data)),
        ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const openModal = useCallback((modalType, status) => {
    let val
    // Evaluate modalType
    switch (true) {
      case !['rejected', 'approved', 'cancelled'].includes(status) && modalType === 'reject':
        val = 'reject'
        break;
        case !['rejected', 'approved', 'cancelled'].includes(status) && modalType === 'approve':
          val = 'approve'
          break;
        case modalType === 'view':
          val = 'view'
          break;
        case status === 'pending' && modalType === 'under_review':
          val = 'under_review'
          break;
        case status === 'rejected' && modalType === 'reject_reason':
          val = 'reject_reason'
          break;
      default:
        break;
    }
    setModalType(val)
  },[setModalType])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.PARKING_ADMIN, ROLES.PARKING_OPERATOR]}
    >
      <Container className='mw-100 p-0'>  
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              activeTab={activeTab}  
              setActiveTab={setActiveTab}
              parkingAdmins={parkingAdmins}
              townManagers={townManagers}
              {...props}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              parkingAdmins={parkingAdmins}
              townManagers={townManagers}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
            />
          </Col>
        </Row>
      </Container>
    </AccessControl>
  )
}

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setFilters: invoke(SET_FILTERS), 
      setRecord: invoke(SET_SINGLE_RECORD) 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.parking_session.filters,
    selectedRecord: state.parking_session.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);




