import { FieldType } from "../form_fields";

const updateFields = (attrs) => {
  const { customInputClass='', customLabelClass=''} = attrs;

  return [
    {
      name: 'name',
      label: 'Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'cost',
      label: 'Cost $',
      customInputClass,
      customLabelClass,
      mandatory: true,
      type: FieldType.NUMBER_FIELD,
    },
    {
      name: 'description',
      label: 'Description',
      customInputClass,
      customLabelClass,
      type: FieldType.TEXT_AREA,
      rows: 3
    },
    {
      name: 'status',
      label: 'Enable',
      customInputClass,
      customLabelClass,
      type: FieldType.SELECT_FIELD,
      options: [
        {label: 'Yes', value: 'enabled'},
        {label: 'No', value: 'disabled'}
      ], 
    }
  ]
}

export { updateFields };
      