import { FieldType } from "../form_fields";

const parkingLotField = (attrs) => {
  const {customInputClass, customLabelClass, parkingLots, customToolTipClass, hourly, valet_type} = attrs
  
  return [
    {
      name: "parking_lot_id",
      label: "Parking Lot",
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: parkingLots.map((parkingLot) => {
        return { value: parkingLot.id, label: parkingLot.name }
      }),
      showToolTip: false,
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: "rate",
      label: `${['hourly_static', 'hourly_dynamic'].includes(hourly) ? 'Hourly' : valet_type === 'flat_rate' ? 'Flat' : 'Daily'} rate $`,
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
      min: 1,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: ['hourly_static', 'hourly_dynamic'].includes(hourly) ? 'd-none' : customToolTipClass,
      tooltipEnd: 'The amount that users will be charged for valet parking services.',
    },
    {
      name: "disabled_persons_rate",
      label: `Disabled ${['hourly_static', 'hourly_dynamic'].includes(hourly) ? 'hourly' : valet_type === 'flat_rate' ? 'flat' : 'daily'} rate $`,
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
      min: 1,
    },
    {
      name: "has_charge_for_normal_parking",
      label: "Charge for normal parking?",
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        {label: 'Yes', value: true},
        {label: 'No', value: false}
      ], 
      showToolTip: false,
      customInputClass,
      customLabelClass,
      mandatory: true,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: `Whether users will pay for vehicle parking in addition to the valet parking ${valet_type === 'flat_rate' ? 'flat' : 'daily'} rate. If yes, users will be charged for parking at the rate specified for the particular parking lot.`,
    },
    {
      name: "hourly",
      label: "Hourly model",
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        {label: 'Static', value: 'hourly_static'},
        {label: 'Dynamic', value: 'hourly_dynamic'}
      ], 
      showToolTip: false,
      customInputClass,
      customLabelClass,
      mandatory: true,
      tooltipWidth: '22px',
      tooltipHeight: '22px',
      tooltipClass: customToolTipClass,
      tooltipEnd: 'Static hourly model means that users will be charged the same hourly rate throughout the day for valet parking. Dynamic hourly model allows you to set different hourly rates for different times of the day.',
    },
  ];
};

const pricingModelFields = (attrs) => {
  const {customLabelClass='', customInputClass='', mute=false} = attrs
  return [
    {
      name: 'valet_type',
      customInputClass,
      customLabelClass,
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: mute,
      list: [
        { label: 'Free', value: 'free' },
        { label: 'Flat Rate', value: 'flat_rate' },
        { label: 'Hourly', value: 'hourly' },
        { label: 'Time Blocks', value: 'time_blocks' },
        { label: 'Daily', value: 'daily' }
      ],
    },
  ]   
}

const fieldsCustomHour = (attrs) => {
  const {customLabelClass='', customInputClass='', id, isDisabledRate } = attrs
  
  return [
    {
      name: `parking_valet_rates_attributes[${id}][from]`,
      customInputClass,
      customLabelClass,
      placeholder:'From',
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    },
    {
      name: `parking_valet_rates_attributes[${id}][to]`,
      customInputClass,
      customLabelClass,
      placeholder:'To',
      type: FieldType.SINGLE_HOUR_FIELD,
      mandatory: true,
      disabled: false,
    },
    {
      name: `parking_valet_rates_attributes[${id}][rate]`,
      customInputClass,
      customLabelClass,
      placeholder: 'Rate $',
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
    },
    {
      name: `parking_valet_rates_attributes[${id}][disabled_persons_rate]`,
      customInputClass,
      customLabelClass,
      placeholder: isDisabledRate ? 'N/A': 'Rate $',
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
      disabled: isDisabledRate,
    },

  ]    
};

const fieldsTimeBlock = (attrs) => {
  const {customLabelClass='', customInputClass='', id, isDisabledRate } = attrs
  
  return [
    {
      name: `parking_valet_rates_attributes[${id}][block_duration_hours]`,
      customInputClass,
      customLabelClass,
      placeholder:'Hours',
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
      disabled: false,
      min: 0
    },
    {
      name: `parking_valet_rates_attributes[${id}][rate]`,
      customInputClass,
      customLabelClass,
      placeholder: 'Rate $',
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
      min: 0
    },
    {
      name: `parking_valet_rates_attributes[${id}][disabled_persons_rate]`,
      customInputClass,
      customLabelClass,
      placeholder: isDisabledRate ? 'N/A': 'Rate $',
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
      disabled: isDisabledRate,
      min: 0
    },

  ]    
};

export { parkingLotField, pricingModelFields, fieldsCustomHour, fieldsTimeBlock };