import React from "react";
import { reportFilterFetcher } from "api/reports";
import IndexTable from "components/base/table";
import TableRow from "./table_row";
import styles from './index.module.sass'
import { map } from "underscore";
import { permitFilterFields } from 'components/helpers/fields/system_reports/permit_report_fields'

const TableData = (props) => {
  const { list, match, history, selectedRecord, openModal, modalType, setSelectedRecord, activeTab, filters, tableHeaders, currentFilters, setCurrentFilters, titleName } = props;

  const renderRecords = () => {
    return list?.map((record) => {
      return (
        <TableRow
          {...props}
          key={record.id} 
          record={record} 
          history={history}
          selectedRecord={selectedRecord}
          match={match}
          openModal={openModal}
          modalType={modalType}
          setSelectedRecord={setSelectedRecord}
          setCurrentFilters={setCurrentFilters}
          currentFilters={currentFilters}
        />
      );
    });
  }

  return (
    <IndexTable
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      {...props}
      filterFetcher={reportFilterFetcher}
      paginationQuery={filters}
      showModalFilter={false}
      filterFields={permitFilterFields}
      columns={
        <React.Fragment>
          {map(tableHeaders, (title) => (
            <th disableSort>{title}</th>
          ))}
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName={`${titleName} Reports`}
    />
  )
}

export default TableData;