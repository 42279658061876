import resourceApi from 'components/modules/resource_api';
import { generatePath } from 'react-router';
import fetchApi from 'components/modules/fetch_api';

const { index, show, create } = resourceApi('users');

const filterFetcher = (params = {}) => {
  const { page = 1, perPage = 20, query, filters = {} } = params;

  let from, to, first_name, last_name, phone, email;
  if (filters.range) {
    from = filters?.range?.from || query?.range?.from;
    to = filters?.range?.to || query?.range?.to;
  }

  if (filters.users) {
    first_name = filters?.users?.first_name;
    last_name = filters?.users?.last_name;
    phone = filters?.users?.phone;
    email = filters?.users?.email;
  }

  return index({
    page,
    perPage,
    query: {
      ...query,
      query: {
        users: {
          first_name,
          last_name,
          phone,
          email
        },
      },
      range: {
        from,
        to
      },
      address: filters.address || query?.address,
      permit_number: filters.permit_number || query?.permit_number,
      plate_number: filters.plate_number || query?.plate_number,
      status: filters.status || query?.status,
      id: filters.id || query?.id,
    }
  });
};

const vehicleIndex = (params = {}) => {
  const { id, page, perPage, query, nestedParams = {} } = params;
  return fetchApi(
    generatePath(`dashboard/vehicles?user_id=${id}`, nestedParams),
    { method: 'GET', params: { page, per_page: perPage, ...query } }
  );
};

const vehicleFilterFetcher = (params = {}) => {
  const { id,page, perPage = 20, filters = {}, query } = params;

  return vehicleIndex({
    page,
    perPage,
    id,
    query: {
      ...query,
      ...filters,
    },
  });
};


const update = (params = {}) => {
  const { id, data = {} } = params;
  const { nestedParams } = data
  
  return fetchApi(generatePath(`dashboard/users/${id}`, nestedParams), {
    method: 'PUT',
    data,
  })
};

export { filterFetcher, show, update, create, index, vehicleFilterFetcher };