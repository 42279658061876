import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Header from 'components/base/header';
import WebappHeader from 'components/base/webapp_header';
import { connect } from 'react-redux';
import ValetAppHeader from '../valet_app_header';

class Layout extends React.Component {
  render () {
    return (
      <React.Fragment>
        {(window.location.href.indexOf("dashboard") > -1) ? (this.props.isAuthorized ? <Header /> : null) : window.location.href.includes('subscriber') ? (
          (this.props.isSubAuthorized ? <WebappHeader /> : null)
        ) :(this.props.isValetAuthorized ? <ValetAppHeader /> : null) }
        <Container fluid className="p-0">
          {this.props.children}
        </Container>
      </React.Fragment>
    );
  }
}

const mapState = state => {
  const { isAuthorized } = state.user.auth;
  const { isSubAuthorized } = state.user.subscriberAuth;
  const { isValetAuthorized } = state.user.valetAuth;
  return { isAuthorized, isSubAuthorized, isValetAuthorized  };
};

Layout.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isSubAuthorized: PropTypes.bool.isRequired,
  isValetAuthorized: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(mapState)(Layout);
