import React from "react";
import { Col, Card, CardTitle} from "reactstrap";
import Chart from 'react-apexcharts'
import style from "./data_card.module.sass";

const BarGraph = () => {

  let state = {
    series: [
      {
        color: '#E8EBED',
        name: 'Past',
        data: [44, 55, 41, 67]
      }, 
      {
        color: '#3A9CED',
        name: 'Present',
        data: [13, 23, 20, 8]
      }
    ],
    options: {
      chart: {
        toolbar: {
          show: false
        },      
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: 'end',
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
        }
      },
      toolbar: {
        show: false,
      },
      grid: {
        show: false
      },      
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Overlapping', 'Unpaid', 'Overtime', 'Boot'],
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      },
      fill: {
        opacity: 1,
        colors: ['#888', '#ea3223']
      },
      legend: {
        show: false,
        position: 'bottom',
        offsetY: 40
      },
    },
  }

  return (
    <Chart options={state.options} series={state.series} type="bar" width={400} height={300} style={{marginLeft: '-20px'}} />
  )

}


const ViolationTypeBarGraphCard = (props) => {

  return(
    <React.Fragment>
      <Card className={style.borderPrimary}>
        {
          <React.Fragment>
            <CardTitle className={`${style.cardTitle} row`}>
              <Col className={`${style.title} pr-0`}>
                Violation Types
              </Col>
            </CardTitle>
            <BarGraph 
              {...props}
            />
          </React.Fragment>
        }
      </Card>
    </React.Fragment>
  );
}

export default ViolationTypeBarGraphCard;