import React from "react";

const TableRow = ({record, setSelectedRecord}) => {

  return (
    <tr onClick={() => setSelectedRecord(record)}>
      {Object.values(record).map((value)=>{
        return <td>{value}</td>
      })}
    </tr>
  );
};

export default TableRow;