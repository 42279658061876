import React, {  useEffect, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields, renderFieldsWithGrid } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import withFetching from 'components/modules/with_fetching';
import { eventFields, exampleData, dateTimeDetails } from 'components/helpers/fields/app/purchase_event_parking/purchase';
import LocationForm from '../location/form/index.jsx'
import { getParkingLots } from 'api/webapp/city_events';
import { getTotalCost } from 'api/events';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default"/>
    </span>
  )
};

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

const PurchaseEventParking = (props) => {
  const  { t, setState,state , closeModal, errors, setErrors, isSaving, purchaseEventParking, formApiRef, setFormApi} = props;
  const [nearbyParkingLots, setNearbyParkingLots] = useState([])
  const [currentLocation , setCurrentLocation] = useState(exampleData)
  const [step , setStep] = useState(1)
  const [alertMsg, setAlertMsg] = useState("")
  const [selectedDates, setSelectedDates] = useState([])
  const [selectedParkingLots, setSelectedParkingLots] = useState()
  const [numVehicles, setNumVehicles] = useState()
  const [totalAvailableSpaces, setTotalAvailableSpaces] = useState()

  const rateCostProps = {
    lSize: 7,
    iSize: 5, 
  }

  const handleCheckboxChange = (parkingLot) => {
    setAlertMsg('')
    const selectedParkingLots = state.selectedParkingLots.slice();
    const parkingLotIndex = selectedParkingLots.indexOf(parkingLot);
    if (parkingLotIndex === -1) {
      selectedParkingLots.push(parkingLot);
    } else {
      selectedParkingLots.splice(parkingLotIndex, 1);
    }
    setSelectedParkingLots(selectedParkingLots);
    const totalSelectedSpaces = selectedParkingLots.reduce((total, selected) => {
      return (total + selected.available_spaces_for_event) >= formApiRef?.current?.getValue('number_of_vehicles') ? formApiRef.current.getValue('number_of_vehicles') : total + selected.available_spaces_for_event 
    }, 0);
    const disableCheckboxes = (totalSelectedSpaces >= formApiRef?.current?.getValue('number_of_vehicles')) 
    setState('selectParkingLot', {
      selectedParkingLots,
      totalSelectedSpaces,
      disableCheckboxes,
    });
  };
  
  const fetchNearbyParkingLots = async (values) => {
    setErrors({})
    setSelectedParkingLots([])
    setState('selectParkingLot', { 
      selectedParkingLots:[], 
      totalSelectedSpaces: 0, 
      disableCheckboxes: false 
    })
    setAlertMsg(" ")
    const latitude = values?.ltd;
    const longitude = values?.lng;
    const zipcode = values?.zip;
    try {
      const response = await getParkingLots({ltd: latitude? latitude : currentLocation?.ltd, lng: longitude? longitude : currentLocation.lng, zipcode: zipcode? zipcode : currentLocation?.zip });
      setNearbyParkingLots(response.data);
    } catch (error) {
    }
  };

  
  const validateFields = () => {
    const {
      event_name,
      contact_person_name,
      organizer_email,
      organizer_phone_number,
      number_of_vehicles,
      event_time,
      location,
    } = formApiRef.current.getValues();
    const errors = {};
    // eslint-disable-next-line no-useless-escape
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (step === 1) {
      if (!event_name) {
        errors["event_name"] = [
          t(
            "events.private_events.purchase_event_parking.validations.event_name"
          ),
        ];
      }
      if (!contact_person_name) {
        errors.contact_person_name = [
          t(
            "events.private_events.purchase_event_parking.validations.contact_person_name"
          ),
        ];
      }
      if (!organizer_email) {
        errors.organizer_email = [
          t(
            "events.private_events.purchase_event_parking.validations.organizer_email.required"
          ),
        ];
      }
      if (organizer_email && !organizer_email?.match(isValidEmail)) {
        errors["organizer_email"] = [
          t(
            "events.private_events.purchase_event_parking.validations.organizer_email.format"
          ),
        ];
      }
      if (!organizer_phone_number) {
        errors.organizer_phone_number = [
          t(
            "events.private_events.purchase_event_parking.validations.organizer_phone_number"
          ),
        ];
      }
      if (!number_of_vehicles) {
        errors.number_of_vehicles = [
          t(
            "events.private_events.purchase_event_parking.validations.number_of_vehicles"
          ),
        ];
      }
      if (isEmpty(selectedDates)) {
        errors.event_dates = [""];
        setAlertMsg(
          t(
            "events.private_events.purchase_event_parking.validations.selected_dates"
          )
        );
      }
      if (!event_time) {
        errors.event_time = [
          t(
            "events.private_events.purchase_event_parking.validations.event_time"
          ),
        ];
      }
    }
    if (step === 2) {
      if (!location) {
        errors["location"] = [
          t(
            "events.private_events.purchase_event_parking.validations.location"
          ),
        ];
      }
    }
    return Object.assign(errors, validateDateTimeAttrs());
  };

  const validateTimeAttrs = (attrs = {}, date) => {
    const errors = {};
    const start_time = formApiRef.current.getValue(`event_start_time_${date}`);
    const end_time = formApiRef.current.getValue(`event_end_time_${date}`);
    if (formApiRef.current.getValue(`event_start_time_${date}`) === undefined) {
      errors[`event_start_time_${date}`] = [
        t(
          "events.private_events.purchase_event_parking.validations.start_time"
        ),
      ];
    }
    if (formApiRef.current.getValue(`event_end_time_${date}`) === undefined) {
      errors[`event_end_time_${date}`] = [
        t("events.private_events.purchase_event_parking.validations.end_time"),
      ];
    }
    if (!!end_time && !!start_time && end_time === start_time) {
      errors[`event_end_time_${date}`] = [
        t(
          "events.private_events.purchase_event_parking.validations.time_cannot_be_same"
        ),
      ];
    }
    if (!!end_time && !!start_time && end_time < start_time) {
      errors[`event_start_time_${date}`] = [
        t(
          "events.private_events.purchase_event_parking.validations.start_time_cannot_be_greater_than_end_time"
        ),
      ];
    }
    return errors;
  };

  const validateDateTimeAttrs = (dateTimeAttrs={}) => {
    if(step ===1){let errors = {}
    const timeSelected = formApiRef.current.getValue('event_time')
    if(timeSelected==='custom'){
      map(selectedDates, (date) => {
      
        const fieldErrors = validateTimeAttrs(dateTimeAttrs, date)
        errors = {...errors, ...fieldErrors}
      })
    }else if(timeSelected==='same'){
      const fieldErrors = validateTimeAttrs(dateTimeAttrs, 'all')
      errors = {...errors, ...fieldErrors}
    }
    return errors;
  }
  }

  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,  
    events: {
      onChange: (_e) => setErrors({}) || setAlertMsg('')
    }
  }
  
  const singleFieldProps = {
    lSize: 4,
    iSize: 8, 
    events: {
      onChange: (_e) => setErrors({}) || setAlertMsg('')
    }
  }
  
    
  const timeSelectionFieldProps = {
    lSize: 5,
    iSize: 6,  
    events: {
      onChange: (_e) => setErrors({})
    }
   }

   const timeFieldProps = {
    lSize: 4,
    iSize: 7,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const handleNext = () => {
    setErrors({})
    setAlertMsg('')
    const validationErrors = validateFields()
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors);
      return;
    }else if(step < 2) {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    setErrors({});
    setAlertMsg('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handlePrevious = () => {
    if (step > 1) {
     setStep(step - 1);
    }
  };

  const initialValues = {
    number_of_vehicles: 1,
  }

  const attrs = {   
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDropdownInputClass: styles.formDropdownInput,
    customExpiryInputClass: styles.cardInput,
    customTimeLabelClass: styles.noteLabel,
    customDateInputClass: styles.formDateInput,
    customIconClass: styles.calendarIcon,
    customRadioInputClass: styles.radioInputs,
    t: t    
  }
  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        fetchNearbyParkingLots={fetchNearbyParkingLots}
        t={t}
      />
    );
  }

  const getFields = () => {
    return eventFields({...attrs, renderLocationModal: renderLocationModal.bind(this)})
  }

  useEffect(() => {
    const fetchTotalCost= async () => {
      const {values} = formApiRef.current.getState();
      const parkingLotIds = selectedParkingLots.map((lot) => {return lot?.id})
      const selectedEventDatesTimes = values.event_time ==='custom' ?  selectedDates?.map((date) => {
        return {date: date, start_time: formApiRef.current.getValue(`event_start_time_${date}`), end_time: formApiRef.current.getValue(`event_end_time_${date}`)}
      }) : selectedDates?.map((date) => {
        return {date: date, start_time: formApiRef.current.getValue(`event_start_time_all`), end_time: formApiRef.current.getValue(`event_end_time_all`)}
      })
      
      try {
        const response = await getTotalCost({event_dates_and_times: selectedEventDatesTimes, parking_lot_ids: parkingLotIds, number_of_vehicles: numVehicles})
        formApiRef.current.setValue('event_cost', response.data)
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      } finally {
        
      }
    }
    if(numVehicles && selectedParkingLots){
      fetchTotalCost();
    }
  },[numVehicles,selectedParkingLots, formApiRef, selectedDates])


  useEffect(() => {
    if(step===2){
      formApiRef.current.setValue('selected_days', selectedDates?.length)
    }
  },[step, selectedDates, formApiRef])

 
  const submitValues = (values) => {
    const validationErrors = validateFields()
    if(!isEmpty(validationErrors)){
      if(step === 2 && isEmpty(selectedParkingLots)){setAlertMsg('Please Select Parking Lot')}
      setErrors(validationErrors);
      return;
    }else if(step === 2 && isEmpty(selectedParkingLots)){
      setAlertMsg('Please Select Parking Lot');
      return;
    }
  
    const selectedEventDatesTimes = values.event_time ==='custom' ?  selectedDates?.map((date) => {
      return {date: date, start_time: formApiRef.current.getValue(`event_start_time_${date}`), end_time: formApiRef.current.getValue(`event_end_time_${date}`)}
    }) : selectedDates?.map((date) => {
      return {date: date, start_time: formApiRef.current.getValue(`event_start_time_all`), end_time: formApiRef.current.getValue(`event_end_time_all`)}
    })
  
    const parkingLotIds = selectedParkingLots?.map((lot) => {return lot?.id})
    const data = { 
      number_of_vehicles: values.number_of_vehicles,
      event_dates_and_times: selectedEventDatesTimes,
      parking_lot_ids: parkingLotIds,
      location: currentLocation,
      event_name: values.event_name,
      contact_person_name: values.contact_person_name,
      organizer_email: values.organizer_email,
      organizer_phone_number: values.organizer_phone_number,
      allow_save: 1
    }
    purchaseEventParking(data)
  }
  
  

  const datepickerFieldProps = {
    lSize: 3,
    iSize: 9,
    events: {
      onChange: (_e) => formatSelectedDates()
    }
  }
  
  const formatSelectedDates = () => {
    const formattedDates = formApiRef?.current?.getValue('event_dates')?.map((date) => {
      return `${date?.month?.number < 10 ? `0${date?.month?.number}` : `${date?.month?.number}`}/${date?.day < 9 ? `0${date.day}` : `${date.day}`}/${date?.year}`;
    })
    setSelectedDates(formattedDates)
  }
  
  useEffect(() => {
    const totalParkingSpaces = () => {
      let totalSpaces = nearbyParkingLots?.reduce(function (prev, current) {
        return prev + +current.available_spaces_for_event;
      }, 0);
      setTotalAvailableSpaces(totalSpaces);
    };
    if(!isEmpty(nearbyParkingLots)){
      totalParkingSpaces();
    }
  }, [nearbyParkingLots, selectedParkingLots])
  
  return (
    <Container className={styles.default} style={{fontFamily:'Roboto, sans-serif'}}>
      <p className={`${styles.title}  p-0`}>Purchase Event Parking Permit</p>
      <fieldset disabled={isSaving}>
        <Form initialValues={initialValues}  getApi={setFormApi} className={styles.form}  onSubmit={submitValues} >
          {({ formState }) => {
            const {event_time, location, number_of_vehicles} = formState.values
            setNumVehicles(number_of_vehicles)
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100'>
                <Row className={step === 1 ? `m-0 ${styles['fieldset-double']}   transition-container fade-in` : 'd-none transition-container fade-out'}>
                  <Row className={`m-0 p-0 ${styles.noteLabel}`} xs={12}>
                         <p className={styles.noteLabel}>{t("events.private_events.purchase_event_parking.provide_event_details")}</p>
                  </Row>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                    { renderFields(newFields[0], { ...singleFieldProps, errors} )}
                  </Col>

                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                    { renderFields(newFields[1], { ...singleFieldProps, errors} )}
                  </Col>

                  <Row className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                      <p className={styles.subNoteLabel}>{t("events.private_events.purchase_event_parking.organizer_details")}</p>
                  </Row>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                    { renderFields(newFields[2], { ...singleFieldProps, errors} )}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                    { renderFields(newFields[3], { ...singleFieldProps, errors} )}
                  </Col>
                
                  <Row className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    <p className={styles.noteLabel}>{t("events.private_events.purchase_event_parking.how_many_vehicles_anticipated")}</p>
                  </Row>

                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                    { renderFields(newFields[4], { ...doubleFieldProps, errors, formState} )}
                  </Col>

                  <Row className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    <p className={styles.noteLabel}>{t("events.private_events.purchase_event_parking.what_are_event_dates")}</p>
                  </Row>

                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                  { renderFields(newFields[5], { ...datepickerFieldProps, errors, formState} )}
                    {
                      alertMsg && isEmpty(selectedDates) &&
                      <p className={localStorage.getItem("selectedLanguage") === "english" ? styles.dateAlertMsg : localStorage.getItem("selectedLanguage") === "spanish" ? styles.dateAlertMsgEs : styles.dateAlertMsgHi }>{alertMsg}</p>
                    }
                  </Col>
                  <Row className='d-flex w-100'>
                    <Col className={styles.timenoteLabel} xs={4}><p>{t("events.private_events.purchase_event_parking.what_time")}</p></Col>
                    <Col className={`m-0 p-0 ${styles['fieldset-triple']} pl-3`} xs={6}>
                        {renderFields(newFields[6], { ...timeSelectionFieldProps, errors, formState} )}
                    </Col>
                  </Row>

                  {event_time==='custom' && 
                    map(selectedDates, (date, idx) => (
                      <React.Fragment key={date}>
                          <Row className="d-flex justify-content-space-between align-items-center my-3 pl-2">
                              <Col className='m-0' xs={3}><span className={styles.subdetailsLabel}>{new Date(date).toLocaleDateString('en-in', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' })}</span></Col>
                              <Col className={`m-0  ${styles['fieldset-db']} pl-5`} xs={9}>
                                 {
                                  renderFieldsWithGrid(dateTimeDetails({date: date, customInputClass:styles.formInput1, customLabelClass: styles.formLabel1, t: t}), 2, 6 ,{ ...timeFieldProps, errors })
                                 }
                              </Col>
                          </Row>
                      </React.Fragment>
                    )) 
                   }
                {event_time==='same' && 
                  <Row className='m-0 p-0  pr-1'>
                  {     
                    <React.Fragment key={''}>
                      <Row className="d-flex align-items-center my-3 pl-1">
                        <Col xs={2}><span className={styles.subdetailsLabel}>{t("events.private_events.purchase_event_parking.select_time")}</span></Col>
                        <Col className={`m-0 p-0 d-flex justify-content ${styles['fieldset-db2']} pl-5`} xs={10}>
                          {
                           renderFieldsWithGrid(dateTimeDetails({date: 'all', customInputClass:styles.formInput1, customLabelClass: styles.formLabel1, t: t}), 2, 6 ,{ ...timeFieldProps, errors })
                          }
                        </Col>
                      </Row>
                    </React.Fragment>
                   }
                  </Row>
                }
                </Row>

                <Row className={step === 2 ? `mx-auto w-100` : `d-none`}>
                  <Row className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    <p className={styles.noteLabel}>{t("events.private_events.purchase_event_parking.where_will_event_held")}</p>
                  </Row>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-2`} xs={12}>
                    { renderFields(newFields[7], { ...doubleFieldProps, errors} )}
                  </Col>
                  {location && currentLocation && <Row className={`${styles.spacesNote} d-flex justify-content-center mb-3 mt-1`}>
                    <p className={styles.totalSpacesText}>{t("events.private_events.purchase_event_parking.lots_found_text", {spaces_count: totalAvailableSpaces? totalAvailableSpaces : 0})}</p>
                  </Row>}

                  <Row className={currentLocation && location ? `mx-auto w-100` : 'd-none'}>
                    <Col className="d-flex flex-wrap justify-content w-100">
                      <div className={styles.scrollableContainer}>
                        {nearbyParkingLots?.map((parkingLot) => (
                          <Row
                            key={parkingLot.name}
                            className={`ml-0 mr-0 ${styles.parkingLotBox} ${
                              selectedParkingLots?.includes(parkingLot) ? styles.selectedParkingLot : ''
                            }`}
                            onClick={() =>
                              !state.selectedParkingLots.includes(parkingLot) && state.disableCheckboxes
                                ? ''
                                : handleCheckboxChange(parkingLot)
                            }
                          >
                            <span>
                              <Col className={`ml-0 mr-0 ${styles.parkingLotCell}`}>
                                <div className={styles.checkboxContainer}>
                                  <input
                                    type="checkbox"
                                    checked={selectedParkingLots?.includes(parkingLot)}
                                    onChange={() => handleCheckboxChange(parkingLot)}
                                    disabled={!state.selectedParkingLots.includes(parkingLot) && state.disableCheckboxes}
                                    className={styles.customCheckBox}
                                  />
                                </div>
                                <div className={styles.contentContainer}>
                                  <div className={styles.boxText}>
                                    <span style={{ fontSize: '14px', fontWeight: 500, color: 'grey' }}>
                                      {parkingLot?.name} ({Math.round(((parseFloat(parkingLot?.distance?.split(' ')[0]) * 0.000621371192) + Number.EPSILON) * 100) / 100} mi)
                                    </span>
                                    <Row className={`${styles.addrTxt} pr-1`}>{parkingLot?.location?.full_address}</Row>
                                  </div>
                                  <div className={styles.spacesTxt}>
                                    <span>{parkingLot.available_spaces_for_event}</span>
                                  </div>
                                </div>
                              </Col>
                            </span>
                          </Row>
                        ))}
                        {isEmpty(nearbyParkingLots) && <div style={{padding: "10px", fontSize: "14px",textAlign: "center", fontWeight: 500, fontFamily: "Roboto, sans-serif", color: "#242E42"}}>
                        No Nearby Parking Spaces Found. Please try changing the location.
                      </div>}
                      </div>
                      
                      {alertMsg && isEmpty(selectedParkingLots) && <p className={styles.alertMsg}>{alertMsg}</p>}
                    </Col>
                  </Row>

                  {!isEmpty(nearbyParkingLots) && location && 
                    <Row className={styles.spacesSection}>
                      <Col>
                        <p className={styles.totalSpacesText}>Total Available Spaces: <span className={styles.countNumber}>{totalAvailableSpaces}</span></p>
                      </Col>
                        
                      <Col>
                        <p className={styles.totalBookedSpacesText}>Total Booked Spaces: <span className={styles.countNumber}>{state?.totalSelectedSpaces}</span></p>
                        <p className={styles.totalNumberVehiclesText}>Total Number Of Vehicles: <span className={styles.countNumber}>{number_of_vehicles}</span></p>
                      </Col>
                    </Row>
                  }

                  { map(newFields[8], (field, idx) => {
                    if(idx === 1) {
                      field = {
                        ...field,
                        icon: dollarIcon(), 
                        customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                      }
                    }
                    return (
                      <Col key={idx} className={isEmpty(selectedParkingLots)? 'd-none' : `m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']} pl-2 pt-2 pr-4`} xs={6}>
                      { renderField(field, { ...rateCostProps, errors} )}
                      </Col>
                    )
                  })}
                </Row>
                
                <Col className="d-flex justify-content-center mb-2 mt-3" xs={12}>
                  {step === 2 &&
                  <Button onClick={handlePrevious} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Previous
                  </Button>
                  }
                  { step === 1 &&
                    <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      Cancel
                    </Button>
                  }
                  {step === 1 && 
                    <Button type='button' className={styles.button}  onClick={handleNext}>
                    Next
                    </Button>
                  }
                  {step===2 &&
                  <Button type='submit' className={styles.button}>
                    {isSaving ? btnSpinner({ className: 'spinner-border' }) : "Submit"}
                  </Button>
                  }
                </Col>
                </Row>
              )
            }
          }
        </Form>
      </fieldset>
    </Container>
  )
}


export default withFetching(PurchaseEventParking);