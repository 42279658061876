import React, { useCallback, useState } from 'react';
import { ROLES } from "config/permissions";
import { SET_LIST, SET_LIST_ELEMENT, SET_FILTERS, SET_SINGLE_RECORD} from 'actions/parking_lot_voi';
import { filterFetcher } from 'api/parking_lot_voi';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from "components/helpers/access-control";
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import ToolBar from "components/pages/parking_lots/view/toolbar.jsx";

const Index = props => {
  const [modalType, setModalType] = useState(null)
  const [activeTab, setActiveTab] = useState(null);
  const [selectedTab, setSelectedTab] = useState('voi')
  
  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  const { isResourceFetching, currentUser, match } = props;

  const parkingLotId = match?.params?.id;

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.PARKING_ADMIN, ROLES.PARKING_OPERATOR]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
          <ToolBar
            {...props}
            resource={resource}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFetching={isResourceFetching}
            pageType="voi"
            parkingLotId={parkingLotId}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
            />
          </Col>
        </Row>
      </Container>
    </AccessControl>
  )
};

const resource = 'parking_lot_voi';

const  mapState = (state) => {
  return { 
    filters: state.parking_lot_voi.filters,
    record: state.parking_lot_voi.records.record
  }
}

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
    },
    dispatch
  );
};

export default withRouter(
  connectList(
    resource,
    SET_LIST,
    resourceFetcher(filterFetcher, resource),
    connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  )
)

