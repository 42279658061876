import { FieldType } from "components/helpers/form_fields";
import moment from "moment";

const identificationLable = (identification) => {
  switch (identification) {
    case 'employee_id':
      return 'Employee ID'
    case 'driving_license':
      return 'DLN'
    case 'ssn':
      return 'SSN'
    default:
      return '';
  }
}

const fieldsNew = (attrs) => {
  const { mute, parkingLots=[], customLabelClass='', customInputClass='', payment_type, expiring_permit,
          permitTypes=[],renderLocationModal, disable, customformInput1,customDropdownInputClass, identification,
          initialCountry, countries, statesList, customMultiDateInputClass, customInput1Class, validityDropdown, periodDropdown} = attrs
  
  return ([
    [
      {
        name: 'permit_type_attributes[permit_type_id]',
        label: 'Permit Type',
        placeholder: 'Please select',
        customInputClass: customInput1Class,
        customLabelClass,
        type: FieldType.DROPDOWN_SELECT_FIELD,
        mandatory: true,
        options: permitTypes,
        disabled: mute
      },
      {
        name: 'location',
        label: 'Location',
        mandatory: true,
        render: renderLocationModal,
        disabled: mute
      },
    ],
    [
      {
        name: 'permit_type_attributes[parking_lot_id]',
        label: 'Parking Lots',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        showToolTip: false,
        options: parkingLots.map((val) => ({label: val?.name, value: val?.id})),
        placeholder: 'Choose a Parking Lot',
        customInputClass: customInput1Class,
        customLabelClass,
        mandatory: true,
        showCount: false,
      },
    ],
    [
      {
        name: 'permit_type_attributes[category]',
        label: 'Category',
        type: FieldType.RADIO_BUTTON_FIELD,
        placeholder: 'Please select',
        list: [
          { label: 'Daily Permit', value: 'day_pass' },
          { label: 'Permanent', value: 'permanent' },
          { label: 'Temporary', value: 'temporary' },
        ],
        disabled: mute,
        mandatory: true,
        customInputClass: customInput1Class,
        customLabelClass,
      },
      {
        name: 'permit_type_attributes[commuters_per_vehicle]',
        label: 'Commuters',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        options: [1,2,3,4,5,6,7,8,9,10].map((val) => ({label: val, value: val})),
        mandatory: true,
        disabled: false,
        customInputClass: customInput1Class,
        customLabelClass,
      }
    ],
    [
      {
        name: 'permit_type_attributes[validity]',
        label: 'Validity',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: validityDropdown,
        mandatory: expiring_permit,
        disabled: disable,
        customInputClass: customInput1Class,
        customLabelClass,
        showToolTip: false,
      },
      {
        name: 'permit_type_attributes[period]',
        label: 'Period',
        type: FieldType.DROPDOWN_SELECT_FIELD,
        placeholder: 'Please select',
        options: periodDropdown,
        mandatory: expiring_permit,
        showToolTip: false,
        disabled: disable,
        customInputClass: customInput1Class,
        customLabelClass,
        showCount: false,
      }
    ],
    [
      {
        name: 'permit_type_attributes[parking_hour_from]',
        label: 'Time from',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: true,
      },
      {
        name: 'permit_type_attributes[parking_hour_to]',
        label: 'Time To',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: true,
      },
      {
        name: 'permit_type_attributes[hourly_rate]',
        label: 'Hourly Rate',
        customInputClass,
        customLabelClass,
        mandatory: payment_type === 'paid',
        min: 0,
        disabled: true,
      },
      {
        name: 'permit_type_attributes[cost]',
        label: 'Cost',
        customInputClass,
        customLabelClass,
        mandatory: payment_type === 'paid',
        min: 0,
        disabled: true,
      }
    ],
    [
      {
        name: 'primary_user_attributes[plate_number]',
        label: 'Vehicle LPN',
        customInputClass,
        customLabelClass,
        disabled: false,
        mandatory: false,
      }
    ],
    [
      {
        name: 'primary_user_attributes[id_type]',
        label: 'ID Type',
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: [
          { value: 'driving_license', label: 'Driver\'s License' },
          { value: 'employee_id', label: 'Employee ID' },
          { value: 'ssn', label: 'SSN' },
        ],
        horizontalDisplay: true,
        mandatory: true,
        customInputClass: customDropdownInputClass,
        customLabelClass,  
        disabled: mute,
    
      },
      {
        name: 'primary_user_attributes[id_number]',
        label: identificationLable(identification),
        horizontalDisplay: true,
        mandatory: true,
        customInputClass: customformInput1,
        customLabelClass,
        disabled: mute,

      },
    ],
    [
      {
        name: 'primary_user_attributes[full_name]', 
        label: 'Full Name',
        disabled: mute,
        customInputClass: customformInput1,
        customLabelClass,
        mandatory: true,
        placeholder: 'First Name Last Name'
      },
      {
        name: 'primary_user_attributes[birthday]', 
        label: 'Date of Birth',
        type: FieldType.SUBSCRIBER_DATE_FIELD,
        disabled: mute,
        customInputClass: customformInput1,
        customLabelClass,
        max: moment().subtract(18, "years")._d,
        mandatory: true,
      },
      {
        name: 'primary_user_attributes[home_phone]',  
        label: 'Home Phone',
        disabled: mute,
        type: FieldType.PHONE_NUMBER_FIELD,
        customInputClass: customformInput1,
        customLabelClass,
        mandatory: true,
        initialCountry: initialCountry?.toLowerCase()
      },
      {
        name: 'primary_user_attributes[email]', 
        label: 'Email',
        disabled: mute,
        customInputClass: customformInput1,
        customLabelClass,
        mandatory: true,
        placeholder:"user@example.com"

      },
      {
        name: 'primary_user_attributes[work_phone]', 
        label: 'Work Phone',
        disabled: mute,
        type: FieldType.PHONE_NUMBER_FIELD,
        customInputClass: customformInput1,
        customLabelClass,
        mandatory: true,
        initialCountry: initialCountry?.toLowerCase()

      },
      {
        name: 'primary_user_attributes[home_address]', 
        label: 'Address Line 1',
        disabled: mute,
        customInputClass: customformInput1,
        customLabelClass,
        mandatory: true,
        placeholder:'123 Street Name'
      },
      {
        name: 'primary_user_attributes[address2]', 
        label: 'Address Line 2',
        disabled: mute,
        customInputClass: customformInput1,
        customLabelClass
      },
      {
        name: 'primary_user_attributes[country_code]', 
        label: 'Country',
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        customInputClass: customDropdownInputClass,
        customLabelClass,
        options: countries? countries : [],
        disabled: mute,
        mandatory: true,
        defaultValue:'US'
      },
      {
        name: 'primary_user_attributes[postal_code]', 
        label: 'Zip Code',
        disabled: mute,
        customInputClass: customformInput1,
        customLabelClass,
        mandatory: true
      },
      {
        name: 'primary_user_attributes[state_code]', 
        label: 'State',
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: mute,
        customInputClass: customDropdownInputClass,
        options: statesList? statesList : [],
        customLabelClass,
        mandatory: true,

      },
      {
        name: 'primary_user_attributes[city]', 
        label: 'City',
        disabled: mute,
        customInputClass: customformInput1,
        customLabelClass,
        mandatory: true
      },
      
    ],
    [{ 
      name: 'permit_type_attributes[day_pass_dates]',
      label: "Select Dates",
      type: FieldType.MULTIPLE_DATE_FIELD,
      mandatory: true,
      customInputClass: customMultiDateInputClass,
      customLabelClass,
      disabled: true,
      min: new Date()
    }],
    
  ]);
} 




export { fieldsNew }