import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
import { isEmpty, map, times } from 'underscore'
import { getParkingLots, validateStep2, fetchStatesList, checkParkingLot } from 'api/app/apply_permit';
import { getOwnerDetails } from 'api/permits.js';
import { show } from 'api/permit_types';
import { fieldsIdentification } from 'components/helpers/fields/permit_types/index'
import { fieldsNew } from 'components/helpers/fields/permits/issue_permit'
import { searchV1 as dropdownsSearch } from 'api/dropdowns'
import LocationForm from '../location/form/index.jsx'
import Button from 'components/base/button'
import {exampleData} from 'components/helpers/fields/permit_types/index'
import styles from './index.module.sass'
import { renderField, renderFields } from 'components/base/forms/common_form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'reactstrap';
import { ReactComponent as FetchIcon } from 'assets/refresh_icon.svg';
import dayjs from 'dayjs';
import CommutersCard from 'components/base/commuters_card/index.jsx';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import moment from 'moment';
import { capitalize, displayDate } from 'components/helpers';

const IssuePermit = (props) => {
  const {issueUserPermit, state, closeModal, setErrors, errors, startFetching, openInnerModal, setCommuterId, setUserType, setVManufacturers} = props
  const [primaryUser] = useState()
  const [commuters] = useState({})
  const [step, setStep] = useState(1)
  const [permitTypes, setPermitTypes] = useState()
  // eslint-disable-next-line
  const [permitType, setPermitType] = useState()
  const [permitTypeName, setPermitTypeName] = useState()
  const [currentLocation, setCurrentLocation] = useState(exampleData)
  const [loc, setLoc] = useState(false)
  const [parkingLots, setParkingLots] = useState()
  // eslint-disable-next-line
  const [emailErr, setEmailErr] = useState()
  // eslint-disable-next-line
  const [plateNError, setPlateNError] = useState()
  const [idType, setIDType] = useState()
  const [isFetchingOwner, setIsFetchingOwner] = useState(false)
  const [stateList, setStateList] = useState([])
  const [countries, setCountries] = useState([])
  const [initialCountry, setInitialCountry] = useState('us')
  const [openCardIndex, setOpenCardIndex] = useState(null);
  const [manufacturers, setManufacturers] = useState()
  const [warning, setWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState()
  const [selectedDayPassDates, setSelectedDayPassDates] = useState()
  const [validityDropdown, setValidityDropdown] = useState([])
  const [periodDropdown, setPeriodDropdown] = useState([])
  const [fetchingPermitData, setFetchingPermitData] = useState(true)
  const record = ""
  const [initialValues, setInitialValues] = useState({'permit_type_attributes[category]': 'permanent'})
  const dayPeriod = [1,2,3,4,5,6].map(val => ({value: `${val} ${val === 1 ? 'day' : 'days'}`, label: `${val} ${val === 1 ? 'day' : 'days'}`}))
  const weekPeriod = [1,2,3].map(val => ({value: `${val} ${val === 1 ? 'week' : 'weeks'}`, label: `${val} ${val === 1 ? 'week' : 'weeks'}`}))
  const monthPeriod = [1,3,6].map(val => ({value: `${val} ${val === 1 ? 'month' : 'months'}`, label: `${val} ${val === 1 ? 'month' : 'months'}`}))

  const { isSaving } = state

  const attrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customMultiDateInputClass: styles.customMultiDateInput,
    customInput1Class: styles.formInput2,
    validityDropdown: validityDropdown
  }

  const btnSpinner = (props = {}) => {
    return (
      <span>
        <Spinner {...props} size="sm" color="default"/>
      </span>
    )
  };

  const formApiRef = useRef()

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const singleFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {checkPermitAvailability(e); setUpdatedErrors()}
    }
  }

  const categoryFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {setUpdatedErrors(); handleCategoryChange(e)}
    }
  }

  const permitFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {setWarning(false); fetchNearbyParkingLots(e); setUpdatedErrors(); handlePermitNameChange(e)}
    }
  }

  
  const locationFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {handleLocationChange(e)}
    }

  }
  
  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {handleInutChange(e); setUpdatedErrors()}
    }
  }

  const lpnFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {onChange: (_e) => validatePlateAndEmail()}
  }

  const ownerFieldsProps = {
    lSize: 4,
    iSize: 6,
    events: {onChange: (_e) => setUpdatedErrors()}
  }

  const ownerFields1Props = {
    lSize: 4,
    iSize: 6,
    events: {onChange: (_e) => validatePlateAndEmail()}
  }

  const vehicleFieldProps = {
    lSize: 4,
    iSize: 6,
    events: {
      onChange: (_e) => setUpdatedErrors()
    }
  }

  const countryFieldProps = {
    lSize: 4,
    iSize: 6,
    events: {
      onChange: (_e) => fetchStates()
    }
  }

  const datepickerFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (_e) => {setUpdatedErrors(); formatSelectedDates()}
    }
  }

  const formatSelectedDates = () => {
    const formattedDates = formApiRef?.current?.getValue('permit_type_attributes[day_pass_dates]')?.map((date) => {
      return `${date?.year}-${date?.month?.number < 10 ? `0${date?.month?.number}` : `${date?.month?.number}`}-${date?.day < 10 ? `0${date.day}` : `${date.day}`}`;
    })
    setSelectedDayPassDates(formattedDates)
  }

  const setUpdatedErrors = () => {
    setErrors((prevErrors) => {
      // Define the exact messages we want to match
      const requiredErrors = {
          "primary_user_attributes[plate_number]": [
              "Entered plate number is already present."
          ],
          "primary_user_attributes[email]": [
              "Entered email is already present."
          ]
      };

      // Filter only if exact messages are present
      const filteredErrors = Object.keys(requiredErrors).reduce((acc, key) => {
          if (
              prevErrors[key] &&
              JSON.stringify(prevErrors[key]) === JSON.stringify(requiredErrors[key])
          ) {
              acc[key] = prevErrors[key];
          }
          return acc;
      }, {});

      // Set filtered errors if there are any matches, otherwise set empty object
      return Object.keys(filteredErrors).length > 0 ? filteredErrors : {};
  });

  }
  const openInnModal = (idx, userType) => {
    setCommuterId(idx)
    setUserType(userType)
    openInnerModal('add_vehicle')
  }

  const fetchStates = async () => {
    setUpdatedErrors()
    const country = formApiRef.current.getValue('primary_user_attributes[country_code]')
    formApiRef.current.setValue('primary_user_attributes[state_code]', '')
    try {
      const statesList = await fetchStatesList(country ? country : 'US')
      setStateList(statesList.data)
    } catch (_error) {
      console.log(_error)
    }
  }

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const manufacturersList = await startFetching(dropdownsSearch('manufacturers_list'))
        setManufacturers(manufacturersList.data)
        setVManufacturers(manufacturersList.data)
      } catch (_error) {
        console.log(_error)
      }
    }
    if(isEmpty(manufacturers)) fetchManufacturers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleCategoryChange = async(e) => {
    if(e === 'day_pass') return
    try {
      const permitTypesList = await startFetching(dropdownsSearch('permit_type_list', { category: e }))
        setPermitTypes(permitTypesList.data)
    } catch (_error) {
      console.log(_error)
    }
  }

  const handlePermitNameChange = (e) => {
    setPermitTypeName(e.label)
    const fetchPermit = async () => {
      try {
        const response = await show({id: e.value})
        setPermitType(response.data)
        setInitialValues((prevState) => ({
          ...prevState,
          'permit_type_attributes[parking_hour_from]': response.data.parking_hour_from,
          'permit_type_attributes[parking_hour_to]': response.data.parking_hour_to
        }));
        setFetchingPermitData(false)
        formApiRef.current.setValue('permit_type_attributes[parking_hour_from]', response.data?.parking_hour_from)
        formApiRef.current.setValue('permit_type_attributes[parking_hour_to]', response.data?.parking_hour_to)
        if (response?.data?.hourly_rate) formApiRef.current.setValue('permit_type_attributes[hourly_rate]', response.data?.hourly_rate)
        if (response?.data?.cost) formApiRef.current.setValue('permit_type_attributes[cost]', response.data?.cost)
        formApiRef.current.setValue('permit_type_attributes[validity]', response.data?.validity[0])
        if(response.data?.name === 'Visitor Permit'){
          setValidityDropdown(response.data?.validity.map(val => ({value: val, label: capitalize(val)})))
          if(response?.data?.validity[0] === 'days') setPeriodDropdown(dayPeriod)
          if(response?.data?.validity[0] === 'weeks') setPeriodDropdown(weekPeriod)
          if(response?.data?.validity[0] === 'months') setPeriodDropdown(monthPeriod)
        }
        if(response.data?.name !== 'Visitor Permit') {
          setValidityDropdown(response.data?.validity.map(val => ({value: val, label: capitalize(val)})))
          formApiRef.current.setValue('permit_type_attributes[validity]', response.data?.validity[0])
          setPeriodDropdown(response.data?.period.map(val => ({value: val, label: val})))
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchPermit()
    if(e.value === 'Visitor Permit') formApiRef.current.setValue('category', 'temporary')
  }

  const handleLocationChange = (e) => {
    if(formApiRef.current.getValue('lot_find_by') === 'zip_code') formApiRef.current.setValue('country', currentLocation.country)
  }

  const validatePlateAndEmail = async() => {
    setErrors({})
    const plateNo = formApiRef?.current?.getValue('primary_user_attributes[plate_number]')
    const email = formApiRef?.current?.getValue('primary_user_attributes[email]')
    try {
      // eslint-disable-next-line
      await validateStep2({
        plate_number: plateNo,
        email: email,
      });
      setPlateNError(false);
      setEmailErr(false);
    } catch (error) {
      const updatedError = {}
      if (!isEmpty(error?.response?.data?.errors?.plate_number)) {
        setPlateNError(true);
        updatedError['primary_user_attributes[plate_number]'] = error.response.data?.errors?.plate_number
      }else if(isEmpty(error?.response?.data?.errors?.plate_number)){
        setPlateNError(false);
        updatedError['primary_user_attributes[plate_number]'] = ''
      }
      if (!isEmpty(error?.response?.data?.errors?.email)) {
        setEmailErr(true);
        updatedError['primary_user_attributes[email]'] = error.response.data?.errors?.email
      }else if(isEmpty(error?.response?.data?.errors?.email)){
        setEmailErr(false);
        updatedError['primary_user_attributes[email]'] = ''
      }
      setErrors(updatedError)
    }
  }

  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await startFetching(dropdownsSearch('permit_type_list', { category: 'permanent'}))
        setPermitTypes(permitTypesList.data)
      } catch (error) {
        console.log(error)
      }
    }
    if(isEmpty(permitTypes)) fetchPermitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesList = await startFetching(dropdownsSearch('countries_list'))
        setCountries(countriesList.data)
      } catch (_error) {
        console.log(_error)
      }
    }
    if(isEmpty(countries)) fetchCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const validatePermitAttrs = (attrs={}) => {
    const { permit_type_id, validity, parking_hour_from: from, parking_hour_to: to, payment_type, hourly_rate,
            permit_type_name, commuters_per_vehicle, custom_date_from, custom_date_to, category, parking_lot_id, day_pass_dates} = attrs
    const errors = {}
    
    if(category !== 'day_pass' && !permit_type_id)  errors['permit_type_attributes[permit_type_id]'] = ['Permit Type can\'t be blank']
    if(category !== 'day_pass' && !validity)  errors['permit_type_attributes[validity]'] = ['Validity can\'t be blank']
    if(!category)  errors['permit_type_attributes[category]'] = ['Category can\'t be blank']
    if(category === 'day_pass' && !day_pass_dates)  errors['permit_type_attributes[day_pass_dates]'] = ['Day pass dates can\'t be blank']
    if(!parking_lot_id)  errors['permit_type_attributes[parking_lot_id]'] = ['Please select parking lot']
    if(!custom_date_from && validity === 'custom')  errors['permit_type_attributes[custom_date_from]'] = ['From can\'t be blank']
    if(!custom_date_to && validity === 'custom')  errors['permit_type_attributes[custom_date_to]'] = ['To can\'t be blank']
    if(!commuters_per_vehicle && permit_type_name === 'car_pool')  errors['permit_type_attributes[commuters_per_vehicle]'] = ['Period can\'t be blank']
    if(!hourly_rate && payment_type === 'paid')  errors['permit_type_attributes[hourly_rate]'] = ['Hourly rate can\'t be blank']
    if(!to)  errors['permit_type_attributes[parking_hour_to]'] = ["Time to can't be blank"] 
    if(!from)  errors['permit_type_attributes[parking_hour_from]'] = ["Time from can't be blank"] 
    if(to && !to.match(/^\d{1,2}:\d{1,2}$/)) errors['permit_type_attributes[parking_hour_to]'] = ['Time is invalid']
    if(from && !from.match(/^\d{1,2}:\d{1,2}$/)) errors['permit_type_attributes[parking_hour_from]'] = ['Time is invalid']
    if(!!to && !!from && to === from)  errors['permit_type_attributes[parking_hour_to]'] = ["Parking hours cannot have the same value"] 
    if(!!to && !!from && to < from)  errors['permit_type_attributes[parking_hour_from]'] = ["Starting Parking Hour cannot be greater than Final Parking Hour"] 
    return errors
  }

  const validateUserAttrs = (attrs={}, type) => {
    const errors = {}
    // eslint-disable-next-line
    const regex = /[!@#$%^&*()\-+={}[\]:;"'<>,.?\/|\\]/;
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const usPhoneNumberRegex = /^\+?1[2-9]\d{9}$/;
    const { plate_number, id_number, id_type, full_name, birthday, home_phone, email, work_phone, home_address, country_code, postal_code, state_code, city } = attrs
    if(!plate_number && type === 'primary_user_attributes') { errors[`${type}[plate_number]`] = ['Plate number can\'t be blank']}
    if(!id_number)  errors[`${type}[id_number]`] = ['ID can\'t be blank']
    if(!id_type)  errors[`${type}[id_type]`] = ['Id type can\'t be blank']
    if(!full_name)  errors[`${type}[full_name]`] = ['Full Name can\'t be blank']
    if(full_name && regex.test(full_name)) errors[`${type}[full_name]`] = ['Full Name contains special characters']
    if(!birthday)  errors[`${type}[birthday]`] = ['Birthday can\'t be blank']
    if(birthday > moment().subtract(18, "years")._d) errors[`${type}[birthday]`] = ['Must be 18 years old']
    if(!home_phone)  errors[`${type}[home_phone]`] = ['Home Phone type can\'t be blank']
    if(!email)  errors[`${type}[email]`] = ['Email type can\'t be blank']
    if(email && !(email?.match(isValidEmail))) errors[`${type}[email]`] = ['Email is invalid']
    if(!work_phone)  errors[`${type}[work_phone]`] = ['Work Phone can\'t be blank']
    if(work_phone && !usPhoneNumberRegex.test(work_phone))  errors[`${type}[work_phone]`] = ['Work Phone is invalid']
    if(home_phone && !usPhoneNumberRegex.test(home_phone))  errors[`${type}[home_phone]`] = ['Home Phone is invalid']
    if(!home_address) errors[`${type}[home_address]`] = ['Address Line 1 can\'t be blank']
    if(!country_code)  errors[`${type}[country_code]`] = ['Country can\'t be blank']
    if(!postal_code)  errors[`${type}[postal_code]`] = ['Postal Code type can\'t be blank']
    if(!state_code)  errors[`${type}[state_code]`] = ['State Code can\'t be blank']
    if(!city)  errors[`${type}[city]`] = ['City can\'t be blank']
    return errors
  }

  const checkPermitAvailability = async (lot) => {
    try {
      const response = await checkParkingLot({id: lot?.value, name: permitTypeName});
      if(response.status === 200){
        setWarning(true)
        setWarningMessage('Warning: All permit spaces are sold out on the selected parking lot.')
      }
    } catch (error) {
      console.log(error)
    }
  };

  const validateCommuterAttrs = (commuterAttrs={}, totalComutters=0) => {
    let errors = {}
    if(step === 1) return errors
    times(totalComutters, idx => {
      const fieldErrors = validateUserAttrs(commuterAttrs[`commuter-${idx + 1}`], `commuter_attributes[commuter-${idx + 1}]`)
      errors = {...errors, ...fieldErrors}
    })

    return errors;
  }

  const validateValues = (formState) => {
    const error = {...errors}
    return Object.assign(
      error, 
      validatePermitAttrs(formState?.permit_type_attributes),
      validateUserAttrs(formState?.primary_user_attributes, 'primary_user_attributes'),
      validateCommuterAttrs(formState.commuter_attributes, formState.permit_type_attributes?.commuters_per_vehicle),
    )
  }
  const handleNext = () => {
    setUpdatedErrors()
    const {values} = formApiRef.current.getState();
    const validationErrors = validateValues(values)
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
      return
    }else{
      setStep(2);
    }
  };

  const save = () => {
    setUpdatedErrors()
    const {values} = formApiRef.current.getState();
    const errors = validateValues(values)
    if(!isEmpty(errors)){ 
      setErrors(errors)
      return
    };
    const storageKey = 'allCommutersData';
    const initialFormValues = JSON.parse(localStorage.getItem(storageKey)) || {};
    const commuterVals =  values.commuter_attributes 
    const updatedCommuterAttributes = commuterVals? Object.keys(commuterVals)?.reduce((acc, idx) => {
      const commuterHash = commuterVals[idx];
      const vehicleDetails = Object.keys(initialFormValues[idx]).filter(objKey =>
        objKey !== 'year' && objKey !== 'vehicle_category').reduce((newObj, key) =>
        {
          newObj[key] = initialFormValues[`${idx}`][key];
          return newObj;
        }, {}
      );
      const updatedCommuterHash = {
        ...commuterHash,
        home_address: commuterHash.address1,
        vehicle_attributes: {...vehicleDetails, manufacture_year: initialFormValues[idx]?.year}, // Add or update attributes here
      };
      // Updating the accumulator with the updated commuter hash
      return {
        ...acc,
        [idx]: updatedCommuterHash,
      };
    }, {}) : ''
    const arrayOfCommuters = Object.entries(updatedCommuterAttributes).map(([commuterId]) => (
      updatedCommuterAttributes[commuterId]
    ));

    const data = { 
      permit_type_attributes: {...values.permit_type_attributes, day_pass_dates: selectedDayPassDates, period: values.permit_type_attributes?.period || ''}, 
      primary_user_attributes: {...values.primary_user_attributes, id_number: values.primary_user_attributes?.id_number?.toString()},
      commuters_attributes: arrayOfCommuters,
      issue: true
    }

    issueUserPermit(data)
  }

  const fetchCommuterOwnerDetails = useCallback(async(idType, idNumber, idx) => {
    setIsFetchingOwner(true)
    try {
      const response =  await getOwnerDetails({id_type: idType, id_number:idNumber})
      if(response.data === null){
        toast.error("User Not Found")
      }
      var tempBirthDate = new Date(response?.data?.birthday);
      var formattedBirthday = dayjs(tempBirthDate);
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][full_name]}`,`${response?.data?.first_name} ${response?.data?.last_name}`)
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][birthday]}`, formattedBirthday? formattedBirthday : '')
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][home_phone]}`, `${response.data.home_phone}`)
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][email]}`, response.data.email? response.data.email : '')
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][work_phone]}`, `${response.data.work_phone}`)
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][address1]}`, response?.data?.home_address?.address1)
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][address2]}`, response?.data?.home_address?.address2? response?.data?.home_address?.address2 : '' )
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][state_code]}`, response?.data?.home_address?.state? response?.data?.home_address?.state : '' )
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][postal_code]}`, response?.data?.home_address?.zip)
      formApiRef.current.setValue(`{commuter_attributes[commuter-${idx}][city]}`, response?.data?.home_address?.city)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingOwner(false)
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[idType, formApiRef])

  const renderCommuters = (totalComutters, commuterValues={}) => {
    const initialFormValues = JSON.parse(localStorage.getItem('allCommutersData')) || {};
    const customLabelClass = styles.commuterLabel
    const customInputClass = styles.commuterFormInput
    let allCommuterFields = []
    
    if(totalComutters && totalComutters > 0){
      times(totalComutters, (idx) => {
        const id_type = commuterValues[`commuter-${idx + 1}`]?.id_type
        const userFields = fieldsIdentification(
          true, 
          `commuter_attributes[commuter-${idx + 1}]`, 
          id_type,
          {customInputClass, customLabelClass, customDropdownInputClass: styles.formDropdownInput1, stateList ,countries, initialCountry},
          !isEmpty(commuters[`commuter-${idx + 1}`])
        )
        allCommuterFields.push(userFields)
      })
    }
    if(isEmpty(allCommuterFields)) return
    return <React.Fragment>
      {
        allCommuterFields.map((userFields, idx) => {
          
          return (
            <React.Fragment key={idx}>
              <CommutersCard
                key={idx}
                headerClassName={styles.headerClass}
                className={`${styles.collapsable} w-100 pr-2`}
                index={idx}
                openCardIndex={openCardIndex ? openCardIndex : 0}
                setOpenCardIndex={setOpenCardIndex}
                header={`Commuter ${idx + 1}`}
              >
                {userFields.map((fields, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Col className='d-flex flex-wrap px-0' xs={12}>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(0,1), {...ownerFieldsProps, errors})}
                        </div>
                        {<div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(1,2), {...ownerFieldsProps, errors})}
                      {commuterValues[`commuter-${idx + 1}`]?.id_type && commuterValues[`commuter-${idx + 1}`]?.id_number && index === 0 &&
                      <Button
                        type='button'
                        className={ errors?.id_number ? styles.fetchBtnErrorStyle1 : styles.fetchBtn1}
                        onClick={() => fetchCommuterOwnerDetails(commuterValues[`commuter-${idx + 1}`]?.id_type, commuterValues[`commuter-${idx + 1}`]?.id_number, idx + 1)}
                      >
                        {isFetchingOwner? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}
                      </Button>}
                        </div>}
                      </Col>
                      <Col className='d-flex flex-wrap px-0'>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(2,5), {...ownerFieldsProps, errors})}
                        </div>
                        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(5,8), {...ownerFieldsProps, errors})}
                        </div>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(8,9), {...vehicleFieldProps, errors})}
                        </div>
                        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(9,10), {...countryFieldProps, errors})}
                        </div>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(10,11), {...vehicleFieldProps, errors})}
                        </div>
                        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                          {renderFields(fields.slice(11,12), {...vehicleFieldProps, errors})}
                        </div>
                        <div className={styles.fieldset}>
                          {renderFields(fields.slice(12,13), {...vehicleFieldProps, errors})}
                        </div>
                      </Col>
                    </React.Fragment>
                  );
                })}

                <Button
                  type="button"
                  className={styles.addVehicleBtn}
                  onClick={() =>
                    openInnModal(
                      idx + 1,
                      `vehicle_attributes[commuter-${idx + 1}]`
                    )
                  }
                >
                  {!isEmpty(initialFormValues[`commuter-${idx + 1}`])
                    ? `${initialFormValues[`commuter-${idx + 1}`]?.color} ${
                        manufacturers.find(
                          (m) =>
                            m.value ===
                            initialFormValues[`commuter-${idx + 1}`]
                              ?.manufacturer_id
                        )?.label
                      } ${initialFormValues[`commuter-${idx + 1}`]?.model}`
                    :  'Add Vehicle'}
                  <EditIcon
                    style={{ marginBottom: "5px", marginLeft: "5px" }}
                  />
                </Button>
              </CommutersCard>
            </React.Fragment>
          );
        })
      }
    </React.Fragment>
  }

  const handleInutChange = (e) => {
    if(e?.target?.name === 'permit_type_attributes[payment_type]' && e.target.value === 'free') { 
      formApiRef.current.setValues({'permit_type_attributes': {hourly_rate: '0.0'}})
    }
  }

  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        setLoc={setLoc}
        loc={loc}
        fetchLocationValuesByZipcode={fetchNearbyParkingLots}
        record={record}
      />
    );
  }

  const getFields = (validity, payment_type, expiring_permit, permit_type_id) => {
    return fieldsNew({mute: false,
                      parkingLots,
                      validity,
                      payment_type,
                      permitTypes,
                      expiring_permit,
                      permit_type_id,
                      renderLocationModal: renderLocationModal,
                      ...attrs,
                      identification: idType,
                      countries,
                      statesList: stateList,
                      initialCountry,
                      customDropdownInputClass: styles.formDropdownInput,
                      customformInput1: styles.formInput1,
                      validityDropdown: validityDropdown,
                      periodDropdown: periodDropdown
                    })
  }

  const fetchNearbyParkingLots = async (values) => {
    setUpdatedErrors()
    const latitude = values?.ltd;
    const longitude = values?.lng;
    const zipcode = values?.zip;
    const permitTypeId = formApiRef.current.getValue('permit_type_attributes[permit_type_id]')
    if(!permitTypeId){
      return
    }
    try {
      const response = await getParkingLots({ permit_type_id: permitTypeId, ltd: latitude? latitude : currentLocation?.ltd, lng: longitude? longitude : currentLocation.lng, zipcode: zipcode? zipcode : currentLocation?.zip });
      setParkingLots(response.data);
    } catch (error) {
      console.log(error)
    }
  };

  const fetchOwnerDetails = useCallback(async() => {
    const idNumber = formApiRef.current.getValue('primary_user_attributes[id_number]')
    setIsFetchingOwner(true)
    try {
      const response =  await getOwnerDetails({id_type: idType, id_number:idNumber})
      if(response.data === null){
        toast.error("User Not Found")
      }
      var tempBirthDate = new Date(response?.data?.birthday);
      var formattedBirthday = dayjs(tempBirthDate);
      formApiRef.current.setValue('full_name',`${response?.data?.first_name} ${response?.data?.last_name}`)
      formApiRef.current.setValue('birthday', formattedBirthday? formattedBirthday : '')
      formApiRef.current.setValue('home_phone', `${response.data.home_phone}`)
      formApiRef.current.setValue('email', response.data.email? response.data.email : '')
      formApiRef.current.setValue('work_phone', `${response.data.work_phone}`)
      formApiRef.current.setValue('home_address', response?.data?.home_address?.address1)
      formApiRef.current.setValue('address2', response?.data?.home_address?.address2? response?.data?.home_address?.address2 : '' )
      formApiRef.current.setValue('state_code', response?.data?.home_address?.state? response?.data?.home_address?.state : '' )
      formApiRef.current.setValue('postal_code', response?.data?.home_address?.zip)
      formApiRef.current.setValue('city', response?.data?.home_address?.city)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetchingOwner(false)
    }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[idType, formApiRef])

  const renderIDFields = (fields) => {
    return(
      <Col className='d-flex flex-wrap px-0' xs={12}>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,1), {...ownerFieldsProps, errors})}
        </div>
        {idType && <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(1,2), {...ownerFieldsProps, errors})}
        </div>}
      </Col>
    )
  }

  const renderVehicleOwnerFields = (fields) => {
    
    return(
      <Col className='d-flex flex-wrap px-0'>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(0,3), {...ownerFieldsProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(3,4), {...ownerFields1Props, errors})}
          {renderFields(fields.slice(4,6), {...ownerFieldsProps, errors})}
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(6,7), {...vehicleFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(7,8), {...countryFieldProps, errors})}
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(8,9), {...vehicleFieldProps, errors})}
        </div>
        <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
          {renderFields(fields.slice(9,10), {...vehicleFieldProps, errors})}
        </div>
        <div className={styles.fieldset}>
          {renderFields(fields.slice(10,11), {...vehicleFieldProps, errors})}
        </div>
      </Col>
    )
  }


  useEffect(() => {
    if(isEmpty(primaryUser)) {return}

    formApiRef.current.setValues({ 'primary_user_attributes': primaryUser })
  },[primaryUser])

  useEffect(() => {
    formApiRef.current.setValues({'commuter_attributes': commuters})
  },[commuters])

  return (
    <div>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} initialValues={initialValues} className={styles.form} onSubmit={save} >
        {({formState}) => {
          const {permit_type_attributes, primary_user_attributes, country_code} = formState.values || {}
          const newFields = getFields(permit_type_attributes?.validity, permit_type_attributes?.payment_type, permit_type_attributes?.permit_type_id)
          setIDType(primary_user_attributes?.id_type)
          setInitialCountry(country_code)
          return (
            <Row className='mx-auto w-100'>
              
              <Row className={step === 1 ? '' : 'd-none'}>
                <Col className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                  {renderFields(newFields[2].slice(0,1), { ...categoryFieldProps, errors} )}
                </Col>
                { map(newFields[0], (field, idx) => {
                  if(idx === 3) {
                    field = {
                      ...field,
                    }
                  }
                  return (
                    <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                      {idx=== 0 && permit_type_attributes?.category && permit_type_attributes?.category !== 'day_pass' &&
                        renderField(field, { ...permitFieldProps, errors} )
                      }                    
                      {idx === 1 &&
                        <React.Fragment>
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']} pl-2`} xs={12}>
                            {renderField(field, { ...locationFieldProps, errors} )}  
                          </Col>
                        </React.Fragment>
                      }

                    </Col>
                    
                  )
                }) }
                { map(newFields[1], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                      { renderField(field, { ...singleFieldProps, errors} )}
                    </Col>
                  )
                })}
                {permitTypeName==='Carpool Permit' && <Col className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                  {renderFields(newFields[2].slice(1,2), { ...doubleFieldProps, errors} )}
                </Col>}
                { permit_type_attributes?.category === 'day_pass' && 
                  <Col className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                    { renderFields(newFields[8], { ...datepickerFieldProps, errors} )}
                  </Col>
                }  
                {permit_type_attributes?.category === 'day_pass' && selectedDayPassDates &&
                  <Col className={`m-0 p-0 pb-4 ${styles.selectedDatesRow}`} xs={12}>
                    <Col className={styles.selectedDaysLabelSection} xs={3}>
                      <p className={styles.selectedDaysLabel}>Selected Days</p>
                    </Col>
                    <Col className={styles.selectedDaysFieldSection} xs={9}>
                      {selectedDayPassDates?.map((d, idx) => {
                        return (
                          <React.Fragment>
                            <Col className={`p-0 mr-0 ${styles.dayFieldContent}`} xs={6}>
                              <Col className={styles.iconSection} xs={2}>
                                <FontAwesomeIcon className={styles.dotIcon} icon={faCircle} color='green'/> 
                              </Col>
                              <Col className={styles.dateContent} xs={10}>
                                <span className={styles.dateValue}>{displayDate(d, 'MMMM Do YYYY')}</span>
                              </Col>
                            </Col>
                          </React.Fragment>
                        )
                      })}
                    </Col>
                  </Col>
                }
                { permit_type_attributes?.category && permit_type_attributes?.category !== 'day_pass' && map(newFields[3], (field, idx) => {
                  return (
                    <React.Fragment>
                    {idx === 0 && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                        {renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>}
                    {idx === 1 && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                      {renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>}
                    </React.Fragment>
                  )
                }) }
                { map(newFields[4], (field, idx) => {
                  if(idx === 4) {
                    field = {...field, options: map(field.options, ({value, label}) => ({
                      value,
                      label: <>
                        <FontAwesomeIcon 
                          className='mr-2' 
                          icon={faCircle}
                          style={{ 
                            color: `${value === 'active' ? 'green' : 'red'}`,
                            width: 10
                          }}
                          /> 
                        {label}
                      </>
                    }))}
                  }
                  return (
                    <React.Fragment>
                      {(idx === 0 || idx === 1) && !isEmpty(permitType) && !fetchingPermitData &&<Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                        { renderField(field, { ...doubleFieldProps, errors} )}
                      </Col>}  

                      {( permitType?.cost &&  idx === 3) && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                        { renderField(field, { ...doubleFieldProps, errors} )}
                      </Col>}
                      {(permitType?.hourly_rate &&  idx === 2 ) && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                        { renderField(field, { ...doubleFieldProps, errors} )}
                      </Col>}
                    </React.Fragment>
                  )
                }) }
                <Col className={`m-0 p-0 ${styles['fieldset-single']}`} xs={12}>
                  { renderFields(newFields[5], { ...lpnFieldProps, errors} )}
                </Col>
                <Col className="m-0 p-0  pt-2" xs={12}>
                  {renderIDFields(newFields[6])}
                </Col>

                {primary_user_attributes?.['id_type'] && primary_user_attributes?.['id_number'] &&
                  <Button type='button' className={ errors?.id_number ? styles.fetchBtnErrorStyle : styles.fetchBtn}  onClick={fetchOwnerDetails}>{isFetchingOwner? btnSpinner({ className: 'spinner-border' }) : <FetchIcon/>}</Button>
                }
            
                <Col className="m-0 p-0" xs={12}>
                  {renderVehicleOwnerFields(newFields[7])}
                </Col>
              </Row>
              
              <Row className={step === 2 ? '' : 'd-none'}>
                { renderCommuters(permit_type_attributes?.commuters_per_vehicle, formState?.values?.commuter_attributes) }
              </Row>

              {warning && <div className={step === 1 ? styles.warning : 'd-none'}>
                {warningMessage}
              </div>}

              <Col className="d-flex justify-content-center my-3">
                {step === 1 && <Button onClick={closeModal} type='button' className={`${styles.cancelButton} mr-4`}>
                  Cancel
                </Button>}
                {step === 2 && <Button onClick={() => setStep(1)}  type='button' className={`${styles.cancelButton} mr-4`}>
                  Previous
                </Button>}
                {(permitTypeName === 'Carpool Permit' && step === 1) && <Button type='button' className={styles.submitButton} onClick={handleNext}  isLoading={isSaving}>
                  Next
                </Button>} 
                {(step === 2 || permitTypeName !== 'Carpool Permit') && <Button type='submit' className={styles.submitButton}   isLoading={isSaving}>
                  Issue
                </Button>}
              </Col>

              </Row>
          )
        }}
        </Form>
      </fieldset>
    </div>
  )
}

export default IssuePermit