import React, { useEffect, useState } from 'react';
import ThemeProvider from 'subscriberThemeProvider';
import WebAppHeader from 'components/base/webapp_header';
import { show } from 'api/webapp/permits';
import Loader from 'components/helpers/loader';
import styles from './index.module.sass';
import { capitalize } from 'components/helpers';
import { isEmpty } from 'underscore';
import { useTranslation } from 'react-i18next';

const PermitDetail = ({ match }) => {
  const [permit, setPermit] = useState({});
  const [isFeteching, setIsFetching] = useState(false);
  const { t } = useTranslation();

  const fetchPermitDetail = async () => {
    setIsFetching(true);
    try {
      const response = await show({
        id: match?.params?.id,
        online_portal: true,
      });
      if (response.status === 200) {
        setPermit(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!match.params?.id) return;
    fetchPermitDetail();

    // eslint-disable-next-line
  }, [match.params]);

  return (
    <ThemeProvider>
      <WebAppHeader />
      <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
        {isFeteching ? (
          <Loader />
        ) : (
          <div className={styles.container}>
            <div className={styles.contentContainer}>
              {isEmpty(permit) ? (
                <p className={styles.error}>{t('no_results_found')}</p>
              ) : (
                <React.Fragment>
                  <p className={styles.header}>{t('permit_detail.heading')}</p>
                  <div className="row mb-2">
                    <div className="col-6">
                      <p className={`${styles.detailHeader} font-weight-bold`}>{t('permit_detail.permit_status')}</p>
                    </div>
                    <div className="col-6">{capitalize(permit?.status)}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-6">
                      <p className={`${styles.detailHeader} font-weight-bold`}>{t('permit_detail.permit_type')}</p>
                    </div>
                    <div className="col-6">{permit?.permit_type}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-6">
                      <p className={`${styles.detailHeader} font-weight-bold`}>{t('permit_detail.vehicle_plate_number')}</p>
                    </div>
                    <div className="col-6">{permit?.vehicle?.plate_number}</div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default PermitDetail;
