import { FieldType } from "components/helpers/form_fields";
import moment from "moment";
const identificationLable = (identification, t) => {
  switch (identification) {
    case 'employee_id':
      return t("permit_application.apply_for_permit.labels.step2.employee_id")
    case 'driving_license':
      return t("permit_application.apply_for_permit.labels.step2.dln")
    case 'ssn':
      return t("permit_application.apply_for_permit.labels.step2.ssn")
    default:
      return '';
  }
}
const permitFields = (attrs) => {
  const {
    mute,
    permitTypes = [],
    periodOptions = [],
    identification,
    customVehicleInputClass,
    customDropdownInputClass = "",
    customLabelClass = "",
    customInputClass = "",
    renderLocationModal,
    countries,
    statesList,
    vehicles,
    years,
    customExpiryDropdownInputClass,
    customExpiryLabel,
    cutomExpiryLabel2,
    initialCountry,
    t,
    months,
    permitType,
    validityOptions,
    customSingleInputClass,
    propertyTypeList,
    residenceTypeList,
    selectedPeriod
  } = attrs;
  
  return [
    [{ 
      name: 'permit',
      label: 'Permit Options:',
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      customInputClass,
      customLabelClass,
      options: [
        { label: 'Ordinary Permit', value: 'ordinary_permit' },
        { label: 'Special Permit', value: 'special_permit' },
        { label: 'Car Pool Permit', value: 'carpool_permit' },
      ], 
    }],[
    {
        name: 'location',
        label: t("permit_application.apply_for_permit.labels.step1.location.label") ,
        mandatory: true,
        render: renderLocationModal,
        disabled: false,
        customInputClass,
        customLabelClass,
        
      }],

      [{ 
        name: 'category',
        label: `${t("permit_application.apply_for_permit.labels.step1.select_permit_category.label")}:`,
        mandatory: true,
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        customInputClass: customDropdownInputClass,
        customLabelClass,
        options: [
          { label: 'Permanent', value: 'permanent' },
          { label: 'Temporary', value: 'temporary' },
          { label: 'Non-Expiring', value: 'non_expiring' },
        ], 
        disabled: false
      }],
    
  
    [{ 
      name: 'permit_type_id',
      label: `${t("permit_application.apply_for_permit.labels.step1.select_permit_type.label")}:`,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      customInputClass: customDropdownInputClass,
      customLabelClass,
      options: permitTypes? permitTypes : [],
      mandatory: true,
      defaultValue: '',
      disabled: false

    }],
    [
      {
        name: 'validity',
        label: `${t("permit_application.apply_for_permit.labels.step1.validity.label")}:`,      
        mandatory: true,
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: permitType?.name === 'Visitor Permit' ? false : true,
        options: validityOptions,
        customInputClass: customDropdownInputClass,
        customLabelClass,
      },
      {
        name: 'period',
        label: `${t("permit_application.apply_for_permit.labels.step1.period.label")}:`,
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: t("permit_application.apply_for_permit.please_select"),
        options: periodOptions,
        mandatory: true,
        showToolTip: false,
        disabled: false,
        customInputClass: customDropdownInputClass,
        customLabelClass,
        showCount: false,
      },
    ],
    [
      {
        name: 'parking_hour_from',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: permitType?.name === 'Visitor Permit' && selectedPeriod === '1 day' ? false : true,
        label: `${t("permit_application.apply_for_permit.labels.step1.time_from")}:`
      },
      {
        name: 'parking_hour_to',
        type: FieldType.SINGLE_HOUR_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        disabled: permitType?.name === 'Visitor Permit' && selectedPeriod === '1 day' ? false : true,
        label: `${t("permit_application.apply_for_permit.labels.step1.time_to")}:`
      },
      {
        name: 'hourly_rate',
        label: `${t("permit_application.apply_for_permit.labels.step1.hourly_rate")}:`,
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled:true      
      },
      {
        name: 'total_cost',
        label: `${t("permit_application.apply_for_permit.labels.step1.total_cost")}:`,
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled:true,
      },
      {
        name: 'applied_from_date',
        label: `${t("permit_application.apply_for_permit.labels.step1.applied_from_date.label")}:`,
        type: FieldType.SUBSCRIBER_DATE_FIELD,
        placeholder: t("permit_application.apply_for_permit.labels.step1.applied_from_date.placeholder"),
        customInputClass,
        customLabelClass,
        disabled:false,
        min: new Date(),
        disabledDate: true,
      },
    ],
    [
      {
        name: 'plate_number',
        label: t("permit_application.apply_for_permit.labels.step2.vehicle_lpn.label"),
        customInputClass: customVehicleInputClass,
        customLabelClass,
        disabled: false,
        mandatory: false,
      }
    ],
    [
      {
        name: 'id_type',
        label: t("permit_application.apply_for_permit.labels.step2.id_type.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: [
          { value: 'driving_license', label: 'Driver\'s License' },
          { value: 'employee_id', label: 'Employee ID' },
          { value: 'ssn', label: 'SSN' },
        ],
        horizontalDisplay: true,
        mandatory: true,
        customInputClass: customDropdownInputClass,
        customLabelClass,  
        disabled: mute,
    
      },
      {
        name: 'id_number',
        label: identificationLable(identification, t),
        horizontalDisplay: true,
        mandatory: true,
        customInputClass,
        customLabelClass,
        disabled: mute,

      },
    ],
    [
      {
        name: 'full_name', 
        label: t("permit_application.apply_for_permit.labels.step2.full_name.label"),
        disabled: mute,
        customInputClass,
        customLabelClass,
        mandatory: true,
        placeholder: `${t("permit_application.apply_for_permit.labels.step2.first_name")} ${t("permit_application.apply_for_permit.labels.step2.last_name")}`
      },
      {
        name: 'birthday', 
        label: t("permit_application.apply_for_permit.labels.step2.date_of_birth.label"),
        placeholder:  t("permit_application.apply_for_permit.labels.step2.date_of_birth.placeholder"),
        type: FieldType.SUBSCRIBER_DATE_FIELD,
        disabled: mute,
        customInputClass,
        customLabelClass,
        max: moment().subtract(18, "years")._d,
        mandatory: true,
      },
      {
        name: 'home_phone',  
        label: t("permit_application.apply_for_permit.labels.step2.home_phone.label"),
        disabled: mute,
        type: FieldType.PHONE_NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        initialCountry: initialCountry?.toLowerCase()
      },
      {
        name: 'email', 
        label: t("permit_application.apply_for_permit.labels.step2.email.label"),
        disabled: mute,
        customInputClass,
        customLabelClass,
        mandatory: true,
        placeholder:"user@example.com"

      },
      {
        name: 'work_phone', 
        label: t("permit_application.apply_for_permit.labels.step2.work_phone.label"),
        disabled: mute,
        type: FieldType.PHONE_NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        initialCountry: initialCountry?.toLowerCase()

      },
      {
        name: 'address1', 
        label: t("permit_application.apply_for_permit.labels.step2.address1.label"),
        disabled: permitType?.name === 'Visitor Permit' ? false : mute,
        customInputClass,
        customLabelClass,
        mandatory: permitType?.name === 'Visitor Permit',
        placeholder:'123 Street Name'
      },
      {
        name: 'address2', 
        label: t("permit_application.apply_for_permit.labels.step2.address2"),
        disabled: permitType?.name === 'Visitor Permit' ? false : mute,
        customInputClass,
        customLabelClass
      },
      {
        name: 'country_code', 
        label: t("permit_application.apply_for_permit.labels.step2.country.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        customInputClass: customDropdownInputClass,
        customLabelClass,
        options: countries? countries : [],
        disabled: permitType?.name === 'Visitor Permit' ? false : mute,
        mandatory: true,
        defaultValue:'US'
      },
      {
        name: 'postal_code', 
        label: t("permit_application.apply_for_permit.labels.step2.zip_code.label"),
        disabled: permitType?.name === 'Visitor Permit' ? false : mute,
        customInputClass,
        customLabelClass,
        mandatory: true
      },
      {
        name: 'state_code', 
        label: t("permit_application.apply_for_permit.labels.step2.state.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: permitType?.name === 'Visitor Permit' ? false : mute,
        customInputClass: customDropdownInputClass,
        options: statesList? statesList : [],
        customLabelClass,
        mandatory: true,

      },
      {
        name: 'city', 
        label: t("permit_application.apply_for_permit.labels.step2.city.label"),
        disabled: permitType?.name === 'Visitor Permit' ? false : mute,
        customInputClass,
        customLabelClass,
        mandatory: true
      },
      {
        name: 'resident_full_name', 
        label: t("permit_application.apply_for_permit.labels.step2.resident_full_name.label"),
        disabled: permitType?.name === 'Visitor Permit' ? false : mute,
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'resident_contact_number', 
        label: t("permit_application.apply_for_permit.labels.step2.resident_contact_number.label"),
        type: FieldType.PHONE_NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        mandatory: true,
        initialCountry: initialCountry?.toLowerCase(),
      },
      {
        name: 'type_of_property', 
        label: t("permit_application.apply_for_permit.labels.step2.type_of_property.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: mute,
        customInputClass: customDropdownInputClass,
        options: propertyTypeList? propertyTypeList : [],
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'type_of_residence', 
        label: t("permit_application.apply_for_permit.labels.step2.type_of_residence.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        disabled: mute,
        customInputClass: customDropdownInputClass,
        options: residenceTypeList? residenceTypeList : [],
        customLabelClass,
        mandatory: true,

      },
    ],
    [
      {
        name: 'permit_type',
        label: t("permit_application.apply_for_permit.labels.step3.permit_type"),
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'permit_cost',
        label: t("permit_application.apply_for_permit.labels.step3.permit_cost"),
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'coupon_discount',
        label: t("permit_application.apply_for_permit.labels.step3.coupon_discount"),
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      {
        name: 'payment_amount',
        label: t("events.city_events.get_pass_form.payment.total_payable_amount"),
        type: FieldType.NUMBER_FIELD,
        customInputClass,
        customLabelClass,
        disabled: true,
      },
      
      {
        name: 'payment_methods',
        label: t("events.city_events.get_pass_form.payment.payment_method.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        options: [{
          "value": 0,
          "label": "Google pay"
        },
        {
          "value": 1,
          "label": "Credit Card"
        }].map(({ value, label }) => {
          return { value, label };
        }),
        customInputClass: customDropdownInputClass,
        customLabelClass,
        mandatory: true,
      }
    ],
    [      
      {
        name: 'expiration_year',
        label: t("events.city_events.get_pass_form.payment.credit_card.expiration_year.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: t("events.city_events.get_pass_form.payment.credit_card.expiration_year.placeholder"),
        options: years? years.map((y) => { return {value: y , label: y}}): [],
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: cutomExpiryLabel2,
        disabled: false,
      },
      {
        name: 'expiration_month',
        label: t("events.city_events.get_pass_form.payment.credit_card.expiration_month.label"),
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder: t("events.city_events.get_pass_form.payment.credit_card.expiration_month.placeholder"),
        options: months?.map((m) => {
          return { value: m < 10 ? `0${m}` : m, label: m < 10 ? `0${m}` : m };
        }),
        customInputClass: customExpiryDropdownInputClass,
        customLabelClass: customExpiryLabel,
        disabled: false,
      },
    ],
    [
      {
        name: 'vehicle_id',
        label: 'Select a Vehicle',
        type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
        placeholder:"Select a Vehicle",
        options: vehicles? vehicles : [],
        customInputClass: customDropdownInputClass,
        customLabelClass,
        disabled: false,
      },
      
    
    ],
    [{ 
      name: 'commuters_per_vehicle',
      label: t("permit_application.apply_for_permit.labels.step1.commuters_per_vehicle"),
      type: FieldType.NUMBER_FIELD,
      mandatory: true,
      customInputClass,
      customLabelClass,
      disabled: true,
    }],
    [
      {
        name: 'purpose_of_visit', 
        label: t("permit_application.apply_for_permit.labels.step2.purpose_of_visit.label"),
        customInputClass: customSingleInputClass,
        customLabelClass,
        mandatory: true,
      },
    ]
    
  ];
};

const vehicleDetails = (attrs) => {
  const { customInputClass='', customLabelClass='',customDropdownInputClass , manufacturers, t, subclasses } = attrs;
  
  return [
    {
      name: 'manufacturer_id',
      label: t("permit_application.apply_for_permit.labels.step2.manufacturer.label"),
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: manufacturers ? manufacturers.concat([{value: 0, label: 'Other'}]) : [],
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
    {
      name: 'manufacturer_name',
      label: t("permit_application.apply_for_permit.labels.step2.manufacturer1.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: false,
    }, 
    {
      name: 'year',
      label: t("permit_application.apply_for_permit.labels.step2.year.label"),
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true,
      min: 0,
    },
    {
      name: 'color',
      label: t("permit_application.apply_for_permit.labels.step2.color.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
    {
      name: 'model',
      label: t("permit_application.apply_for_permit.labels.step2.model.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
  
    {
      name: 'registration_state',
      label: t("permit_application.apply_for_permit.labels.step2.registration_state.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
    
    {
      name: 'vehicle_class',
      label: t("permit_application.apply_for_permit.labels.step2.class.label"),
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [{
        "value": "car",
        "label": "Car"
      },
      {
        "value": 'motorcycle',
        "label": "Motorcycle"
      },{
        "value": 'micro_mobility_vehicle',
        "label": "Micro Mobility Vehicle"
      }].map(({ value, label }) => {
        return { value, label };
      }),
      mandatory: true
    }, 
    {
      name: 'sub_class',
      label: t("permit_application.apply_for_permit.labels.step2.vehicle_sub_class.label"),
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: subclasses ? subclasses : [],
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    }, 
    {
      name: 'vehicle_type',
      label: t("permit_application.apply_for_permit.labels.step2.vehicle_type.label"),
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [{
        "value": "gasoline",
        "label": "Gasoline"
      },
      {
        "value": 'electric',
        "label": "Electric"
      },].map(({ value, label }) => {
        return { value, label };
      }),
      mandatory: true
    },
    {
      name: 'vehicle_category',
      label: t("permit_application.apply_for_permit.labels.step2.category.label"),
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [{
        "value": 'personal',
        "label": "Personal"
      },
      {
        "value": 'government',
        "label": "Government"
      },{
        "value": 'agency',
        "label": "Agency"
      }].map(({ value, label }) => {
        return { value, label };
      }),
      mandatory: true

    },
    {
      name: 'institution_name',
      label: t("permit_application.apply_for_permit.labels.step2.institution_name.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    }, 

    
  ]
}



const commuterVehicleDetails = (attrs) => {
  const { customInputClass='', customLabelClass='',customDropdownInputClass , manufacturers, subclasses, t } = attrs;

  return [
    {
      name: 'manufacturer_id',
      label: t("permit_application.apply_for_permit.labels.step2.manufacturer.label"),
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: manufacturers ? manufacturers.concat([{value: 0, label: 'Other'}]) : [],
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
    {
      name: 'manufacturer_name',
      label: t("permit_application.apply_for_permit.labels.step2.manufacturer1.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: false,
    }, 
    {
      name: 'year',
      label: t("permit_application.apply_for_permit.labels.step2.year.label"),
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
    {
      name: 'color',
      label: t("permit_application.apply_for_permit.labels.step2.color.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
    {
      name: 'model',
      label: t("permit_application.apply_for_permit.labels.step2.model.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
  
    {
      name: 'registration_state',
      label: t("permit_application.apply_for_permit.labels.step2.registration_state.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    },
    
    {
      name: 'vehicle_class',
      label: t("permit_application.apply_for_permit.labels.step2.class.label"),
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [{
        "value": "car",
        "label": "Car"
      },
      {
        "value": 'motorcycle',
        "label": "Motorcycle"
      },{
        "value": 'micro_mobility_vehicle',
        "label": "Micro Mobility Vehicle"
      }].map(({ value, label }) => {
        return { value, label };
      }),
      mandatory: true

    }, 
    {
      name: 'sub_class',
      label: t("permit_application.apply_for_permit.labels.step2.sub_class.label"),
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: subclasses ? subclasses : [],
      mandatory: true

    }, 
    {
      name: 'vehicle_type',
      label: t("permit_application.apply_for_permit.labels.step2.vehicle_type.label"),
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [{
        "value": "gasoline",
        "label": "Gasoline"
      },
      {
        "value": 'electric',
        "label": "Electric"
      },].map(({ value, label }) => {
        return { value, label };
      }),
      mandatory: true
    },
    {
      name: 'vehicle_category',
      label: t("permit_application.apply_for_permit.labels.step2.category.label"),
      customInputClass: customDropdownInputClass,
      customLabelClass,
      disabled: false,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [{
        "value": 'personal',
        "label": "Personal"
      },
      {
        "value": 'government',
        "label": "Government"
      },{
        "value": 'agency',
        "label": "Agency"
      }],
      mandatory: true

    },
    {
      name: 'institution_name',
      label: t("permit_application.apply_for_permit.labels.step2.institution_name.label"),
      customInputClass,
      customLabelClass,
      disabled: false,
      mandatory: true
    }, 
    
  ]
}

const fieldsUser = (userType, attrs) => {
  const {customDropdownInputClass, customInputClass, countries, stateList, initialCountry,customLabelClass, t} = attrs
  return [
    {
      name: `${userType}[full_name]`, 
      label: t("permit_application.apply_for_permit.labels.step2.full_name.label"),
      disabled: false,
      customInputClass,
      customLabelClass,
      mandatory: true,
      placeholder: 'First Name Last Name'
    },
    {
      name: `${userType}[birthday]`, 
      label: t("permit_application.apply_for_permit.labels.step2.date_of_birth.label"),
      type: FieldType.SUBSCRIBER_DATE_FIELD,
      disabled: false,
      customInputClass,
      customLabelClass,
      max: moment().subtract(18, "years")._d,
      mandatory: true,
    },
    {
      name: `${userType}[home_phone]`,  
      label: t("permit_application.apply_for_permit.labels.step2.home_phone.label"),
      disabled: false,
      type: FieldType.PHONE_NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
      initialCountry: initialCountry?.toLowerCase()
    },
    {
      name: `${userType}[email]`, 
      label: t("permit_application.apply_for_permit.labels.step2.email.label"),
      disabled: false,
      customInputClass,
      customLabelClass,
      mandatory: true,
      placeholder:"user@example.com"
    },
    {
      name: `${userType}[work_phone]`, 
      label: t("permit_application.apply_for_permit.labels.step2.work_phone.label"),
      disabled: false,
      type: FieldType.PHONE_NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      mandatory: true,
      initialCountry: initialCountry?.toLowerCase()

    },
    {
      name: `${userType}[address1]`, 
      label: t("permit_application.apply_for_permit.labels.step2.address1.label"),
      disabled: false,
      customInputClass,
      customLabelClass,
      placeholder:'123 Street Name'
    },
    {
      name: `${userType}[address2]`, 
      label: t("permit_application.apply_for_permit.labels.step2.address2"),
      disabled: false,
      customInputClass,
      customLabelClass,
    },
    {
      name: `${userType}[country_code]`, 
      label: t("permit_application.apply_for_permit.labels.step2.country.label"),
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      customInputClass: customDropdownInputClass,
      customLabelClass,
      options: countries,
      disabled: false,
      mandatory: true,
      defaultValue:'US'
    },
    {
      name: `${userType}[postal_code]`, 
      label: t("permit_application.apply_for_permit.labels.step2.zip_code.label"),
      disabled: false,
      ...attrs,
      mandatory: true
    },
    {
      name: `${userType}[state_code]`, 
      label: t("permit_application.apply_for_permit.labels.step2.state.label"),
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      disabled: false,
      customInputClass: customDropdownInputClass,
      options: stateList,
      customLabelClass,
      mandatory: true,

    },
    {
      name: `${userType}[city]`, 
      label: t("permit_application.apply_for_permit.labels.step2.city.label"),
      disabled: false,
      customInputClass,
      customLabelClass,
      mandatory: true
    },
  ]
}

const fieldsIdentification = (mute, userType, identification, attrs, t) => {
  let data = [[
    {
      name: `${userType}[id_type]`,
      label: 'ID Type',
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      options: [
        { value: 'driving_license', label: 'Driver\'s License' },
        { value: 'employee_id', label: 'Employee ID' },
        { value: 'ssn', label: 'SSN' },
      ],
      horizontalDisplay: true,
      mandatory: true,
      customInputClass: attrs?.customDropdownInputClass,
    },
  ]]
  if(identificationLable(identification, t)) {
    data[0] = data[0].concat([
      {
        name: `${userType}[id_number]`,
        label: identificationLable(identification, t),
        horizontalDisplay: true,
        mandatory: true,
        ...attrs
      },
    ])
  }
  
  data.push(fieldsUser(userType, attrs))
  
  return data
}

 
const exampleData = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? {
  country: "USA",
  city: "Easton",
  state: "MD",
  zip:  21601,
  street: "E Earle Ave",
  ltd: '38.768148',
  lng: '-76.075782'
} : {};
export {permitFields, exampleData, vehicleDetails, fieldsIdentification, fieldsUser, commuterVehicleDetails};