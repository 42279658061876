import React, { useCallback, useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap';
import styles from './index.module.sass'
import Button from 'components/base/button';
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import { ReactComponent as ArrownDownToBracketIcon } from 'assets/arrow-down-to-bracket.svg'
import { search as dropdownsSearch } from "api/dropdowns";
import FilterForm from './filterForm';
import env from '.env'
import qs from 'qs';

const CollapseContent = ({ startFetching, setList, setFilters, match, resource, initialFilter, setInitialFilter, formApiRef, setFormApi,
                           currentFilters, setCurrentFilters, reportName, currentUser, filters, dateRange, setDateRange}) => {
  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
    permitTypes: [],
    permitTypeCategory: [],
    parkingSessionStatuses: [],
    ticketStatuses: [],
    violationTypes: [],
    citationStatuses: [],
    disputeStatuses: [],
    disputeTypes: [],
    townManagers: [],
    parkingAdmins: [],
    agencyTypes: []
  })
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("parking_lot_list")).then((response) =>
          setDropdowns(state => ({...state, parkingLots: response.data }))),
        startFetching(dropdownsSearch("permit_type_list")).then((response) => 
          setDropdowns(state => ({...state, permitTypes: response.data }))),
        startFetching(dropdownsSearch("permit_type_category")).then((response) =>
          setDropdowns(state => ({...state, permitTypeCategory: response.data }))),
        startFetching(dropdownsSearch("parking_session_statuses_list")).then((response) =>
          setDropdowns(state => ({...state, parkingSessionStatuses: response.data }))),
        startFetching(dropdownsSearch("tickets_statuses_field")).then((response) =>
          setDropdowns(state => ({...state, ticketStatuses: response.data }))),
        startFetching(dropdownsSearch('violation_type_field')).then((response) =>
          setDropdowns(state => ({...state, violationTypes: response.data }))),
        startFetching(dropdownsSearch('citation_ticket_statuses_field')).then((response) =>
          setDropdowns(state => ({...state, citationStatuses: response.data }))),
        startFetching(dropdownsSearch("dispute_statuses_field")).then((response) =>
          setDropdowns(state => ({...state, disputeStatuses: response.data }))),
        startFetching(dropdownsSearch("dispute_types_field")).then((response) =>
          setDropdowns(state => ({...state, disputeTypes: response.data }))),
        startFetching(dropdownsSearch('admins_by_role-parking_admin', { admin_id: currentUser.id })).then(response =>
          setDropdowns(state => ({...state, parkingAdmins: response.data }))),
        startFetching(dropdownsSearch('admins_by_role-town_manager')).then(response =>
          setDropdowns(state => ({...state, townManagers: response.data }))),
        startFetching(dropdownsSearch("agency_type")).then((response) =>
          setDropdowns(state => ({...state, agencyTypes: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        setInitialFilter={setInitialFilter}
        initialFilter={initialFilter}
        formApiRef={formApiRef}
        setFormApi={setFormApi}
        setCurrentFilters={setCurrentFilters}
        currentFilters={currentFilters}
        reportName={reportName}
        filters={filters}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </Row>
  )
}

const ToolBar = (props) => {
  const {isFetching, activeTab, setActiveTab, openModal, reportName, filters, titleName} = props;

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  const exportFile = (type) => {
    const query = qs.stringify({
      token: localStorage.TOKEN,
      ...filters,
    }, { arrayFormat: 'brackets' })
  
    const url = `${env.backend_url}/dashboard/reports/system_report?${query}&generate=true&file_type=${type}&module_name=${reportName}`
    window.open(url, '_blank');
    window.focus();
  }
  
  return (
    <React.Fragment>
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      backTab='/dashboard/reports'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center p-0'>
        <span className={`${styles.title} general-text-1 mr-3`}>
          {`${titleName} Reports (${props.total})`}
        </span>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          className={styles.createButton}
          onClick={ () => openModal('schedule')}
          isActive={true}
          icon={<PenToSquareSvg />}
        >
          <span className='mr-3 d-inline-block'>Report Scheduler</span>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={activeTab === 'filters' ? styles.activeIcon : ''}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
      <Row className={`m-0 py-3 mt-3 ${styles.filters1} ${!activeTab ? 'mb-3' : '' }`}>
      <Col className='d-flex justify-content-left pl-3 pr-0' style={{marginLeft: '-16px'}}>
        <SlideButton
          onClick={ () => exportFile('csv')}
          isActive={true}
          icon={<ArrownDownToBracketIcon />}
        >
          <span className='mr-3 d-inline-block'>Export as CSV</span>
        </SlideButton>
        <SlideButton
          className={styles.exportButton}
          onClick={ () => exportFile('pdf')}
          isActive={true}
          icon={<ArrownDownToBracketIcon />}
        >
          <span className='mr-3 d-inline-block'>Export as PDF</span>
        </SlideButton>
      </Col>
      </Row>
    </React.Fragment>
  )
}

export default ToolBar;