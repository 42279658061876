import React, { useCallback, useContext, useReducer, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Edit from "../edit";
import Create from "../create";
import Delete from "../delete";
import Show from "../show";
/* Assets */
import styles from "./index.module.sass";
/* API */
import { create, destroy, update } from "api/disabilities";
import { capitalize } from "components/helpers";
import { AlertMessagesContext } from "components/helpers/alert_messages";
import ActivateDeactivate from "../activate_deactivate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { map, values } from 'underscore'

const initState = {
  errors: {},
  isSaving: false,
  parkingLots: [],
  permitTypes: [],
};

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case "isSaving":
      return { ...state, isSaving: payload, errors: payload ? {} : errors };
    case "isDeleting":
      return { ...state, isDeleting: payload, errors: payload ? {} : errors };
    case "errors":
      return { ...state, errors: payload };
    case "dropdowns":
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
};

const DisabilityModal = (props) => {
  const {
    addListElement,
    record,
    openModal,
    modalType,
    setListElement,
    popListElement,
    setActiveTab,
  } = props;
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const [errors, setErrors] = useState({});

  const closeModal = useCallback(() => {
    openModal();
    setActiveTab();
  }, [openModal, setActiveTab]);

  const setState = useCallback(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [dispatch]
  );

  const createDisability = useCallback(
    async (data) => {
      setState("isSaving", true);
      try {
        const response = await create({ data: {disability: data} });
        addListElement({ setList: setList, data: response.data });
        closeModal();
        addAlertMessages(
          [
            {
              type: "primary",
              text: `${capitalize(
                response.data.name
              )} is Added Successfully`,
              onlyMessage: true,
            },
          ],
          "center"
        );
      } catch (error) {
        setState("errors", error.response.data.errors);
        setErrors(error?.response?.data?.errors);
      } finally {
        setState("isSaving", false);
      }
    },
    [setState, addListElement, addAlertMessages, closeModal]
  );

  const editDisability = useCallback(
    async (data) => {
      setState("isSaving", true);
      try {
        const response = await update({ data: {disability: data}, id: record.id });
        setListElement(response.data);
        closeModal();
        addAlertMessages(
          [
            {
              type: "primary",
              text: `${capitalize(
                response.data.name
              )} Disability was successfully edited`,
              onlyMessage: true,
            },
          ],
          "center"
        );
      } catch (error) {
        setState("errors", error?.response?.data?.errors);
        setErrors(error?.response?.data?.errors);
      } finally {
        setState("isSaving", false);
      }
    },
    [setState, addAlertMessages, setListElement, record.id, closeModal]
  );

  const deleteDisability = useCallback(async () => {
    setState("isDeleting", true);
    try {
      const response = await destroy({ id: record.id });
      popListElement({ id: record.id });
      closeModal();
      addAlertMessages(
        [
          {
            type: "danger",
            text: `${capitalize(
              response.data.name
            )} Disability was successfully removed`,
            onlyMessage: true,
          },
        ],
        "center"
      );
    } catch (error) {
      setState("errors", error?.response?.data?.errors);
      addAlertMessages(
        map(values(error.response?.data?.errors), (message) => {
          return (
            {
              type: 'danger',
              text:  message,
              onlyMessage: true,
            }
          )
        }),
        'center'
      )
      closeModal()
    } finally {
      setState("errors", {});
      setState("isDeleting", false);
    }
  }, [setState, popListElement, record.id, closeModal, addAlertMessages]);

  const toggleStatus = useCallback(
    async (attrs) => {
      const data = Object.assign(attrs);
      try {
        const response = await update({ data: {disability: data}, id: record.id });
        closeModal();
        const message = `The ${
          response?.data?.name
        } Disability is successfully ${
          response.data.status ? "activated" : "deactivated"
        }`;
        addAlertMessages(
          [
            {
              type: response.data.status ? "primary" : "danger",
              text: message,
              onlyMessage: true,
            },
          ],
          "center"
        );
        setListElement(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    [record, addAlertMessages, setListElement, closeModal]
  );

  const modalProps = useCallback(() => {
    const maxWidth = 480;
    switch (modalType) {
      case "show":
          return { title: `${record?.name}`, maxWidth: "500px" };  
      case "edit":
        return { title: "Edit Disability", maxWidth: "500px" };
      case "delete":
        return { title: "Are you sure?", maxWidth: "490px" };
      case "create":
        return { title: "Add A Disability", maxWidth: "500px" };
      case "activate_deactivate":
        return {
          title: `Disability ${
            record?.status ? "Deactivation" : "Activation"
          }`,
          maxWidth,
        };
      default:
        return { maxWidth };
    }
  }, [modalType, record]);

  const closeBtn = useCallback(() => {
    return (
      <FontAwesomeIcon
        icon={faTimes}
        className={styles.closeIcon}
        onClick={closeModal}
      />
    );
  }, [closeModal]);

  return (
    <Modal
      isOpen={!!modalType}
      centered
      contentClassName={styles.modal}
      style={{ maxWidth: modalProps().maxWidth, width: "100%" }}
    >
      {!!modalType && (
        <ModalHeader close={closeBtn()} className="border-0 pb-0" />
      )}
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {modalType === "show" && (
          <Show
            record={record}
            isSaving={state.isSaving}
            closeModal={closeModal}
          />
        )}
        {modalType === "create" && (
          <Create
            errors={errors}
            setErrors={setErrors}
            createDisability={createDisability}
            isSaving={state.isSaving}
            closeModal={closeModal}
          />
        )}
        {modalType === "activate_deactivate" && (
          <ActivateDeactivate
            name={record.name}
            closeModal={closeModal}
            toggleStatus={toggleStatus}
            status={record.status ? false : true}
          />
        )}
        {modalType === "edit" && (
          <Edit
            record={record}
            errors={errors}
            setErrors={setErrors}
            editDisability={editDisability}
            isSaving={state.isSaving}
            closeModal={closeModal}
          />
        )}
        {modalType === "delete" && (
          <Delete
            record={record}
            deleteDisability={deleteDisability}
            state={state}
            closeModal={closeModal}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default DisabilityModal;