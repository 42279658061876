import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Checkbox,
  Row,
  Col,
  DatePicker,
  Radio,
  ConfigProvider,
  Button as AntdButton,
  Tag,
} from "antd";
import {
  CloseOutlined,
  CloudUploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import styles from "./index.module.sass";
import "./styles.css";
import {
  filterFetcher,
  addFormFields,
  updateFormFields,
  updateFieldsPosition,
} from "api/form_fields";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import Dragger from "antd/es/upload/Dragger";
import Button from "components/base/button";
import { isEmpty } from "underscore";
import Loader from "components/helpers/loader";
import { ReactComponent as EditIcon } from "assets/pencil-icon.svg";
import { AlertMessagesContext } from "components/helpers/alert_messages";
import Tabs from "components/base/tabs";
import { Spinner } from "reactstrap";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import { getErrorMsg } from "components/helpers/getErrorMsg";

const { Option } = Select;
const { TextArea } = Input;
const FIELD_TYPES = [
  { field_type: "text_input", label: "Text Input", name: "Text Input" },
  { field_type: "text_area", label: "Text Area", name: "Text Area" },
  { field_type: "number", label: "Number", name: "Number" },
  { field_type: "dropdown", label: "Dropdown", name: "Dropdown" },
  { field_type: "upload", label: "Upload", name: "Upload" },
  { field_type: "checkbox", label: "Checkbox", name: "Checkbox" },
  { field_type: "radio", label: "Radio", name: "Radio" },
  { field_type: "date", label: "Date Picker", name: "Date Picker" },
  { field_type: "time", label: "Time Picker", name: "Time Picker" },
];
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const inputLabelStyle = {
  display: "inline-block",
  wordWrap: "break-word",
  wordBreak: "break-word",
  textAlign: "left",
  fontSize: "14px",
  paddingLeft: "2px",
  width: "100%",
};

const inputStyle = { width: "100%", height: "40px" };

const DynamicForm = () => {
  const [activeTab, setActiveTab] = useState("user");
  const [list, setList] = useState([]);
  const [formFields, setFormFields] = useState({
    user: [],
    vehicle: [],
    permit_application: [],
  });
  const [droppingNew, setDroppingNew] = useState(false);
  const [newlyAddedFields, setNewlyAddedFields] = useState({
    user: [],
    vehicle: [],
    permit_type: [],
    permit_application: [],
  });
  const [isFetching, setIsFetching] = useState(false);
  const [editable, setEditable] = useState(false);
  const [selectedFieldToUpdate, setSelectedFieldToUpdate] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { addAlertMessages } = useContext(AlertMessagesContext);
  const [fieldPositionChanged, setFieldsPositionChanged] = useState(false);
  const [addNewFields, setAddNewFields] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [permitApplicationType, setPermitApplicationType] = useState(
    "permit_application_residential"
  );
  const [allPermitApplicationFields, setAllPermitApplicationFields] = useState(
    []
  );

  useEffect(() => {
    fetchFields();
  }, []);

  const handleLabelChange = (e, field) => {
    const newLabel = e.target.value;
    setFormFields((prev) => ({
      ...prev,
      [activeTab]: prev[activeTab].map((f) =>
        f.id === field.id ? { ...f, name: newLabel } : f
      ),
    }));
    setNewlyAddedFields((prev) => ({
      ...prev,
      [activeTab]: prev[activeTab].map((f) =>
        f.id === field.id ? { ...f, name: newLabel } : f
      ),
    }));
    handleUpdate(field.id, "name", e.target.value);
  };

  const fetchFields = async () => {
    setIsFetching(true);
    try {
      const response = await filterFetcher({ perPage: 10000 });
      const data = response?.data;
      setList(data);
      const userFields = data?.filter((field) => field.form_section === "user");
      const vehicleFields = data?.filter(
        (field) => field.form_section === "vehicle"
      );
      const permitTypeFields = data?.filter(
        (field) => field.form.name === "permit_type_form"
      );
      const residentialPermitApplicationFields = data?.filter(
        (field) => field.form.name === "permit_application_residential"
      );
      setAllPermitApplicationFields(
        data?.filter(
          (field) => field.form.name.split("_").includes("application") === true
        )
      );
      setFormFields({
        user: userFields.map((field) => ({
          ...field,
          status: field.status === "enabled",
          mandatory: field.mandatory || false,
        })),
        vehicle: vehicleFields.map((field) => ({
          ...field,
          status: field.status === "enabled",
          mandatory: field.mandatory || false,
        })),
        permit_type: permitTypeFields.map((field) => ({
          ...field,
          status: field.status === "enabled",
          mandatory: field.mandatory || false,
        })),
        permit_application: residentialPermitApplicationFields.map((field) => ({
          ...field,
          status: field.status === "enabled",
          mandatory: field.mandatory || false,
        })),
      });
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {});

  const handleDragStart = (index, tab) => (e) => {
    e.dataTransfer.setData("field-index", index);
    e.dataTransfer.setData("tab", tab); // Store the tab information for reference
  };

  const handleDropExisting = (index, tab) => (e) => {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData("field-index");
    const updatedFields = [...formFields[tab]];
    const [draggedField] = updatedFields.splice(draggedIndex, 1);
    updatedFields.splice(index, 0, draggedField);
    // Update positions after reordering
    const reorderedFields = updatedFields.map((field, idx) => ({
      ...field,
      position: idx + 1, // Recalculate position for all fields
    }));
    setFormFields((prev) => ({ ...prev, [tab]: reorderedFields }));
    setFieldsPositionChanged(true);
  };

  const handleDropNew = (e) => {
    e.preventDefault();
    const field = JSON.parse(e.dataTransfer.getData("field"));
    field.id =
      formFields.user.length +
      formFields.vehicle.length +
      formFields.permit_type.length +
      2;
    field.status = true; // Default to enabled
    field.mandatory = false; // Default to not mandatory
    field.custom = true; // Default to not mandatory
    field.position = 1; // New field will be always added at position 1
    // Updating the positions of existing fields
    if (field.field_type === "dropdown" || field.field_type === "radio") {
      field.options = []; // Initialize options as an empty array
    }
    setFormFields((prev) => {
      const updatedFields = prev[activeTab].map((f) => ({
        ...f,
        position: f.position + 1, // Increment position of all existing fields
      }));
      return {
        ...prev,
        [activeTab]: [{ ...field, isEditable: true }, ...updatedFields],
      };
    });
    setNewlyAddedFields((prev) => ({
      ...prev,
      [activeTab]: [{ ...field, isEditable: true }, ...prev[activeTab]],
    }));
    setDroppingNew(false);
  };

  const handleAddOption = (fieldId, newOption) => {
    const field = formFields[activeTab].find((f) => f.id === fieldId);
    if (!newOption) {
      setFieldErrors((prev) => ({
        ...prev,
        [fieldId]: "Option is Required!", // Set error message if option is empty
      }));
      return;
    }
    if (field.options?.includes(newOption)) {
      setFieldErrors((prev) => ({
        ...prev,
        [fieldId]: "Option already exists.", // Set error message if option already exists
      }));
      return;
    }

    // Clear error if option is non-empty and non-duplicate
    setFieldErrors((prev) => ({
      ...prev,
      [fieldId]: undefined,
    }));

    setNewlyAddedFields((prev) => ({
      ...prev,
      [activeTab]: prev[activeTab].map((field) =>
        field.id === fieldId
          ? { ...field, options: [...(field.options || []), newOption] } // Add new option
          : field
      ),
    }));

    // Also update formFields
    setFormFields((prev) => ({
      ...prev,
      [activeTab]: prev[activeTab].map((field) =>
        field.id === fieldId
          ? { ...field, options: [...(field.options || []), newOption] } // Add new option
          : field
      ),
    }));
  };

  const handleRemoveOption = (fieldId, optionIndex) => {
    setNewlyAddedFields((prev) => ({
      ...prev,
      [activeTab]: prev[activeTab].map((field) =>
        field.id === fieldId
          ? {
              ...field,
              options: field.options.filter(
                (_, index) => index !== optionIndex
              ), // Remove option
            }
          : field
      ),
    }));

    // Also update formFields
    setFormFields((prev) => ({
      ...prev,
      [activeTab]: prev[activeTab].map((field) =>
        field.id === fieldId
          ? {
              ...field,
              options: field.options.filter(
                (_, index) => index !== optionIndex
              ), // Remove option
            }
          : field
      ),
    }));
  };

  const moveToTop = (index, tab) => {
    const updatedFields = [...formFields[tab]];
    const [selectedField] = updatedFields.splice(index, 1);
    updatedFields.unshift(selectedField);
    // Updating the positions of existing fields
    const reorderedFields = updatedFields.map((field, idx) => ({
      ...field,
      position: idx + 1, // Recalculating position for all fields
    }));
    setFormFields((prev) => ({ ...prev, [tab]: reorderedFields }));
    setFieldsPositionChanged(true);
  };

  const renderLabel = (field, index) => {
    return (
      <div
        aria-disabled
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Input
          value={field.name}
          onChange={(e) => handleLabelChange(e, field)}
          bordered={false}
          style={inputLabelStyle}
          // eslint-disable-next-line
          disabled={
            (!field.isEditable && !editable) ||
            selectedFieldToUpdate?.id !== field.id
          }
        />
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {/* Default Field Tag */}
          {!field.custom && <Tag className={styles.defaultTag}>Default</Tag>}
          {
            <Checkbox
              disabled={!editable || field?.id !== selectedFieldToUpdate?.id}
              checked={field.status}
              onChange={(e) =>
                handleFieldAttributeChange(field.id, "status", e.target.checked)
              }
            >
              Enabled
            </Checkbox>
          }
          {/* Mandatory Toggle */}
          {
            <Checkbox
              disabled={!editable || field?.id !== selectedFieldToUpdate?.id}
              checked={field.mandatory}
              onChange={(e) =>
                handleFieldAttributeChange(
                  field.id,
                  "mandatory",
                  e.target.checked
                )
              }
            >
              Mandatory
            </Checkbox>
          }
          <Button
            className={styles.editBtn}
            onClick={() => {
              setEditable(!editable);
              setSelectedFieldToUpdate(field);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            className={styles.moveUpBtn}
            onClick={() => moveToTop(index, activeTab)}
          >
            <FontAwesomeIcon
              icon={faArrowUp}
              className="move-top-icon"
              color="#242E42"
            />
          </Button>
        </div>
      </div>
    );
  };

  const handleFieldAttributeChange = (id, attribute, value) => {
    setFormFields((prev) => ({
      ...prev,
      [activeTab]: prev[activeTab].map((field) =>
        field.id === id ? { ...field, [attribute]: value } : field
      ),
    }));
    handleUpdate(id, attribute, value);
  };

  const renderField = (field, index) => {
    switch (field.field_type) {
      case "text_input":
        return <Input placeholder={field.name} style={inputStyle} />;
      case "text_area":
        return (
          <TextArea
            maxLength={6}
            rows={4}
            placeholder={field.name}
            style={{ width: "100%" }}
          />
        );
      case "number":
        return field.name === "Phone Number" ||
          field.name.toLowerCase().split(" ").includes("phone") ? (
          <PhoneInput
            inputClass={styles.phoneInput}
            dropdownClass={styles.dropdown}
            searchClass={styles.search}
            searchStyle={{
              fontSize: "13px",
              height: "30px",
              width: "90%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            disableSearchIcon
            searchPlaceholder="Search for Countries"
            searchNotFound="Not Found"
            country="us"
            enableSearch={true}
            autocompleteSearch={true}
          />
        ) : (
          <Input type="number" placeholder={field.name} style={inputStyle} />
        );
      case "dropdown":
        return field.custom ? (
          <Select
            placeholder={field.name}
            style={inputStyle}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <div style={{ padding: "8px", display: "flex", gap: "8px" }}>
                  <Form.Item
                    // label={field.name}
                    validateStatus={fieldErrors[field.id] ? "error" : ""}
                    help={fieldErrors[field.id]}
                    style={{ width: "100%", height: "35px" }}
                  >
                    <Input
                      id={`option-input-${field.id}`}
                      placeholder="Add new option"
                      style={{ height: "35px" }}
                      onPressEnter={(e) => {
                        handleAddOption(field.id, e.target.value);
                        e.target.value = "";
                        e.stopPropagation();
                      }}
                      onChange={() => {
                        setFieldErrors((prev) => ({
                          ...prev,
                          [field.id]: undefined,
                        }));
                      }}
                    />
                  </Form.Item>

                  <AntdButton
                    icon={<PlusOutlined />}
                    onClick={() => {
                      const input = document.getElementById(
                        `option-input-${field.id}`
                      );
                      const newOption = input.value.trim();
                      if (newOption) {
                        handleAddOption(field.id, newOption);
                        input.value = ""; // Clear input after adding
                      }
                    }}
                  />
                </div>
              </div>
            )}
            value={null}
          >
            {field.options?.map((option, index) => (
              <Option key={index} value={option}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{option}</p>
                  <AntdButton
                    icon={<TrashIcon />}
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation(); // Preventing dropdown from closing
                      handleRemoveOption(field.id, index);
                    }}
                  />
                </div>
              </Option>
            ))}
          </Select>
        ) : (
          <Select placeholder={field.name} style={inputStyle}>
            {field.options &&
              !isEmpty(field.options) &&
              field.options?.map((option, index) => (
                <Option key={index} value={option}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{option}</p>
                    <AntdButton
                      icon={<TrashIcon />}
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation(); // Preventing dropdown from closing
                        handleRemoveOption(field.id, index);
                      }}
                    />
                  </div>
                </Option>
              ))}
            {isEmpty(field.options) && (
              <div>
                <Option value="1">Option 1</Option>
                <Option value="2">Option 2</Option>
              </div>
            )}
          </Select>
        );
      case "upload":
        return field.input_attribute === "avatar" ? (
          <Upload disabled>
            {
              <div className={styles.upload} style={{ opacity: 1 }}>
                <PlusOutlined />
                <div className={styles.uploadText}>{field.name}</div>
              </div>
            }
          </Upload>
        ) : (
          <Dragger
            disabled
            style={{
              alignSelf: "center",
              marginRight: "10px",
              marginLeft: "-20px",
              padding: 0,
            }}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined style={{ color: "grey" }} />
            </p>
            <p className="ant-upload-text">
              Click or drag a file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Supported formats: .pdf, .jpg, .png, .jpeg
            </p>
          </Dragger>
        );

      case "checkbox":
        return <Checkbox>{field.name}</Checkbox>;

      case "date":
        return (
          <DatePicker
            disabledDate={(current) =>
              current && current > moment().subtract(18, "years")
            }
            suffixIcon={
              <FontAwesomeIcon
                icon={faCalendarAlt}
                color="grey"
                className={styles.calenderIcon}
              />
            }
            placeholder={field.name}
            className={styles.calenderInput}
            style={inputStyle}
            format="DD/MM/YYYY"
          />
        );

      case "radio":
        return field.custom ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
              alignItems: "flex-start",
            }}
          >
            {field.options?.map((option, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                  marginBottom: "8px",
                }}
              >
                <Radio value={option}>{option}</Radio>
                <AntdButton
                  type="link"
                  icon={
                    <CloseOutlined
                      style={{ fontSize: "12px", color: "#ff4d4f" }}
                    />
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveOption(field.id, index);
                  }}
                  style={{ padding: 0 }}
                />
              </div>
            ))}
            <Form.Item
              validateStatus={fieldErrors[field.id] ? "error" : ""}
              help={fieldErrors[field.id]}
              style={{ marginBottom: "8px", width: "200px" }}
            >
              <Input
                id={`option-input-${field.id}`}
                placeholder={
                  field.options.length > 1
                    ? "Add more options"
                    : "Add new option"
                }
                onPressEnter={(e) => {
                  const newOption = e.target.value.trim();
                  if (newOption) {
                    handleAddOption(field.id, newOption);
                    e.target.value = "";
                    e.stopPropagation();
                  }
                }}
                onChange={() => {
                  setFieldErrors((prev) => ({
                    ...prev,
                    [field.id]: undefined,
                  }));
                }}
              />
            </Form.Item>
          </div>
        ) : (
          <Radio.Group required className={styles.radioBtns} value={field.name}>
            <Radio className="pr-4" value={true}>
              Yes
            </Radio>
            <Radio className="pl-2" value={false}>
              No
            </Radio>
          </Radio.Group>
        );

      default:
        return <Input placeholder={field.name} style={inputStyle} />;
    }
  };

  const getFormIDForPermitApplication = () => {
    const id = allPermitApplicationFields.filter(
      (f) => f.form.name === permitApplicationType
    )[0].form.id;
    return id;
  };

  const handleSave = async () => {
    const newData = {
      form_id:
        activeTab === "user"
          ? 1
          : activeTab === "vehicle"
          ? 2
          : activeTab === "permit_type"
          ? 3
          : activeTab === "permit_application"
          ? getFormIDForPermitApplication()
          : "",
      fields: newlyAddedFields[activeTab],
    };
    try {
      const response = await addFormFields({ data: newData });
      if (response.status === 200) {
        addAlertMessages(
          [
            {
              type: "primary",
              text: `Saved Changes Successfully`,
              onlyMessage: true,
            },
          ],
          "center"
        );
        setTimeout(() => updateFieldsOrder(), 1000);
        setNewlyAddedFields({ user: [], vehicle: [], permit_type: [] });
      }
    } catch (error) {
      addAlertMessages(
        [
          {
            type: "danger",
            text: getErrorMsg(error),
            onlyMessage: true,
          },
        ],
        "center"
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdate = (id, attribute, value) => {
    if (
      isEmpty(
        list?.filter((l, idx) => {
          return l?.id === id;
        })
      )
    ) {
      return;
    }
    let data = {};
    if (attribute === "status") {
      data.status = value;
    } else if (attribute === "mandatory") {
      data.mandatory = value;
    } else if (attribute === "name") {
      data.name = value;
    }
    updateFormFields({ id: id, data: data });
  };

  const updateFieldsOrder = async () => {
    const data = {
      form_id:
        activeTab === "user"
          ? 1
          : activeTab === "vehicle"
          ? 2
          : activeTab === "permit_type"
          ? 3
          : activeTab === "permit_application"
          ? getFormIDForPermitApplication()
          : "",
      fields_order: formFields[activeTab]?.map((field) => {
        return { id: field?.id, position: field?.position };
      }),
    };
    try {
      setIsSaving(true);
      const response = await updateFieldsPosition(data);
      if (response.status === 200) {
        addAlertMessages(
          [
            {
              type: "primary",
              text: `Saved Changes Successfully`,
              onlyMessage: true,
            },
          ],
          "center"
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false);
      setFieldsPositionChanged(false);
    }
  };
  const handleDragEnter = (e) => {
    const container = e.currentTarget;
    const { clientY } = e;
    const { top, bottom } = container.getBoundingClientRect();
    const offset = 20;
    if (clientY < top + offset) {
      // Scroll up
      container.scrollTop -= 20;
    } else if (clientY > bottom - offset) {
      // Scroll down
      container.scrollTop += 20;
    }
  };

  const tabs = [
    { value: "user", label: "Subscriber Registration - User" },
    { value: "vehicle", label: "Subscriber Registration - Vehicle" },
    { value: "permit_type", label: "Create Permit Type Form" },
    { value: "permit_application", label: "Apply for Permit / Issue Permit" },
  ];

  const handlePermitApplicationTypeChange = (type) => {
    setPermitApplicationType(type);
    const permitApplicationFields = allPermitApplicationFields?.filter(
      (field) => field.form.name === type
    );
    setFormFields((prev) => ({
      ...prev,
      permit_application: permitApplicationFields.map((field) => ({
        ...field,
        status: field.status === "enabled",
        mandatory: field.mandatory || false,
      })),
    }));
  };

  return (
    <div className={styles.section} style={{ display: "flex" }}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "#242E42",
              labelRequiredMarkColor: "#7ab7f3",
              labelFontSize: "13px",
              colorError: "rgba(251, 116, 91, 0.9)",
              fontFamily: "Roboto, sans-serif",
              fontSize: "13px",
            },
            Radio: {
              buttonBg: "#000000",
            },
          },
          token: {
            colorPrimary: "#3A9CED",
            fontFamily: "Roboto, sans-serif",
          },
        }}
      >
        <Row gutter={16} style={{ width: "100%" }}>
          <Col
            span={6}
            style={{
              height: "100%",
              overflowY: "auto",
              padding: "20px",
              paddingTop: "12px",
              borderRadius: "8px",
            }}
          >
            <p className={styles.header}>Form Configurations</p>
            <Button
              onClick={() => setAddNewFields(!addNewFields)}
              className={`${styles.addNewButton} ${
                addNewFields ? styles["addNewButton-bg-secondary"] : ""
              }`}
            >
              {addNewFields ? "Cancel" : "Add New Fields"}
            </Button>
            <div
              style={{
                pointerEvents: addNewFields ? "" : "none",
                background: addNewFields ? "" : "transparent",
              }}
              className="field-types"
            >
              {FIELD_TYPES.map((field) => (
                <div
                  key={field.field_type}
                  className="field-type"
                  draggable
                  style={{ background: addNewFields ? "" : "transparent" }}
                  onDragStart={(e) => {
                    e.dataTransfer.setData("field", JSON.stringify(field));
                    setDroppingNew(true);
                  }}
                >
                  {field.label}
                </div>
              ))}
            </div>
          </Col>
          <Col span={18}>
            <Col className={`${styles.tabSection} pl-2`}>
              <Tabs
                className={styles.tabs}
                list={tabs}
                defaultTab={activeTab}
                onClick={setActiveTab}
              />
              <Row>
                {(!isEmpty(newlyAddedFields.user) ||
                  !isEmpty(newlyAddedFields?.vehicle) ||
                  !isEmpty(newlyAddedFields?.permit_type) ||
                  !isEmpty(newlyAddedFields?.permit_application)) && (
                  <Button
                    onClick={handleSave}
                    className={`${styles.button} ${styles["button-bg-submit"]}`}
                  >
                    {isSaving ? (
                      <Spinner
                        className="spinner-border"
                        size="sm"
                        color="default"
                      />
                    ) : (
                      "Save New Fields"
                    )}
                  </Button>
                )}
                {fieldPositionChanged && !addNewFields && (
                  <Button
                    onClick={updateFieldsOrder}
                    className={`${styles.button} ${styles["button-bg-submit"]}`}
                  >
                    {isSaving ? (
                      <Spinner
                        className="spinner-border"
                        size="sm"
                        color="default"
                      />
                    ) : (
                      "Save Field Order"
                    )}
                  </Button>
                )}
              </Row>
            </Col>
            {activeTab === "user" && (
              <div
                style={{ marginLeft: "12px" }}
                tab="Subscriber Registration - User"
                key="user"
              >
                <div
                  className="form-section"
                  draggable
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={droppingNew && handleDropNew}
                  onDragEnter={handleDragEnter}
                >
                  {isFetching && <Loader />}
                  {formFields.user.map((field, index) => (
                    <div
                      key={field.id || index}
                      className={styles.formItem}
                      draggable
                      onDragStart={handleDragStart(index, "user")}
                      onDrop={handleDropExisting(index, "user")}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <Form.Item
                        label={
                          activeTab === "user" && renderLabel(field, index)
                        }
                        {...formItemLayout}
                      >
                        {renderField(field, index)}
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeTab === "vehicle" && (
              <div
                style={{ marginLeft: "12px" }}
                tab="Subscriber Registration - Vehicle"
                key="vehicle"
              >
                <div
                  className="form-section"
                  style={{ minHeight: "100%" }}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={droppingNew && handleDropNew}
                  onDragEnter={handleDragEnter}
                >
                  {isFetching && <Loader />}
                  {formFields.vehicle.map((field, index) => (
                    <div
                      key={field.id || index}
                      className={styles.formItem}
                      draggable
                      onDragStart={handleDragStart(index, "vehicle")}
                      onDrop={handleDropExisting(index, "vehicle")}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <Form.Item
                        label={
                          activeTab === "vehicle" && renderLabel(field, index)
                        }
                        {...formItemLayout}
                      >
                        {renderField(field)}
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeTab === "permit_type" && (
              <div
                style={{ marginLeft: "12px" }}
                tab="Create Permit Type Form"
                key="permit_type"
              >
                <div
                  className="form-section"
                  draggable
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={droppingNew && handleDropNew}
                  onDragEnter={handleDragEnter}
                >
                  {isFetching && <Loader />}
                  {formFields.permit_type.map((field, index) => (
                    <div
                      key={field.id || index}
                      className={styles.formItem}
                      draggable
                      onDragStart={handleDragStart(index, "permit_type")}
                      onDrop={handleDropExisting(index, "permit_type")}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <Form.Item
                        label={
                          activeTab === "permit_type" &&
                          renderLabel(field, index)
                        }
                        {...formItemLayout}
                      >
                        {renderField(field)}
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeTab === "permit_application" && (
              <div
                style={{ marginLeft: "12px" }}
                tab="Apply for Permit / Issue Permit"
                key="permit_application"
              >
                <Select
                  className={styles.formInput}
                  value={permitApplicationType}
                  showSearch
                  optionFilterProp="label"
                  options={[
                    {
                      value: "permit_application_residential",
                      label: "Permit Application - Residential Permit",
                    },
                    {
                      value: "permit_application_carpool",
                      label: "Permit Application - Carpool Permit",
                    },
                    {
                      value: "permit_application_visitor",
                      label: "Permit Application - Visitor Permit",
                    },
                    {
                      value: "permit_application_special",
                      label: "Permit Application - Special Permit",
                    },
                  ]}
                  onChange={(e) => {
                    handlePermitApplicationTypeChange(e);
                  }}
                />
                <div
                  className="application-form-section"
                  draggable
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={droppingNew && handleDropNew}
                  onDragEnter={handleDragEnter}
                >
                  {isFetching && <Loader />}
                  {formFields.permit_application.map((field, index) => (
                    <div
                      key={field.id || index}
                      className={styles.formItem}
                      draggable
                      onDragStart={handleDragStart(index, "permit_application")}
                      onDrop={handleDropExisting(index, "permit_application")}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <Form.Item
                        label={
                          activeTab === "permit_application" &&
                          renderLabel(field, index)
                        }
                        {...formItemLayout}
                      >
                        {renderField(field, index)}
                      </Form.Item>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </ConfigProvider>
    </div>
  );
};

export default DynamicForm;
