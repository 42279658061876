import React, { useEffect, useCallback, useRef, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { isEmpty, map } from 'underscore'
import { index } from "api/valet_parkings";
import { index as settingIndex } from 'api/pricing_models';
import { search as dropdownsSearch } from "api/dropdowns";
import { renderFields } from 'components/base/forms/common_form';
import { parkingLotField, pricingModelFields, fieldsCustomHour, fieldsTimeBlock } from 'components/helpers/fields/pricing_models';
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";

const PricingModel = ({closeModal, createPricingRate, errors, setErrors, startFetching}) => {
  const [valetSetting, setValetSetting] = useState()
  const [diffDisableRate, setDiffDisableRate] = useState(false)
  const [fieldsState, setFieldsState] = useState([1]);
  const [timeFieldsState, setTimeFieldsState] = useState([{id: 0}]);
  const formApiRef = useRef();
  const [disabledFields, setDisabledFields] = useState({});
  const [repeatFields, setRepeatFields] = useState({});
  const [repeatChecked, setRepeatChecked] = useState(false)

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
  })
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch('valet_parking_list')
        ).then((response) => setDropdowns(state => ({...state, parkingLots: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    parkingLots: dropdowns.parkingLots,
    customToolTipClass: styles.customTooltip,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => {setErrors({}); fetchConfigurations()}
    }
  }

  const configFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const fourFieldProps = {
    lSize: 3,
    iSize: 7,
    events: {
      onChange: (_e) => setErrors({})
    }
  };

  const threeFieldProps = {
    lSize: 3,
    iSize: 7,
    events: {
      onChange: (_e) => setErrors({})
    }
  };

  const applicationData = useCallback((data) => {
    const { valet_type, parking_valet_rates={} } = data
    setDiffDisableRate(parking_valet_rates[0]?.has_diff_rate_for_disabled_persons)
    if(valet_type === 'hourly_dynamic'){
      setFieldsState([])
      let arr = []
      // eslint-disable-next-line
      {map(parking_valet_rates, (item, idx) => {
        arr.push(item);
        setFieldsState((prevSections) => [...prevSections, prevSections.length + 1]);
        setDisabledFields((prevState) => {
          return { ...prevState, [`${idx}`]: item?.has_diff_rate_for_disabled_persons, };
        });
      })}
      
      return ({
        valet_type: ['hourly_static', 'hourly_dynamic'].includes(valet_type) ? 'hourly' : valet_type,
        parking_valet_rates_attributes: arr,
        rate: parking_valet_rates[0]?.rate,
        disabled_persons_rate: parking_valet_rates[0]?.disabled_persons_rate,
        has_charge_for_normal_parking: parking_valet_rates[0]?.has_charge_for_normal_parking,
        hourly: valet_type
      })
    }else if(valet_type === 'time_blocks'){
      setTimeFieldsState([{id: 0}])
      let arr = []
      // eslint-disable-next-line
      {map(parking_valet_rates, (item, idx) => {
        arr.push(item);
        if(idx !== parking_valet_rates.length-1){
          setTimeFieldsState((prevSections) => [...prevSections, { id: prevSections[prevSections.length - 1]?.id + 1 }]);
        }
        setDisabledFields((prevState) => {
          return { ...prevState, [`${idx}`]: item?.has_diff_rate_for_disabled_persons, };
        });
        setRepeatFields((prevState) => {
          return { ...prevState, [`${idx}`]: item?.block_repeat};
        });
      })}
      
      return ({
        valet_type: valet_type,
        parking_valet_rates_attributes: arr,
        rate: parking_valet_rates[0]?.rate,
        disabled_persons_rate: parking_valet_rates[0]?.disabled_persons_rate,
        has_charge_for_normal_parking: parking_valet_rates[0]?.has_charge_for_normal_parking,
        hourly: valet_type,
      })
    }else{
      return ({
        valet_type: ['hourly_static', 'hourly_dynamic'].includes(valet_type) ? 'hourly' : valet_type,
        rate: parking_valet_rates[0]?.rate,
        disabled_persons_rate: parking_valet_rates[0]?.disabled_persons_rate,
        has_charge_for_normal_parking: parking_valet_rates[0]?.has_charge_for_normal_parking,
        hourly: valet_type
      })
    }
  },[])

  const fetchConfigurations = async () => {
    const {values} = formApiRef.current.getState();
    try {
      const response = await settingIndex({parking_lot_id: values?.parking_lot_id})
      if(response.status === 200 || response.status === 201) {
        setValetSetting(applicationData(response.data));
        formApiRef.current.setValues(applicationData(response.data))
      }
    } catch(error) {
      console.log(error)
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await index().then((response) =>
        setDropdowns(state => ({...state, parkingLots: response.data })
      ))
    };
    fetch();
  }, []);

  const validateValues = useCallback((formState) => {
    const errors = {}
    const { valet_type, parking_lot_id, rate, has_charge_for_normal_parking, disabled_persons_rate, hourly, parking_valet_rates_attributes} = formState;
    if(!parking_lot_id) { errors['parking_lot_id'] = ['Parking Lot is required']}
    if(!valet_type) { errors['valet'] = ['Valet Type is required']}
    if(['flat_rate', 'daily'].includes(valet_type) || hourly === 'hourly_static'){
      if(!rate) { errors['rate'] = [`${hourly === 'hourly_static' ? 'Hourly' : valet_type === 'flat_rate' ? 'Flat' : 'Daily'} rate is required`]}
      if(has_charge_for_normal_parking === undefined) { errors['has_charge_for_normal_parking'] = ['Charge for normal parking is required']}
      if(diffDisableRate && !disabled_persons_rate) { errors['disabled_persons_rate'] = [`Disabled ${hourly === 'hourly_static' ? 'hourly' : valet_type === 'flat_rate' ? 'flat' : 'daily'} rate is required`]}
      if(valet_type === 'hourly' && !hourly) { errors['hourly'] = ['Hourly model is required']}
    }

    if(hourly === 'hourly_dynamic'){
      // eslint-disable-next-line
      {map(parking_valet_rates_attributes, (item, idx) => {
        if(item.from === "NaN:NaN"){ errors[`parking_valet_rates_attributes[${idx}][from]`] = ['From time can\'t be blank']}
        if(item.to === "NaN:NaN"){ errors[`parking_valet_rates_attributes[${idx}][to]`] = ['To time can\'t be blank']}
        if(!item.rate){ errors[`parking_valet_rates_attributes[${idx}][rate]`] = ['Hourly rate is required']}
        if(item.from !== "NaN:NaN" && item.to !== "NaN:NaN" && item.from >= item.to) {
          errors[`parking_valet_rates_attributes[${idx}][to]`] = ['To time should be greater than From time']
        }
      })}
      if(has_charge_for_normal_parking === undefined) { errors['has_charge_for_normal_parking'] = ['Charge for normal parking is required']}
    }

    if (valet_type === 'time_blocks') {
      if (!parking_valet_rates_attributes) { errors[`parking_valet_rates_attributes[${0}][block_duration_hours]`] = ['Duration can\'t be blank'] }
      if (!parking_valet_rates_attributes) { errors[`parking_valet_rates_attributes[${0}][rate]`] = ['Fee can\'t be blank'] }
      if (!parking_valet_rates_attributes && disabledFields[0]) { errors[`parking_valet_rates_attributes[${0}][disabled_persons_rate]`] = ['Disabled Fee can\'t be blank'] }
      if (parking_valet_rates_attributes && !parking_valet_rates_attributes[0]?.disabled_persons_rate && parking_valet_rates_attributes.length === 1 && disabledFields[0]) { errors[`parking_valet_rates_attributes[${0}][disabled_persons_rate]`] = ['Disabled Fee can\'t be blank'] }
      // eslint-disable-next-line
      if (parking_valet_rates_attributes && parking_valet_rates_attributes.length < timeFieldsState.length) {
        // eslint-disable-next-line
        {
          map(timeFieldsState, (item, idx) => {
            if (idx === 0) return
            if (!item.block_duration_hours) { errors[`parking_valet_rates_attributes[${idx}][block_duration_hours]`] = ['Duration can\'t be blank'] }
            if (!item.rate) { errors[`parking_valet_rates_attributes[${idx}][rate]`] = ['Fee can\'t be blank'] }
            if (!parking_valet_rates_attributes[idx]?.disabled_persons_rate && parking_valet_rates_attributes.length > 1 && disabledFields[idx]) { errors[`parking_valet_rates_attributes[${0}][disabled_persons_rate]`] = ['Disabled Fee can\'t be blank'] }
          })
        }

      } else if (parking_valet_rates_attributes && parking_valet_rates_attributes.length === timeFieldsState.length) {
        // eslint-disable-next-line
        {
          map(parking_valet_rates_attributes, (item, idx) => {
            if (!item.block_duration_hours) { errors[`parking_valet_rates_attributes[${idx}][block_duration_hours]`] = ['Duration can\'t be blank'] }
            if (!item.rate) { errors[`parking_valet_rates_attributes[${idx}][rate]`] = ['Fee can\'t be blank'] }
            if (!item?.disabled_persons_rate && parking_valet_rates_attributes.length > 1 && disabledFields[idx]) { errors[`parking_valet_rates_attributes[${idx}][disabled_persons_rate]`] = ['Disabled Fee can\'t be blank'] }
          })
        }
      }
      // eslint-disable-next-line
      {
        map(parking_valet_rates_attributes, (item, idx) => {
          if (!item.block_duration_hours) { errors[`parking_valet_rates_attributes[${idx}][block_duration_hours]`] = ['Duration can\'t be blank'] }
          if (!item.rate) { errors[`parking_valet_rates_attributes[${idx}][rate]`] = ['Fee can\'t be blank'] }
        })
      }
      if (has_charge_for_normal_parking === undefined) { errors['has_charge_for_normal_parking'] = ['Charge for normal parking is required'] }
    }
    
    return errors
  // eslint-disable-next-line
  }, [timeFieldsState, disabledFields])

  const save = useCallback((values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const dayEntry = {
      rate: values.rate,
      has_diff_rate_for_disabled_persons: diffDisableRate ? diffDisableRate : false,
      disabled_persons_rate: values.disabled_persons_rate,
      has_charge_for_normal_parking: values.has_charge_for_normal_parking
    };
    if(values.hourly === 'hourly_dynamic' || values.valet_type === 'time_blocks'){
    Object.keys(values?.parking_valet_rates_attributes).forEach((key) => {
      Object.assign(values?.parking_valet_rates_attributes[key], 
        { from: values?.parking_valet_rates_attributes[key]?.from, 
          to: values?.parking_valet_rates_attributes[key]?.to,
          has_diff_rate_for_disabled_persons: disabledFields[key] ? disabledFields[key] : false,
          has_charge_for_normal_parking: values.has_charge_for_normal_parking,
          block_repeat: repeatFields[key] ? repeatFields[key] : false
        })
    });}
    const initialData = {
      ...values,
    }
    if(values.valet_type === 'hourly'){
      Object.assign(initialData, {...initialData, valet_type: values.hourly})
    }

    if(['flat_rate', 'daily'].includes(values.valet_type) || values.hourly === 'hourly_static'){
      Object.assign(initialData, {parking_valet_rates_attributes: [dayEntry]})
    }
    createPricingRate(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, createPricingRate, diffDisableRate, disabledFields, repeatFields, timeFieldsState]);


  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fields = (hourly, valet_type) => {
    return parkingLotField({...fieldAttrs, hourly, valet_type})
  }

  const hourTypeFieldProps = {
    iSize: 8,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  };

  const onAdd = () => {
    setFieldsState((prevSections) => [...prevSections, prevSections.length + 1]);
  };

  const onTimeAdd = () => {
    setTimeFieldsState([...timeFieldsState, { id: timeFieldsState[timeFieldsState.length - 1].id + 1 }]);
  };

  const onRemove = (idx) => {
    setTimeFieldsState(timeFieldsState.filter((field) => field.id !== idx))
    setDisabledFields((prevState) => {
      return { ...prevState, [`${idx}`]: !prevState[`${idx}`], };
    });
  };

  const handleHourlyRateCheckboxChange = (id) => {
    setDisabledFields((prevState) => {
      return { ...prevState, [`${id}`]: !prevState[`${id}`], };
    });
    formApiRef.current.setValue(`parking_valet_rates_attributes[${id}][disabled_persons_rate]`, undefined)
  };

  const handleRepeatCheckboxChange = (id) => {
    setRepeatFields((prevState) => {
      return { ...prevState, [`${id}`]: !prevState[`${id}`], };
    });
    setRepeatChecked(!repeatChecked)
  };

  const renderPricingModels = useCallback(
    (fields) => {
      return (
        <Row className="justify-content-center pt-2 pl-3 ml-1">
          {renderFields(fields, 4, 12, { ...hourTypeFieldProps, errors })}
        </Row>
      );
    },
    // eslint-disable-next-line
    []
  );

  const parkingHourTypeSettingFieldsWeekdays = pricingModelFields({...fieldAttrs});

  return (
    <Container>
      <p className={`${styles.title}  p-0`}></p>
      <Form initialValues={valetSetting} getApi={setFormApi} className='m-auto' onSubmit={save}>
        {({ formState }) => {
          const { valet_type, hourly, parking_lot_id} = formState.values;
          const newFields = fields(hourly, valet_type)
          const field = fieldsState
          const timeBlock = timeFieldsState
          return (
            <Row className='w-100' style={{padding: '10px', marginLeft: '-5px'}}>
              <Col className='pb-4'>
                <Col>
                  <div>
                    {renderFields(newFields.slice(0, 1), {...singleFieldProps, errors})}
                    {renderPricingModels(parkingHourTypeSettingFieldsWeekdays)}
                  </div>
                </Col>
                <Col>
                  {valet_type === 'free' && 
                    <div className={`${styles.subTitle} px-0`}>
                      Users will not be charged for the valet parking service. They will only be charged for parking at the rates specified for each parking lot.
                    </div>
                  }

                  {!valet_type && parking_lot_id && <div className={`${styles.subTitle} px-0`}>
                    Please configure the valet pricing model to be used and add at least one valet staff member for the service to become active.
                  </div> }
                  
                  {(valet_type === 'flat_rate' || valet_type === 'daily') && 
                    <Col className={`${styles.subTitle} px-0`}>
                      <div className='mb-3'>
                        {`Specify the ${valet_type === 'flat_rate' ? 'flat' : 'daily'} rate to be charged for valet parking services. In addition to the ${valet_type === 'flat_rate' ? 'flat' : 'daily'} rate, you may choose to charge customers for normal parking or not.`}
                      </div>
                      <div>
                      {renderFields(newFields.slice(1, 2), {...configFieldProps, errors})}
                      </div>
                      <div className='d-flex' style={{marginLeft: '8rem', marginBottom: '0.7rem'}}>
                      <input
                        type="checkbox"
                        checked={diffDisableRate}
                        onChange={() => setDiffDisableRate(!diffDisableRate)}
                        className={`mr-1 ${styles.customCheckStyle}`}
                        />
                      <label className={styles.checkboxText}>Different rate for disabled persons</label>
                      </div>
                      {diffDisableRate && renderFields(newFields.slice(2, 3), {...configFieldProps, errors})}
                      {renderFields(newFields.slice(3, 4), {...configFieldProps, errors})}
                    </Col>
                  }
                  {valet_type === 'hourly' && 
                    <Col className={`${styles.subTitle} px-0`}>
                      <div className='mb-3'>
                        Specify the hourly rates to be charged for valet parking. In addition to the hourly rate, you may choose to charge customers for normal parking or not.
                      </div>
                      {renderFields(newFields.slice(4, 5), {...configFieldProps, errors})}
                      {hourly === 'hourly_static' && <div>
                        {renderFields(newFields.slice(1, 2), {...configFieldProps, errors})}
                        <div className='d-flex' style={{marginLeft: '8rem', marginBottom: '0.7rem'}}>
                          <input
                            type="checkbox"
                            checked={diffDisableRate}
                            onChange={() => setDiffDisableRate(!diffDisableRate)}
                            className={`mr-1 ${styles.customCheckStyle}`}
                            />
                          <label className={styles.checkboxText}>Different rate for disabled persons</label>
                        </div>
                        {diffDisableRate && renderFields(newFields.slice(2, 3), {...configFieldProps, errors})}
                      </div>}
                      {hourly === 'hourly_dynamic' && <div style={{marginLeft: '-14px'}}>
                        <React.Fragment >
                          {field?.map((idx) => (
                            <Row >
                              <Row className={styles.customLabelRow} style={{marginTop: '5px'}}>
                                <Col
                                  className={styles.customLabelSection2}
                                  xs={6}
                                >
                                  <label className={styles.customLabels2}>
                                    Hourly Rate $
                                  </label>
                                </Col>
                                <Col
                                  className={styles.customLabelSection3}
                                  xs={5}
                                >
                                  <label className={styles.customLabels3}>
                                    Disabled Rate $
                                  </label>
                                </Col>
                              </Row>
                              <Row className="m-auto align-items-center">
                                <Col xs={3}>
                                  <div className="d-flex align-items-center pl-3 pb-2">
                                    <span className={styles.subdetailsLabel}>
                                      Select Time
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-four"]} d-flex`}
                                  xs={9}
                                >
                                  {renderFields(
                                    fieldsCustomHour({
                                      id: idx,
                                      customInputClass: styles.input,
                                      customLabelClass: styles.inputLabel,
                                      isDisabledRate:
                                        !disabledFields[idx]
                                    }),
                                    { ...fourFieldProps, errors }
                                  )}
                                    <Button
                                      type="button"
                                      onClick={() => onAdd()}
                                      className={idx === 0 ? styles.addButtonCustom : styles.disableButtonCustom}
                                      disabled={idx !== 0}
                                    >
                                      +
                                    </Button>
                                </Col>
                              </Row>
                              <Row className={styles.checkboxRow}>
                                <label className={styles.checkboxFree1}>
                                  <input
                                    type="checkbox"
                                    checked={
                                      disabledFields[idx] || false
                                    }
                                    onChange={() =>
                                      handleHourlyRateCheckboxChange(idx)
                                    }
                                    className={styles.customCheckBox}
                                  />
                                  <span className={styles.checkboxLabel}>
                                    Different rate for disabled persons
                                  </span>
                                </label>
                              </Row>
                            </Row>
                          ))}
                        </React.Fragment>
                      </div>}
                      {renderFields(newFields.slice(3, 4), {...configFieldProps, errors})}
                    </Col>
                  }
                  {valet_type === 'time_blocks' && 
                    <Col className={`${styles.subTitle} px-0`}>
                      <div className='mb-3'>
                        Specify the rates to be charged within blocks of time. In addition to the rates, you may choose to charge customers for normal parking or not.
                      </div>
                      <div style={{marginRight: '-2px'}}>
                        <React.Fragment >
                          {timeBlock?.map((field, idx) => (
                            <Row key={field.id} style={{marginRight: '5px'}}>
                              <Row className={styles.customLabelRow1}>
                                <Col
                                  className={styles.customLabelSection4}
                                  xs={5}
                                >
                                  <label className={styles.customLabels4}>
                                    Duration (Hrs)
                                  </label>
                                </Col>
                                <Col
                                  className={styles.customLabelSection4}
                                  xs={3}
                                  style={{marginLeft: '-24px'}}
                                >
                                  <label className={styles.customLabels4}>
                                    Fee $
                                  </label>
                                </Col>
                                <Col
                                  className={styles.customLabelSection3}
                                  xs={4}
                                >
                                  <label className={styles.customLabels5}>
                                    Disabled Fee $
                                  </label>
                                </Col>
                              </Row>
                              <Row className="align-items-center">
                                <Col xs={2}>
                                  <div className="d-flex align-items-center pl-3 pb-2">
                                    <span className={styles.subdetailsLabel}>
                                      {`Block ${idx+1}`}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-four"]} d-flex`}
                                  xs={10}
                                >
                                  {renderFields(
                                    fieldsTimeBlock({
                                      id: field.id,
                                      customInputClass: styles.input,
                                      customLabelClass: styles.inputLabel,
                                      isDisabledRate:
                                        !disabledFields[field.id]
                                    }),
                                    { ...threeFieldProps, errors }
                                  )}
                                  {
                                    <Col className='pl-0' xs={2}>
                                      {idx === timeBlock.length - 1 &&  <label className={styles.checkboxFree2}>
                                        <input
                                          type="checkbox"
                                          checked={
                                            repeatFields[field.id]
                                          }
                                          onChange={() =>
                                            handleRepeatCheckboxChange(field.id)
                                          }
                                          className={styles.customCheckBox}
                                        />
                                        <span className={styles.checkboxLabel1}>
                                          Repeat
                                        </span>
                                      </label>}
                                    </Col>
                                  }
                                  {
                                    <Col className='pl-0' xs={1}>
                                      {idx === 0 ? (!repeatChecked &&
                                        <Button
                                          type="button"
                                          onClick={() => onTimeAdd()}
                                          className={styles.addButtonCustom}
                                        >
                                          +
                                        </Button>
                                      ) : 
                                      (
                                        <TrashIcon
                                          className={styles.trashIconStatic}
                                          onClick={() => onRemove(field.id)}
                                        />
                                      )}
                                    </Col>
                                  }
                                </Col>
                              </Row>
                              <Row className={styles.checkboxRow}>
                                <label className={styles.checkboxFree3}>
                                  <input
                                    type="checkbox"
                                    checked={disabledFields[field.id]}
                                    onChange={() => handleHourlyRateCheckboxChange(field.id)}
                                    className={styles.customCheckBox}
                                  />
                                  <span className={styles.checkboxLabel}>
                                    Different rate for disabled persons
                                  </span>
                                </label>
                              </Row>
                            </Row>
                          ))}
                        </React.Fragment>
                      </div>
                      {renderFields(newFields.slice(3, 4), {...configFieldProps, errors})}
                    </Col>
                  }
                </Col>
              </Col>
              <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={styles.button}>
                  Save
                </Button>
              </Col>
            </Row>
          )
        }}
      </Form>
    </Container>
  )
}

export default PricingModel;