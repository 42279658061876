import React from "react";
import { map } from "underscore";
import styles from "./index.module.sass";
import Action from "./action";
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { camelize } from 'components/helpers';

const TableRow = ({record, selectedRecord, modalType, setSelectedRecord, history, dateRange}) => {
  const actions = [
    { 
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => history.push(`/dashboard/reports/${record?.module_name}`, {reportName: record, dateRange: dateRange}, localStorage.setItem('initialCall', true)),
      text: 'View Report',
      isActive: modalType === 'edit' && selectedRecord === record.id,
    },
  ];

  return (
    <tr onClick={() => setSelectedRecord(record)}>
      <td>{record?.id}</td>
      <td>{record?.name}</td>
      <td>{camelize(record.report_type)}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;