import env from ".env";
import axios from "axios";
import fetchApi from "components/modules/fetch_api";

const auth = (username, password) => {
  return axios.post(`${env.backend_url}/v1/users/sign_in`, {
    user: { email: username, password: password, allow_login: true },
  });
};

const signUp = (data) => {
  return axios.post(`${env.backend_url}/v1/users/sign_up`, data);
};

const sendResetPasswordInstructionsRequestSubscriber = (username) => {
  return axios.post(
    `${env.backend_url}/v1/users/send_reset_password_instructions`,
    { user: { email: username } }
  );
};

const confirmUser = (username, otp) => {
  return axios.put(`${env.backend_url}/v1/users/confirm`, {
    user: { email: username, confirmation_token: otp },
  });
};

const sendConfirmationInstructions = (email) => {
  return axios.post(
    `${env.backend_url}/v1/users/send_confirmation_instructions`,
    { user: { email: email } }
  );
};

const resetPasswordRequestSubscriber = (password, resetPasswordToken) => {
  return axios.put(`${env.backend_url}/v1/users/reset_password`, {
    password,
    reset_password_token: resetPasswordToken,
  });
};

const checkPasswordTokenSubscriber = (token) => {
  return axios.post(`${env.backend_url}/v1/users/check_reset_password_token`, {
    token,
  });
};

const checkMe = () => {
  const critical = true;
  return fetchApi(`v1/users/me `, { method: "GET" }, critical);
};

const update = (params) => {
  return fetchApi(`v1/users/update_settings`, {
    method: "PUT",
    data: { user: params },
  });
};

const subscriberWalletTransactions = () => {
  return fetchApi(`v1/user/wallets/recent_transactions`, { method: "GET" });
};

const topUp = (params) => {
  return fetchApi(`v1/user/wallets`, {
    method: "PUT",
    data: { gateway_params: params },
  });
};

const handleSocialSignIn = (token, provider) => {
  return axios.post(`${env.backend_url}/v1/users/social_sign_up`, {
    token,
    provider,
  });
};

const getSignUpFormFields = (params) => {
  return fetchApi(`v1/form_fields`, { method: "GET", params: params });
};

const logout = () => {
  return fetchApi(`v1/users/sign_out`, { method: "DELETE" });
};

export {
  auth,
  sendResetPasswordInstructionsRequestSubscriber,
  resetPasswordRequestSubscriber,
  checkPasswordTokenSubscriber,
  update,
  checkMe,
  signUp,
  confirmUser,
  sendConfirmationInstructions,
  subscriberWalletTransactions,
  topUp,
  handleSocialSignIn,
  getSignUpFormFields,
  logout,
};
