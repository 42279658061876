import fetchApi from "components/modules/fetch_api";
import resourceApi from "components/modules/resource_api";

const { index, create, show, update } = resourceApi("permits");

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query } = params;

  return index({
    page,
    perPage,
    query: {
      ...query,
      ...filters,
    },
  });
};

const carPoolPermitFetcher = () => {
  return fetchApi(`dashboard/permits/car_pool_permits`, { method: 'GET' })
}

const importPermits = (data) => {
  return fetchApi(`dashboard/permits/import`, { method: 'PUT', data })  
}

const revokePermit = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/permits/${id}/revoke`, { method: 'PUT', data })
};

const renewPermit = ({id}) => {
  return fetchApi(`dashboard/permits/${id}/renew_temporary_permit`, { method: 'PUT' })
};

const extendPermit = (params) => {
  const { id, ...data } = params
  return fetchApi(`dashboard/permits/${id}/extend_temporary_permit`, { method: 'PUT', data })
};

const getOwnerDetails = (data) => {
  const {id_type, id_number} = data
  return fetchApi(`dashboard/permits/user_details`, { method: 'GET', params: {id_type, id_number} })
}
const getVehicleDetails = (plate_number) => {
  return fetchApi(`dashboard/mtas/fetch_details?plate_number=${plate_number}`, { method: 'GET' })
}

const generatePermitQrCode = (data) => {
  const { id } = data;
  return fetchApi(`dashboard/permits/${id}/generate_qr`, { method: 'GET' });
};

const sendPermitQrCode = (data) => {
  const { id } = data;
  return fetchApi(`dashboard/permits/${id}/send_qr_code`, { method: 'GET' });
};

export { carPoolPermitFetcher, create, filterFetcher, importPermits, revokePermit, show, update, extendPermit, renewPermit, getOwnerDetails, getVehicleDetails, generatePermitQrCode, sendPermitQrCode };
