import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const { index } = resourceApi('reports');
const { create } = resourceApi('report_schedulers');
const filterFetcher = (params = {}) => {
  const { page, perPage = 20, query, filters = {} } = params;
  if (filters.range) {
    var from = filters.range.from;
    var to = filters.range.to;
  }
  return index({
    page,
    perPage,
    query: {
      ...query,
      name: filters.name,
      type: filters.type,
      range: {
        from,
        to
      }
    }
  });
};

const systemReportIndex = (params = {}) => {
  const { id, page, perPage, query, nestedParams = {}, module_name, filters } = params;
  return fetchApi(
    generatePath(`dashboard/reports/system_report?module_name=${module_name}`, nestedParams),
    { method: 'GET', params: { id, page, per_page: perPage, ...query, ...filters } }
  );
};

const reportFilterFetcher = (params = {}) => {
  const { page, perPage = 20, filters = {}, query, module_name } = params;

  return systemReportIndex({
    page,
    perPage,
    query: {
      ...query,
    },
    filters,
    module_name
  });
};

const schduledReports = (params = {}) => {
  const { id, page, perPage, query, nestedParams = {}, module_name, filters } = params;
  return fetchApi(
    generatePath(`dashboard/report_schedulers/scheduled_reports?module_name=${module_name}`, nestedParams),
    { method: 'GET', params: { id, page, per_page: perPage, ...query, ...filters } }
  );
};

const deactivateSchedules = (params = {}) => {
  const { data } = params;
  return fetchApi(generatePath(`dashboard/report_schedulers/deactivate`), {
    method: 'PUT',
    data
  })
};

export { filterFetcher, reportFilterFetcher, create, schduledReports, deactivateSchedules };
