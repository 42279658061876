import React, { useRef, useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { renderFields } from 'components/base/forms/common_form'
import { appealFields } from 'components/helpers/fields/parking_citation_tickets/showFields'
import { isEmpty } from 'underscore'
import { search as dropdownsSearch } from "api/dropdowns";

const Appeal = ({ closeModals, appealCitation, isSaving, errors, setErrors, record, startFetching}) => {
  const [dropdowns, setDropdowns] = useState()
  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(
          dropdownsSearch("dispute_types_field")
        ).then((response) => setDropdowns(response.data)),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    dispute_type: dropdowns
  }

  const singleFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const fields = () => {
    return appealFields({...fieldAttrs})
  }
  
  const validateValues = (formState) => {
    let errors = {}
    const { dispute_type, detailed_reason, entry_time, exit_time } = formState;
    // eslint-disable-next-line
    if(!detailed_reason) {
      errors['detailed_reason'] = ['Detailed Reason can\'t be blank']
    }
    if(detailed_reason && detailed_reason.length > 500) {
      errors['detailed_reason'] = ['Detailed Reason can have max 500 characters']
    }
    if(!dispute_type) {
      errors['dispute_type'] = ['Dispute Type can\'t be blank']
    }
    if(dispute_type === 'time') {
      if(!entry_time || entry_time === "NaN:NaN") {
        errors['entry_time'] = ['Entry Time can\'t be blank']
      }
      if(!exit_time || exit_time === "NaN:NaN") {
        errors['exit_time'] = ['Exit Time can\'t be blank']
      }
    }
    if(entry_time !== "NaN:NaN" && exit_time !== "NaN:NaN" && entry_time >= exit_time) {
      errors['exit_time'] = ['Exit Time should be greater than Entry Time']
    }
    if(!detailed_reason && dispute_type) {
      errors['detailed_reason'] = ['Detailed Reason can\'t be blank']
    }
    return errors
   }

  const save = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash = {
      ...values,
      dispute_type: values.dispute_type.toLowerCase(),
      citation_ticket_id: record?.id
    }
    appealCitation(updatedHash);
  }

  return (
    <Container>
      <p className={`${styles.title} p-0`}>Appeal Citation</p>
      <Form getApi={setFormApi} className='m-auto' onSubmit={save}>
        {({ formState }) => {
          const newFields = fields()
          const {dispute_type} = formState.values
          return (
            <Row className='mx-auto w-100 p-0 pt-3'>
              <Col className={styles.modalContent}>
                {renderFields(newFields.slice(0, 1), {...singleFieldProps, errors})}
                {dispute_type === 'time' && <Row>
                  <Col className={styles.fieldset}>
                    {renderFields(newFields.slice(2, 3), {...doubleFieldProps, errors})}
                  </Col>
                  <Col className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                    {renderFields(newFields.slice(3, 4), {...doubleFieldProps, errors})}
                  </Col>
                </Row>}
                {renderFields(newFields.slice(1, 2), {...singleFieldProps, errors})}
              </Col>
              <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                <Button onClick={()=>  closeModals()} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={styles.button}  isLoading={isSaving}>
                  Save
                </Button>
              </Col>
            </Row>
          )
        }}
      </Form>
    </Container>
  )
}

export default Appeal;
