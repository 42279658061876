import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button } from "antd";
import {
  fetchPrivacyPolicy,
  acceptPrivacyPolicy,
} from "api/webapp/privacy_policy";
import ReactMarkdown from "react-markdown";
import styles from "./index.module.sass";
import "./markdown.css";
import { Spinner } from "reactstrap";

const PrivacyPolicyModal = (props) => {
  const { history } = props;
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  useEffect(() => {
    const getPrivacyPolicy = async () => {
      setIsFetching(true);
      try {
        const policy = await fetchPrivacyPolicy("account_types_list");
        setPrivacyPolicy(policy.data.body_1);
      } catch (_error) {
        console.log(_error);
      } finally {
        setIsFetching(false);
      }
    };
    if (!privacyPolicy) getPrivacyPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptPrivacyPolicy = useCallback(async () => {
    setIsAccepting(true);
    try {
      await acceptPrivacyPolicy();
      setIsModalVisible(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsAccepting(false);
    }
  }, []);

  const handleDecline = () => {
    setIsModalVisible(false);
    history.push("/subscriber/sign_out");
  };

  const renderTitle = () => {
    return (
      <div className={styles.header}>
        <p className={styles.title}>Privacy Policy</p>
      </div>
    );
  };

  return (
    <Modal
      title={renderTitle()}
      centered
      open={isModalVisible}
      loading={isFetching}
      onCancel={handleDecline}
      closable={false}
      width="90%"
      footer={[
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className={styles.declineBtn}
            key="decline"
            onClick={handleDecline}
          >
            Decline
          </Button>
          <Button
            className={styles.acceptBtn}
            key="accept"
            type="primary"
            onClick={handleAcceptPrivacyPolicy}
          >
            {isAccepting ? <Spinner size="sm" color="default" /> : "Accept"}
          </Button>
        </div>,
      ]}
    >
      <div
        className="react-markdown"
        style={{ height: "650px", maxHeight: "700px", overflowY: "auto" }}
      >
        <ReactMarkdown>{privacyPolicy}</ReactMarkdown>
      </div>
    </Modal>
  );
};

export default PrivacyPolicyModal;
