import React from "react";
import { filterFetcher } from "api/parking_lot_voi";
import IndexTable from "components/base/table";
import TableRow from "./table_row";
import styles from './index.module.sass'

const TableData = (props) => {
  const { list, match, history, setRecord ,openModal, modalType, activeTab , filters} = props;

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id} 
          record={record} 
          history={history}
          match={match}
          setRecord={setRecord} 
          openModal={openModal}
          modalType={modalType}
        />
      );
    });
  }
  return (
    <IndexTable
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      {...props}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      // showModalFilter={false}
      columns={
        <React.Fragment>
          <th attr="date_and_time">Date and Time</th>
          <th attr="plate_number">Vehicle Plate Number</th>
          <th attr="manufacturer">Vehicle Manufacturer</th>
          <th attr="model">Vehicle Model</th>
          <th attr="color">Vehicle Color</th>
          <th attr="violations_count">Violations Number</th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='VOI Vehicles'
    />
  )
}

export default TableData;