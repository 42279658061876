import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router';
import Index from 'components/pages/clients/index/index';

const Routing = ({ match }) => {
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Index} />
    </React.Fragment>
  );
}

Routing.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Routing);
