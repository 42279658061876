import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* API */
import { search as dropdownsSearch } from 'api/dropdowns';
/* Helpers */
/* Styles/Assets */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import withFetching from 'components/modules/with_fetching';
import {dateTimeDetails, exampleData} from 'components/helpers/fields/event_parking_applications/index'
import eventSettings  from '../../../helpers/fields/event_parking_applications/event_settings';
import styles from './index.module.sass'
import { renderFieldsWithGrid } from 'components/base/forms/common_form'
import { getParkingLots } from 'api/event_parking_applications';
import LocationForm from './location/form/index.jsx'


const initialValues = {  
  number_of_vehicles: 0,
  event_type: 'pvt'
}

const Create = (props) => {
  const  { setState ,state  , closeModal, errors, setErrors,isSaving,createApplication, setEventType, alertMsg, setAlertMsg } = props;
  const formApiRef = useRef();
  const [selectedDates, setSelectedDates] = useState([])
  const [nearbyParkingLots, setNearbyParkingLots] = useState()
  const [currentLocation , setCurrentLocation] = useState(exampleData)
  const [selectedParkingLots, setSelectedParkingLots] = useState([]);
  const [categories, setCategories] = useState([])
  const [totalAvailableSpaces, setTotalAvailableSpaces] = useState()
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const attrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDateInputClass:  formApiRef?.current?.getValue('location')===undefined? styles.formDateInput : styles['formDateInput-md']
  }

  useEffect(() => {
    const get_event_categories = async() => { await dropdownsSearch("event_categories_list").then((response) => setCategories(response.data))}
    get_event_categories()
  }, []) 
  
  
  const validateValues = (formState) => {
    const errors = {}
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const {event_name, event_category_id, event_time, event_type, contact_person_name, organizer_email, organizer_phone_number, event_dates,number_of_vehicles, location,attendees_cost_type, organizer_cost_type, attendees_cost, organizer_cost} = formState;   
    if(!event_name ) { errors['event_name'] = ['Event Name can\'t be blank']}
    if(!event_category_id) { errors['event_category_id'] = ['Event Category can\'t be blank']}
    if(!event_type) { errors['event_type'] = ['Please select Event Type']}
    if(!contact_person_name && event_type==='pvt') { errors['contact_person_name'] = ['Organizer Name can\'t be blank']}
    if(!organizer_email && event_type==='pvt') { errors['organizer_email'] = ['Organizer Email can\'t be blank']}
    if (organizer_email && !organizer_email?.match(isValidEmail)) {
      errors["organizer_email"] = ["Organizer Email is invalid!"];
    }
    if(!organizer_phone_number && event_type==='pvt') { errors['organizer_phone_number'] = ['Organizer Phone can\'t be blank']}
    if(isEmpty(event_dates)) { errors['event_dates'] = ['Please Select Event Date !']}
    if(!event_time) { errors['event_time'] = ['Please Select Event Time !']}
    if(!number_of_vehicles) { errors['number_of_vehicles'] = ['Number of Attendees can\'t be blank']}
    if(!location) { errors['location'] = ['Location can\'t be blank']}
    if(!attendees_cost_type && event_type === 'city') { errors['attendees_cost_type'] = ['Please Select Cost to Attendees']}
    if(!organizer_cost_type && event_type === 'pvt') { errors['organizer_cost_type'] = ['Please Select Cost to Organizer']}
    if(!attendees_cost && attendees_cost_type==='paid' && event_type === 'city') { errors['attendees_cost'] = ['Attendees Cost can\'t be blank']}
    if(!organizer_cost && organizer_cost_type==='paid' && event_type==='pvt') { errors['organizer_cost'] = ['Organizer Cost can\'t be blank']}
    return Object.assign(
     errors, 
     validateDateTimeAttrs())
  }

  const validateTimeAttrs = (attrs={}, date) => {
    const errors = {};
    const start_time = formApiRef.current.getValue(`event_start_time_${date}`)
    const end_time = formApiRef.current.getValue(`event_end_time_${date}`)
    if(start_time === undefined){errors[`event_start_time_${date}`] = ['Start Time is required !']}
    if(end_time === undefined){errors[`event_end_time_${date}`] = ['End Time is required !']}
    if(!!end_time && !!start_time && end_time === start_time) {errors[`event_end_time_${date}`] = ["End Time cannot have the same value"] }
    if(!!end_time && !!start_time && end_time < start_time) {errors[`event_start_time_${date}`] = ["Start Time cannot be greater than End Time"] }
    return errors
  }

  const validateDateTimeAttrs = (dateTimeAttrs={}) => {
    let errors = {}
    const timeSelected = formApiRef.current.getValue('event_time')
    if(timeSelected==='custom'){
      map(selectedDates, (date,idx) => {
      
        const fieldErrors = validateTimeAttrs(dateTimeAttrs, date)
        errors = {...errors, ...fieldErrors}
      })
    }else if(timeSelected==='same'){
      const fieldErrors = validateTimeAttrs(dateTimeAttrs, 'all')
      errors = {...errors, ...fieldErrors}
    }
    return errors;
  
  }



   const fetchNearbyParkingLots = async (values) => {
    setErrors({})
    setSelectedParkingLots([])
    setState('selectParkingLot', { selectedParkingLots:[], totalSelectedSpaces: 0, disableCheckboxes: false })
    const latitude = values?.ltd;
    const longitude = values?.lng;
    const zipcode = values?.zip;
    if(!values.ltd && !values.lng){
      setNearbyParkingLots([])
      return
    }
    try {
      const response = await getParkingLots({ltd: latitude, lng: longitude , zipcode: zipcode });
      setNearbyParkingLots(response.data);
    } catch (error) {
    }
  };

   const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        fetchNearbyParkingLots={fetchNearbyParkingLots}
      />
    );
  }
  

   
  const doubleFieldProps = {
    lSize: 3,
    iSize: 9,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

    
  const numberOfAttendeesFieldProps = {
    lSize: 3,
    iSize: 9,
    events: {
      onChange: (_e) => {setErrors({}); setState('selectParkingLot', { selectedParkingLots:[], totalSelectedSpaces: 0, disableCheckboxes: false })    }
    }
  }

  const datepickerFieldProps = {
    lSize: 3,
    iSize: 9,
    events: {
      onChange: (_e) => formatSelectedDates()
    }
  }

  const timeFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (_e) => setErrors({})
    }
    
  }

  const attendeeCostFieldProps = {
    lSize: 7,
    iSize: 5,
    events: {
      onChange: (_e) => {handleAttendeeInputChange(_e)}
    }  
  }

  const organzerCostFieldProps = {
    lSize: 7,
    iSize: 5,
    events: {
      onChange: (_e) => {handleOrganizerInputChange(_e)}
    }  
  }



  const handleAttendeeInputChange = (e) => {
    setErrors({})
    if(e?.value === 'free' && e?.label === 'Free') {
      formApiRef.current.setValue('attendees_cost', '0.0')
    }
  }


  const handleOrganizerInputChange = (e) => {
    setErrors({})
    if(e?.value === 'free' && e?.label === 'Free') {
      formApiRef.current.setValue('organizer_cost', '0.0')
    }
  }


  const formatSelectedDates = () => {
    setErrors({})
    const formattedDates = formApiRef?.current?.getValue('event_dates')?.map((date, idx) => {
      return `${date?.year}/${date?.month?.number < 10 ? `0${date?.month?.number}` : `${date?.month?.number}`}/${date?.day < 9 ? `0${date.day}` : `${date.day}`}`;
    })
    setSelectedDates(formattedDates)
  }

  
  const save = useCallback(() => {
    setErrors({})
    setAlertMsg('')
    const {values} = formApiRef.current.getState();
    values.event_dates_and_times = values.event_time ==='custom' ?  selectedDates?.map((date, idx) => {
      return {date: date, start_time: formApiRef.current.getValue(`event_start_time_${date}`), end_time: formApiRef.current.getValue(`event_end_time_${date}`)}
    }) : selectedDates?.map((date, idx) => {
      return {date: date, start_time: formApiRef.current.getValue(`event_start_time_all`), end_time: formApiRef.current.getValue(`event_end_time_all`)}
    })
    values.location = currentLocation
    values.parking_lot_ids = selectedParkingLots.map((lot, idx) => {return lot?.id})
    values.description = values.event_description
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors) || isEmpty(selectedParkingLots)){ 
      if(isEmpty(selectedParkingLots)){setAlertMsg("Please Select Parking Lots !")};
      setErrors(inputErrors)
      return
    };
    createApplication(values)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, selectedDates, selectedParkingLots, createApplication, currentLocation]);

  const getFields = (attendees_cost_type, organizer_cost_type) => {
    return eventSettings({
      mute: false,
      ...attrs,
      renderLocationModal: renderLocationModal.bind(this),
      icon: dollarIcon(),
      customIconInputClass: attrs.customInputClass.concat(
        " ",
        styles.iconInput
      ),
      cost_to_attendees: attendees_cost_type,
      cost_to_organizer: organizer_cost_type,
      customDropdownStyle: errors.event_category_id
        ? styles.dropDownInputError
        : styles.dropDownInput,
      categories,
    });
  };

  useEffect(() => {
    setErrors({});
    setAlertMsg('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  const handleCheckboxChange = (parkingLot) => {
    setAlertMsg('')
    const selectedParkingLots = state.selectedParkingLots.slice();
    const parkingLotIndex = selectedParkingLots.indexOf(parkingLot);
    if (parkingLotIndex === -1) {
      selectedParkingLots.push(parkingLot);
    } else {
      selectedParkingLots.splice(parkingLotIndex, 1);
    }
    setSelectedParkingLots(selectedParkingLots);
    const totalSelectedSpaces = selectedParkingLots.reduce((total, selected) => {
      return (total + selected.available_spaces_for_event) >= formApiRef?.current?.getValue('number_of_vehicles') ? formApiRef.current.getValue('number_of_vehicles') : total + selected.available_spaces_for_event 
    }, 0);
    const disableCheckboxes = (totalSelectedSpaces >= formApiRef?.current?.getValue('number_of_vehicles')) 
    setState('selectParkingLot', {
      selectedParkingLots,
      totalSelectedSpaces,
      disableCheckboxes,
    });
  };
  const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

  useEffect(() => {
    const totalParkingSpaces = () => {
      let totalSpaces = nearbyParkingLots?.reduce(function (prev, current) {
        return prev + +current.available_spaces_for_event;
      }, 0);
      setTotalAvailableSpaces(totalSpaces);
    };
    if(!isEmpty(nearbyParkingLots) && !isEmpty(selectedParkingLots)){totalParkingSpaces();}
  }, [nearbyParkingLots, selectedParkingLots])
  
  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Create New City Event</p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >

        {({ formState }) => {
            const {event_time , location, number_of_vehicles, event_type, attendees_cost_type, organizer_cost_type} = formState.values;
            setEventType(event_type)
            const newFields = getFields(attendees_cost_type,organizer_cost_type)
            return (
              <Row className='mx-auto w-100'>
                { map(newFields[0], (field, idx) => {
                  return (
                    <Col key={idx} className={`m-0 p-0 pl-1`} xs={12}>
                      { renderField(field, { ...doubleFieldProps, errors} )}
                    </Col>
                  )
                }) }
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[1], { ...doubleFieldProps,errors,formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[2], { ...doubleFieldProps,errors,formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[3], { ...doubleFieldProps, errors, formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[4], { ...doubleFieldProps, errors, formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[5], { ...doubleFieldProps, errors, formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[6], { ...doubleFieldProps, errors, formState} )}
                </Col>
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[7], { ...datepickerFieldProps, errors, formState} )}
                </Col>

                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[8], { ...datepickerFieldProps, errors, formState} )}
                </Col>

                {event_time==='custom' && 
                  <Col className='m-0 p-0 pl-2 pr-1'>
                  {     
                    map(selectedDates, (date, idx) => (
                      <React.Fragment key={date}>
                          <div className="d-flex align-items-center my-3 pl-1">
                            <span className={styles.subdetailsLabel}>{new Date(date).toLocaleDateString('en-in', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' })}</span>
                            <span>
                             <Col className={`m-0 p-0 ${styles['fieldset-double']}`}>
                                 {
                                  renderFieldsWithGrid(dateTimeDetails({date: date, customInputClass:styles.formInput, customLabelClass: styles.formLabel}), 2, 6 ,{ ...timeFieldProps, errors })
                                 }
                             </Col>
                          </span>
                          </div>
                      </React.Fragment>
                    )) 
                  }
                  </Col>
                }
                {event_time==='same' && 
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} my-3`}>
                    {
                     renderFieldsWithGrid(dateTimeDetails({date: 'all', customInputClass:styles.formInput, customLabelClass: styles.formLabel}), 2, 6 ,{ ...timeFieldProps, errors })
                    }
                  </Col>
                }
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[9], { ...numberOfAttendeesFieldProps, errors, formState} )}
                </Col>
                
                <Col className={`m-0 p-0 ${styles['fieldset-single']} pl-1`} xs={12}>
                  { renderFields(newFields[10], { ...doubleFieldProps, errors, formState} )}
                </Col>

                <label className={currentLocation && location ? `${styles.formLabel2}`: 'd-none'}>Parking Lots Nearby:</label>
                  <Row className={currentLocation && location  ? `mx-auto w-100`: 'd-none'}>
                  <Col className="d-flex flex-wrap justify-content w-100">
                    <div className={styles.scrollableContainer}>
                      {nearbyParkingLots?.map((parkingLot) => (
                        <div
                          key={parkingLot.name}
                          className={`${styles.parkingLotBox} ${
                            selectedParkingLots?.includes(parkingLot)
                              ? styles.selectedParkingLot
                              : ''
                          }` }
                          onClick={() => (!state.selectedParkingLots.includes(parkingLot) && state.disableCheckboxes) ? '' : handleCheckboxChange(parkingLot)}
                          >
                        <span>
                          <div className={`${styles.parkingLotCell}`}>
                            <input
                              type="checkbox"
                              checked={selectedParkingLots?.includes(parkingLot)}
                              onChange={() => handleCheckboxChange(parkingLot)}
                              disabled={(!state.selectedParkingLots.includes(parkingLot) && state.disableCheckboxes) }
                              className={styles.customCheckBox}
                            />
                            <span className={styles.boxText}>
                              <span style={{fontSize:"14px", fontWeight:500, color:"grey", position:"relative"}}>{parkingLot?.name} ({Math.round(((parseFloat(parkingLot?.distance?.split(" ")[0]) * 0.000621371192) + Number.EPSILON) * 100)/100 } mi) </span> <br/> {parkingLot?.location?.full_address}
                              <span className={styles.spacesTxt}>{parkingLot.available_spaces_for_event}</span>
                            </span>
                          </div>
                        </span>
                      </div>
                      ))}
                    </div>
                    {alertMsg &&
                      <p className={`${styles.alertMsg}`}>{alertMsg}</p>
                    }

                  </Col>
                </Row>
                {!isEmpty(nearbyParkingLots) && 
                  <Row className={styles.spacesSection}>
                    <Col>
                      <p className={styles.totalSpacesText}>Total Available Spaces: <span className={styles.countNumber}>{totalAvailableSpaces}</span></p>
                    </Col>
                      
                    <Col>
                      <p className={styles.totalBookedSpacesText}>Total Booked Spaces: <span className={styles.countNumber}>{state.totalSelectedSpaces}</span></p>
                      <p className={styles.totalNumberVehiclesText}>Total Number Of Vehicles: <span className={styles.countNumber}>{number_of_vehicles}</span></p>
                    </Col>
                  </Row>
                }
                {event_type === 'city' && 
                  <Col className={`m-0 p-0 ${styles['fieldset-single']} pr-3`} xs={12}>
                    {
                     renderFieldsWithGrid(newFields[11], 2, 6 ,{ ...attendeeCostFieldProps, errors})
                    }
                  </Col>
                }
                {event_type==='pvt' && 
                  <Col className={`m-0 p-0 ${styles['fieldset-single']} pr-3`} xs={12}>
                    {
                     renderFieldsWithGrid(newFields[12], 2, 6 ,{ ...organzerCostFieldProps,errors})
                    }
                  </Col>
                }
                <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                  <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                    Cancel
                  </Button>
                  <Button type='submit' className={`${styles.button}`}  isLoading={isSaving}>
                    Create
                  </Button>
                </Col>
              </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);
