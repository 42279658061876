import { FieldType } from "components/helpers/form_fields";

const vehicleDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true } = attrs;
  return [
    {
      name: 'vehicle[plate_number]',
      label: 'Plate Number',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[registration_state]',
      label: 'State',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[color]',
      label: 'Color',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[year]',
      label: 'Year',
      type: FieldType.NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[make]',
      label: 'Manufacturer',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
    {
      name: 'vehicle[model]',
      label: 'Model',
      customInputClass,
      customLabelClass,
      disabled: mute,
    },
  ]
}

export default vehicleDetails;