import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router';
import ValetLogin from 'components/pages/valet_login';
import ValetResetPassword from 'components/pages/valet_reset_password';

const Routing = ({ match }) => (
  <React.Fragment>
    <Route exact path={match.path} component={ValetLogin} />
    <Route exact path={`${match.path}/valet_reset_password`} component={ValetResetPassword} />
  </React.Fragment>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Routing);
