const residentialDetails = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, customInput3Class, customLabe2Class='' } = attrs;
  return [
    [
      {
        name: 'resident_address[address1]',
        label: 'Address Line 1',
        customInputClass: customInput3Class,
        customLabelClass: customLabe2Class,
        disabled: mute,
      },
      {
        name: 'resident_address[address2]',
        label: 'Address Line 2',
        customInputClass: customInput3Class,
        customLabelClass: customLabe2Class,
        disabled: mute,
      }
    ],
    [
      {
        name: 'resident_address[country_code]',
        label: 'Country',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'resident_address[postal_code]',
        label: 'Zip Code',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'resident_address[state_code]',
        label: 'State',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'resident_address[city]',
        label: 'City',
        customInputClass,
        customLabelClass,
        disabled: mute,
      }
    ]
  ]
}

export default residentialDetails;
