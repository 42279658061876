import React, { useCallback, useContext, useReducer } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styles from './index.module.sass'
import { create, update, destroy } from 'api/emails';
import { capitalize } from 'components/helpers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import Add from '../add';
import Edit from '../edit';
import Delete from '../delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const initState = { errors: {}, isSaving: false, parkingLots: [], permitTypes: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const EmailModal = (props) => {
  const { addListElement, record, openModal, modalType,setListElement, setActiveTab, popListElement, setErrors, errors } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
    setErrors({})
  }, [openModal, setActiveTab, setErrors])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const addEmail = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data})
      if(response?.status === 201 || response?.status === 200){
        addListElement({setList: setList, data: response?.data})
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: `${capitalize(response?.data?.email)} was successfully created`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors)
      setState('errors', error?.response?.data?.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, addListElement, addAlertMessages, closeModal])

  const editEmail = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await update({id: record.id, data})
      if(response?.status === 201 || response?.status === 200){
        setListElement(response.data)
        closeModal()
        addAlertMessages([{
          type: 'primary',
          text: `${capitalize(response.data.email)} was successfully changed`,
          onlyMessage: true
          }],
          'center'
        )
      }
    } catch (error) {
      setErrors(error.response.data.errors)
      setState('errors', error.response.data.errors)
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, record, addAlertMessages, closeModal])

  const deleteEmail = useCallback(async () => {
    setState('isDeleting', true)
    try {
      await destroy({id: record.id})
      popListElement({id: record.id})
      closeModal();
    } catch (error) {
      setState('errors', error.response.data.errors)
    }finally {
      setState('isDeleting', false)
    }
  },[setState,record, closeModal, popListElement])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'edit_email':
        return {title: 'Edit Email Address', maxWidth: '480px'}
      case 'delete_email':
        return {title: 'Are you Sure?', maxWidth: '480px' }
      case 'add_email':
        return {title: 'Add Email Address', maxWidth: '520px'}
      default:
        return {maxWidth}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[modalType, record.status]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'add_email' && 
          <Add
            closeModal={closeModal}
            addEmail={addEmail}
            errors={errors}
            setErrors={setErrors}
          />
        }
        {
          modalType === 'edit_email' && 
          <Edit
            closeModal={closeModal}
            record={record}
            editEmail={editEmail}
            errors={errors}
            setErrors={setErrors}
          />
        }
        {
          modalType === 'delete_email' && 
          <Delete
            state={state}
            closeModal={closeModal}
            deleteEmail={deleteEmail}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default EmailModal;
