import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import styles from './index.module.sass';
import { generatePermitQrCode } from 'api/permits';
import Loader from 'components/helpers/loader';
import Button from 'components/base/button';

const QrCode = (props) => {
  const { record, downloadQrCode, sendQrToEmail } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const generateQrCode = async (data) => {
      setIsFetching(true);
      try {
        const response = await generatePermitQrCode(data);
        setImageUrl(response?.data?.qr_code);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };
    generateQrCode(record);
  }, [record]);

  if (isFetching) return <Loader />;

  return (
    <Container className='p-0 my-3'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{record.permit_type}</p>
      <div className={styles.container}>
        <img alt={'issued permit qr code'} src={imageUrl} className={styles.qr_image} />
        <div className={styles.buttonContainer}>
          <Button onClick={() => downloadQrCode(record.id)} className={styles.button}>
            Download QR
          </Button>
          <Button onClick={() => sendQrToEmail(record)} className={styles.button}>
            Share via Email
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default QrCode;
