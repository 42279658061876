import React, { useEffect, useCallback, useState } from 'react'
/* Styles/Assets */
import { ReactComponent as SettingIcon } from 'assets/setting-gear-icon.svg';
import styles from './index.module.sass';
/* Base */
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { Col, Row } from 'reactstrap';
import Tabs from 'components/base/tabs';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import { ReactComponent as PlusIcon } from 'assets/plus_icon.svg';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { useHistory } from "react-router-dom";
import { index } from 'api/valet_parkings';
import Button from 'components/base/button';
import { search as dropdownsSearch } from "api/dropdowns";
import FilterForm from 'components/pages/valet_parkings/valet_staff_management/index/filterForm.jsx';

const tabs = [
  { label: 'Value Added Services', value: 'value_added_services' },
  { label: 'Staff Management', value: 'staff_management' },
  { label: 'Valet Sessions', value: 'valet_sessions' },
  { label: 'Bookings & Requests', value: 'bookings' },
  { label: 'Vehicle Damage Claims', value: 'vehicle_damage_claims' },
  { label: 'Analytics', value: 'analytics' },
]

const CollapseContent = ({ startFetching, setList, setFilters, match, resource, currentUser}) => {
  const [dropdowns, setDropdowns] = useState({
    parkingLots: []
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("valet_parking_list", { admin_id: currentUser.id })
        ).then((response) => setDropdowns(state => ({...state, parkingLots: response.data }))),
      ])
    }

    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm 
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
      />
    </Row>
  )
}

const ToolBar = (props) => {
  const {openModal, activeTab, setListType, valetStatus, listType, setValetStatus, setActiveTab, isFetching} = props
  const history = useHistory()
  

  const handleTabsChange = (tab) => {
    setListType(tab);
    history.push(`/dashboard/${tab}`, {
      listType: listType,
    });
  };

  useEffect(() => {
    const showValetSetting = async () => {
      try {
        const response = await index()
        if(response.status===200){
          Object.values(response.data).forEach(function (key) {
            if(key.valet_enabled) { 
              setValetStatus(true)
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    showValetSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[valetStatus])

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
       <Col className='d-flex justify-content-start align-items-center p-0'>
        <Tabs className={styles.tabs} list={tabs} defaultTab={listType} onClick={handleTabsChange} classNameInnerTab={styles.innerTab}/>
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          onClick={() => openModal('pricing_model')}
          icon={<PlusIcon className='svg-white'/>}
          isActive={true}
        >
          <span className='mr-3 d-inline-block'>
            Pricing Models
          </span>
        </SlideButton>
        {listType === 'value_added_services' && 
          <SlideButton
            onClick={() => openModal('create_service')}
            icon={<PlusIcon className='svg-white'/>}
          >
            <span className='mr-3 d-inline-block' style={{width: '201px'}}>
              Create Value Added Service
            </span>
          </SlideButton>
        }
        {listType === 'staff_management' && 
          <SlideButton
            onClick={() => openModal('create_staff')}
            icon={<PlusIcon className='svg-white'/>}
          >
            <span className='mr-3 d-inline-block' style={{width: '131px'}}>
              New Staff Account
            </span>
          </SlideButton>
        }
        {valetStatus && 
          <SlideButton
            onClick={() => openModal('edit')}
            icon={<PenToSquareSvg />}
          >
            <span className='mr-3 d-inline-block' style={{width: '184px'}}>
              Edit Valet Parking Service
            </span>
          </SlideButton>
        }
        <SlideButton
          className={`${valetStatus ? styles.activeSettingTab : styles.inActiveSettingTab}`}
          onClick={() => openModal(`${valetStatus ? 'disable' : 'enable'}`)}
          icon={<SettingIcon onClick={() => openModal('edit')}/>}
        >
          <span className='mr-3 d-inline-block' style={{width: 'fit-content'}}>
            {`${valetStatus ? 'Disable' : 'Enable'} Valet Parking Service`}
          </span>
        </SlideButton>
        {listType === 'staff_management' && <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />}
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;