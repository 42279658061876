import React, { useCallback, useContext, useMemo, useReducer, useState, useRef } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Edit from '../edit';
import Create from '../create';
import Delete from '../delete';
import IssuePermit from '../issue_permit';
/* Assets */
import styles from './index.module.sass'
/* API */
import { create, destroy, update } from 'api/permit_types';
import { create as issuePermit } from 'api/permits';
import { capitalize } from 'components/helpers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { isEmpty } from 'underscore';
import ActivateDeactivate from '../activate_deactivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddVehicle from '../add_vehicle';
import PermitSettingConfirmation from '../permit_setting_confirmation';
import { updateSetting as updatePermitSetting } from 'api/permit_applications';
import AllowOpenCitation from '../allow_open_citation';

const initState = { errors: {}, isSaving: false, parkingLots: [], permitTypes: []}

const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    case 'innerModal':
      return { ...state, innerModal: payload }
    default:
      return { ...state };
  }
}

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const PermitModal = (props) => {
  const { addListElement, record, openModal, modalType, setListElement, popListElement, setActiveTab, 
          currentLocation, setCurrentLocation, userLocation, setUserLocation, setting,
          allowMultipleVehicle, allowMultipleDailyPermit, setAllowMultipleVehicle, setAllowMultipleDailyPermit } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [isFetching, setIsFetching] = useState(false)
  const [errors, setErrors] = useState({})
  const [specialParkingLots, setSpecialParkingLots] = useState([]);
  const [commuterId, setCommuterId] = useState()
  const [userType, setUserType] = useState() 
  const [commuterVehicleAttributes, setCommuterVehicleAttributes] = useState({})
  const [vmanufacturers, setVManufacturers] = useState([])
  const [issuePermitData, setIssuePermitData] = useState()
  const [openCitationCount, setOpenCitationCount] = useState()

  const closeModal = useCallback( () => {
    setState('innerModal', null)
    openModal()
    setActiveTab()
    setErrors({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const innerModal = (innerModalType) => {
    setState('innerModal', innerModalType)
  }

  const innerCloseBtn = useCallback(() => {
    return <FontAwesomeIcon 
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={() => {setState('innerModal', null)}}
    />
  },[setState]);

  const addPermitType = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await create({data: {permit_type: data}})
      addListElement({setList: setList, data: response.data})
      closeModal()
      addAlertMessages([{
        type: 'primary',
        text: `${capitalize(response.data.name)} was successfully created`,
        onlyMessage: true
        }],
        'center'
      )
    } catch (error) {
      setErrors(error.response.data.errors)
      if(error.response.data?.errors?.parking_lots){
        setErrors({...errors, parking_lot_ids: error.response.data?.errors?.parking_lots})
      }
    } finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, addListElement, addAlertMessages, closeModal])
  
  const updatePermitType = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await update({data: {permit_type: data}, id: record.id })
      setListElement(response.data)
      closeModal();
      addAlertMessages([{
        type: 'primary',
        text: `${capitalize(response.data.name)} was successfully edited`,
        onlyMessage: true
        }],
        'center'
      )
    } catch (error) {
      setState('errors', error?.response?.data?.errors)
      setErrors(error.response.data.errors)
      if(error.response.data?.errors?.parking_lots){
        setErrors({...errors, parking_lot_ids: error.response.data?.errors?.parking_lots})
      }
    }finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, addAlertMessages, setListElement, record.id, closeModal, errors])

  const deletePermitType = useCallback(async () => {
    setState('isDeleting', true)
    try {
      await destroy({id: record.id})
      popListElement({id: record.id})
      closeModal();
    } catch (error) {
      setState('errors', error.response.data.errors)
    }finally {
      setState('isDeleting', false)
    }
  },[setState, popListElement, record.id, closeModal])

  const issueUserPermit = useCallback(async(data) => {
    setState('isSaving', true)
    try {
      await issuePermit({ data: { permit: data } })
      closeModal()
      addAlertMessages([{
          type: 'primary',
          text: 'Parking Permit Issued.',
          onlyMessage: true
        }],
        'center'
      )
      localStorage.removeItem('allCommutersData');
    } catch (error) {
      setState('errors', error.response.data.errors)
      if(error.response.data?.errors?.email) setErrors({...errors, 'primary_user_attributes[email]': error.response.data?.errors?.email})
      if(!isEmpty(error?.response?.data?.errors?.citation_ticket)){
        setIssuePermitData(data)
        setOpenCitationCount(error?.response?.data?.errors?.citation_ticket?.[0].split(' ').at(-1))
        innerModal('allow_open_citation')
      }
    }
    setState('isSaving', false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, addAlertMessages, closeModal, errors])

  const toggleStatus = useCallback(async (attrs) => {
    try {
      const response  = await update({data: {permit_type: attrs}, id : record.id})
      closeModal()
      const message = `The ${response.data.name} permit type is successfully ${response.data.status === 'active' ? 'activated' : 'deactivated'}`
      addAlertMessages([{
        type: response.data.status === 'active' ? 'primary' : 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement, closeModal])

  const permitSettingData = useMemo(() => {
    if (setting === 'vehicle_setting'){
      return {allow_to_add_multiple_vehicle: allowMultipleVehicle ? 'false' : 'true'};
    }else{
      return {apply_for_multiple_daily_permit: allowMultipleDailyPermit ? 'false' : 'true'};
    }
  }, [setting, allowMultipleVehicle, allowMultipleDailyPermit]);

  const updatePermitSettings = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await updatePermitSetting(permitSettingData)
      if(response.status === 200){
        setAllowMultipleVehicle(response?.data?.allow_to_add_multiple_vehicle)
        setAllowMultipleDailyPermit(response?.data?.apply_for_multiple_daily_permit)
        addAlertMessages([{
          type: 'primary',
          text: 'Permit Settings were successfully saved',
          onlyMessage: true,
        }], 'center')
      }
    } catch (error) {
      setState('errors', error.response.data.errors)
      addAlertMessages([{
        type: 'danger',
        text: error.response.data.errors.status,
        onlyMessage: true,
      }], 'center')
    } finally {
      closeModal()
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, closeModal, addAlertMessages, setAllowMultipleDailyPermit, setAllowMultipleVehicle, setting, permitSettingData])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'edit':
        return {title: 'Edit Permit Type', maxWidth: '715px'}
      case 'delete':
        return {title: 'Are you Sure?', maxWidth }
      case 'create':
        return {title: 'Create New Permit Type', maxWidth: '715px'}
      case 'issue_permit':
        return {title: 'Issue Permit', maxWidth: '715px'}
      case 'activate_deactivate':
        return { title: `Permit Type ${record.status === 'active' ? 'Deactivation' : 'Activation' }`, maxWidth}
      default:
        return {title: '', maxWidth}
    }
  },[modalType, record.status]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>

        {
          modalType === 'activate_deactivate' && 
          <ActivateDeactivate
            permitName={record.name}
            closeModal={closeModal}
            toggleStatus={toggleStatus}
            status={`${record.status === 'active' ? 'deactivate' : 'activate'}`}
          />
        }
        {
          modalType === 'issue_permit' &&
            <IssuePermit
              {...props}
              issueUserPermit={issueUserPermit}
              state={state}
              setState={setState}
              closeModal={closeModal}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              errors={errors}
              setErrors={setErrors}
              setSpecialParkingLots={setSpecialParkingLots}
              specialParkingLots={specialParkingLots}
              openInnerModal={innerModal}
              commuterId={commuterId}
              setCommuterId={setCommuterId}
              setUserType={setUserType}
              setVManufacturers={setVManufacturers}
            />
        }
        {
          modalType === 'edit' && 
          <Edit
            state={state}
            setState={setState}
            closeModal={closeModal}
            record={record}
            updateRecord={updatePermitType}
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
            userLocation={userLocation}
            setUserLocation={setUserLocation}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            errors={errors}
            setErrors={setErrors}
            setSpecialParkingLots={setSpecialParkingLots}
            specialParkingLots={specialParkingLots}
            modalType={modalType}
          />
        }

        {
          modalType === 'create' && 
          <Create
            state={state}
            setState={setState}
            closeModal={closeModal}
            addRecord={addPermitType}
            currentLocation={currentLocation}
            setCurrentLocation={setCurrentLocation}
            userLocation={userLocation}
            setUserLocation={setUserLocation}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            errors={errors}
            setErrors={setErrors}
            setSpecialParkingLots={setSpecialParkingLots}
            specialParkingLots={specialParkingLots}
            openInnerModal={innerModal}
          />
        }

        {
          modalType === 'delete' && 
            <Delete
              state={state}
              setState={setState}
              closeModal={closeModal}
              deletePermitType={deletePermitType}
              record={record}
            />
        }
        { 
          modalType === 'permit_setting_confirmation' && 
          <PermitSettingConfirmation 
            updatePermitSettings={updatePermitSettings}
            closeModal={closeModal}
            setting={setting}
            setAllowMultipleVehicle={setAllowMultipleVehicle}
            setAllowMultipleDailyPermit={setAllowMultipleDailyPermit}
            allowMultipleVehicle={allowMultipleVehicle}
            allowMultipleDailyPermit={allowMultipleDailyPermit}
          />
        }
        {!!state.innerModal &&
          <Modal
            isOpen={!!state.innerModal}
            centered
            backdropClassName={styles.backdropC}
            style={{maxWidth: state.innerModal === 'allow_open_citation'? '480px' : '580px', width: '100%'}}
          >
            { !!state.innerModal && <ModalHeader close={innerCloseBtn()} className='border-0 pb-0' /> }
            <ModalBody>
              {state.innerModal === 'add_vehicle' && 
                <AddVehicle
                  renewPermit='add_vehicle'
                  closeModals={() => { setState('innerModal', null)}}
                  isSaving={state.isSaving}
                  errors={errors}
                  setErrors={setErrors}
                  setState={setState}
                  commuterId={commuterId}
                  setFormApi={setFormApi}
                  formApiRef={formApiRef}
                  userType={userType}
                  setCommuterVehicleAttributes={setCommuterVehicleAttributes}
                  commuterVehicleAttributes={commuterVehicleAttributes}
                  manufacturers={vmanufacturers}                  
                  
                />
              }
              {state.innerModal === 'allow_open_citation' && 
                <AllowOpenCitation
                  closeModal={() => { setState('innerModal', null)}}
                  issuePermitData={issuePermitData}
                  issueUserPermit={issueUserPermit}
                  openCitationCount={openCitationCount}
                />
              }
            </ModalBody>
          </Modal>
        }
      </ModalBody>
    </Modal>
  )
}

export default PermitModal;