import React, {
  useCallback,
  useMemo,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";
import { Form } from "informed";
import { Col, Row } from "reactstrap";
/* API */
import { update } from "api/parking_lots";
/* Base */
import { renderFields } from "components/base/forms/common_form";
import Button from "components/base/button";
/* Helpers */
import { fieldsDetailedShow } from "components/helpers/fields/parking_lots";
/* Styles/Assets */
import styles from "./index.module.sass";
/* Helpers */
import { AlertMessagesContext } from "components/helpers/alert_messages";
import { search as dropdownsSearch } from "api/dropdowns";
import LocationForm from "components/pages/parking_lots/shared/location/form/index";
import { isEmpty, map } from "underscore";
import withCurrentUser from "components/modules/with_current_user";
import { Label } from "reactstrap";
import { ReactComponent as CloudDownloadIcon } from "assets/cloud-download.svg";
import ViolationFinesSection from "components/pages/parking_lots/shared/violation_fines";
import { index as ParkingRules } from "api/parking_rules";
import { cloneDeep } from "lodash";
import { Spinner } from "reactstrap";
import { capitalize } from "components/helpers";
import { ConfigProvider, Input } from "antd";
import { ReactComponent as MoreIcon } from "assets/union-dots-primary.svg";

const fieldProps = { lSize: 4, iSize: 8 };

const ParkingLotForm = (props) => {
  const {
    currentUserPermissions,
    state,
    record,
    setState,
    setRecord,
    currentUser,
    openModal,
  } = props;
  const [parkingAdmins, setParkingAdmins] = useState([]);
  const [townManagers, setTownManagers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [currentLocation, setCurrentLocation] = useState();
  const formApiRef = useRef();
  const [fileName, setFileName] = useState();
  const fileRef = useRef();
  const [image, setImage] = useState(null);
  const [parkingRules, setParkingRules] = useState([]);
  const [updatedParkingRules, setUpdatedParkingRules] = useState(parkingRules);
  const { errors, isSaving } = state;
  const { addAlertMessages } = useContext(AlertMessagesContext);

  const weekDays = [
    {
      label: "Monday",
      value: "monday",
    },
    {
      label: "Tuesday",
      value: "tuesday",
    },
    {
      label: "Wednesday",
      value: "wednesday",
    },
    {
      label: "Thursday",
      value: "thursday",
    },
    {
      label: "Friday",
      value: "friday",
    },
    {
      label: "Saturday",
      value: "saturday",
    },
    {
      label: "Sunday",
      value: "sunday",
    },
  ];

  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    const get_admins_roles = async () => {
      await dropdownsSearch("admins_by_role-parking_admin", {
        admin_id: currentUser?.id,
      }).then((response) => setParkingAdmins(response.data));
    };
    const get_town_managers = async () => {
      await dropdownsSearch("admins_by_role-town_manager").then((response) =>
        setTownManagers(response.data)
      );
    };
    const get_agencies = async () => {
      await dropdownsSearch("agency_list").then((response) =>
        setAgencies(response.data)
      );
    };

    if (isEmpty(parkingAdmins)) {
      get_admins_roles();
    }
    if (isEmpty(townManagers)) {
      get_town_managers();
    }
    if (isEmpty(agencies)) {
      get_agencies();
    }
  }, [agencies, currentUser, parkingAdmins, townManagers]);

  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const values = useMemo(() => {
    const values = Object.assign({}, record);
    values.agency_id = record?.agency_id;
    values.parking_admin_id = record?.parking_admin?.id;
    values.email = record.email;
    values.parking_lot = record?.parking_lot?.name || "";
    values.town_manager_id = record?.town_manager?.id;
    values.status = record.status;
    values.location = record.location;
    values.occupied_spaces = record.occupied_slots_count;
    values.violation_fine = parseFloat(
      record?.setting?.violation_fine?.split("$")[1]
    );
    setSelectedDays(record?.setting?.active_days);
    setCurrentLocation(record?.location);
    setImage(record?.avatar);
    return values;
  }, [record]);

  const save = useCallback(
    async (values) => {
      values.location = cloneDeep(currentLocation);
      values.rules = !isEmpty(updatedParkingRules)
        ? updatedParkingRules
        : parkingRules;
      values.setting = {};
      values.setting.violation_fine = values.violation_fine;
      try {
        setState("isSaving", true);
        const response = await update({
          data: { parking_lot: values },
          id: record.id,
        });
        if (response.status === 200) {
          setRecord(response.data);
          addAlertMessages(
            [
              {
                type: "primary",
                text: "Changes Saved",
                onlyMessage: true,
              },
            ],
            "center"
          );
        }
      } catch (error) {
        formApiRef.current.reset();
        setState("errors", error.response?.data?.errors);
      } finally {
        setState("isSaving", false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      addAlertMessages,
      setState,
      record.id,
      setRecord,
      updatedParkingRules,
      parkingRules,
      currentLocation,
    ]
  );

  const attrs = useMemo(() => {
    return {
      currentUserPermissions,
      admins: parkingAdmins,
      managers: townManagers,
      agencies,
      customInputClass: styles.formInput,
      customLabelClass: styles.formLabel,
    };
  }, [currentUserPermissions, agencies, parkingAdmins, townManagers]);

  const renderLocationModal = (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
      />
    );
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    } else {
      setFileName(e.target.files[0]?.name);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (
        e.dataTransfer.files.format === ".pdf" ||
        e.dataTransfer.files.format === ".png" ||
        e.dataTransfer.files.format === ".jpg"
      ) {
        setFileName(e.dataTransfer.files[0].name);
        fileRef.current = { files: e.dataTransfer.files };
      }
    }
  };

  const fetchParkingRules = async () => {
    try {
      const { data } = await ParkingRules({
        query: { parking_lot_id: record?.id },
      });
      setParkingRules(data.filter((rule)=> rule.status));
    } finally {
    }
  };
  useEffect(() => {
    if (isEmpty(parkingRules)) {
      fetchParkingRules();
    }
  });

  const btnSpinner = (props = {}) => {
    return (
      <span>
        <Spinner {...props} size="sm" color="default" />
      </span>
    );
  };

  const getFields = () => {
    return fieldsDetailedShow({
      ...attrs,
      renderLocationModal: renderLocationModal.bind(this),
      admins: parkingAdmins,
      managers: townManagers,
      agencies: agencies,
      customToggleOnClass: styles.toggleOn,
      customToggleClass: styles.toggle,
    });
  };

  const handleCheckboxChange = (day) => {
    const updatedSelectedDays = [...selectedDays];

    if (!updatedSelectedDays.includes(day)) {
      updatedSelectedDays.push(day);
    } else {
      const dayIndex = updatedSelectedDays.indexOf(day);
      updatedSelectedDays.splice(dayIndex, 1);
    }

    setSelectedDays(updatedSelectedDays);
  };

  const fields = getFields();

  const renderViolationFines = useCallback(() => {
    return (
      <ViolationFinesSection
        parkingLotId={record.id}
        setFormApi={setFormApi}
        formApiRef={formApiRef}
        parkingRules={parkingRules}
        setUpdatedParkingRules={setUpdatedParkingRules}
        updatedParkingRules={updatedParkingRules}
        state={state}
        record={record}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkingRules]);

  return (
    <React.Fragment>
      {
        <Form
          getApi={setFormApi}
          initialValues={values}
          onSubmit={save}
          className={styles.form}
        >
          <Row className="m-auto align-items-center">
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(0, 1), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(1, 2), { ...fieldProps, errors })}
            </Col>
            <Col>
              <span>
                <label className={styles.fileLabel}>Parking Lot Picture</label>
              </span>
              <div
                style={{ display: "flex" }}
                onDragEnter={handleDrag}
                onDrop={handleDrop}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
              >
                <input
                  ref={fileRef}
                  name="id_proof"
                  className="d-none"
                  id="id-proof-file-upload"
                  type="file"
                  accept=".jpg, .png, .jpeg, .pdf"
                  onChange={showFileName}
                />
                <Label
                  htmlFor="id-proof-file-upload"
                  className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}
                >
                  <span className="mb-2 mt-3">
                    {image ? (
                      <img className={styles.img} alt="Lot" src={image} />
                    ) : (
                      <CloudDownloadIcon className={styles.cloudIcon} />
                    )}
                  </span>

                  {fileName && (
                    <span className={styles.fileName}>{fileName}</span>
                  )}
                  <span>
                    <span className={styles.boldText1}>Choose a file</span>
                    <span className="ml-1">or drag it here</span>
                  </span>
                  <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
                </Label>
              </div>
            </Col>

            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(2, 3), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(3, 4), { ...fieldProps, errors })}
            </Col>
            <Col></Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(4, 5), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(5, 6), { ...fieldProps, errors })}
            </Col>
            <Col />
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(6, 7), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(7, 8), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(8, 9), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(9, 10), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(12, 13), { ...fieldProps, errors })}
            </Col>
            <Col className={`m-0 ${styles.fieldset}`} xs={4}>
              {renderFields(fields.slice(11, 12), { ...fieldProps, errors })}
            </Col>

            <Col className="m-0 pt-3" xs={12}>
              <div className="d-flex align-items-center my-3">
                <span className={styles.detailsLabel}>Parking Days</span>
                <span className="border border-secondary flex-grow-1 ml-2"></span>
              </div>
              <div className={styles.daysContainer}>
                {map(weekDays, (d, idx) => {
                  return (
                    <div>
                      <label className={styles.checkboxText}>
                        <input
                          type="checkbox"
                          checked={selectedDays?.includes(d.value)}
                          onChange={() => handleCheckboxChange(d.value)}
                          className={styles.customCheckBox}
                        />
                        <span className={styles.dayLabel}>{`${capitalize(
                          d?.label
                        )}`}</span>
                      </label>
                    </div>
                  );
                })}
              </div>

              <Col className="m-0 p-0 pt-3">
                <div className="d-flex align-items-center my-3 pt-2">
                  <span className={styles.detailsLabel}>Parking Hours</span>
                  <span className="border border-secondary flex-grow-1 ml-2"></span>
                </div>
                <div className={styles.parkingHourFieldSections}>
                  <label className={styles.parkingHoursLabel}>All Days</label>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorTextDisabled: "#242E42",
                      },
                    }}
                  >
                    <Input
                      value={
                        record?.setting?.active_day_setting === "hourly_static"
                          ? "Hourly Static"
                          : record?.setting?.active_day_setting ===
                            "hourly_dynamic"
                          ? "Hourly Dynamic"
                          : "Custom"
                      }
                      disabled={true}
                      suffix={
                        <span
                          onClick={() => openModal("settings")}
                          className={styles.iconSection}
                        >
                          <MoreIcon className={styles.moreIcon} />
                        </span>
                      }
                      size="large"
                      className={styles.parkingHourInput}
                    />
                  </ConfigProvider>
                </div>
              </Col>
              <Col className="m-0 p-0 pt-3">
                <div className="d-flex align-items-center my-3 pt-2">
                  <span className={styles.detailsLabel}>Violation Fines</span>
                  <span className="border border-secondary flex-grow-1 ml-2"></span>
                </div>

                <div className="mt-1" />
                {renderViolationFines()}
              </Col>
            </Col>

            <Col className="d-flex justify-content-center pt-3 m-0" xs={12}>
              <Button
                className={`${styles.btn} ${styles["background-primary"]}`}
                type="submit"
                isLoading={isSaving}
              >
                {isSaving
                  ? btnSpinner({ className: "spinner-border" })
                  : "Save Changes"}
              </Button>
            </Col>
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default withCurrentUser(ParkingLotForm);
