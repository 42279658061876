import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col, 
  Row
} from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";
import withCurrentSubscriber from "components/modules/with_current_subscriber";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as ArrowDownIcon } from "assets/menu_icons/arrow_down_icon.svg";
import { ReactComponent as TranslatorIcon } from "assets/app/translator_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import CurrentTime from "components/pages/dashboard/current_time";
import styles from "./header.module.sass";
import { unread } from 'api/webapp/notifications';
import LanguageSelector from "../language_selector";
import { useTranslation } from "react-i18next";
import ReportIncidentModal from "../../pages/report_incident";
import { ToastContainer, toast } from "react-toastify";

function WebappHeader(props) {
  const history = useHistory();
  const { t } = useTranslation()
  const { currentSubscriber } = props;
  const [willLogOut, setWillLogOut] = useState(false);
  const [count, setCount] = useState();
  const [reportModal, setReportModal] = useState(false)

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      const response = await unread();
      if(response?.status === 200){
        setCount(response?.headers['x-total'])
      }
    }
    // eslint-disable-next-line
    if(currentSubscriber) fetchUnreadNotifications();
  },[currentSubscriber])

  return (
    <div className={styles.container}>
    <Navbar sticky="top"  light expand className={styles.navbar}>
        <Link
          to={currentSubscriber? "/subscriber" : "/homepage"}
          className={`${styles.logoLink} d-flex align-items-center ml-4 btn-default text-light pointer`}
        >
          <Logo />
          <div className="ml-1">
            <div className={styles.title}>{t("navbar.header")}</div>
            <div className={`${styles.whiteText} general-text-2`}></div>
          </div>
        </Link>
        <Button
          type="primary"
          onClick={()=> setReportModal(true)}
          className={styles.reportButton}
        >
          Report an Incident
        </Button>
        <Nav className="ml-auto" navbar>
          <Col className="d-flex justify-content-center align-items-center" style={{paddingRight: `${currentSubscriber ? '9rem' : ''}`}}>
            <TranslatorIcon className={styles.translatorIcon} />
            <LanguageSelector currentSubscriber={currentSubscriber}/>
          </Col>
          {currentSubscriber && <Col className="d-flex justify-content-center align-items-center p-0" style={{paddingRight: '1rem'}}>
            {count>0 && <span className={`badge badge-light ${styles.notificationCount}`}>{count}</span>}
            <Button className={styles.notificationButton} onClick={() => history.push('/subscriber/notifications')}>
              Notifications
            </Button>
            </Col>}
          <UncontrolledDropdown
            nav
            inNavbar
            className="d-flex align-items-center"
          >
            <DropdownToggle nav className="text-light float-right">
              {currentSubscriber ? (
                <span className={`${styles.dFlex} align-items-center`}>
                  <img
                    src={
                      currentSubscriber.avatar ||
                      "https://i.stack.imgur.com/34AD2.jpg"
                    }
                    alt="profile"
                    className="rounded-circle mr-2"
                    width="40"
                    height="40"
                  />
                  <span className="d-none d-sm-inline">
                    <DropdownToggle nav className="float-right text-light pl-0">
                      <ArrowDownIcon className={styles.arrowDownIcon} />
                    </DropdownToggle>
                    <span className={`${styles.dFlex} ${styles.dFlexColumn}`}>
                      <span className={styles.username}>
                        {process.env.NODE_ENV !== "production"
                          ? currentSubscriber.first_name
                          : currentSubscriber.first_name}
                      </span>
                      <CurrentTime className={styles.currentTime} />
                    </span>
                  </span>
                </span>
              ) : (history?.location?.pathname !== "/login" && 
                <Row>
                  <Col xs={6}>
                    <Button
                      className={styles.logInBtn}
                      onClick={() => history.push("/login")}
                    >
                      {t("login")}&rarr;
                    </Button>
                  </Col>
                  {history?.location?.pathname !== "/signup" && (
                    <Col xs={6}>
                      <Button
                        className={styles.registerBtn}
                        onClick={() => history.push("/signup")}
                      >
                        {t("register")}&rarr;
                      </Button>
                    </Col>
                  )}
                </Row>
              )}
            </DropdownToggle>
            {currentSubscriber && (
              <DropdownMenu right>
                <DropdownItem>
                  <Link className="nav-link menu-points" to="/profile">
                    <FontAwesomeIcon
                      size="xs"
                      icon={faPencilAlt}
                      className="mr-2"
                    />
                    Edit account
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    className="nav-link menu-points"
                    onClick={() => setWillLogOut(!willLogOut)}
                  >
                    <FontAwesomeIcon
                      size="xs"
                      icon={faSignOutAlt}
                      className="mr-2"
                    />
                    Log out
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        </Nav>
        <Modal
          centered
          isOpen={willLogOut}
          backdropClassName={styles.backdropC}
          style={{ width: "100%" }}
        >
          <ModalHeader className="border-0 pb-0 justify-content-center text-center">
            You&apos;re logging out!
          </ModalHeader>
          <ModalBody
            className={`d-flex justify-content-center align-items-center pt-4 pb-3`}
          >
            Are you sure you want to log out?
          </ModalBody>
          <ModalFooter className="mx-auto w-100 border-0">
            <Col className="d-flex justify-content-center mb-3 mt-2" xs={12}>
              <Button
                className={styles.logOutBtns}
                onClick={() => history.push("/subscriber/sign_out")}
              >
                Yes
              </Button>
              <Button
                className={`${styles.logOutBtns} ${styles["logOutBtns-bg-secondary"]}`}
                onClick={() => setWillLogOut(!willLogOut)}
              >
                No
              </Button>
            </Col>
          </ModalFooter>
        </Modal>
      </Navbar>
      <ReportIncidentModal
        {...props}
        setReportModal={setReportModal}
        isOpen={reportModal}
        toast={toast}
      />
      {currentSubscriber && <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClicks
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />}
    </div>
  );
}

WebappHeader.propTypes = {
  currentSubscriber: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default withRouter(withCurrentSubscriber(WebappHeader, WebappHeader));
