import { capitalize } from "components/helpers";
import { FieldType } from "components/helpers/form_fields";

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass='', mute=true, statuses=[], icon, iconInputClass='', citationTicket } = attrs;
  return [
    [
      {
        name: 'id',
        label: 'Citation Ticket ID',
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'violation_id',
        label: "Violation Report",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'violation_type',
        label: "Violation Type",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'vehicle',
        label: "Vehicle LPN",
        customInputClass,
        customLabelClass,
        disabled: ["sent_to_court", "settled"].includes(citationTicket?.status)
      },
      {
        name: 'created_at',
        label: "Created at",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'updated_at',
        label: "Updated at",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'status',
        label: "Citation Status",
        type: FieldType.DROPDOWN_SELECT_FIELD,
        options: statuses,
        customInputClass,
        customLabelClass,
        mandatory: true,
      },
      {
        name: 'fine',
        label: "Fine Amount",
        icon: icon,
        customInputClass: iconInputClass,
        customLabelClass,
        disabled: true,
      }
    ],
    [
      {
        name: 'dispute[type]',
        label: "Appeal Type",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'dispute[raised_on]',
        label: "Appeal Raised On",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'dispute[raised_by]',
        label: "Appeal Raised By",
        customInputClass,
        customLabelClass,
        disabled: mute,
      },
      {
        name: 'dispute[reason]',
        label: "Appeal Reason",
        type: 'text_area',
        rows: 3,
        customInputClass,
        customLabelClass,
        disabled: mute,
      }
    ]
  ]
}

const fineFields = (attrs) => {
  const { customInputClass='', customLabelClass='', icon, iconInputClass='' } = attrs;
  return[
    {
      name: 'updated_fine',
      label: 'Fine amount',
      mandatory: true,
      type: FieldType.NUMBER_FIELD,
      icon: icon,
      customInputClass: iconInputClass,
      customLabelClass,
    },
    {
      name: 'reason',
      label: 'Reason for update',
      placeholder: 'Description',
      type: FieldType.TEXT_AREA,
      mandatory: true,
      rows: 3,
      customInputClass,
      customLabelClass,

    }
  ]
}

const escalationFields = (attrs) => {
  const { customLabelClass='', icon, customInputDayClass='', iconInputClass } = attrs;
  return[
    {
      name: 'sent_period',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: "days", label: "Days" },
        { value: "weeks", label: "Weeks" },
        { value: "months", label: "Months" }
      ],
      customInputClass: customInputDayClass,
      customLabelClass,
    },
    {
      name: 'late_fees',
      icon: icon,
      customInputClass: iconInputClass,
      customLabelClass,
    },
    {
      name: 'late_period',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [
        { value: "days", label: "Days" },
        { value: "weeks", label: "Weeks" },
        { value: "months", label: "Months" }
      ],
      customInputClass: customInputDayClass,
      customLabelClass,
    },
  ]
}

const appealFields = (attrs) => {
  const { customInputClass='', customLabelClass='', dispute_type=[] } = attrs;
  return[
    {
      name: 'dispute_type',
      label: 'Appeal Type',
      mandatory: true,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      placeholder: 'Please Select',
      options: dispute_type.map(type => { return { value: type.label, label: capitalize(type.label) } }),
      customInputClass,
      customLabelClass,
    },
    {
      label: 'Appeal Reason',
      name: 'detailed_reason',
      type: FieldType.TEXT_AREA,
      mandatory: true,
      rows: 3,
      maxLength: 10,
      customLabelClass,
      customInputClass
    },
    {
      name: 'entry_time',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      label: 'Entry Time:'
    },
    {
      name: 'exit_time',
      type: FieldType.SINGLE_HOUR_FIELD,
      customInputClass,
      customLabelClass,
      label: 'Exit Time:'
    },
  ]
}

export {showFields, fineFields, escalationFields, appealFields};