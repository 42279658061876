import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, isAuthorized, isSubAuthorized, isValetAuthorized, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      (props.match.path.indexOf("dashboard") > -1) ? (
        isAuthorized ? (<Component {...props} />) : (
          <Redirect
            to={{
              pathname: '/dashboard/login',
            }}
          />
        )
      ) : props.match.path.includes("subscriber") ?
        isSubAuthorized ? (<Component {...props} />) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
        : isValetAuthorized ? (<Component {...props} />) : (
          <Redirect
            to={{
              pathname: '/valet_login',
            }}
          />
        )

    }
  />
);

function mapState(state) {
  const { isAuthorized } = state.user.auth;
  const { isSubAuthorized } = state.user.subscriberAuth;
  const { isValetAuthorized } = state.user.valetAuth;
  return { isAuthorized, isSubAuthorized, isValetAuthorized };
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isSubAuthorized: PropTypes.bool.isRequired,
  isValetAuthorized: PropTypes.bool.isRequired,
  location: PropTypes.object
};

export default connect(
  mapState
)(PrivateRoute);
