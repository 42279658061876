import React, { useCallback, useContext, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Remove from '../remove';
import Delete from '../delete';
import Create from '../create';
import Update from '../update';
import Add from '../add';
import styles from './index.module.sass'
import { create, update_code, delete_code, add_permit_type, remove_permit_type } from 'api/permit_type_codes';
import { capitalize } from 'components/helpers';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { flatten } from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initState = { errors: {}, isSaving: false, parkingLots: []}


const reducer = (state, action) => {
  const { type, payload } = action;

  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'isDeleting':
      return { ...state, isDeleting: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    case 'dropdowns':
      return { ...state, ...payload }
    default:
      return { ...state };
  }
}

const PermitTypeCodeModal = (props) => {
  const { record, openModal, modalType, setActiveTab, code, setCode, setpermitTypeCodeList } = props
  const [state, dispatch] = useReducer(reducer, initState);
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})

  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
  }, [openModal, setActiveTab])

  const setState = useCallback((type, payload) => {
    dispatch({type, payload})
  }, [dispatch])

  const createPermitTypeCode = useCallback(async (data) => {
    try {
      const response = await create({ data })
      if (response.status === 200 || response.status === 201){
        addAlertMessages([{
          type: 'primary',
          text: 'Permit Type Code Created',
          onlyMessage: true,
        }], 'center')
        closeModal()
        setCode()
        setpermitTypeCodeList()
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
    }

  },[setErrors, closeModal, addAlertMessages, setCode, setpermitTypeCodeList])

  const removePermitType = useCallback(async () => {
    setState('isSaving', true)
    try {
      const response = await remove_permit_type({id: code.id, data: {permit_type_id: record.id}})
      if (response.status === 200 || response.status === 201){
        closeModal();
        addAlertMessages([{
          type: 'primary',
          text: `Permit Type was successfully removed`,
          onlyMessage: true
          }],
          'center'
        )
        setCode()
        setpermitTypeCodeList()
      }
    } catch (error) {
      if(error?.response?.data?.errors) { 
        toast.error(`${flatten(Object.values(error?.response?.data?.errors))}`);
      }
    }finally {
      setState('isSaving', false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setState, record, setCode, closeModal, addAlertMessages, setpermitTypeCodeList])

  const addPermitType = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response = await add_permit_type({id: data.id, data})
      if (response.status === 200 || response.status === 201){
        closeModal();
        addAlertMessages([{
          type: 'primary',
          text: 'Permit Type was successfully added',
          onlyMessage: true
          }],
          'center'
        )
        setCode()
        setpermitTypeCodeList()
      }
    } catch (error) {
      if(error?.response?.data?.errors) { 
        toast.error(`${flatten(Object.values(error?.response?.data?.errors))}`);
      }
    }finally {
      setState('isSaving', false)
    }

  },[setState, closeModal, addAlertMessages, setCode, setpermitTypeCodeList])

  const updatePermitTypeCode = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await update_code({data, id: code.id })
      closeModal();
      addAlertMessages([{
        type: 'primary',
        text: `${capitalize(response.data.code)} was successfully edited`,
        onlyMessage: true
        }],
        'center'
      )
      setCode()
      setpermitTypeCodeList()
    } catch (error) {
      setState('errors', error.response.data.errors)
    }finally {
      setState('isSaving', false)
      closeModal();
    }
  },[setState, code, closeModal, addAlertMessages, setCode, setpermitTypeCodeList])

  const deletePermitTypeCode = useCallback(async () => {
    setState('isDeleting', true)
    try {
      await delete_code({id: code.id})
      closeModal();
      addAlertMessages([{
        type: 'danger',
        text: `${capitalize(code.code)} was successfully deleted`,
        onlyMessage: true
        }],
        'center'
      )
      setCode()
      setpermitTypeCodeList()
    } catch (error) {
      setState('errors', error.response.data.errors)
    }finally {
      setState('isDeleting', false)
    }
  },[setState, code, closeModal, addAlertMessages, setCode, setpermitTypeCodeList])

  const modalProps = () => {
    let width = '500px'
    switch (modalType) {
      case 'create':
        return {title: 'Create New Code', maxWidth : '600px'}
      case 'update':
        return {title: 'Update Code', maxWidth : '600px'}
      case 'add':
        return {title: 'Add Permit Types', maxWidth : '600px'}
      case 'remove':
        return {title: 'Remove Permit Type', maxWidth : '600px'}
      case 'destroy':
        return {title: 'Delete Permit Type Code', maxWidth : '600px'}
      default:
        break;
    }
    return { width }
  }

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const commmonProps = {state, setState, closeModal, setErrors, errors, code}

  return (
    <Modal 
      isOpen={!!modalType}  
      centered contentClassName={styles.couponModal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'create' && 
          <Create
            {...commmonProps}
            createPermitTypeCode={createPermitTypeCode}
          />
        }
        {
          modalType === 'remove' && 
            <Remove
              {...commmonProps}
              record={record}
              removePermitType={removePermitType}
            />
        }
        {
          modalType === 'update' && 
          <Update
            {...commmonProps}
            record={record}
            updatePermitTypeCode={updatePermitTypeCode}
          />
        }
        {
          modalType === 'destroy' && 
          <Delete
            {...commmonProps}
            deletePermitTypeCode={deletePermitTypeCode}
          />
        }
        {
          modalType === 'add' && 
          <Add
            {...commmonProps}
            addPermitType={addPermitType}
          />
        }
      </ModalBody>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClicks
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Modal>
  )
}

export default PermitTypeCodeModal;
