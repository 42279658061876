import React, { useCallback, useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import { Form } from "informed";
/* API */
import { validateStep1 } from "api/parking_lots";
/* Module */
import withCurrentUser from "components/modules/with_current_user";
import withFetching from "components/modules/with_fetching";
import { capitalize } from "components/helpers";
import {
  fieldsCreate,
  fieldsCustomHour,
  fieldsHour,
  lotSettingsFields,
  fieldsParkingHourTypeSettingsWeekdays,
  fieldsParkingHoursFormatDynamicWeekdays,
  fieldsNoChargePeriod,
  fieldsNoChargePeriodDynamic,
  fieldsNoChargeDay,
} from "components/helpers/fields/parking_lots";
/* Base */
import Button from "components/base/button";
import { renderFields } from "components/base/forms/common_form";
/* Assets */
import styles from "./create.module.sass";
import { map } from "underscore";
import Tabs from "components/base/tabs";
import LocationForm from "../shared/location/form";
import { Label } from "reactstrap";
import { ReactComponent as CloudDownloadIcon } from "assets/cloud-download.svg";
import { isEmpty } from "underscore";
import { cloneDeep } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import { Divider } from "antd";
import dayjs from "dayjs";

const Create = (props) => {
  const {
    createParkingLot,
    closeModal,
    record,
    parkingAdmins,
    townManagers,
    agencies,
    contextHolder,
    errors,
    setErrors,
  } = props;
  // eslint-disable-next-line
  const [parkingLot, setParkingLot] = useState({});
  const [selectedTab, setSelectedTab] = useState("information");
  const [currentLocation, setCurrentLocation] = useState({});
  const formApiRef = useRef();
  const [fileName, setFileName] = useState();
  const [image, setImage] = useState(null);
  // eslint-disable-next-line
  const [parkingSchedules, setParkingSchedules] = useState([]);
  // eslint-disable-next-line
  const [fetchedParkingLot, setFetchedParkingLot] = useState([]);
  const [selectedDays, setSelectedDays] = useState([
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
  ]);
  // eslint-disable-next-line
  const [pHSetting, setPHSetting] = useState("custom");
  // eslint-disable-next-line
  const [parkingRules, setParkingRules] = useState([]);
  // eslint-disable-next-line
  const [updatedParkingRules, setUpdatedParkingRules] = useState(parkingRules);
  // eslint-disable-next-line
  const [vehicleClassSettings, setVehicleClassSettings] = useState([]);
  const [noChargeFields, setNoChargeFields] = useState([]);
  const [staticNoChargeFields, setStaticNoChargeFields] = useState([]);
  const [customVehicleClass, setCustomVehicleClass] = useState("");
  const [fieldsState, setFieldsState] = useState({});
  const [disabledFields, setDisabledFields] = useState({
    hourly: {},
    disabled: {},
  });
  const [sets, setSets] = useState([]);
  const fileRef = useRef();
  const setFormApi = (formApi) => {
    formApiRef.current = formApi;
  };

  const fieldAttrs = {
    admins: parkingAdmins,
    managers: townManagers,
    agencies,
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customToolTipClass: styles.customTooltip,
    customIDInputClass: styles.idInput,
  };

  const weekDays = [
    {
      label: "Monday",
      value: "monday",
    },
    {
      label: "Tuesday",
      value: "tuesday",
    },
    {
      label: "Wednesday",
      value: "wednesday",
    },
    {
      label: "Thursday",
      value: "thursday",
    },
    {
      label: "Friday",
      value: "friday",
    },
  ];

  const doubleFieldProps = {
    lSize: 1,
    iSize: 12,
  };

  const singleFieldProps = {
    iSize: 8,
    lSize: 3,
  };

  const settingFieldProps = {
    iSize: 8,
    lSize: 4,
  };

  const noChargeDayFieldProps = (id) => {
    return {
      iSize: 8,
      lSize: 4,
      events: {
        onChange: (e) => handleDayChange(id, e),
      },
    };
  };

  const classFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (e) => handleVehicleClassChange(e),
    },
  };

  const customClassFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (e) => handleCustomVehicleClassChange(e),
    },
  };

  const renderLocationModal = (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
      />
    );
  };

  const handleVehicleClassChange = (e) => {
    if (
      parkingLot?.parking_hours_type === "hourly_static" ||
      parkingLot?.parking_hours_type === "hourly_dynamic"
    ) {
      const vehicleSetting =
        fetchedParkingLot?.setting?.vehicle_class_settings?.filter((vs) => {
          return vs.vehicle_class === e.value;
        });
      setVehicleClassSettings((prevSetting) => [
        ...prevSetting,
        {
          vehicle_class: e.value,
          hourly_rate: formApiRef.current.getValue("rate"),
          disabled_parking_rate: formApiRef.current.getValue(
            "disabled_parking_rate"
          ),
        },
      ]);
      formApiRef.current.setValue("rate", vehicleSetting[0]?.hourly_rate);
      formApiRef.current.setValue(
        "disabled_parking_rate",
        vehicleSetting[0]?.disabled_parking_rate
      );
      setVehicleClass();
    }
  };

  const setVehicleClass = () => {
    setVehicleClassSettings((prevData) => {
      const existingItemIndex = prevData?.findIndex(
        (item) =>
          item?.vehicle_class === formApiRef.current.getValue("vehicle_class")
      );
      if (existingItemIndex !== -1) {
        // Update existing item
        const updatedData = prevData.map((item, index) =>
          index === existingItemIndex
            ? {
                ...item,
                hourly_rate: formApiRef.current.getValue("rate"),
                disabled_parking_rate: formApiRef.current.getValue(
                  "disabled_parking_rate"
                ),
              }
            : item
        );
        return updatedData;
      } else {
        // Add new item
        return [
          ...prevData,
          {
            vehicle_class: formApiRef.current.getValue("vehicle_class"),
            hourly_rate: formApiRef.current.getValue("rate"),
            disabled_parking_rate: formApiRef.current.getValue(
              "disabled_parking_rate"
            ),
          },
        ];
      }
    });
  };

  const handleCustomVehicleClassChange = (e) => {
    setErrors({});
    const validationErrors = handexVehiclClassUpdate();

    if (isEmpty(validationErrors)) {
      setCustomVehicleClass(e.value);

      setFieldsState((prevFields) => {
        const newFields = { ...prevFields };

        // Initialize the vehicle class if it doesn't exist
        if (!newFields[e.value]) {
          newFields[e.value] = {};
        }

        // Ensure each selected day has at least one field
        selectedDays.forEach((d) => {
          if (!newFields[e.value][d]) {
            newFields[e.value][d] = [{ id: 1 }]; // Initialize with a default field
          }
        });

        // Remove days that are no longer selected
        Object.keys(newFields[e.value]).forEach((d) => {
          if (!selectedDays.includes(d)) {
            delete newFields[e.value][d]; // Remove unselected days
          }
        });

        return newFields;
      });
    } else {
      setErrors(validationErrors);
      formApiRef.current.setValue("vehicle_class", customVehicleClass);
      return;
    }
  };

  const transformData = (input) => {
    const output = [];

    Object.entries(input).forEach(([vehicleClass, days]) => {
      Object.entries(days).forEach(([dayName, timeSlots]) => {
        Object.values(timeSlots).forEach(({ from, to, rate, disabled }) => {
          const existingEntry = output.find(
            (entry) =>
              entry.day_name === dayName &&
              entry.parking_hour_from === from &&
              entry.parking_hour_to === to
          );

          const vehicleInfo = {
            vehicle_class: vehicleClass,
            hourly_rate: rate,
            disabled_parking_rate: disabled,
          };

          if (existingEntry) {
            existingEntry.vehicle_class_setting.push(vehicleInfo);
          } else {
            output.push({
              day_name: dayName,
              parking_hour_from: from,
              parking_hour_to: to,
              vehicle_class_setting: [vehicleInfo],
            });
          }
        });
      });
    });

    return output;
  };

  const validateFields = () => {
    const {
      name,
      town_manager_id,
      status,
      agency_id,
      location,
      parking_lot_type,
      category,
    } = formApiRef.current.getValues();
    const errors = {};

    if (selectedTab === "information") {
      if (!name) {
        errors["name"] = ["Parking Lot Name is required!"];
      }
      if (!town_manager_id) {
        errors.town_manager_id = ["Please select town manager!"];
      }
      if (!status) {
        errors.status = ["Please select status!"];
      }
      if (!agency_id) {
        errors.agency_id = ["Please select agency!"];
      }
      if (!location) {
        errors.location = ["Location is required!"];
      }
      if (!parking_lot_type) {
        errors.parking_lot_type = ["Parking Lot Type is required!"];
      }
      if (!category) {
        errors.category = ["Parking Lot Category is required!"];
      }
    }

    return Object.assign(errors);
  };

  const handleNext = () => {
    const { name, phone } = formApiRef.current.getValues();
    setErrors({});
    const validationErrors = validateFields();

    if (isEmpty(validationErrors)) {
      validateStep({ name, phone });
    } else {
      setErrors(validationErrors);
    }
  };

  const validateStep = useCallback(
    async (data) => {
      try {
        const response = await validateStep1(data);
        if (response.status === 200) {
          setSelectedTab("settings");
        }
      } catch (error) {
        setErrors(error.response?.data?.errors);
      }
    },
    // eslint-disable-next-line
    [errors]
  );

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        setImage(reader.result);
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const showFileName = (e) => {
    const selectedFile = e.target.files[0];
    const validFileTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    if (!validFileTypes.includes(selectedFile?.type)) {
      return;
    } else {
      setFileName(e.target.files[0]?.name);
      getBase64(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (
        e.dataTransfer.files.format === ".pdf" ||
        e.dataTransfer.files.format === ".png" ||
        e.dataTransfer.files.format === ".jpg"
      ) {
        setFileName(e.dataTransfer.files[0].name);
        fileRef.current = { files: e.dataTransfer.files };
      }
    }
  };

  const getFields = () => {
    return fieldsCreate({
      ...fieldAttrs,
      renderLocationModal: renderLocationModal.bind(this),
    });
  };

  const getLotSettingsFields = () => {
    return lotSettingsFields({
      ...fieldAttrs,
      renderLocationModal: renderLocationModal.bind(this),
    });
  };

  const list = () => [
    {
      label: <span style={{ opacity: "0.65" }}>Information</span>,
      value: "information",
    },
    {
      label: (
        <React.Fragment>
          <span className="mr-2" style={{ opacity: "0.65" }}>
            Settings
          </span>
        </React.Fragment>
      ),
      value: "settings",
    },
  ];
  const renderParkingLotFields = (fields) => {
    return (
      <React.Fragment>
        {renderFields(fields.slice(0, 1), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(1, 2), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(2, 3), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(3, 4), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(4, 5), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(5, 6), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(6, 7), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(7, 8), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(8, 9), { ...singleFieldProps, errors })}
        {renderFields(fields.slice(9, 10), { ...singleFieldProps, errors })}
        <span>
          <label className={styles.fileLabel}>Parking Lot Picture</label>
        </span>
        <div
          style={{ display: "flex" }}
          onDragEnter={handleDrag}
          onDrop={handleDrop}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
        >
          <input
            ref={fileRef}
            name="id_proof"
            className="d-none"
            id="id-proof-file-upload"
            type="file"
            accept=".jpg, .png, .jpeg"
            onChange={showFileName}
          />
          {!image && (
            <Label
              htmlFor="id-proof-file-upload"
              className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}
            >
              <span className="mb-2">
                {image ? (
                  <img
                    className={styles.img}
                    alt="Parking Lot Pic"
                    src={image}
                  />
                ) : (
                  <CloudDownloadIcon />
                )}
              </span>
              <span>
                <span className={styles.boldText1}>Add Picture</span>
                <span className="ml-1">or drag it here</span>
              </span>
              <p className={styles.formatsText}>JPEG, PNG (200x200)</p>
            </Label>
          )}
          {image && (
            <Label
              htmlFor="id-proof-file-upload"
              className={`${styles.dragDropLabel} d-flex flex-column h-95 w-100 text-center p-3`}
            >
              <span className="mb-2 mt-2">
                <img className={styles.img} alt="Parking Lot" src={image} />
              </span>
              <span className="mb-2 mt-2">
                <CloudDownloadIcon className={styles.cloudIcon} />
              </span>
              <span className={styles.uploadText}>
                <span className={styles.boldText2}>Add Another Picture</span>

                <p className={styles.formatsText2}>JPEG, PNG (200x200)</p>
              </span>
            </Label>
          )}
        </div>
      </React.Fragment>
    );
  };

  const hourTypeFieldProps = {
    iSize: 16,
    lSize: 4,
    events: {
      onChange: () => setNoChargeFields([]),
    },
  };

  const renderParkingHourSettingsFields = useCallback(
    (fields) => {
      return (
        <Row className="pt-2 pl-3 ml-1">
          {renderFields(fields, { ...hourTypeFieldProps, errors })}
          {!isEmpty(errors["parking_hours_type"]) && (
            <p
              className={styles.alertMsg}
              style={{ textAlign: "left" }}
            >{`( ${errors["parking_hours_type"]} )`}</p>
          )}
        </Row>
      );
    },
    // eslint-disable-next-line
    [parkingLot, parkingSchedules, record, fetchedParkingLot]
  );

  const parkingHourTypeSettingFieldsWeekdays =
    fieldsParkingHourTypeSettingsWeekdays({
      ...fieldAttrs,
      renderLocationModal: renderLocationModal.bind(this),
    });

  const rateFieldProps = {
    iSize: 8,
    lSize: 4,
    events: {
      onChange: (e) => setVehicleClass(),
    },
  };
  const renderParkingLotSettingsFields = (fields, formState) => {
    return (
      <Col className={`${styles.fieldset} pt-2`} xs={10}>
        {renderFields(fields.slice(0, 1), {
          ...classFieldProps,
          formState,
          errors,
        })}
        {renderFields(fields.slice(1, 2), {
          ...rateFieldProps,
          formState,
          errors,
        })}
        {renderFields(fields.slice(2, 3), {
          ...rateFieldProps,
          formState,
          errors,
        })}
      </Col>
    );
  };

  const renderParkingLotCustomSettingsFields = (fields, formState) => {
    return (
      <Col className={`${styles.fieldset} pt-2`} xs={10}>
        {renderFields(fields.slice(7, 8), {
          ...settingFieldProps,
          formState,
          errors,
        })}
        {renderFields(fields.slice(3, 4), { ...settingFieldProps, formState })}
        {renderFields(fields.slice(4, 5), { ...settingFieldProps, formState })}
        {renderFields(fields.slice(5, 6), { ...settingFieldProps, formState })}
        {renderFields(fields.slice(6, 7), { ...settingFieldProps, formState })}
      </Col>
    );
  };

  const handleCheckboxChange = (day) => {
    const vehicleClass = customVehicleClass || "car";

    const dayOrder = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    setSelectedDays((prevDays) => {
      const updatedDays = prevDays.includes(day)
        ? prevDays.filter((d) => d !== day) // Deselect the day if already selected
        : [...prevDays, day]; // Select the day if not already selected

      // Sort the days while preserving existing fields
      const sortedDays = [...updatedDays].sort(
        (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
      );

      // Preserve fields for existing days when sorting
      setFieldsState((prevFields) => {
        const newFields = { ...prevFields };

        // Initialize the vehicle class if it doesn't exist
        if (!newFields[vehicleClass]) {
          newFields[vehicleClass] = {};
        }

        // Ensure each selected day has at least one field
        sortedDays.forEach((d) => {
          if (!newFields[vehicleClass][d]) {
            newFields[vehicleClass][d] = [{ id: 1 }]; // Initialize with a default field
          }
        });

        // Remove days that are no longer selected
        Object.keys(newFields[vehicleClass]).forEach((d) => {
          if (!sortedDays.includes(d)) {
            delete newFields[vehicleClass][d]; // Remove unselected days
          }
        });

        return newFields;
      });

      return sortedDays;
    });
  };

  const transformNoChargeForAPI = (noChargePeriodDynamic) => {
    const transformedData = [];

    // Loop through each key in noChargePeriodDynamic
    Object.keys(noChargePeriodDynamic).forEach((key) => {
      const entry = noChargePeriodDynamic[key];
      // Check if it's a day entry
      if (key.startsWith("day-")) {
        const dayKey = key.split("-")[1];
        const day_name = entry.day.toLowerCase();
        // Find or create the day entry in transformedData

        let dayEntry = transformedData.find(
          (item) => item?.item?.day_name === day_name
        );

        if (!dayEntry) {
          dayEntry = {
            day_name,
            setting: [],
          };
          transformedData.push(dayEntry);
        }
        // Find corresponding periods
        const periodKey = `field-${dayKey}-`;
        const setting = Object.keys(noChargePeriodDynamic)
          .filter((k) => k.startsWith(periodKey))
          .map((k) => noChargePeriodDynamic[k]);
        // Push setting into day entry
        dayEntry.setting.push(...setting);
      }
    });
    return transformedData;
  };

  const validateTimeRanges = (values) => {
    const errors = {};
    const { parking_hours_type, hourFormat, parkingHours } = values;

    const isTimeValid = (from, to) =>
      dayjs(from, "HH:mm").isBefore(dayjs(to, "HH:mm"));

    const addError = (field, message) => {
      if (!errors[field]) errors[field] = [];
      errors[field].push(message);
    };

    const validateRequiredFields = (fields) => {
      fields.forEach(({ field, message }) => {
        if (!values[field]) addError(field, message);
      });
    };

    const validateTimeFields = (fromField, toField, fromValue, toValue) => {
      if (!fromValue) addError(fromField, "From time is required!");
      if (!toValue) addError(toField, "To time is required!");
      if (fromValue && toValue && !isTimeValid(fromValue, toValue)) {
        addError(fromField, "From time must be less than To time");
        addError(toField, "To time must be greater than From time");
      }
    };

    // Validate required fields
    validateRequiredFields([
      {
        field: "violation_fine",
        message: "Default Violation Fine is required",
      },
      { field: "vehicle_class", message: "Vehicle class is required" },
    ]);

    if (
      parking_hours_type === "hourly_dynamic" ||
      parking_hours_type === "hourly_static"
    ) {
      validateRequiredFields([
        { field: "rate", message: "Hourly rate is required" },
        {
          field: "disabled_parking_rate",
          message: "Disabled parking rate is required",
        },
      ]);
    }

    if (selectedDays.length === 0) {
      addError("selectedDays", "Please select at least one day");
    }

    if (!parking_hours_type) {
      addError("parking_hours_type", "Please select at least one hour format");
    }

    // Validate parking hours for hourly_static
    if (
      parking_hours_type === "hourly_static" &&
      hourFormat?.hourly_static === "custom"
    ) {
      validateTimeFields(
        "parkingHours[hourly_static][from]",
        "parkingHours[hourly_static][to]",
        parkingHours?.hourly_static?.from,
        parkingHours?.hourly_static?.to
      );
    }

    // Validate parking hours for hourly_dynamic
    if (parking_hours_type === "hourly_dynamic") {
      selectedDays.forEach((day) => {
        if (hourFormat[day] === "custom") {
          validateTimeFields(
            `parkingHours[${day}][from]`,
            `parkingHours[${day}][to]`,
            !parkingHours ? null : parkingHours[day]?.from,
            !parkingHours ? null : parkingHours[day]?.to
          );
        }
      });
    }

    // Validate custom days
    if (parking_hours_type === "custom") {
      validateCustomHoursFields(addError, isTimeValid);
    }

    // Validate no charge periods
    const validateNoChargePeriods = (prefix) => {
      formApiRef.current.emitter.fields.forEach((field) => {
        if (field?.field?.startsWith(prefix)) {
          const match =
            prefix === "noChargePeriod["
              ? field.field.match(
                  new RegExp(`noChargePeriod\\[(\\w+)\\]\\[(\\w+)\\]`)
                )
              : field?.field.match(
                  /noChargePeriodDynamic\[(\w+-\d+-\d+)\]\[(\w+)\]/
                );

          if (match) {
            // eslint-disable-next-line
            const [_, id, type] = match;
            const fromField = `${
              prefix === "noChargePeriod[" ? "noChargePeriod" : prefix
            }[${id}][from]`;
            const toField = `${
              prefix === "noChargePeriod[" ? "noChargePeriod" : prefix
            }[${id}][to]`;
            const fromValue = formApiRef.current.getValue(fromField);
            const toValue = formApiRef.current.getValue(toField);

            if (type === "from" && !fromValue)
              addError(field.field, "From time is required");
            if (type === "to" && !toValue)
              addError(field.field, "To time is required");

            if (fromValue && toValue && !isTimeValid(fromValue, toValue)) {
              addError(fromField, "From time must be less than To time");
              addError(toField, "To time must be greater than From time");
            }
          } else if (!formApiRef.current.getValue(field.field)) {
            errors[field.field] = ["Parking day is required"];
          }
        }
      });
    };

    if (parking_hours_type === "hourly_dynamic")
      validateNoChargePeriods("noChargePeriodDynamic");
    if (parking_hours_type === "hourly_static")
      validateNoChargePeriods("noChargePeriod[");

    return errors;
  };

  const handexVehiclClassUpdate = () => {
    const errors = {};
    const isTimeValid = (from, to) =>
      dayjs(from, "HH:mm").isBefore(dayjs(to, "HH:mm"));

    const addError = (field, message) => {
      if (!errors[field]) errors[field] = [];
      errors[field].push(message);
    };

    validateCustomHoursFields(addError, isTimeValid);

    return errors;
  };

  const validateCustomHoursFields = (addError, isTimeValid) => {
    // Validate custom days
    formApiRef.current.emitter.fields.forEach((field) => {
      if (field?.field?.startsWith(`customDays[${customVehicleClass}]`)) {
        const match = field.field.match(
          /customDays\[(\w+)\]\[(\w+)\]\[(\w+-\d+)\]\[(\w+)\]/
        );
        if (match) {
          // eslint-disable-next-line
          const [_, vehicleClass, day, id, type] = match;
          const fromField = `customDays[${vehicleClass}][${day}][${id}][from]`;
          const toField = `customDays[${vehicleClass}][${day}][${id}][to]`;
          const fromValue = formApiRef.current.getValue(fromField);
          const toValue = formApiRef.current.getValue(toField);

          if (type === "from" && !fromValue)
            addError(field.field, "From time is required");
          if (type === "to" && !toValue)
            addError(field.field, "To time is required");

          if (
            !disabledFields.hourly[`${vehicleClass}-${id}`] &&
            type === "rate" &&
            !formApiRef.current.getValue(field.field)
          )
            addError(field.field, "Rate is required");
          if (
            !disabledFields.disabled[`${vehicleClass}-${id}`] &&
            type === "disabled" &&
            !formApiRef.current.getValue(field.field)
          )
            addError(field.field, "Disabled rate is required");

          if (fromValue && toValue && !isTimeValid(fromValue, toValue)) {
            addError(fromField, "From time must be less than To time");
            addError(toField, "To time must be greater than From time");
          }
        }
      }
    });
  };

  const submit = useCallback(
    async (values) => {
      const { parking_hours_type, parkingHours, noChargePeriodDynamic } =
        values;

      setErrors({});
      const validationErrors = validateTimeRanges(values);
      if (!isEmpty(validationErrors)) {
        setErrors(validationErrors);
        return;
      }

      values.agency_id = `${values?.agency_id}`;
      values.location = cloneDeep(currentLocation);
      values.rules = !isEmpty(updatedParkingRules)
        ? updatedParkingRules
        : parkingRules;
      if (fileName) {
        values.avatar = image;
      }

      const parkingDaySettings =
        parking_hours_type === "hourly_static"
          ? [
              {
                parking_hour_from: parkingHours
                  ? parkingHours["hourly_static"]["from"]
                  : "00:00",
                parking_hour_to: parkingHours
                  ? parkingHours["hourly_static"]["to"]
                  : "23:59",
              },
            ]
          : parking_hours_type === "hourly_dynamic"
          ? selectedDays.map((day, idx) => {
              return {
                day_name: day,
                parking_hour_from:
                  parkingHours && parkingHours[day]
                    ? parkingHours[day]?.from
                    : "00:00",
                parking_hour_to:
                  parkingHours && parkingHours[day]
                    ? parkingHours[day]?.to
                    : "23:59",
              };
            })
          : parking_hours_type === "custom"
          ? transformData(formApiRef.current.getValues().customDays)
          : [];
      const noChargePeriods =
        parking_hours_type === "hourly_static" &&
        !isEmpty(values?.noChargePeriod)
          ? [
              {
                setting: values?.noChargePeriod?.map((ncp) => {
                  return { from: ncp?.from, to: ncp?.to };
                }),
              },
            ]
          : parking_hours_type === "hourly_dynamic" &&
            !isEmpty(values?.noChargePeriodDynamic)
          ? transformNoChargeForAPI(noChargePeriodDynamic)
          : [];

      values.setting = {
        active_days: selectedDays,
        active_day_setting: parking_hours_type,
        violation_fine: values.violation_fine,
        overtime: values.overtime,
        parked: values.parked,
        period: values.period,
        incremental: values.incremental,
        parking_days: {
          no_charge_period: noChargePeriods,
          parking_days_settings: parkingDaySettings,
          vehicle_class_setting:
            parking_hours_type !== "custom"
              ? [
                  {
                    vehicle_class: values?.vehicle_class,
                    hourly_rate: values?.rate,
                    disabled_parking_rate: values.disabled_parking_rate,
                  },
                ]
              : [],
        },
      };

      createParkingLot(values);
    },
    // eslint-disable-next-line
    [
      updatedParkingRules,
      parkingRules,
      currentLocation,
      image,
      createParkingLot,
      selectedDays,
      fileName,
      disabledFields,
      customVehicleClass,
    ]
  );

  const handleNoChargePeriodAddition = (type) => {
    if (type === "hourly_static") {
      const newId =
        staticNoChargeFields.length > 0
          ? staticNoChargeFields[staticNoChargeFields.length - 1].id + 1
          : 0;

      setStaticNoChargeFields([...staticNoChargeFields, { id: newId }]);
    } else if (type === "hourly_dynamic") {
      const newSetId = sets.length ? sets[sets.length - 1].id + 1 : 1;
      setSets([
        ...sets,
        {
          id: newSetId,
          day: "monday",
          days: selectedDays.filter(
            (n) =>
              !sets
                ?.map((set) => {
                  return set?.day;
                })
                .includes(n)
          ),
          fields: [{ id: 1 }],
        },
      ]);
    }
  };

  const handleNoChargePeriodRemoval = (type, id) => {
    if (type === "hourly_static") {
      setStaticNoChargeFields(
        staticNoChargeFields.filter((field) => field.id !== id)
      );
    } else if (type === "hourly_dynamic") {
      //logic for dynamic
      setNoChargeFields(noChargeFields.filter((fieldId) => fieldId !== id));
    }
  };

  useEffect(() => {
    if (selectedDays) {
      selectedDays.forEach((day) => {
        if (formApiRef.current.getValue(`hourFormat[${day}]`) === undefined) {
          formApiRef.current.setValue(`hourFormat[${day}]`, "24_hours");
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedDays]); // Add proper dependencies

  useEffect(() => {
    formApiRef.current.setValue("incremental", 60);
    formApiRef.current.setValue("overtime", 120);
    formApiRef.current.setValue("parked", 120);
    formApiRef.current.setValue("period", 1800);
    formApiRef.current.setValue("hourFormat[hourly_static]", "24_hours");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAdd = (day) => {
    const vehicleClass = customVehicleClass || "car"; // Default to 'car' if not selected
    setFieldsState((prevState) => {
      const vehicleData = prevState[vehicleClass] || {};
      const dayFields = vehicleData[day] || [];
      const lastField = dayFields[dayFields.length - 1];
      const newId = lastField ? lastField.id + 1 : 1;
      return {
        ...prevState,
        [vehicleClass]: {
          ...vehicleData,
          [day]: [...dayFields, { id: newId }],
        },
      };
    });
  };

  const onRemove = (day, id) => {
    const vehicleClass = customVehicleClass || "car";
    setFieldsState((prevState) => {
      const vehicleData = prevState[vehicleClass] || {};
      const updatedDayFields = (vehicleData[day] || []).filter(
        (field) => field.id !== id
      );
      return {
        ...prevState,
        [vehicleClass]: {
          ...vehicleData,
          [day]: updatedDayFields,
        },
      };
    });
  };

  const handleHourlyRateCheckboxChange = (day, id) => {
    const vehicleClass = customVehicleClass || "car";
    setDisabledFields((prevState) => {
      const newHourlyDisabled = {
        ...prevState.hourly,
        [`${vehicleClass}-${day}-${id}`]:
          !prevState.hourly[`${vehicleClass}-${day}-${id}`],
      };
      return { ...prevState, hourly: newHourlyDisabled };
    });
    formApiRef.current.setValue(
      `customDays[${vehicleClass}][${day}][${day}-${id}][rate]`,
      ""
    );
  };

  const handleDisabledRateCheckboxChange = (day, id) => {
    const vehicleClass = customVehicleClass || "car";
    setDisabledFields((prevState) => {
      const newDisabledRateDisabled = {
        ...prevState.disabled,
        [`${vehicleClass}-${day}-${id}`]:
          !prevState.disabled[`${vehicleClass}-${day}-${id}`],
      };
      return { ...prevState, disabled: newDisabledRateDisabled };
    });
    formApiRef.current.setValue(
      `customDays[${vehicleClass}][${day}][${day}-${id}][disabled]`,
      ""
    );
  };

  const fourFieldProps = {
    lSize: 3,
    iSize: 7,
  };

  const handleRemoveSet = (selectedSet) => {
    setSets((prevSet) => {
      const removedSet = prevSet.find((set) => set.id === selectedSet?.id);
      if (!removedSet) return prevSet;

      return prevSet
        .filter((set) => set.id !== selectedSet?.id)
        .map((set) => ({
          ...set,
          days: [...new Set([...set.days, removedSet.day])],
        }));
    });
  };

  const handleAddField = (setId) => {
    setSets(
      sets.map((set) => {
        if (set.id === setId) {
          const newFieldId = set.fields.length
            ? set.fields[set.fields.length - 1].id + 1
            : 1;
          return { ...set, fields: [...set.fields, { id: newFieldId }] };
        }
        return set;
      })
    );
  };

  const handleRemoveField = (setId, fieldId) => {
    setSets(
      sets.map((set) => {
        if (set.id === setId) {
          return {
            ...set,
            fields: set.fields.filter((field) => field.id !== fieldId),
          };
        }
        return set;
      })
    );
  };

  const handleDayChange = (setId, event) => {
    const newDay = event.value;
    const removedSet = sets.find((set) => set.id === setId);
    setSets(
      sets.map((set) => {
        if (set.id === setId) {
          return { ...set, day: newDay };
        } else {
          return {
            ...set,
            days: [...new Set([...set.days, removedSet.day])].filter(
              (d) => d !== newDay
            ),
          };
        }
      })
    );
  };

  return (
    <Container className="p-0">
      {contextHolder}
      <p className={`${styles.title} mx-0 mb-3 p-0`}>
        Create New Parking Lot Account
      </p>
      <fieldset>
        <Form getApi={setFormApi} className={styles.form} onSubmit={submit}>
          {({ formState }) => {
            const { parking_hours, parking_hours_type, parking_hours_format } =
              formState.values;
            setPHSetting(parking_hours);
            const fields = getFields();
            const lotSettings = getLotSettingsFields();
            return (
              <Row className="mx-auto w-100">
                <Col
                  xs={11}
                  className="m-auto py-3 pb-3 pr-4 pl-1 pointer-events-none"
                >
                  <Tabs
                    list={list()}
                    defaultTab={selectedTab}
                    className={styles.tabs}
                  />
                </Col>
                <Col
                  className={
                    selectedTab === "information"
                      ? `${styles.fieldset} pt-3 pl-4 ml-3`
                      : "d-none"
                  }
                >
                  {renderParkingLotFields(fields)}
                </Col>
                <Row
                  className={
                    selectedTab === "settings" ? "pl-3 pr-3" : "d-none"
                  }
                >
                  <Col>
                    <div className="d-flex align-items-center my-3 ml-2">
                      <span className={styles.detailsLabel}>Parking Days</span>
                      <span className="border border-2 flex-grow-1 ml-2"></span>
                    </div>
                  </Col>
                  <div className={styles.daysContainer}>
                    {!isEmpty(errors["selectedDays"]) && (
                      <p
                        className={styles.alertMsg}
                        style={{ textAlign: "left" }}
                      >{`( ${errors["selectedDays"]} )`}</p>
                    )}
                  </div>
                  <div className={styles.daysContainer}>
                    {map(weekDays, (d, idx) => {
                      return (
                        <div>
                          <label className={styles.checkboxText}>
                            <input
                              type="checkbox"
                              checked={selectedDays?.includes(d.value)}
                              onChange={() => handleCheckboxChange(d.value)}
                              className={styles.customCheckBox}
                            />
                            {`${capitalize(d?.label)}`}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.daysContainer2}>
                    <div>
                      <label className={styles.checkboxText}>
                        <input
                          type="checkbox"
                          checked={selectedDays?.includes("saturday")}
                          onChange={() => handleCheckboxChange("saturday")}
                          className={styles.customCheckBox}
                        />
                        Saturday
                      </label>
                    </div>
                    <div className={styles.sundaySection}>
                      <label className={styles.checkboxText}>
                        <input
                          type="checkbox"
                          checked={selectedDays?.includes("sunday")}
                          onChange={() => handleCheckboxChange("sunday")}
                          className={styles.customCheckBox}
                        />
                        Sunday
                      </label>
                    </div>
                  </div>
                  <div className={styles.form}>
                    <div className="d-flex align-items-center my-3 mr-2">
                      <span className={styles.detailsLabel}>Parking Hours</span>
                      <span className="border border-2 flex-grow-1 ml-2 mr-2"></span>
                    </div>
                    <div className={styles.daysContainer}>
                      {!isEmpty(errors["parking_hours_type"]) && (
                        <p
                          className={styles.alertMsg}
                          style={{ textAlign: "left", marginLeft: "5px" }}
                        >{`( ${errors["parking_hours_type"]} )`}</p>
                      )}
                    </div>
                    <div>
                      {renderParkingHourSettingsFields(
                        parkingHourTypeSettingFieldsWeekdays
                      )}
                    </div>
                    {parking_hours_format === "custom" && (
                      <Row
                        className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-double"]} pl-2`}
                        xs={8}
                      >
                        {renderFields(
                          fieldsHour({
                            name: "",
                            customInputClass: styles.input,
                            customLabelClass: styles.inputLabel,
                          }),
                          { ...doubleFieldProps, errors }
                        )}
                      </Row>
                    )}
                    {selectedDays?.map((selectedDay) => (
                      <Row
                        className={
                          parking_hours_type === "hourly_dynamic"
                            ? "m-auto align-items-center"
                            : "d-none"
                        }
                        key={selectedDay}
                      >
                        <Col>
                          <div className="d-flex align-items-center pl-0 pb-2">
                            <span className={styles.subdetailsLabel}>
                              {capitalize(selectedDay)}
                            </span>
                          </div>
                        </Col>
                        <Col
                          className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-radio"]} pt-2`}
                          xs={8}
                        >
                          {renderFields(
                            fieldsParkingHoursFormatDynamicWeekdays({
                              name: `${selectedDay}`,
                              customInputClass: styles.input,
                              customLabelClass: styles.inputLabel,
                              ...fieldAttrs,
                            })
                          )}
                        </Col>
                        {formState?.values?.hourFormat &&
                          formState?.values?.hourFormat[selectedDay] ===
                            "custom" && (
                            <Row
                              className={`p-0 ${styles.fieldset} ${styles["fieldset-double"]}`}
                              style={{ marginLeft: "11.5rem" }}
                              xs={8}
                            >
                              {renderFields(
                                fieldsHour({
                                  name: selectedDay,
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps, errors }
                              )}
                            </Row>
                          )}
                      </Row>
                    ))}

                    {
                      <Row
                        className={
                          parking_hours_type === "hourly_static"
                            ? "m-auto align-items-center"
                            : "d-none"
                        }
                        key="hourly_static"
                      >
                        <Col>
                          <div className="d-flex align-items-center">
                            <span className={styles.subdetailsLabel}>
                              Parking Hours
                            </span>
                          </div>
                        </Col>
                        <Col
                          className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-radio"]} pt-2`}
                          xs={8}
                        >
                          {renderFields(
                            fieldsParkingHoursFormatDynamicWeekdays({
                              name: `hourly_static`,
                              customInputClass: styles.radioBtns,
                              customLabelClass: styles.inputLabel,
                              ...fieldAttrs,
                            })
                          )}
                        </Col>
                        {formState?.values?.hourFormat &&
                          formState?.values?.hourFormat["hourly_static"] ===
                            "custom" && (
                            <Row
                              className={`p-0 ${styles.fieldset} ${styles["fieldset-double"]}`}
                              style={{ marginLeft: "11.5rem" }}
                              xs={8}
                            >
                              {renderFields(
                                fieldsHour({
                                  name: "hourly_static",
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps, errors }
                              )}
                            </Row>
                          )}
                      </Row>
                    }

                    {parking_hours_type !== "custom" &&
                      renderParkingLotSettingsFields(lotSettings, formState)}

                    {parking_hours_type === "custom" && (
                      <Col
                        className={`${styles.fieldset} m-0 p-0 pl-2`}
                        xs={10}
                      >
                        {renderFields(lotSettings.slice(0, 1), {
                          ...customClassFieldProps,
                          formState,
                          errors,
                        })}
                      </Col>
                    )}

                    {selectedDays?.map((selectedDay) => {
                      return Object.keys(fieldsState).map((key) => {
                        const fields =
                          (fieldsState[key] && fieldsState[key][selectedDay]) ||
                          [];
                        return (
                          <React.Fragment key={`${key}-${selectedDay}`}>
                            {fields?.map((field, idx) => (
                              <Row
                                className={
                                  parking_hours_type === "custom" &&
                                  customVehicleClass === key
                                    ? ``
                                    : "d-none"
                                }
                                key={field.id}
                              >
                                <Row className={styles.seperator}>
                                  {idx === 0 && (
                                    <Divider className={styles.divider} />
                                  )}
                                </Row>
                                <Row className={styles.customLabelRow}>
                                  <Col
                                    className={styles.customLabelSection}
                                    xs={5}
                                  >
                                    <label className={styles.customLabels}>
                                      Duration (From -To)
                                    </label>
                                  </Col>
                                  <Col
                                    className={styles.customLabelSection2}
                                    xs={4}
                                  >
                                    <label className={styles.customLabels2}>
                                      Hourly Rate $
                                    </label>
                                  </Col>
                                  <Col
                                    className={styles.customLabelSection3}
                                    xs={3}
                                  >
                                    <label className={styles.customLabels3}>
                                      Disabled Rate $
                                    </label>
                                  </Col>
                                </Row>
                                <Row className="m-auto align-items-center">
                                  <Col style={{ maxWidth: "22%" }}>
                                    <div className="d-flex align-items-center pl-3 pb-2">
                                      {idx === 0 && (
                                        <span
                                          className={styles.subdetailsLabel}
                                        >
                                          {capitalize(selectedDay)}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                  <Col
                                    className={`m-0 p-0 ${styles.fieldset} ${styles["fieldset-four"]} d-flex`}
                                    xs={8}
                                  >
                                    {renderFields(
                                      fieldsCustomHour({
                                        day: selectedDay,
                                        id: field.id,
                                        customInputClass: styles.input,
                                        customLabelClass: styles.inputLabel,
                                        vehicleClass: key,
                                        isHourlyRateDisabled:
                                          disabledFields.hourly[
                                            `${key}-${selectedDay}-${field.id}`
                                          ],
                                        isDisabledRateDisabled:
                                          disabledFields.disabled[
                                            `${key}-${selectedDay}-${field.id}`
                                          ],
                                      }),
                                      { ...fourFieldProps, errors }
                                    )}
                                    {idx === 0 && (
                                      <Button
                                        type="button"
                                        onClick={() => onAdd(selectedDay)}
                                        className={styles.addButtonCustom}
                                      >
                                        +
                                      </Button>
                                    )}
                                    {idx !== 0 && (
                                      <Button
                                        type="button"
                                        onClick={() =>
                                          onRemove(selectedDay, field.id)
                                        }
                                        className={styles.removeButtonCustom}
                                      >
                                        -
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                                <Row className={styles.checkboxRow}>
                                  <label className={styles.checkboxFree1}>
                                    <input
                                      type="checkbox"
                                      checked={
                                        disabledFields.hourly[
                                          `${key}-${selectedDay}-${field.id}`
                                        ] || false
                                      }
                                      onChange={() =>
                                        handleHourlyRateCheckboxChange(
                                          selectedDay,
                                          field.id
                                        )
                                      }
                                      className={styles.secondaryCheck}
                                    />
                                    <span className={styles.checkboxLabel}>
                                      Free
                                    </span>
                                  </label>
                                  <label className={styles.checkboxFree2}>
                                    <input
                                      type="checkbox"
                                      checked={
                                        disabledFields.disabled[
                                          `${key}-${selectedDay}-${field.id}`
                                        ] || false
                                      }
                                      onChange={() =>
                                        handleDisabledRateCheckboxChange(
                                          selectedDay,
                                          field.id
                                        )
                                      }
                                      className={styles.secondaryCheck}
                                    />
                                    <span className={styles.checkboxLabel}>
                                      Free
                                    </span>
                                  </label>
                                </Row>
                              </Row>
                            ))}
                          </React.Fragment>
                        );
                      });
                    })}

                    {parking_hours_type === "custom" && (
                      <Row className={styles.seperator}>
                        <Divider className={styles.divider} />
                      </Row>
                    )}
                    {renderParkingLotCustomSettingsFields(
                      lotSettings,
                      formState
                    )}

                    {parking_hours_type === "hourly_static" &&
                      staticNoChargeFields.map((field, index) => (
                        <React.Fragment key={field.id}>
                          <Row
                            className={
                              parking_hours_type === "hourly_static"
                                ? "m-auto align-items-center"
                                : "d-none"
                            }
                            key="no_charge"
                          >
                            <Col>
                              <div className="d-flex align-items-center pl-1">
                                <span
                                  className={
                                    index === 0
                                      ? styles.subdetailsLabel
                                      : "d-none"
                                  }
                                >
                                  No Charge Period{" "}
                                  <span className={styles.required}>*</span>
                                </span>
                              </div>
                            </Col>

                            <Row
                              className={`p-0 ${styles.fieldset} ${
                                styles["fieldset-noCharge"]
                              } ${
                                errors[
                                  `noChargePeriod[${field.id}][from]`
                                ]?.find(Boolean)?.length >= 30 &&
                                styles["fieldset-noCharge-error"]
                              }`}
                              xs={10}
                            >
                              {renderFields(
                                fieldsNoChargePeriod({
                                  name: field.id,
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps, errors }
                              )}
                            </Row>
                            <TrashIcon
                              className={styles.trashIconStatic}
                              onClick={() =>
                                handleNoChargePeriodRemoval(
                                  parking_hours_type,
                                  field.id
                                )
                              }
                            />
                          </Row>
                        </React.Fragment>
                      ))}

                    {sets.map((set) => (
                      <React.Fragment key={set.id}>
                        <Col
                          className={
                            parking_hours_type === "hourly_dynamic"
                              ? `${styles.fieldset} pt-2`
                              : "d-none"
                          }
                          xs={10}
                        >
                          {renderFields(
                            fieldsNoChargeDay({
                              name: `day-${set.id}`,
                              customInputClass: styles.input,
                              customLabelClass: styles.inputLabel,
                              days: set?.days,
                            }),
                            { ...noChargeDayFieldProps(set.id), errors }
                          )}
                          <TrashIcon
                            className={styles.setTrashIcon}
                            onClick={() => handleRemoveSet(set)}
                          />
                          <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.setAddIcon}
                            onClick={() => handleAddField(set.id)}
                          />
                        </Col>
                        {set.fields.map((field, index) => (
                          <Row
                            key={field.id}
                            className={
                              parking_hours_type === "hourly_dynamic"
                                ? "m-auto align-items-center"
                                : "d-none"
                            }
                          >
                            <Col>
                              <div className="d-flex align-items-center pl-1">
                                {index === 0 && (
                                  <span className={styles.subdetailsLabel}>
                                    No Charge Hours{" "}
                                    <span className={styles.required}>*</span>
                                  </span>
                                )}
                              </div>
                            </Col>
                            <Row
                              className={`p-0 ${styles.fieldset} ${
                                styles["fieldset-noCharge"]
                              } ${
                                errors[
                                  `noChargePeriodDynamic[field-${set.id}-${field.id}][from]`
                                ]?.find(Boolean)?.length >= 30 &&
                                styles["fieldset-noCharge-error"]
                              }`}
                              xs={10}
                            >
                              {renderFields(
                                fieldsNoChargePeriodDynamic({
                                  name: `field-${set.id}-${field.id}`,
                                  customInputClass: styles.input,
                                  customLabelClass: styles.inputLabel,
                                }),
                                { ...doubleFieldProps, errors }
                              )}
                            </Row>
                            {index !== 0 && (
                              <TrashIcon
                                className={styles.fieldTrashIcon}
                                onClick={() =>
                                  handleRemoveField(set.id, field.id)
                                }
                              />
                            )}
                          </Row>
                        ))}
                      </React.Fragment>
                    ))}

                    {parking_hours_type !== "custom" && (
                      <Col className={`${styles.fieldset} pt-2`} xs={10}>
                        <div className={styles.daysContainer}>
                          {!isEmpty(
                            errors["parking_days_no_charge_period"]
                          ) && (
                            <p
                              className={styles.alertMsg}
                              style={{ textAlign: "left" }}
                            >{`( ${errors["parking_days_no_charge_period"]} )`}</p>
                          )}
                        </div>
                        <Button
                          type="button"
                          onClick={() =>
                            handleNoChargePeriodAddition(parking_hours_type)
                          }
                          className={`${styles.addButton} mr-4 mt-2`}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.plusIcon}
                          />
                          <span> Add No Charge Period</span>
                        </Button>
                      </Col>
                    )}
                  </div>
                </Row>
                <Col
                  className="d-flex justify-content-center mb-3 mt-3"
                  xs={12}
                >
                  {selectedTab === "information" && (
                    <Button
                      onClick={() => closeModal()}
                      className={`${styles.cancelButton} mb-2`}
                    >
                      Close
                    </Button>
                  )}
                  {selectedTab === "settings" && (
                    <Button
                      onClick={() => setSelectedTab("information")}
                      className={`${styles.cancelButton} mb-2`}
                    >
                      Previous
                    </Button>
                  )}
                  {selectedTab === "information" && (
                    <Button
                      type="button"
                      onClick={() => handleNext()}
                      className={styles.submitButton}
                    >
                      Next
                    </Button>
                  )}
                  {selectedTab === "settings" && (
                    <Button type="submit" className={styles.submitButton}>
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            );
          }}
        </Form>
      </fieldset>
    </Container>
  );
};

export default withFetching(withCurrentUser(Create));
