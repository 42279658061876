import React, { useEffect, useState } from 'react'
import { map } from 'underscore';
/* Styles and Assets */
import styles from './index.module.sass'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'
import { ReactComponent as UnionDotsIcon } from 'assets/union-dots.svg'
import { useSelector } from 'react-redux';
import { capitalize, camelize } from 'components/helpers';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    setShowText(isActive)
  },[isActive])

  return (
    <div
      onMouseEnter={() => setShowText(true)}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx === 0 ? 'border-right' : ''}`}
    >
      { showText 
        ? <span className={highlightedClass}>{text}</span>
        : icon
      }
    </div>
  )
}

const DetailsIcon = () => {
  return <div 
    role='button'
    className={`${styles['icon-details']} d-flex justify-content-center align-items-center ml-3`}
  >
    <UnionDotsIcon style={{ width: 14, height: 10}} />
  </div>
}

const TableRow = ({history, record, match, openModal, modalType, setRecord}) => {
  const selectedRecordId = useSelector(state => state.permit_type.records.record?.id)

  const actions = [
    { 
      icon: <EyeIcon className={`${styles['icon-eye']} mr-3`}/>,
      highlightedClass: `${styles['background-primary']} mr-3 text-white`,
      onClick: () => {openModal('view')},
      text: 'View',
      isActive: modalType === 'view' && selectedRecordId === record.id,
    },
 
    { 
      icon: <DetailsIcon/>,
      highlightedClass: `${styles['background-secondary']} ml-3 text-white`,
      text: 'Details',
      onClick: () => {history.push(`${match.path}/${record.id}/voi`, {parking_lot_id: record?.id })}
    }
  ]

  return (
    <tr onClick={() => setRecord(record)}>
      <td>{record.id}</td>
      <td>{record.name}</td>
      <td>{record.location.full_address}</td>
      <td>{camelize(record.category)}</td>
      <td>{record.email}</td>
      <td>{record.parking_admin ? record.parking_admin.name : null}</td>
      <td>{record.town_manager ? record.town_manager.name : null}</td>
      <td>{capitalize(record?.status)}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
        </div>
      </td>
    </tr>
  )
}

export default TableRow;