import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Switch } from 'react-router';
import Index from 'components/pages/reports/index';
import Show from 'components/pages/reports/system_reports/index/index';
import DetailedReports from 'components/pages/reports/detailed_reports';
import renderWithBackPath from 'components/modules/render_with_back_path';

const Routing = (props) => {
  const { match } = props;
  return (
    <React.Fragment>
      <Route exact path={match.path} component={Index}/>
      <Route exact path={`${match.path}/detailed/:report_type`} component={DetailedReports}/>
      <Switch>
        <Route path={`${match.path}/:module_name`} render={(props) => (
          <React.Fragment>
            <Route exact path={`${props.match.path}`} component={renderWithBackPath(Show, `${match.url}/`)} />
          </React.Fragment>
        )} />
      </Switch>
    </React.Fragment>
  );
};

Routing.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Routing);
