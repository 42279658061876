import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorBoundary from "components/base/errors/error_boundary";
import styles from "./index.module.sass";
import ValetAppContent from "../valet_app_content";
import RedirectIfAuthorized from "components/modules/redirect_if_authorized";

const ValetAppLayout = (props) => {
  const { serverError } = props;
  const params = new URLSearchParams(props.location.search || "");

  if (params.get("frame") === "headless") {
    return (
      <ErrorBoundary serverError={serverError}>
        <ValetAppContent />
      </ErrorBoundary>
    );
  }

  return (
    <div className="d-flex">
      <div className={`${styles.contentContainer} frame-container`}>
        <ErrorBoundary serverError={serverError}>
          <ValetAppContent />
        </ErrorBoundary>
      </div>
    </div>
  );
};

const mapState = (state) => {
  const { server } = state;
  const { error = {} } = server;
  return { serverError: error.payload };
};

ValetAppLayout.propTypes = {
  serverError: PropTypes.object,
};

export default connect(mapState)(RedirectIfAuthorized(ValetAppLayout));
