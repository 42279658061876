import React, {useCallback} from 'react'
import PropTypes from 'prop-types';
/* API */
import { filterFetcher } from 'api/parking_lots';
/* Helpers */
import { filterFields } from 'components/helpers/fields/event_parking_applications/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';
import TableRow from './tableRow';

const TableData = (props) => {
  
  const { list, setRecord, filters, isResourceFetching, openModal, modalType, selectedRecord, activeTab } = props

  const renderRecords = useCallback(() => {
    return list.map((record) => {
      return <TableRow 
        {...props}
        key={record.id} 
        record={record} 
        selectedRecordId={selectedRecord.id} 
        openModal={openModal} 
        modalType={modalType} 
        setRecord={setRecord}
      />
    });
  },[list, modalType, setRecord, openModal,selectedRecord.id, props])

  return (
    <IndexTable
      {...props}
      className={`${styles.table} ${activeTab ? styles.tableActive : ''}`}
      isFetching={() => isResourceFetching}
      filterFields={filterFields}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      columns={
        <React.Fragment>
          <th attr="id" style={{ width: '10%', minWidth: 50 }}>Lot ID</th>
          <th attr="name" style={{ width: '14%', minWidth: 120 }}>Name</th>
          <th attr="full_address" style={{ width: '30%', minWidth: 250 }}>Location</th>
          <th attr="phone" style={{ width: '11%', minWidth: 100 }}>Category</th>
          <th attr="email" style={{ width: '11%', minWidth: 100 }}>Email</th>
          <th attr="parking_admin" style={{ width: '11%', minWidth: 100 }}>Parking Operator</th>
          <th attr="town_manager" style={{ width: '11%', minWidth: 100 }}>Town Manager</th>
          <th attr="status" style={{ width: '14%', minWidth: 50 }}>Status</th>
          <th className='d-flex justify-content-center' attr="actions" style={{ width: '20%', minWidth: '210px', }} disableSort> Actions </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Parking Lots'
    />
  )
}

TableData.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired
};

export default TableData