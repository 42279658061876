import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/clients";
import Loader from 'components/helpers/loader';
import { showFields } from 'components/helpers/fields/clients';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { Link } from 'react-router-dom';

const Show = (props) => {
  const { startFetching, selectedRecordId, isSaving, updateClient, setUpdateData, errors, setErrors, openDeleteModal} = props
  const [isFetching, setIsFetching] = useState()
  const [client, setClient] = useState({})

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const applicationData = useCallback((data) => {
    const { id, client  } = data
    
    return ({  
      id: id,
      name: client?.name,
      phone: client?.phone,
      email: `${client?.name?.toLowerCase()}@gamil.com`,
      status: 'active'
    })
  },[])

  useEffect(() => {
    setIsFetching(true)
    const fetchClient = async () => {
      if(!selectedRecordId)  return;
      try {
        const response = await show({id: selectedRecordId?.id})
        if(response.status === 200) {
          const { data } = response;
          setClient(applicationData(data));
        }
      } catch(error) {
      } finally {
        setIsFetching(false)
      }
    };
    fetchClient();
  }, [selectedRecordId, applicationData, startFetching])

  const validateValues = (formState) => {
    const errors = {}
    const { name, email, status } = formState;
    if(!name) { errors['name'] = ['Name is required']}
    if(!email) { errors['email'] = ['Email is required']}
    if(!status) { errors['status'] = ['Status is required']}
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
   
    setUpdateData(values)
    updateClient(values)
  }

  const fields = () => {
    return showFields({...fieldAttrs})
  }

  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{client?.username}</p>
      <Form initialValues={client} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col>
              <div >
                {renderFields(newFields.slice(0, 8), {...singleFieldProps, errors})}
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <Button 
                type='button'
                onClick={()=>  openDeleteModal()}
                className={`${styles.deleteButton} mb-3`}
              >
                Delete
              </Button>
              
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Save
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Show);