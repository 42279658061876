import faker from "faker";
import { FieldType } from "components/helpers/form_fields";
import permissions from "config/permissions";
import fieldsWithPermission from "./fields_with_permission";
import { AGENCIES_FILTER_FORM_LABELS as LABELS } from "../../../lang/en";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';


const fieldsNew = (
  officers,
	managers,
	agencyTypes,
	parkingLots,
	renderLocationModal
) => [
  {
    name: "location",
    label: LABELS.LOCATION,
    mandatory: true,
    render: renderLocationModal,
  },
  { name: "email", label: LABELS.EMAIL, mandatory: true },
  { name: "name", label: LABELS.NAME, mandatory: true },
  { name: "phone", label: LABELS.PHONE },
  // TODO add condition to only show status field to system and super admin
  {
    name: "status",
    label: "Status",
    mandatory: true,
    type: FieldType.SELECT_FIELD,
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
    ],
    defaultValue: "active",
  },
  {
    name: "manager_id",
    label: "Manager",
    mandatory: true,
    type: FieldType.SELECT_FIELD,
    emptyValue: 0,
    options: managers.map((manager) => {
      return { value: manager.value, label: manager.label };
    }),
  },
  {
    name: "agency_type_id",
    label: "Agency Type",
    mandatory: true,
    type: FieldType.SELECT_FIELD,
    emptyValue: 0,
    options: agencyTypes.map((agencyType) => {
      return { value: agencyType.value, label: agencyType.label };
    }),
  },
  {
    name: "officer_ids",
    label: "Officers",
    mandatory: true,
    type: FieldType.MULTISELECT_FIELD,
    options: officers.map((officer) => {
      return { value: officer.value, label: officer.label };
    }),
  },
  {
    name: "parking_lot_ids",
    label: "Parking Lots",
    mandatory: true,
    type: FieldType.MULTISELECT_FIELD,
    options: parkingLots.map((parkingLot) => {
      return { value: parkingLot.value, label: parkingLot.label }
    })
  }
];

const fieldsShow = (
  officers,
	managers,
	agencyTypes,
  parkingLots,
	renderLocationModal,
	userPermissions
) =>
  fieldsWithPermission(
    fieldsNew(
      officers,
	    managers,
	    agencyTypes,
      parkingLots,
	    renderLocationModal
    ),
    userPermissions,
    permissions.UPDATE_AGENCY
  );

const exampleData = () =>
  process.env.NODE_ENV === "production"
    ? {
        status: "active",
      }
    : {
        email: faker.internet.email(),
        name: faker.company.companyName(),
        phone: "+13583767678",
        status: "active",
      };

const filterFields = (attrs) => {
  const {agency_type = [], agency_manager=[], customInputClass, customLabelClass} = attrs
  return [
    { 
      name: "id",
      label: "Agency ID",
      customInputClass,
      customLabelClass
    },
  { 
    name: "name",
    label: "Agency Name",
    customInputClass,
    customLabelClass
  },
  {
    name: "agency_type",
    label: "Agency Type",
    mandatory: true,
    type: FieldType.SELECT_FIELD,
    emptyValue: 0,
    options: agency_type,
    customInputClass,
    customLabelClass
  },
  {
    name: "manager_id",
    label: "Manager",
    mandatory: true,
    type: FieldType.SELECT_FIELD,
    emptyValue: 0,
    options: agency_manager,
    customInputClass,
    customLabelClass
  },
  {
    name: "status",
    label: "Status",
    mandatory: true,
    type: FieldType.SELECT_FIELD,
    customInputClass,
    customLabelClass,
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
    ],
  }
  ];
};

const newFields = (attrs) => {
  const { customInputClass='', customLabelClass='', managers=[], agencyTypes=[], officers=[], parkingLots=[], renderLocationModal, customInputClass1='' } = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      suspended: 'red',
      active: 'green'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'location',
      label: 'Location',
      mandatory: true,
      render: renderLocationModal,
      disabled: false
    },
    {
      name: 'email',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'name',
      label: 'Agency Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass: customInputClass1,
      customLabelClass,
      mandatory: true,
    },
    {
      name: "manager_id",
      label: "Manager",
      mandatory: true,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      emptyValue: 0,
      options: managers,
      customInputClass: customInputClass1,
      customLabelClass,
    },
    {
      name: "officer_ids",
      label: "Officers",
      type: FieldType.MULTISELECT_FIELD,
      options: officers,
      customInputClass: customInputClass1,
      customLabelClass,
      showToolTip: false,
      mandatory: true,
    },
    {
      name: "agency_type_id",
      label: "Agency Type",
      mandatory: true,
      type: FieldType.DROPDOWN_SELECT_FIELD,
      emptyValue: 0,
      options: agencyTypes,
      customInputClass: customInputClass1,
      customLabelClass,
    },
    {
      name: "parking_lot_ids",
      label: "Parking Lots",
      type: FieldType.MULTISELECT_FIELD,
      options: parkingLots,
      customInputClass: customInputClass1,
      customLabelClass,
      showToolTip: false,
    }

  ]
}

export { fieldsNew, fieldsShow, exampleData, filterFields, newFields };
