import { me } from "api/users";
import { checkMe } from "api/webapp/subscriber";
import { checkValet } from "api/valetApp/valet";
const SET_TOKEN = "SET_TOKEN";
const SET_SUB_TOKEN = "SET_SUB_TOKEN";
const SET_VALET_TOKEN = "SET_VALET_TOKEN";
const CLEAR_TOKEN = "CLEAR_TOKEN";
const CLEAR_SUB_TOKEN = "CLEAR_SUB_TOKEN";
const CLEAR_VALET_TOKEN = "CLEAR_VALET_TOKEN";
const SET_CURRENT_USER_DATA = "SET_CURRENT_USER_DATA";
const SET_CURRENT_SUB_DATA = "SET_CURRENT_SUB_DATA";
const SET_CURRENT_VALET_DATA = "SET_CURRENT_VALET_DATA";
const LOG_OUT = "LOG_OUT";
const SUB_LOG_OUT = "SUB_LOG_OUT";
const VALET_LOG_OUT = "SUB_LOG_OUT";

const setDashboardToken = (payload) => {
  return {
    type: SET_TOKEN,
    payload: payload,
  };
};

const setSubscriberToken = (payload) => {
  return {
    type: SET_SUB_TOKEN,
    payload: payload,
  };
};

const setValetToken = (payload) => {
  return {
    type: SET_VALET_TOKEN,
    payload: payload,
  };
};

const clearToken = {
  type: CLEAR_TOKEN,
};

const clearSubToken = {
  type: CLEAR_SUB_TOKEN,
};

const clearValetToken = {
  type: CLEAR_VALET_TOKEN,
};

const valetLogOut = () => ({
  type: VALET_LOG_OUT,
});

const subLogOut = () => ({
  type: SUB_LOG_OUT,
});

const logOut = () => ({
  type: LOG_OUT,
});

const setCurrentUserData = () => (dispatch) =>
  me().then((res) => {
    res &&
      dispatch({
        type: SET_CURRENT_USER_DATA,
        payload: res.data,
      });
  });

const setCurrentSubData = () => (dispatch) =>
  checkMe().then((res) => {
    res &&
      dispatch({
        type: SET_CURRENT_SUB_DATA,
        payload: res.data,
      });
  });

const setCurrentValetData = () => (dispatch) =>
  checkValet().then((res) => {
    res &&
      dispatch({
        type: SET_CURRENT_VALET_DATA,
        payload: res.data,
      });
  });

export {
  LOG_OUT,
  SET_TOKEN,
  SET_SUB_TOKEN,
  SET_VALET_TOKEN,
  CLEAR_SUB_TOKEN,
  CLEAR_TOKEN,
  CLEAR_VALET_TOKEN,
  SET_CURRENT_USER_DATA,
  SET_CURRENT_SUB_DATA,
  SET_CURRENT_VALET_DATA,
  SUB_LOG_OUT,
  VALET_LOG_OUT,
  logOut,
  setDashboardToken,
  setSubscriberToken,
  setValetToken,
  clearToken,
  setCurrentUserData,
  setCurrentSubData,
  setCurrentValetData,
  subLogOut,
  clearSubToken,
  valetLogOut,
  clearValetToken,
};
