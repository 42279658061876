import { FieldType } from 'components/helpers/form_fields'

const filterFields = ({customLabelClass, customInput1Class='', datesToFilter,  dateRange}) => [
  {
    name: 'period',
    label: 'Period',
    type: FieldType.DROPDOWN_SELECT_FIELD,
    options: datesToFilter,
    customInputClass: customInput1Class,
    customLabelClass,
    placeholder: 'Select date...'
  },
  { 
    name: 'range["from"]', 
    label: 'Date',
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    placeholder: 'From',
    customInputClass: customInput1Class,
    customLabelClass,
  },
  { 
    name: 'range["to"]', 
    label: '',
    type: FieldType.SINGLE_DATE_FIELD,
    max: new Date(),
    placeholder: 'To',
    customInputClass: customInput1Class,
    customLabelClass,
  },
];

export { filterFields };
