import resourceApi from 'components/modules/resource_api';
import fetchApi from 'components/modules/fetch_api';

const { index, show, update, destroy, create } = resourceApi('clients');

const search = (query) => {
  return fetchApi(`dashboard/clients/search`, { method: 'GET', params: { ...query, status: 'active' } });
};

const filterFetcher = (params = {}) => {
  const { page, perPage = 20, query, filters = {} } = params;
  return (
    index({
      page,
      perPage,
      query: {
        ...query,
        ...filters,
      },
    })
  );
};

const fetchClientModuleSetting = (id) => {
  return fetchApi(`dashboard/module_settings/${id}`, { method: 'GET' });
};

export { filterFetcher, show, update, destroy, create, search, fetchClientModuleSetting };
