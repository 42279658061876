import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { map } from 'underscore';
/* Helpers */
import { camelize, displayDate } from 'components/helpers';
/* Base */
import Button from 'components/base/button';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg';
import { ReactComponent as EditIcon } from 'assets/pencil-icon.svg';
import { ReactComponent as QrCodeIcon } from 'assets/qr_code_icon.svg';
import SlideButton from 'components/base/slide_button';
import { ReactComponent as ShareIcon } from 'assets/share.svg';

const Action = (props) => {
  const { isActive, text, icon, onClick, highlightedClass, idx, disableHighlight } = props;
  const [showText, setShowText] = useState(isActive)

  const handleClick = () => {
    if(!onClick || disableHighlight) return

    setShowText(true); 
    onClick();
  }

  useEffect(() => {
    if(disableHighlight) return
    setShowText(isActive)
  },[isActive, disableHighlight])

  return (
    <div
      onMouseEnter={() => {if(!disableHighlight) setShowText(true)}}
      onClick={handleClick}
      onMouseLeave={() => { if(!isActive) {setShowText(false)}}}
      className={`d-flex align-items-center justify-content-center ${idx !== 0 ? 'border-left' : ''}`}
      role='button'
      style={{cursor: `${disableHighlight && 'not-allowed'}`}}
    >
      { showText
        ? <span 
            className={`${showText ? 'd-inline-flex justify-content-center align-items-center' : 'd-none'} ${highlightedClass}`}
            style={{padding: '5px 10px', borderRadius: '15px' }}
          >
            {text}
          </span>
        : <span className={`${showText ? 'd-none' : 'd-inline-flex justify-content-center align-items-center'}`}>{icon}</span>
      }
    </div>
  )
}

const TableRow = ({openModal, record, setRecord, modalType}) => {
  
  const selectedRecordId = useSelector(state => state.permit.records.record?.id)
  
  const actions = [
    {
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => {openModal('show')},
      text: 'View',
      isActive: modalType === 'show' && selectedRecordId === record.id,
    },
    { 
      icon: <EditIcon className='mx-3'/>,
      highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
      onClick: () => {openModal('edit')},
      text: 'Edit',
      isActive: modalType === 'edit' && selectedRecordId === record.id,
      disableHighlight: record.shared || record.permit_type === "Day Pass"
    },
    {
      icon: <QrCodeIcon className='mx-3'/>,
      highlightedClass: `${styles['background-secondary']} mx-3 text-white`,
      onClick: () => { openModal('generate_qr_code'); },
      text: 'QR',
      isActive: modalType === 'generate_qr_code' && selectedRecordId === record.id,
    }
  ];

  return (
    <tr onClick={() => setRecord(record)}>
      <td className=''>{record.permit_number}</td>
      <td >
        <div className='d-flex no-wrap align-items-center'>
          <span>{camelize(record.permit_type)}</span>
          { record.shared && (
            <SlideButton icon={<ShareIcon />} className={` ${styles.badge} ml-2`} status='orange-light'>
              <span className='mr-3 d-inline-block' style={{width: '34px'}}>Shared</span>
            </SlideButton>
          )}
        </div>
      </td>
      <td className=''>{displayDate(record.issue_date, 'MMMM Do YYYY')}</td>
      <td className='text-uppercase'>{record.vehicle?.plate_number}</td>
      <td className='text-capitalize'>{record.vehicle?.owner_name || record.owner_name}</td>
      <td className='text-capitalize'>{record.vehicle?.model}</td>
      <td className='text-capitalize'>{record.permit_expired ? 'Yes' : 'No'}</td>
      <td className=''>{record.expiry_date === 'Non-Expiring'? 'Non-Expiring' : displayDate(record.expiry_date, 'MMMM Do YYYY')}</td>
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `} >
          { map(actions, (action, idx) => <Action key={idx} {...action} idx={idx} />)}
          {
            record.status === 'revoked' 
              ? <span className={`${styles.revokedText} mx-3 d-flex align-items-center justify-content-center`}>Revoked</span> 
              : <span>
                  <Button
                    className={`${record.status === 'cancelled' ?  styles.disabledBg : styles.dangerBg} ${styles.revokeButton} mx-3`} 
                    onClick={() => openModal('revoke', record.status)}
                  >
                    Revoke
                  </Button>
                </span>
          }
        </div>
      </td>
    </tr>

  )
}

export default TableRow;
