import fetchApi from "components/modules/fetch_api";

const fetchPrivacyPolicy = () => {
  return fetchApi(`v1/pages/privacy_policy`, { method: "GET" });
};

const acceptPrivacyPolicy = () => {
  return fetchApi(`v1/users/accept_privacy_policy`, { method: "PUT" });
};

export { fetchPrivacyPolicy, acceptPrivacyPolicy };
