import React, { useState, useEffect, useCallback } from "react";
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  Steps,
  Upload,
  message,
  Modal,
  Table,
  Row,
  Col,
  Select,
  Tag,
  Progress,
  Radio,
  DatePicker,
  Divider,
} from "antd";
import { PlusOutlined, CloudUploadOutlined } from "@ant-design/icons";
import styles from "./index.module.sass";
import PhoneInput from "react-phone-input-2";
import { searchV1 as dropdownsSearch } from "api/dropdowns";
import { setSubscriberToken, setCurrentSubData } from "actions/users";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
/* Modules */
import { isEmpty } from "underscore";
import { ReactComponent as EditIcon } from "assets/edit_icon.svg";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signUp, getSignUpFormFields } from "api/webapp/subscriber";
import withFetching from "components/modules/with_fetching";
/* Modules */
import RedirectIfAuthorized from "components/modules/redirect_if_authorized";
import moment from "moment";
import { validateStep2 } from "api/app/apply_permit";
import { Spinner, Container } from "reactstrap";
import WebAppHeader from "components/base/webapp_header";
import { useTranslation } from "react-i18next";
import "components/pages/signup/antd.css";
import Loader from "components/helpers/loader";
import ThemeProvider from "subscriberThemeProvider";

const { Dragger } = Upload;

const { Step } = Steps;

const SignUpPage = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [vehicleForm] = Form.useForm();
  const [profilePicture, setProfilePicture] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [countries, setCountries] = useState();
  const [accountTypes, setAccountTypes] = useState([]);
  const [stateList, setStateList] = useState();
  const [manufacturers, setManufacturers] = useState();
  const [disabilities, setDisabilities] = useState();
  const [base64ProfilePicture, setBase64ProfilePicture] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isNewVehicle, setIsNewVehicle] = useState();
  useState(false);
  const [countryCode, setCountryCode] = useState("us");
  // eslint-disable-next-line
  const [emailExists, setEmailExists] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // eslint-disable-next-line
  const [errors, setErrors] = useState();
  const [isFetchingFields, setIsFetchingFields] = useState(false);
  const [userFields, setUserFields] = useState([]);
  const [vehicleFields, setVehicleFields] = useState([]);
  const [sortedUserFields, setSortedUserFields] = useState([]);
  const [sortedVehicleFields, setSortedVehicleFields] = useState([]);
  const [vehicleClasses, setVehicleClasses] = useState([]);
  const [vehicleSubClasses, setVehicleSubClasses] = useState([]);
  const [vehicleCategories, setVehicleCategories] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleFileUpload = (file, field) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        const key = field.input_attribute || field.name;
        setUploadedFiles((prev) => ({
          ...prev,
          [key]: { file, base64 },
        }));
        resolve(false);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const fetchVehicleSubclasses = async (e) => {
    try {
      const subclasses = await dropdownsSearch("vehicle_sub_classes_list", {
        vehicle_class_id: e,
      });
      setVehicleSubClasses(subclasses.data);
    } catch (_error) {}
  };

  const fetchVehicleCategories = async (e) => {
    try {
      const categories = await dropdownsSearch("vehicle_categories_list");
      setVehicleCategories(categories.data);
    } catch (_error) {}
  };

  const fetchVehicleClasses = async (e) => {
    try {
      const classes = await dropdownsSearch("vehicle_classes_list");
      setVehicleClasses(classes.data);
    } catch (_error) {}
  };

  const fetchVehicleTypes = async (e) => {
    try {
      const types = await dropdownsSearch("vehicle_types_list");
      setVehicleTypes(types.data);
    } catch (_error) {}
  };

  useEffect(() => {
    fetchVehicleCategories();
    fetchVehicleClasses();
    fetchVehicleTypes();
  }, []);
  useEffect(() => {
    const fetchFormFields = async () => {
      setIsFetchingFields(true);
      try {
        const fields = await getSignUpFormFields({
          form_id: currentStep === 0 ? 1 : 2,
          per_page: 10000,
        });
        setUserFields(
          fields?.data?.filter((field) => field.form_section === "user" && field.status === 'enabled')
        );
        setVehicleFields(
          fields?.data?.filter((field) => field.form_section === "vehicle" && field.status === 'enabled')
        );
      } catch (_error) {
        setIsFetchingFields(false);
      } finally {
        setIsFetchingFields(false);
      }
    };
    fetchFormFields();
  }, [currentStep]);

  useEffect(() => {
    setSortedUserFields(userFields.sort((a, b) => a.position - b.position));
    setSortedVehicleFields(
      vehicleFields.sort((a, b) => a.position - b.position)
    );
  }, [userFields, vehicleFields]);

  const getFormLabel = (fieldName, label, required) => {
    const isError = errors?.[fieldName] || false;
    return (
      <Col className={styles.labelCol}>
        <span className={styles.formLabel}>{label}</span>
        {required && (
          <Tag
            className={styles.requiredMark}
            style={{ color: isError ? "rgba(251, 116, 91, 0.6)" : "#3a9ced" }}
          >
            *
          </Tag>
        )}
      </Col>
    );
  };

  // Get dropdown options based on input_attribute
  const getDropdownOptions = (field) => {
    switch (field?.input_attribute) {
      case "account_type_id":
        return accountTypes;
      case "vehicle_class_id":
        return vehicleClasses;
      case "vehicle_sub_class_id":
        return vehicleSubClasses;
      case "vehicle_category_id":
        return vehicleCategories;
      case "vehicle_type_id":
        return vehicleTypes;
      case "state_code":
        return stateList;
      case "country_code":
        return countries;
      case "disabilities_ids":
        return disabilities;
      case "manufacturer_id":
        return manufacturers;
      case "registration_state":
        return stateList;
      default:
        return field?.options?.map((op) => {
          return { value: op, label: op };
        });
    }
  };

  const renderField = (field) => {
    const commonProps = {
      name: field.custom ? `custom_${field.id}` : field.input_attribute,
      label: getFormLabel(
        field.input_attribute || field.name,
        field.name,
        field.mandatory
      ),
      rules: [
        { required: field.mandatory, message: `${field.name} is required` },
      ],
    };
    switch (field.field_type) {
      case "text_input":
        return (
          <Form.Item
            {...commonProps}
            rules={
              field.input_attribute === "email"
                ? [
                    {
                      required: currentStep === 0,
                      message: t("signup.validations.email.required"),
                    },
                    {
                      type: "email",
                      message: t("signup.validations.email.format"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || !emailExists) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("signup.validations.email.unique"))
                        );
                      },
                    }),
                  ]
                : commonProps.rules
            }
            key={field.id}
          >
            {field.input_attribute === "password" ||
            field.input_attribute === "repeat_password" ? (
              <Input.Password
                className={styles.formInput}
                placeholder={`Enter ${field.name}`}
              />
            ) : (
              <Input
                onChange={(e) => {
                  field.input_attribute === "email" &&
                    validateEmail(e.target.value);
                }}
                className={styles.formInput}
                placeholder={`Enter ${field.name}`}
              />
            )}
          </Form.Item>
        );
      case "dropdown":
        const options = getDropdownOptions(field);
        return (
          <Form.Item {...commonProps} key={field.id}>
            <Select
              className={styles.formInput}
              placeholder={`Select ${field.name}`}
              showSearch
              optionFilterProp="label"
              options={options}
              onChange={(e) => {
                field.input_attribute === "vehicle_class_id" &&
                  fetchVehicleSubclasses(e);
                field.input_attribute === "country_code" && setCountryCode(e);
              }}
            />
          </Form.Item>
        );
      case "multi_select":
        const multiSelectOptions = getDropdownOptions(field);
        return (
          <Form.Item {...commonProps} key={field.id}>
            <Select
              mode="multiple"
              maxTagCount={"responsive"}
              className={styles.formInput}
              placeholder={`Select ${field.name}`}
              showSearch
              optionFilterProp="label"
              options={multiSelectOptions}
              onChange={(e) => {
                field.input_attribute === "vehicle_class_id" &&
                  fetchVehicleSubclasses(e);
                field.input_attribute === "country_code" && setCountryCode(e);
              }}
            />
          </Form.Item>
        );
      case "date":
        const disabledDate = (current) => {
          return field.input_attribute === "birthday"
            ? current && current > moment().subtract(18, "years")
            : field.input_attribute === "dln_expiry_date"
            ? current && current < moment().startOf("day")
            : null;
        };
        return (
          <Form.Item {...commonProps} key={field.id}>
            <DatePicker
              className={styles.formInput}
              placeholder={`Select ${field.name}`}
              disabledDate={disabledDate}
              style={{ width: "100%" }}
            />
          </Form.Item>
        );
      case "upload":
        if (field.input_attribute === "avatar") {
          return (
            <Form.Item {...commonProps} label="" key={field.id}>
              <Upload {...uploadProps}>
                {profilePicture ? (
                  <div className={styles.uploadedFileContainer}>
                    <img
                      src={base64ProfilePicture}
                      alt="Profile"
                      className={styles.profilePicture}
                    />
                    <Button
                      type="text"
                      className={styles.deleteButton}
                      onClick={removeProfilePicture}
                      icon={<TrashIcon className={styles.removeIcon} />}
                    />
                  </div>
                ) : (
                  <div
                    className={styles.upload}
                    style={{ opacity: profilePicture ? 0.5 : 1 }}
                  >
                    <PlusOutlined />
                    <div className={styles.uploadText}>
                      {t("signup.Upload")}
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          );
        } else {
          const key = field.input_attribute || field.name;
          const uploadedFile = uploadedFiles[key];

          return (
            <Form.Item {...commonProps} key={field.id}>
              <Dragger
                style={{ marginLeft: -40, width: "98%" }}
                beforeUpload={(file) => handleFileUpload(file, field)}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    message.success(
                      `${field.name} ${t(
                        "signup.validations.file_upload_success"
                      )}`
                    );
                  } else if (info.file.status === "error") {
                    message.success(
                      `${field.name} ${t(
                        "signup.validations.file_upload_fail"
                      )}`
                    );
                  }
                }}
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined style={{ color: "grey" }} />
                </p>
                {uploadedFile ? (
                  <p className="ant-upload-text">{uploadedFile.file.name}</p>
                ) : (
                  <p className="ant-upload-text">{t("signup.uploadText")}</p>
                )}
                <p className="ant-upload-hint">{t("signup.formatsText")}</p>
              </Dragger>
            </Form.Item>
          );
        }

      case "radio":
        return (
          <Form.Item {...commonProps} key={field.id}>
            <Radio.Group value={field.default_value}>
              <Radio value="true">{t("choices.yes")}</Radio>
              <Radio value="false">{t("choices.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        );
      case "number":
        return field.input_attribute === "phone" ? (
          <Form.Item
            name={field.input_attribute}
            label={getLabel(
              field.input_attribute,
              "Phone Number",
              currentStep === 0
            )}
            rules={[
              {
                required: currentStep === 0,
                message: t("signup.validations.phone_number.required"),
              },
              {
                pattern: /^\d{11 || 12}$/,
                message: t("signup.validations.phone_number.format"),
              },
            ]}
          >
            <PhoneInput
              inputClass={styles.phoneInput}
              dropdownClass={styles.dropdown}
              searchClass={styles.search}
              searchStyle={{
                fontSize: "13px",
                height: "30px",
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              disableSearchIcon
              searchPlaceholder="Search for Countries"
              searchNotFound="Not Found"
              country="us"
              enableSearch={true}
              autocompleteSearch={true}
            />
          </Form.Item>
        ) : (
          <Form.Item {...commonProps} key={field.id}>
            {
              <Input
                className={styles.formInput}
                placeholder={`Enter ${field.name}`}
              />
            }
          </Form.Item>
        );
      default:
        return null;
    }
  };
  const renderFieldsInRows = (fields) => {
    const rows = [];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.field_type === "upload") {
        rows.push(
          <Row key={field.id}>
            <Col span={24}>{renderField(field)}</Col>
          </Row>
        );
      } else {
        const nextField = fields[i + 1];
        rows.push(
          <Row gutter={16} key={field.id}>
            <Col span={12}>{renderField(field)}</Col>
            {nextField && nextField.field_type !== "upload" && (
              <Col span={12}>{renderField(nextField)}</Col>
            )}
          </Row>
        );
        if (nextField && nextField.field_type !== "upload") i++;
      }
    }
    return rows;
  };

  const btnSpinner = (props = {}) => {
    return (
      <span>
        <Spinner {...props} size="sm" color="default" />
      </span>
    );
  };
  // Step navigation
  const nextStep = async () => {
    try {
      await form.validateFields();
      if (currentStep === 1) {
        onFinish(form.getFieldsValue());
        setUserDetails(form.getFieldsValue());
      } else {
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const prevStep = () => setCurrentStep(currentStep - 1);

  const uploadProps = {
    name: "file",
    accept: "image/*",
    showUploadList: false,
    beforeUpload: (file) => {
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isValidFileType) {
        message.error(t("signup.validations.invalid_file_format"));
        return false;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setProfilePicture(file);
        setBase64ProfilePicture(reader.result);
      };
      return false;
    },
    onChange: (info) => {
      if (info.file.status === "uploading") {
        setUploadProgress(info.file.percent);
      }
      if (info.file.status === "done") {
        message.success(
          `${info.file.name} ${t("signup.validations.file_upload_success")}`
        );
        setUploadProgress(0);
      } else if (info.file.status === "error") {
        message.error(
          `${info.file.name} ${t("signup.validations.file_upload_fail")}`
        );
        setUploadProgress(0);
      }
    },
  };

  const removeProfilePicture = () => {
    setProfilePicture(null);
    setBase64ProfilePicture("");
  };

  const showModal = () => {
    setIsNewVehicle(true);
    setModalVisible(true);
    vehicleForm.resetFields();
  };

  const handleModalCancel = () => {
    vehicleForm.resetFields();
    setModalVisible(false);
    setSelectedVehicle(null);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleModalSave = (values) => {
    if (
      values.registration_card &&
      typeof values.registration_card !== "string"
    ) {
      getBase64(values.registration_card?.file, (result) => {
        values.registration_card = result;
      });
    }

    const existingVehicle = vehicles.find(
      (vehicle) => vehicle.plate_number === values.plate_number
    );
    const vehicleToBeUpdated = existingVehicle
      ? existingVehicle
      : selectedVehicle;
    if (vehicleToBeUpdated) {
      const updatedVehicles = vehicles.map((vehicle) =>
        vehicle === vehicleToBeUpdated ? { ...vehicle, ...values } : vehicle
      );
      setVehicles(updatedVehicles);
    } else {
      setVehicles([...vehicles, values]);
    }
    setModalVisible(false);
    setSelectedVehicle(null);
    vehicleForm.resetFields();
  };

  const handleRemove = (record) => {
    setVehicles(vehicles.filter((vehicle) => vehicle !== record));
    if (selectedVehicle === record) {
      setSelectedVehicle();
    }
  };

  const registerUser = useCallback(async (user) => {
    setIsSaving(true);
    try {
      const response = await signUp({ user });
      if (response.status === 200 || response.status === 201) {
        window.sessionStorage.setItem("userEmail", user?.email);
        window.sessionStorage.setItem("userName", user?.first_name);
        localStorage.removeItem("countdownEndTime");
        history.push("/confirm_user_account");
      }
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    } finally {
      setIsSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (record) => {
    setIsNewVehicle(false);
    setSelectedVehicle(record);
    vehicleForm.setFieldsValue(record);
    setModalVisible(true);
  };

  const columns = [
    {
      title: t("vehicle_attributes.plate_number.label"),
      dataIndex: "plate_number",
      key: "plate_number",
    },
    {
      title: t("vehicle_attributes.model.label"),
      dataIndex: "model",
      key: "model",
    },
    {
      title: t("vehicle_attributes.color.label"),
      dataIndex: "color",
      key: "color",
    },
    {
      title: t("buttons.actions"),
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            type="text"
            icon={<EditIcon className="svg-dark position-relative" />}
            onClick={() => handleEdit(record)}
          />
          <Button
            type="text"
            icon={<TrashIcon />}
            onClick={() => handleRemove(record)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countriesList = await dropdownsSearch("countries_list");
        setCountries(countriesList.data);
      } catch (_error) {}
    };
    if (isEmpty(countries)) fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        const accountTypeList = await dropdownsSearch("account_types_list");
        setAccountTypes(accountTypeList.data);
      } catch (_error) {}
    };
    if (isEmpty(accountTypes)) fetchAccountTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const manufacturerList = await dropdownsSearch("manufacturers_list");
        setManufacturers(manufacturerList.data);
      } catch (_error) {}
    };
    if (isEmpty(manufacturers)) fetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDisabilities = async () => {
      try {
        const disabilitiesList = await dropdownsSearch("disabilities_list");
        setDisabilities(disabilitiesList.data);
      } catch (_error) {}
    };
    if (isEmpty(disabilities)) fetchDisabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const statesList = await dropdownsSearch("states_list", {
          country_code: countryCode,
        });
        setStateList(statesList.data);
      } catch (_error) {}
    };
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const validateEmail = async (email) => {
    setEmailExists(false);
    try {
      // eslint-disable-next-line
      const response = await validateStep2({ email: email });
      setEmailExists(false);
    } catch (_error) {
      if (_error?.response?.data?.errors?.email) {
        setEmailExists(true);
      }
    }
  };

  const getLabel = (fieldName, label, required) => {
    const isError = errors?.[fieldName] || false;
    return (
      <Col className={styles.labelCol}>
        <span className={styles.formLabel}>{t(`signup.${label}`)}</span>
        {required && (
          <Tag
            className={styles.requiredMark}
            style={{ color: isError ? "rgba(251, 116, 91, 0.6)" : "#3a9ced" }}
          >
            *
          </Tag>
        )}
      </Col>
    );
  };

  const onFinish = () => {
    const regularFields = {};
    const customFields = [];

    Object.entries(userDetails).forEach(([key, value]) => {
      if (key.startsWith("custom_")) {
        // Extracting field_id and content from the custom field name
        const fieldId = key.replace("custom_", "");
        customFields.push({
          form_field_id: fieldId,
          content: value,
        });
      } else {
        // Regular field
        if (value) regularFields[key] = value;
      }
    });

    const payload = {
      ...regularFields, // Default fields data
      custom_fields: customFields, // Custom fields data
      vehicles: vehicles,
    };
    if (uploadedFiles["address_proof"]) {
      payload.address_proof = uploadedFiles["address_proof"]?.base64;
    }
    if (currentStep === 1) registerUser(payload);
    if (currentStep === 0) {
      setUserDetails(form.getFieldsValue());
    }
  };

  return (
    <ThemeProvider>
      <Container className="m-0 p-0 mw-100">
        <WebAppHeader />
        <div className={styles.signUpPage}>
          <div className={styles.formContainer}>
            <h2 className={styles.registerText}>{t("register")}</h2>
            <ConfigProvider
              theme={{
                components: {
                  Form: {
                    labelColor: "#242E42",
                    labelRequiredMarkColor: "#7ab7f3",
                    labelFontSize: "13px",
                    colorError: "rgba(251, 116, 91, 0.9)",
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "13px",
                  },
                },
                token: {
                  colorPrimary: "#1890ff",
                  fontFamily: "Roboto, sans-serif",
                },
              }}
            >
              <div className="signUpPage">
                <Steps
                  current={currentStep}
                  className={styles.steps}
                  responsive={true}
                >
                  <Step title="User Information" />
                  <Step title="Vehicle Information" />
                </Steps>
                <Form
                  name="signup"
                  form={form}
                  layout="vertical"
                  colon={false}
                  onFieldsChange={onFinish}
                  requiredMark={false}
                  className={styles.form}
                  labelAlign="left"
                  onFinish={(values) => onFinish(values)}
                >
                  {isFetchingFields && currentStep === 0 && <Loader />}
                  {currentStep === 0 && (
                    <div className="userStep">
                      {renderFieldsInRows(sortedUserFields)}
                    </div>
                  )}
                  {currentStep === 1 && (
                    <div className="vehicleStep">
                      {vehicles.length === 0 && (
                        <p className="text-center">
                          {t("signup.vehiclesText")}
                        </p>
                      )}
                      {vehicles.length > 0 && (
                        <>
                          <Divider>{t("signup.addedVehicles")}</Divider>
                          <Table
                            dataSource={vehicles}
                            columns={columns}
                            pagination={false}
                            className="table"
                            sticky={true}
                            scroll={{ y: 550 }}
                          />
                        </>
                      )}
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        className={styles.addButton}
                        onClick={showModal}
                      >
                        {t("signup.Add Vehicle")}
                      </Button>
                      <Modal
                        centered
                        title={
                          isNewVehicle
                            ? t("signup.Add Vehicle")
                            : "Edit Vehicle"
                        }
                        open={modalVisible}
                        onCancel={handleModalCancel}
                        footer={null}
                        classNames={{ header: "text-center" }}
                        width="650px"
                      >
                        <Form
                          onFinish={handleModalSave}
                          form={vehicleForm}
                          layout="vertical"
                          className={styles.modalForm}
                          colon={false}
                          requiredMark={false}
                        >
                          {renderFieldsInRows(sortedVehicleFields)}
                          <Form.Item className={styles.buttonContainer}>
                            <Button
                              className={styles.prevButton}
                              onClick={handleModalCancel}
                            >
                              {t("buttons.cancel")}
                            </Button>
                            <Button
                              type="primary"
                              className={styles.button}
                              htmlType="submit"
                            >
                              {t("buttons.save")}
                            </Button>
                          </Form.Item>
                        </Form>
                      </Modal>
                    </div>
                  )}
                  <Form.Item className={styles.buttonContainer}>
                    {currentStep > 0 && (
                      <Button onClick={prevStep} className={styles.prevButton}>
                        {t("signup.Previous")}
                      </Button>
                    )}
                    {currentStep < 1 && (
                      <Button className={styles.button} onClick={nextStep}>
                        {t("signup.Next")}
                      </Button>
                    )}
                    {currentStep === 1 && (
                      <Button
                        className={styles.button}
                        type="primary"
                        onClick={nextStep}
                      >
                        {isSaving
                          ? btnSpinner({ className: "spinner-border" })
                          : t("signup.Submit")}
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </div>
            </ConfigProvider>
            {uploadProgress > 0 && (
              <Progress
                percent={uploadProgress}
                status="active"
                style={{ width: "50%", margin: "20px auto", display: "block" }}
              />
            )}
          </div>
          <ToastContainer position="top-center" />
        </div>
      </Container>
    </ThemeProvider>
  );
};

function mapDispatch(dispatch) {
  return {
    ...bindActionCreators({ setSubscriberToken, setCurrentSubData }, dispatch),
  };
}

export default connect(
  null,
  mapDispatch
)(withFetching(RedirectIfAuthorized(SignUpPage)));
