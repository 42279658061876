import React, { useCallback } from 'react'
import { Col, Row } from 'reactstrap';
import styles from './index.module.sass'
import Button from 'components/base/button';
import SlideButton from 'components/base/slide_button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import FilterForm from './filterForm';
import Tabs from 'components/base/tabs';

const CollapseContent = ({ startFetching, setList, setFilters, match, resource, initialFilter, setInitialFilter, dateRange, setDateRange, filters}) => {

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        fiters={filters}
        setInitialFilter={setInitialFilter}
        initialFilter={initialFilter}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </Row>
  )
}

const ToolBar = (props) => {
  const {isFetching, activeTab, setActiveTab, openModal} = props;

  const tabs = [
    {label: 'Default Reports', value: 'default'},
    {label: 'Custom Reports', value: 'custom'}
  ]

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])
  
  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start align-items-center p-0'>
        <span className={`${styles.title} general-text-1 mr-3`}>
          {`System Reports (${props.total})`}
        </span>
        <Tabs className={styles.tab} list={tabs} defaultTab={'default'} />
      </Col>
      <Col className='d-flex justify-content-end pr-0'>
        <SlideButton
          className={styles.createButton}
          onClick={ () => openModal('create')}
          isActive={true}
          icon={<PenToSquareSvg />}
        >
          <span className='mr-3 d-inline-block'>Create New Report</span>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse('filters')}
          className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={activeTab === 'filters' ? styles.activeIcon : ''}/>}
          size="md"
        />
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;