import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

const RedirectIfAuthorized = (Component) => {
  const HOC = class extends React.Component {

    componentDidMount () {
      const { history, isAuthorized, isSubAuthorized, isValetAuthorized } = this.props;

      if (isAuthorized && (this.props.match.path.indexOf("dashboard") > -1)) {
        history.push('/dashboard');
      }
      if (isSubAuthorized && (this.props.match.path === '/signup' || this.props.match.path === '/login')){
        history.push('/subscriber');
      }
      if (isValetAuthorized && (this.props.match.path === '/valet_login' || this.props.match.path === '/valet')){
        history.push('/valet/home');
      }
    }

    render() {
      return <Component {...this.props}/>
    }
  }
  return connect(
    mapState,
    null
  )(HOC)
}

function mapState (state) {
  const { isAuthorized } = state.user.auth;
  const { isSubAuthorized } = state.user.subscriberAuth;
  const { isValetAuthorized } = state.user.valetAuth;

  return { isAuthorized, isSubAuthorized, isValetAuthorized };
}

RedirectIfAuthorized.propTypes = {
  history: PropTypes.object.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isSubAuthorized: PropTypes.bool.isRequired,
  isValetAuthorized: PropTypes.bool.isRequired,
};

export default RedirectIfAuthorized;