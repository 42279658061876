import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
} from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";
import withCurrentValetStaff from "components/modules/with_current_valet_staff";
import { ReactComponent as Logo } from "assets/logo.svg";
import { ReactComponent as ArrowDownIcon } from "assets/menu_icons/arrow_down_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import CurrentTime from "components/pages/dashboard/current_time";
import styles from "./index.module.sass";
import { useTranslation } from "react-i18next";

function ValetAppHeader(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentValetStaff } = props;
  const [willLogOut, setWillLogOut] = useState(false);

  return (
    <div className={styles.container}>
      <Navbar sticky="top" light expand className={styles.navbar}>
        <Link
          to={currentValetStaff ? "/valet" : "/valet_login"}
          className={`${styles.logoLink} d-flex align-items-center ml-4 btn-default text-light pointer`}
        >
          <Logo />
          <div className="ml-1">
            <div className={styles.title}>{t("navbar.header")}</div>
            <div className={`${styles.whiteText} general-text-2`}></div>
          </div>
        </Link>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown
            nav
            inNavbar
            className="d-flex align-items-center"
          >
            <DropdownToggle nav className="text-light float-right">
              {currentValetStaff ? (
                <span className={`${styles.dFlex} align-items-center`}>
                  <img
                    src={
                      currentValetStaff.avatar ||
                      "https://i.stack.imgur.com/34AD2.jpg"
                    }
                    alt="profile"
                    className="rounded-circle mr-2"
                    width="40"
                    height="40"
                  />
                  <span className="d-none d-sm-inline">
                    <DropdownToggle nav className="float-right text-light pl-0">
                      <ArrowDownIcon className={styles.arrowDownIcon} />
                    </DropdownToggle>
                    <span className={`${styles.dFlex} ${styles.dFlexColumn}`}>
                      <span className={styles.username}>
                        {process.env.NODE_ENV !== "production"
                          ? currentValetStaff.name
                          : currentValetStaff.name}
                      </span>
                      <CurrentTime className={styles.currentTime} />
                    </span>
                  </span>
                </span>
              ) : null}
            </DropdownToggle>
            {currentValetStaff && (
              <DropdownMenu right>
                <DropdownItem>
                  <Link className="nav-link menu-points" to="/profile">
                    <FontAwesomeIcon
                      size="xs"
                      icon={faPencilAlt}
                      className="mr-2"
                    />
                    Edit account
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    className="nav-link menu-points"
                    onClick={() => setWillLogOut(!willLogOut)}
                  >
                    <FontAwesomeIcon
                      size="xs"
                      icon={faSignOutAlt}
                      className="mr-2"
                    />
                    Log out
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        </Nav>
        <Modal
          centered
          isOpen={willLogOut}
          backdropClassName={styles.backdropC}
          style={{ width: "100%" }}
        >
          <ModalHeader className="border-0 pb-0 justify-content-center text-center">
            You&apos;re logging out!
          </ModalHeader>
          <ModalBody
            className={`d-flex justify-content-center align-items-center pt-4 pb-3`}
          >
            Are you sure you want to log out?
          </ModalBody>
          <ModalFooter className="mx-auto w-100 border-0">
            <Col className="d-flex justify-content-center mb-3 mt-2" xs={12}>
              <Button
                className={styles.logOutBtns}
                onClick={() => history.push("/valet/sign_out")}
              >
                Yes
              </Button>
              <Button
                className={`${styles.logOutBtns} ${styles["logOutBtns-bg-secondary"]}`}
                onClick={() => setWillLogOut(!willLogOut)}
              >
                No
              </Button>
            </Col>
          </ModalFooter>
        </Modal>
      </Navbar>
    </div>
  );
}

ValetAppHeader.propTypes = {
  currentValetStaff: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default withRouter(
  withCurrentValetStaff(ValetAppHeader, ValetAppHeader)
);
