import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map, filter } from 'underscore';
/* Base */
import { renderField } from 'components/base/forms/common_form'
import Button from 'components/base/button';
import FormParkingLots from '../formparkingLots';
/* API */
import { search as dropdownSearch } from 'api/dropdowns'
import { index as parkingLotIndex } from 'api/parking_lots';
/* Helpers */
import { fieldsNew } from 'components/helpers/fields/permit_types/index'
/* Styles/Assets */
import styles from '../edit/index.module.sass';
import Loader from 'components/helpers/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import LocationForm from '../location/form/index'
import withFetching from 'components/modules/with_fetching';
import { getParkingLotsByZip, getParkingLotsByLatLong } from 'api/permit_types';
import {exampleData} from 'components/helpers/fields/permit_types/index'
import { capitalize } from 'components/helpers';
import TooltipInfo from 'components/helpers/tooltip_info';

const initialValues = { 
  expiring_permit: true, 
  lot_find_by: 'zip_code',
  cost_type: 'hourly_rate'
}

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

const attrs = {
  customLabelClass: styles.formLabel,
  customInputClass: styles.formInput,
}

const Create = (props) => {
  const  { addRecord, closeModal, errors, setErrors ,isSaving, startFetching, setSpecialParkingLots, specialParkingLots } = props;
  const formApiRef = useRef();
  const [parkingLots, setParkingLots] = useState()
  const [isFetchingLot, setIsFetchingLot] = useState(false)
  const [isFetchingLotUpdated, setIsFetchingLotUpdated] = useState(false)
  const [permitTypes, setPermitTypes] = useState()
  const [currentLocation, setCurrentLocation] = useState(exampleData)
  const [loc, setLoc] = useState(false)
  const [share, setShare] = useState(false)
  const [purposeOfVisit, setPurposeOfVisit] = useState(false)
  const [residentDetails, setResidentDetails] = useState(false)
  const [showHourlyRate, setShowHourlyRate] = useState(true)
  const record = ""

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const singleFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {handleExpiringPermitChange(e); setErrors({})}
    }
  }

  const permitFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {fetchSpecialParkingLots(e); setErrors({}); handlePermitNameChange(e)}
    }
  }

  
  const locationFieldProps = {
    lSize: 2,
    iSize: 10,
    events: {
      onChange: (e) => {handleLocationChange(e)}
    }

  }

  const costTypeFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {handleCostTypeChange(e); setErrors({})}
    }
  }
  
  const doubleFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {handleInputChange(e); setErrors({})}
    }
  }

  const zipFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {fetchParkingLotsByZip()}
    }
  }

  const latlongFieldProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {fetchParkingLotsByLatLong()}
    }
  }

  const handleCostTypeChange = (e) => {
    if(e.value === 'hourly_rate'){
      setShowHourlyRate(true)
    }else if (e.value === 'fixed_cost'){
      setShowHourlyRate(false)
    }
  }

  const handlePermitNameChange = (e) => {
    if(e.value === 'Visitor Permit'){
      fetchParkingLots()
    }
  }

  const handleLocationChange = (e) => {
    if(formApiRef.current.getValue('lot_find_by') === 'zip_code') formApiRef.current.setValue('country', currentLocation.country)
  }

  const handleExpiringPermitChange = (e) => {
    if( e === true) setShowHourlyRate(true)
    if (e === false ) setShowHourlyRate(false)
  }
  const handleInputChange = (e) => {
    if (e?.value === "free" && e?.label === "Free") {
      if (
        formApiRef?.current?.getValue("expiring_permit") === true &&
        formApiRef?.current?.getValue("name") !== "Visitor Permit" &&
        formApiRef?.current?.getValue("cost_type") === "hourly_rate"
      )
        formApiRef.current.setValue("hourly_rate", "0.0");
      if (
        formApiRef?.current?.getValue("expiring_permit") === true &&
        formApiRef?.current?.getValue("name") !== "Visitor Permit" &&
        formApiRef?.current?.getValue("cost_type") === "fixed_cost"
      )
        formApiRef.current.setValue("cost", "0.0");
      if (
        formApiRef?.current?.getValue("expiring_permit") === false ||
        formApiRef?.current?.getValue("name") === "Visitor Permit"
      ) {
        if (formApiRef?.current?.getValue("validity")?.includes("days"))
          formApiRef.current.setValue("cost_per_day", "0.0");
        if (formApiRef?.current?.getValue("validity")?.includes("weeks"))
          formApiRef.current.setValue("cost_per_week", "0.0");
        if (formApiRef?.current?.getValue("validity")?.includes("months"))
          formApiRef.current.setValue("cost_per_month", "0.0");
      }
    }
  };

  const verifyTimeValues = (formState) => {
    const errors = {}
    const { parking_hour_from: from,
            parking_hour_to: to,
            hourly_rate,
            payment_type,
            name,
            validity,
            category,
            parking_lot_ids,
            period,
            allow_cancellation,
            status,
            expiring_permit,
            lot_find_by,
            country,
            city,
            state,
            zipcode,
            latitude,
            longitude,
            distance,
            cost,
            cost_per_day,
            cost_per_week,
            cost_per_month,
            commuters_per_vehicle,
            minimum_chargeable_time,
            cost_type } = formState;
            
    if(!name) { errors['name'] = ['Name can\'t be blank']}
    if(!lot_find_by && name==='Residential Permit') { errors.lot_find_by = ['Please Select the Finding Method']}
    if(!category && expiring_permit && name !== 'Visitor Permit') { errors.category = ['Category can\'t be blank']}
    if(!validity && expiring_permit) { errors['validity'] = ['Validity can\'t be blank']}
    if(!allow_cancellation) { errors.allow_cancellation = ['Allow cancellation can\'t be blank']}
    if(!status) { errors['status'] = ['Status can\'t be blank']}
    if(!payment_type) { errors['payment_type'] = ['Payment type can\'t be blank']}
    if(isEmpty(period) && expiring_permit && name!=='Visitor Permit') { errors['period'] = ['Period can\'t be blank']}
    if(isEmpty(parking_lot_ids)) { errors.parking_lot_ids = ['Please select at least 1 parking lot']}
    if(name !== 'Visitor Permit' && payment_type !== 'free' && hourly_rate === undefined && cost_type === 'hourly_rate') { errors.hourly_rate = ['Hourly rate is required'] }
    if(name !== 'Visitor Permit' && payment_type === 'paid' && hourly_rate && parseFloat(hourly_rate) <= 0) { errors.hourly_rate = ['Hourly rate must be greater than 0'] }
    if((name==='Visitor Permit' && validity?.includes('days') && !to) ||(name!=='Visitor Permit' && !to)) errors.parking_hour_to = ["Time to can't be blank"]
    if((name==='Visitor Permit' && validity?.includes('days') && !from) ||(name!=='Visitor Permit' && !from)) errors.parking_hour_from = ["Time from can't be blank"]
    if(!!to && !!from && to === from) errors.parking_hour_to = ["Parking hours cannot have the same value"]
    if(!!to && !!from && to < from) errors.parking_hour_from = ["Starting Parking Hour cannot be greater than Final Parking Hour"]
    if(name==='Residential Permit' && lot_find_by === 'zip_code' && !country) errors.country = ['Country can\'t be blank']
    if(name==='Residential Permit' && lot_find_by === 'zip_code' && !city) errors.city = ['City can\'t be blank']
    if(name==='Residential Permit' && lot_find_by === 'zip_code' && !state) errors.state = ['State can\'t be blank']
    if(name==='Residential Permit' && lot_find_by === 'zip_code' && !zipcode) errors.zipcode = ['Zipcode can\'t be blank']
    if(name==='Residential Permit' && lot_find_by === 'location_perimeter' && !latitude) errors.latitude = ['Latitude can\'t be blank']
    if(name==='Residential Permit' && lot_find_by === 'location_perimeter' && !longitude) errors.longitude = ['Longitude can\'t be blank']
    if(name==='Residential Permit' && lot_find_by === 'location_perimeter' && !distance) errors.distance = ['Distance can\'t be blank']
    if(name==='Visitor Permit' && cost === undefined && payment_type === 'paid') errors.cost = ['Minimum cost can\'t be blank']
    if(name==='Visitor Permit' && validity?.includes('days') && cost_per_day === undefined && payment_type === 'paid') errors.cost_per_day = ['Cost Per Day can\'t be blank']
    if(name==='Visitor Permit' && validity?.includes('weeks') && cost_per_week === undefined && payment_type === 'paid') errors.cost_per_week = ['Cost Per Week can\'t be blank']
    if(name==='Visitor Permit' && validity?.includes('months') && cost_per_month === undefined && payment_type === 'paid') errors.cost_per_month = ['Cost Per Month can\'t be blank']
    if(name==='Carpool Permit' && !commuters_per_vehicle) errors.commuters_per_vehicle = ['Commuters can\'t be blank']
    if(name==='Visitor Permit' && !minimum_chargeable_time) errors.minimum_chargeable_time = ['Minimum Chargable Time is required']
    if(name !== 'Visitor Permit' && payment_type !== 'paid' && cost_type === 'fixed_cost') { errors.cost = ['Fixed cost is required'] }
    if(name !== 'Visitor Permit' && payment_type === 'paid' && cost && parseFloat(cost) <= 0) { errors.cost = ['Fixed cost must be greater than 0'] }
    if(name==='Visitor Permit' && validity?.includes('days') && cost_per_day <= 0 && payment_type === 'paid') errors.cost_per_day = ['Cost per day must be greater than zero.']
    if(name==='Visitor Permit' && validity?.includes('weeks') && cost_per_week <= 0 && payment_type === 'paid') errors.cost_per_week = ['Cost per week must be greater than zero.']
    if(name==='Visitor Permit' && validity?.includes('months') && cost_per_month <= 0 && payment_type === 'paid') errors.cost_per_month = ['Cost per month must be greater than zero.']

    return errors
  }
   

  const save = useCallback(() => {
    setErrors({})
    // eslint-disable-next-line
    const {values: values} = formApiRef.current.getState();
    const inputErrors = verifyTimeValues(values)
    // eslint-disable-next-line
    const permitTypeName= formApiRef.current.getValue('name')
    // eslint-disable-next-line
    const expiring_permit= formApiRef.current.getValue('expiring_permit')
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
  
    values.parking_lots_array = values.parking_lot_ids?.map(id => ({id: parseInt(id)})) || []
    if(["Special Permit", "Disabled Permit", "Government Permit", "Military Permit", "Medical Assistance Permit"].includes(permitTypeName)){
      const parking_lots_array = map(values.lots, lot => {
        const { id, allocated_spaces} = lot
        return { id, reserved_spaces: allocated_spaces }
    })
      values.parking_lots_array = parking_lots_array;
    }
    values.permit_type_name = 'ordinary';
    values.commuters_per_vehicle = values?.commuters_per_vehicle
    if(values.lot_find_by === 'zip_code'){
      values.location = {country: values.country , state: values.state, city: values.city, zip: values.zipcode}
    }
    if(values.lot_find_by === 'location_perimeter'){
      values.location = {ltd: values.latitude , lng: values.longitude} 
    }
    if(!expiring_permit){
      values.category = "non_expiring"
    }
    if(values.name !== 'Visitor Permit' && typeof(values?.validity) === 'string'){
      values.validity = values?.validity?.split()
    }
    const updatedValues = {
      ...values,
      sharing: share ? 'true' : 'false', // Add or update attributes here
      purpose_of_visit: `${purposeOfVisit}`,
      details_of_resident: `${residentDetails}`,
    };
    Object.assign({updatedValues}, {purpose_of_visit: purposeOfVisit}, { details_of_resident: residentDetails })
    addRecord(updatedValues);
  },[setErrors, addRecord, share, purposeOfVisit, residentDetails]);

  
  const renderLocationModal= (field, props) => {
    return (
      <LocationForm
        errors={props.errors}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        setLoc={setLoc}
        loc={loc}
        fetchLocationValuesByZipcode={fetchLocationValuesByZipcode}
        record={record}
      />
    );
  }

  const getFields = (validity, payment_type, expiring_permit, name) => {
    return fieldsNew({mute: false, parkingLots, validity, payment_type, permitTypes, expiring_permit, name, renderLocationModal: renderLocationModal, ...attrs})
  }
  
  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    const fetchParkingLotsByZip = async() => {
      const zip = formApiRef.current.getValue('zipcode')
      try {
        const response =  await getParkingLotsByZip(zip)

        if(response.data){setParkingLots(response.data.map((lot, idx) => {return {value: lot?.id , label: capitalize(lot.name)}}))}else{setParkingLots([])}
      } catch (error) {
        console.log(error)
      } finally {
      }  
    }

    const fetchParkingLotsByLatLong = async() => {
      const lat = formApiRef.current.getValue('latitude')
      const long = formApiRef.current.getValue('longitude')
      const distance = formApiRef.current.getValue('distance')
      try {
        if(lat && long){const response =  await getParkingLotsByLatLong({ ltd: lat, lng: long , distance: distance})

        if(response.data){setParkingLots(response.data.map((lot, idx) => {return {value: lot?.id , label: capitalize(lot.name)}}))}else{setParkingLots([])}}
      } catch (error) {
        console.log(error)
      } finally {
      }  
    }
 


 
    const fetchLocationValuesByZipcode = (values) => {
      
      const findBy = formApiRef.current.getValue('lot_find_by')
      if(findBy === 'zip_code'){formApiRef.current.setValue('country', values.country)
      formApiRef.current.setValue('state', values.state)
      formApiRef.current.setValue('city', values.city)
      formApiRef.current.setValue('zipcode', values.zip)
      fetchParkingLotsByZip()
    }
      if(findBy === 'location_perimeter'){
      formApiRef.current.setValue('latitude', values.ltd)
      formApiRef.current.setValue('longitude', values.lng)
      fetchParkingLotsByLatLong()
      }
    }
  


  useEffect(() => {
    const fetchPermitTypes = async () => {
      try {
        const permitTypesList = await startFetching(dropdownSearch('permit_type_list'))
        setPermitTypes(permitTypesList.data)
      } catch (_error) {
      }
    }
    if(isEmpty(permitTypes)) fetchPermitTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchParkingLots = async () => {
      try {
        const parkingLotsList = await startFetching(dropdownSearch('permit_parking_lot_list'))
        setParkingLots(parkingLotsList.data)
      } catch (_error) {
        console.log(_error)
      }
    }
  useEffect(() => {
    
   fetchParkingLots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


    const fetchSpecialParkingLots = async (e) => {
      const value = e?.value
      if(["Special Permit", "Disabled Permit", "Government Permit", "Military Permit", "Medical Assistance Permit"].includes(value)){
      setIsFetchingLot(true)
        try {
          const response = await parkingLotIndex({query: {reserved_for: 'special_permits'}, perPage: 10000000});
          setSpecialParkingLots( 
            map( 
              filter(response.data, pl => pl.reserved_spaces_count.special_permits >= 1), 
              ({id, reserved_spaces_count, name}) => ({id, reserved_spaces: reserved_spaces_count, name}))
          )
        } catch (_error) {
        }finally {
          setIsFetchingLot(false)
        }
      }
    }

  return (
    <Container className='p-0'>
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form getApi={setFormApi} className={`${styles.form}`} initialValues={initialValues} onSubmit={save} >

        {({ formState }) => {
            const { validity, payment_type, expiring_permit, name, lot_find_by, parking_lot_ids } = formState.values;
            const newFields = getFields(validity, payment_type, expiring_permit, name)

            return (
                    <Row className='mx-auto w-100'>
                      
                      { map(newFields[0], (field, idx) => {
                        if(idx === 3) {
                          field = {
                            ...field,
                          }
                        }
                        return (
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                            {idx=== 0 &&
                              renderField(field, { ...singleFieldProps, errors} )}
                            {idx=== 1 &&
                              renderField(field, { ...permitFieldProps, errors} )}
                            {idx=== 2 &&
                              renderField(field, { ...singleFieldProps, errors} )}
                            {(idx === 3) &&
                            <div className={name ==='Residential Permit' ? '' : 'd-none'}>
                              {renderField(field, { ...singleFieldProps, errors} )}
                            </div>
                            }
                            {name ==='Residential Permit' && (idx === 4) &&
                              <React.Fragment>
                               <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']} pl-2`} xs={12}>
                                 {renderField(field, { ...locationFieldProps, errors} )}  
                               </Col>
                              </React.Fragment>
                            }
      
                          </Col>
                          
                        )
                      }) }
                      
                      
      
                      { map(newFields[1], (field, idx) => {
                        if(idx === 1) {
                          field = {
                            ...field,
                          }
                        }
                        return (
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                           {name ==='Residential Permit' && lot_find_by === 'zip_code' && (idx === 0 || idx === 1 || idx === 2 ) &&
                              renderField(field, { ...doubleFieldProps, errors} )
                            }
                            {name ==='Residential Permit' && lot_find_by === 'zip_code' && (idx === 3 ) && <React.Fragment>
                              {renderField(field, { ...zipFieldProps, errors} )}
                              </React.Fragment>
                            }
                            {name ==='Residential Permit' && lot_find_by === 'location_perimeter' && (idx === 4 || idx === 5 ) &&
                              renderField(field, { ...latlongFieldProps, errors} )
                            }
                            {name ==='Residential Permit' && lot_find_by === 'location_perimeter' && (idx === 6 ) && <React.Fragment>
                              {renderField(field, { ...latlongFieldProps, errors} )}
                              </React.Fragment>
                            }

                            {idx=== 8 && expiring_permit &&
                              renderField(field, { ...doubleFieldProps, errors} )}
                            {name==='Carpool Permit' && idx===9 &&
                              renderField(field, { ...doubleFieldProps, errors} )
                            }
                          </Col>
                        )
                      }) }
      
                      {
                        expiring_permit && <React.Fragment>
                        { map(newFields[2], (field, idx) => {
                        return (
                          <React.Fragment>
                          {idx === 0 && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                             {renderField(field, { ...doubleFieldProps, errors} )}
                          </Col>}
                          {idx === 1 && name !== 'Visitor Permit' && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                            {renderField(field, { ...doubleFieldProps, errors} )}
                          </Col>}
                          {idx === 2 && name === 'Visitor Permit' && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                            {renderField(field, { ...doubleFieldProps, errors} )}
                          </Col>}
                          </React.Fragment>
                        )
                      }) }
                        </React.Fragment>
                      }
                      { map(newFields[3], (field, idx) => {
                        return (
                          <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-single']}`} xs={12}>
                            { renderField(field, { ...singleFieldProps, errors} )}
                          </Col>
                        )
                      }) }
                      {["Special Permit", "Disabled Permit", "Government Permit", "Military Permit", "Medical Assistance Permit"].includes(name) && <React.Fragment>
                      <Col className='mb-3 p-0' xs={12}>
                        {isFetchingLot && !isEmpty(parking_lot_ids)  
                          ? <Loader />
                          : <FormParkingLots 
                              selectedLots={parking_lot_ids} 
                              parkingLots={specialParkingLots}
                              formState={formState}
                              formApiRef={formApiRef}
                              permitParkingLots={record?.permit_parking_lots}
                              setParkingLots={setParkingLots}
                              setIsFetchingLotUpdated={setIsFetchingLotUpdated}
                              isFetchingLotUpdated={isFetchingLotUpdated}
                            /> 
                        }
                      </Col>
                        </React.Fragment>
                      }
      
                      { map(newFields[4], (field, idx) => {
                        
                        if(idx === 4) {
                          field = {...field, options: map(field.options, ({value, label}) => ({
                            value,
                            label: <React.Fragment>
                              <FontAwesomeIcon 
                                className='mr-2' 
                                icon={faCircle}
                                style={{ 
                                  color: `${value === 'active' ? 'green' : 'red'}`,
                                  width: 10
                                }}
                               /> 
                              {label}
                            </React.Fragment>
                          }))}
                        }
                        return (
                          <React.Fragment>
                            {name === 'Visitor Permit' && validity?.includes('days') && (idx === 0 || idx === 1) && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {name !== 'Visitor Permit' && (idx === 0 || idx === 1) && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {(idx === 2 || idx === 3 || idx === 4) && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }

                      {name !== 'Visitor Permit' && <React.Fragment>{ map(newFields[8], (field, idx) => {
                      
                      return (
                        <React.Fragment>
                          { <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                            { renderField(field, { ...costTypeFieldProps, errors} )}
                          </Col>}
                        </React.Fragment>
                      )
                      }) }</React.Fragment>}

                      {expiring_permit && showHourlyRate && <React.Fragment>{ map(newFields[5], (field, idx) => {
                        if(idx === 0) {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                        return (
                          <React.Fragment>
                            {name !== 'Visitor Permit' && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              { renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }</React.Fragment>}
                      
                      {<React.Fragment>{ map(newFields[6], (field, idx) => {
                        if(idx === 0) {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        }
                        return (
                          <React.Fragment>
                            {/* eslint-disable-next-line */}
                            {(!showHourlyRate || name === "Visitor Permit") && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }</React.Fragment>}
                      {
                        expiring_permit && <React.Fragment>
                        {name === 'Visitor Permit'&&  map(newFields[7], (field, idx) => {
                          field = {
                            ...field,
                            icon: dollarIcon(), 
                            customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                          }
                        return (
                          <React.Fragment>
                            {idx === 0 && validity?.includes('days') && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {idx === 1 && validity?.includes('weeks') && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                            {idx === 2 && validity?.includes('months') && <Col key={idx} className={`m-0 p-0 ${styles.fieldset} ${styles['fieldset-double']}`} xs={6}>
                              {renderField(field, { ...doubleFieldProps, errors} )}
                            </Col>}
                          </React.Fragment>
                        )
                      }) }
                        </React.Fragment>
                      }
                      <Row style={{ display: "flex", width: "100%" }}>
                        <label className={styles.checkboxText}>Space Sharing</label>
                        <input
                          type="checkbox"
                          checked={share}
                          onChange={() => setShare(!share)}
                          className={styles.customCheckStyle}
                        />
                        <span className={styles.checkboxDescription} style={{color: (share ? 'green' : 'red')}}>
                          * Parking space shared is
                        </span>
                        <span className={styles.checkboxDescription1} style={{color: (share ? 'green' : 'red')}}>
                          {share ? 'enabled' : 'disabled'}
                        </span>
                        <TooltipInfo 
                          className={styles.toolTip}
                          text='Enabling this will allow permit holders to share their parking spaces when not using them.'
                          target='share'
                        />
                      </Row>
                      {name === 'Visitor Permit' && <Col className='p-0' style={{ marginTop: '0.5rem' }} xs={12}>
                        <Row style={{ display: "flex", width: "100%" }}>
                          <label className={styles.checkboxText1}>Additional details to ask the applicant</label>
                        </Row>
                        <Row style={{ display: "flex", width: "100%", marginLeft: '1.5rem' }}>
                          <input
                            type="checkbox"
                            checked={purposeOfVisit}
                            onChange={() => setPurposeOfVisit(!purposeOfVisit)}
                            className={styles.customCheckStyle}
                          />
                          <span className={styles.checkboxDescription2}>
                            Purpose of the visit
                          </span>
                        </Row>
                        <Row style={{ display: "flex", width: "100%", marginLeft: '1.5rem', marginTop: '0.5rem' }}>
                          <input
                            type="checkbox"
                            checked={residentDetails}
                            onChange={() => setResidentDetails(!residentDetails)}
                            className={styles.customCheckStyle}
                          />
                          <span className={styles.checkboxDescription2} >
                            Details of the resident the applicant is visiting
                          </span>
                        </Row>
                      </Col>}
                    <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
                      <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                        Cancel
                      </Button>
                      <Button type='submit' className={`${styles.button} ${styles['button-bg-submit']}`}   isLoading={isSaving}>
                        Create
                      </Button>
                    </Col>
                </Row>
            )
          }
        }
        </Form>
      </fieldset>
    </Container>
  )
}
  

export default withFetching(Create);
