import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "underscore";
import Loader from "components/helpers/loader";

const withCurrentValetStaff = (Component, CustomLoader = null) => {
  const HOC = class extends React.Component {
    state = {
      currentValetStaff: null,
      currentValetStaffPermissions: [],
    };

    componentWillUnmount() {
      this.setState({
        currentValetStaff: null,
        currentValetStaffPermissions: [],
      });
    }

    componentWillReceiveProps(nextProps, nextContext) {
      if (!isEmpty(nextProps.currentValetStaff)) {
        this.setState({
          currentValetStaff: nextProps.currentValetStaff,
          currentValetStaffPermissions: [],
        });
      }
    }

    componentDidMount() {
      this.setState({
        currentValetStaff: this.props.currentValetStaff,
        currentValetStaffPermissions: this.props.currentValetStaff ? [] : [],
      });
    }

    render() {
      const {
        currentValetStaff,
        currentValetStaffPermissions,
        ...other_props
      } = this.props;
      return this.state.currentValetStaff ? (
        <Component {...this.state} {...other_props} />
      ) : CustomLoader ? (
        <CustomLoader />
      ) : this.props.hideLoader ? null : (
        <Loader />
      );
    }
  };

  return connect(mapState, null)(HOC);
};

const mapState = (state) => {
  const { valetData = null } = state.user;
  return { currentValetStaff: valetData };
};

export default withCurrentValetStaff;
