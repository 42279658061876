import React, { useCallback, useContext, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Edit from '../edit';
import styles from './index.module.sass'
import { update } from 'api/parking_rules';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import ActivateDeactivate from '../activate_deactivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const RulesModal = (props) => {
  const { record, openModal, modalType,setListElement, setActiveTab, agencyOfficer, parkingLotId, fetchData } = props
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const [errors, setErrors] = useState({})
  const closeModal = useCallback( () => {
    openModal()
    setActiveTab()
  }, [openModal, setActiveTab])
  
  const updateParkingRule = useCallback(async (data) => {
    try {
      const response  = await update({data: {parking_rules: data}, id : record.id, parking_lot_id: parkingLotId})
      closeModal();
      addAlertMessages([{
        type: 'primary',
        text: `Officer with ID ${response.data[0].admin_id} was successfully assigned to Rule ID ${response.data[0].id}`,
        onlyMessage: true
      }],
      'center'
    )
    setListElement(response.data)
    } catch (error) {
      setErrors({admin_id: Object.values(error.response.data.errors)[0]})
    }
  },[addAlertMessages, setListElement, record, closeModal, parkingLotId])

  const toggleStatus = useCallback(async (attrs) => {
    const data = Object.assign(record, attrs)
    try {
      const response  = await update({data: {parking_rules: data}, id : record.id, parking_lot_id: parkingLotId})
      closeModal()
      const message = `Rule with ID ${response.data[0].id} is successfully ${response.data[0].status === true ? 'activated' : 'deactivated'}`
      addAlertMessages([{
        type: response.data[0].status === true ? 'primary' : 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      addAlertMessages([{
        type: 'danger',
        text: Object.values(error.response.data.errors)[0][0],
        onlyMessage: true,
      }], 'center')
     
    }
    fetchData()

    // eslint-disable-next-line
  },[record, addAlertMessages, setListElement, closeModal, parkingLotId])

  const modalProps = useCallback(() => {
    const maxWidth = 480
    switch (modalType) {
      case 'edit':
        return {title: 'Assign Agency Officer', maxWidth: '715px'}
      case 'activate_deactivate':
        return { title: `Parking Rule ${record.status === true ? 'Deactivation' : 'Activation' }`, maxWidth}
      default:
        return {title: '', maxWidth}
    }
  },[modalType, record.status]);

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal 
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.modal} 
      style={{maxWidth: modalProps().maxWidth, width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        <p className={`${styles.modalTitle} m-0 p-0`}>{modalProps().title}</p>
        {
          modalType === 'activate_deactivate' && 
          <ActivateDeactivate
            record={record}
            closeModal={closeModal}
            toggleStatus={toggleStatus}
            status={`${record.status === 'active' ? 'deactivate' : 'activate'}`}
          />
        }
        {
          modalType === 'edit' && 
          <Edit
            closeModal={closeModal}
            record={record}
            updateRecord={updateParkingRule}
            errors={errors}
            setErrors={setErrors}
            modalType={modalType}
            agencyOfficer={agencyOfficer}
            parkingLotId={parkingLotId}
          />
        }
      </ModalBody>
    </Modal>
  )
}

export default RulesModal;