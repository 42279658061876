import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import styles from './index.module.sass'
import Button from 'components/base/button';
import SlideButton from 'components/base/slide_button';
import Tabs from 'components/base/tabs';
import NavigationToolbar from 'components/base/navigationToolbar';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import FilterForm from './filterForm';
/* API */
import { search as dropdownsSearch } from "api/dropdowns";
import { ReactComponent as PenToSquareSvg } from 'assets/pen-to-square.svg';
import { ReactComponent as ArrownDownToBracketIcon } from 'assets/arrow-down-to-bracket.svg'

const tabs = [
  { value: 'admins', label: 'User Accounts', to:'dashboard/admins'},
  { value: 'roles', label: 'User Roles', to:'dashboard/roles'},
]
const CollapseContent = ({ startFetching, setList, setFilters, match, resource, currentUser}) => {
  const [dropdowns, setDropdowns] = useState({
    roles: [],
   
  })

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(
          dropdownsSearch("role_names_filter", { admin_id: currentUser.id })
        ).then((response) => setDropdowns(state => ({...state, roles: response.data }))),
       
      ])
    }

    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm 
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
      />
    </Row>
  )
}


const ToolBar = (props) => {
  const {isFetching, listType, activeTab, setActiveTab, openModal, modalType} = props;
 
  const history = useHistory()

  const handleTabChange = (value) => {
    history.push(`${value}`)
  };

  const handleToggleCollapse = useCallback((tab) => {
    if(isFetching) return
    setActiveTab(activeTab === tab ? null : tab)
  },[activeTab, setActiveTab, isFetching])
  
  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent
          activeTab={activeTab}
          {...props}
        />
      }
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start pl-0'>
        <Tabs list={tabs} defaultTab={listType}  onClick={handleTabChange} />
      </Col>

      {listType === 'admins' && (
        <Col className='d-flex justify-content-end pr-0'>
          <SlideButton
            icon={<ArrownDownToBracketIcon />}
            isActive={['import'].includes(modalType)}
            onClick={() => {
              openModal('import');
            }}
          >
            <span className='mr-3 d-inline-block' style={{width: '136px'}}>Import User Data</span>
          </SlideButton>
          <SlideButton
            className={styles.createButton}
            onClick={ () => openModal('create')}
            isActive={true}
            icon={<PenToSquareSvg />}>  <span className='mr-3 d-inline-block' style={{width: '103px',fontWeight:400, marginRight:"70px", marginTop:"2px", fontSize:"15px"}}>Create Account</span>
          </SlideButton>
          <Button
            onClick={() => handleToggleCollapse('filters')}
            className={`${styles.btnFilter} ${activeTab === 'filters' ? styles.activeTab : '' }`}
            icon={<FilterIcon className={`${activeTab === 'filters' ? styles.activeIcon : ''}`}/>}
            size="md"
          />
        </Col>
      )}


      {listType === 'roles' && (
        <Col className='d-flex justify-content-end pr-0'>
          <SlideButton
            isActive={true}
            onClick={ () => openModal('create')}
            icon={<PenToSquareSvg />}>  <span className='mr-3 d-inline-block' style={{width: '81px',fontWeight:400, marginRight:"70px", marginTop:"2px", fontSize:"15px"}}>Create Role</span>
          </SlideButton>
        </Col>
      )}
    </NavigationToolbar>
  )
}

export default ToolBar;