import React, { useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { fetchPermissionsAvailable } from 'api/roles';
import Loader from 'components/helpers/loader';
import { createFields } from 'components/helpers/fields/roles';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import PermissionTable from '../shared/permission_table';
import doesUserHasPermission from 'components/modules/does_user_has_permission';
import constPermissions from 'config/permissions';
import { normalizePermissions } from '../shared/permission_table/utils';

const Clone = (props) => {
  const { startFetching, closeModal, selectedRecord, isSaving, createRole, errors, setErrors, currentUserPermissions} = props
  const [isFetching, setIsFetching] = useState()
  const [availablePermissions, setAvailablePermissions] = useState()
  const [permissions, setPermissions] = useState(null)

  const initialValues = {
    ...selectedRecord,
    display_name: selectedRecord?.name
  };

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const handleFetchFinished = () => {
    if (selectedRecord && !isEmpty(availablePermissions)) {
      setPermissions(normalizePermissions(selectedRecord.permissions, availablePermissions))
    };
    
  }

  useEffect(() => {
    const handleFetchFinished = () => {
      if (permissions) return;
      setPermissions(normalizePermissions(selectedRecord.permissions, availablePermissions))
    }
    handleFetchFinished();
  }, [permissions, availablePermissions, selectedRecord])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!selectedRecord)  return;
      try {
        const response = await fetchPermissionsAvailable()
        if(response.status === 200) {
          setAvailablePermissions(response?.data);
          handleFetchFinished()
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchCitationTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecord, startFetching, setAvailablePermissions])

  const validateValues = (formState) => {
    const errors = {}
    const { display_name } = formState;
    if(!display_name) { errors['display_name'] = ['Role Name is required']}
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    const updatedHash = {
      name: values?.display_name,
      permissions: permissions,
    };
    createRole(updatedHash)
  }

  const fields = (category) => {
    return createFields({category, ...fieldAttrs})
  }

  const handlePermissionTableChange = (permissions) => {
    setPermissions(permissions);
  }

  const doesUserHasUpdatePermissions = doesUserHasPermission(
    currentUserPermissions,
    constPermissions.UPDATE_ROLE
  );


  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0'>
      <Form initialValues={initialValues} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col>
                <div >
                  {renderFields(newFields.slice(0, 8), {...singleFieldProps, errors})}
                </div>
              </Col>
              <Col>
                  <div className="d-flex align-items-center my-3">
                    <span className={styles.detailsLabel}>Permissions</span>
                    <span className="border border-2 flex-grow-1 ml-2"></span>
                  </div>
              </Col>
              <div className='mt-3 hide-scroll' style={{ margin: '10px'}}>
                <PermissionTable
                  value={permissions || []}
                  availablePermissions={availablePermissions}
                  onChange={handlePermissionTableChange}
                  disabled={!doesUserHasUpdatePermissions || isSaving}
                  setErrors={setErrors}
                />
                  <p className={styles.alertMsg}>{errors?.permissions}</p>
              </div>
            <Col className='d-flex justify-content-center'>
              <Button 
                onClick={()=>  closeModal()}
                className={`${styles.cancelButton} mb-3`}
              >
                Cancel
              </Button>
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Clone
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Clone);