import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ROLES } from "config/permissions";
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, ADD_LIST_ELEMENT, POP_LIST_ELEMENT } from 'actions/reports';
import { filterFetcher } from 'api/reports';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from "components/helpers/access-control";
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { list as selectList } from 'selectors/list';
import moment from 'moment';

const Index = props => {
  const { isResourceFetching, currentUser, startFetching, setList, match, setFilters } = props;
  const [modalType, setModalType] = useState(null)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [activeTab, setActiveTab] = useState('filters');
  const [initialFilter, setInitialFilter] = useState(null)
  const [dateRange, setDateRange] = useState(null)

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])
  
  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  useEffect(() => {
    const fetchPermitReport = async () => {
      startFetching(filterFetcher(Object.assign({ ...match.params })))
      .then((res) => {
        setList(selectList(res));
        setFilters({
          period: 'Today',
          range: { 
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD")
          },
        })
      })
      .catch(error => console.log(error))
    }
    fetchPermitReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              resource={resource}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isFetching={isResourceFetching}
              openModal={openModal}
              modalType={modalType}
              setInitialFilter={setInitialFilter}
              initialFilter={initialFilter}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Col>
        </Row>
      </Container>
    </AccessControl>
  )
  
};

Index.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'report';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS),
      addListElement: invoke(ADD_LIST_ELEMENT),
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.report.filters,
    record: state.report.records
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
  SET_FILTERS
);
