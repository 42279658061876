import React, { useState, useRef, useCallback, useEffect} from "react";
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { Col, Row } from 'reactstrap';
import Button from 'components/base/button';
import { raiseFields1 } from 'components/helpers/fields/disputes/raiseFields';
import { createDispute } from 'api/app/citation_details';
import { renderFieldsWithGrid, renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass';
import { flatten } from 'underscore';
import { searchV1 as dropdownsSearch } from 'api/dropdowns';
import { isEmpty } from "underscore";

const Dispute =(props) =>{
  const { citationTicketID, handleDisputeClose, toast, setCitationTicketStatus } = props;
  const [errors, setErrors] = useState({})
  const [dropdowns, setDropdowns] = useState({
    types: [],
  })

  const fieldAttrs = {
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    customInputTimeClass: styles.formInputTime,
    customDropdownInputClass: styles.formDropdownInput,
    disputeTypes: dropdowns.types
  }
  
  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const formApiRef = useRef();


  const fieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const field1Props = {
    iSize: 12,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const field2Props = {
    iSize: 12,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const doubleFieldProps = {
    lSize: 3,
    iSize: 9,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const validateValues = (formState) => {
    let errors = {}
    const { dispute_type, detailed_reason, user_email, entry_time, exit_time } = formState;
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if(!user_email) {
      errors['user_email'] = ['Email can\'t be blank']
    }
    if(user_email && !(user_email?.match(isValidEmail))) {
      errors[`user_email`] = ['Invalid email address !']
    }
    if(!detailed_reason) {
      errors['detailed_reason'] = ['Detailed Reason can\'t be blank']
    }
    if(detailed_reason && detailed_reason.length > 500) {
      errors['detailed_reason'] = ['Detailed Reason can have max 500 characters']
    }
    if(!dispute_type) {
      errors['dispute_type'] = ['Dispute Type can\'t be blank']
    }
    if(dispute_type === 'time') {
      if(!entry_time || entry_time === "NaN:NaN") {
        errors['entry_time'] = ['Entry Time can\'t be blank']
      }
      if(!exit_time || exit_time === "NaN:NaN") {
        errors['exit_time'] = ['Exit Time can\'t be blank']
      }
    }
    if(entry_time !== "NaN:NaN" && exit_time !== "NaN:NaN" && entry_time >= exit_time) {
      errors['exit_time'] = ['Exit Time should be greater than Entry Time']
    }
    if(!detailed_reason && dispute_type) {
      errors['detailed_reason'] = ['Detailed Reason can\'t be blank']
    }
    return errors
   }
  
  const fields = raiseFields1(fieldAttrs)

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await dropdownsSearch('dispute_types_field')
        setDropdowns(state => ({...state, types: response?.data }))
      } catch (error) {
        console.log(error)
      }
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const createCitationDispute = useCallback(async (data) => {
    try {
      data.citation_ticket_id = citationTicketID
      const response = await createDispute(data)
      if (response.status === 201){
        toast.success(`Citation Ticket ${response?.data?.citation_ticket?.id} was successfully disputed`);
        handleDisputeClose()
        setCitationTicketStatus(response?.data?.citation_ticket?.status)
      }
    } catch(error) {
      if(error?.response?.data?.errors) { 
        toast.error(`${flatten(Object.values(error?.response?.data?.errors))}`);
      }
    }
    // eslint-disable-next-line
  },[ handleDisputeClose, citationTicketID])

  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(inputErrors)
      return
    };
    createCitationDispute(values);
  },[createCitationDispute]);

  return (
    <Form getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({ formState }) => {
        const {dispute_type} = formState.values
        return (
          <Row className='mx-auto w-100'>
            <Col>
              <div>
                {renderFieldsWithGrid(fields?.slice(0,1), 1, 12, { ...fieldProps, errors })}
              </div>
              { dispute_type === 'time' && 
                <Col className={`d-flex flex-wrap ${styles.timeFields}`}>
                  <div className={styles.fieldset}>
                    {renderFields(fields.slice(3,4), {...doubleFieldProps, errors})}
                  </div>
                  <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                    {renderFields(fields.slice(4), {...doubleFieldProps, errors})}
                  </div>
                </Col>
              }
              <div>
                {renderFieldsWithGrid(fields?.slice(1,2), 1, 12, { ...field1Props, errors })}
              </div>
              <div>
                {renderFieldsWithGrid(fields?.slice(2,3), 1, 12, { ...field2Props, errors })}
              </div>
            </Col>
            <Col className="d-flex justify-content-center mb-3 mt-3" xs={12}>
              <Button onClick={handleDisputeClose} type='button' className={`${styles.btn}  ${styles['btn-cancel']} mr-4`}>
                Cancel
              </Button>
              <Button type='submit' className={`${styles.btn} ${styles['btn-dispute']}`}  >
                Submit
              </Button>
            </Col>
          </Row>
        )
        }
      }
    </Form>
  );
}

Dispute.propTypes = {
  backPath: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUserPermissions: PropTypes.array,
  record: PropTypes.object
};

export default Dispute;
