import React from 'react'
import {  Col } from 'reactstrap';
import styles from './index.module.sass';
import Button from 'components/base/button';
import NavigationToolbar from 'components/base/navigationToolbar';
import { isEmpty } from 'underscore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ToolBar = (props) => {

  const {openModal, setEditActive, list, setCreateActive, editActive, createActive} = props;

  return (
    <NavigationToolbar
      className='d-flex justify-content-space-between'
    >
      {(createActive || editActive) && <div className="d-flex align-items-center mr-2">
        <div onClick={()=> {setCreateActive(false); setEditActive(false)}} className={`${styles.backTab} ${styles.tab} ${styles.title} ${styles.activeTab}`} >
          <FontAwesomeIcon size="sm" color="grey" icon={faChevronLeft} className='mr-2' />
          <span>
            Back
          </span>
        </div>
      </div>}
      <Col className='d-flex justify-content-start align-items-center pl-1'>
        <span className={`${styles.title} general-text-1`}>
          Parking Regulations
        </span>
      </Col>
      <Col className='d-flex justify-content-end p-0'>
        {!isEmpty(list) && <Button
          onClick={() => setEditActive(true)}
          className={styles.editButton}
          size="md"
        >
          Edit Document
        </Button>}
        {!isEmpty(list) && <Button
          onClick={() => openModal("delete")}
          className={styles.deleteButton}
          size="md"
        >
          Delete Document
        </Button>}
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;