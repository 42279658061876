import React from "react";
import { Col, Container } from "reactstrap";
import { Form } from "informed";
import { newFields } from "components/helpers/fields/clients";
import Button from "components/base/button";
import { isEmpty } from "underscore";
import { renderFields } from "components/base/forms/common_form";
import styles from "./index.module.sass";
import { Link } from "react-router-dom";

const Create = (props) => {
  const {
    closeModal,
    isSaving,
    createClient,
    errors,
    setErrors,
  } = props;

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
  };

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({}),
    },
  };

  const validateValues = (formState) => {
    const errors = {};
    const { username, email, status } = formState;
    if (!username) {
      errors["username"] = ["User Name is required"];
    }
    if (!email) {
      errors["email"] = ["Email is required"];
    }
    if (!status) {
      errors["status"] = ["Status is required"];
    }
    return errors;
  };

  const submitValues = (values) => {
    setErrors({});
    const inputErrors = validateValues(values);
    if (!isEmpty(inputErrors)) {
      setErrors(Object.assign(inputErrors));
      return;
    }
    createClient(values);
  };

  const fields = () => {
    return newFields({ ...fieldAttrs });
  };

  return (
    <Container className="p-0">
      <p className={`${styles.title} mx-0 mb-3 p-0`}>Create New Client</p>
      <Form className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields();
          return (
            <Col>
              <Col>
                <div>
                  {renderFields(newFields.slice(0, 5), {
                    ...singleFieldProps,
                    errors,
                  })}
                </div>
              </Col>
              <Col className="d-flex justify-content-center">
                <Button
                  onClick={() => closeModal()}
                  className={`${styles.cancelButton} mb-3`}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className={styles.submitButton}
                  isLoading={isSaving}
                >
                  Create
                </Button>
              </Col>
            </Col>
          );
        }}
      </Form>
    </Container>
  );
};

export default Create;
