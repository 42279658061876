import React, { useCallback, useState, useEffect, useReducer } from 'react'
import NavigationToolbar from 'components/base/navigationToolbar';
import Button from 'components/base/button';
import { Col, Row } from 'reactstrap';
import ParkingLotDetails from './parkingLotDetails';
import FilterForm from 'components/pages/parking_lots/view/voi/filterForm.jsx';
import styles from './index.module.sass';
import { ReactComponent as ParkingLotIcon } from 'assets/menu_icons/parking_lot_icon.svg';
import Tabs from 'components/base/tabs';
import { ReactComponent as FilterIcon } from 'assets/filter_icon.svg';
import { show } from 'api/parking_lots';
import { filterFetcher } from 'api/parking_rules';

const tabs = [
  { label: 'VOI',  value: 'voi'},
  { label: 'Parking Rules',  value: 'rules'},
  { label: 'Parking Spaces',  value: 'spaces'},
]

const initState = {
  isDropdownFetching: false,
  isSaving: false,
  inputChanged: false,
  dropdowns: {
    statuses: [],
  },
  errors: {},
  activeTab: '',
}

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors } = state
  switch (type) {
    case 'isDropdownFetching':
      return { ...state, isDropdownFetching: payload }
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'inputChanged':
      return { ...state, inputChanged: payload }
    case 'dropdowns':
      return { ...state, dropdowns: {...state.dropdowns, ...payload} }
    case 'errors':
      return { ...state, errors: payload }
    case 'activeTab':
      return { ...state, activeTab: payload }
    case 'filters':
      return { ...state, filters: payload }
    default:
      return { ...state };
  }
};

const CollapseContent = (props) => {
  const {setFilters, filters, setList, activeTab, selectedParkingLot } = props

  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pb-2 mx-0`} sm="12">
      { activeTab === 'parking_lots' && <ParkingLotDetails {...props} record={selectedParkingLot}/> }
      { activeTab === 'voi_filters' && 
      <FilterForm 
        {...props}
        setFilters={setFilters}
        filters={filters}
        setList={setList}
      /> }
    </Row>
  )
}

const ToolBar = (props) => {
  const { history, activeTab, setActiveTab, pageType, parkingLotId, list} = props
  const [state, dispatch] = useReducer(reducer, initState)
  const [selectedParkingLot, setSelectedParkingLot] = useState();
  // eslint-disable-next-line
  const [parkingRules, setParkingRules] = useState()
  const [listType, setListType] = useState(pageType ? pageType : "ev");

  useEffect(() => {
    const fetchResource = async () => {
      if(!parkingLotId)  return;
      try {
        const response = await show({id: parkingLotId});
        setSelectedParkingLot(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchResource()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const fetchCitationTicket = async () => {
      if(!selectedParkingLot)  return;
      try {
        const response = await filterFetcher({id: selectedParkingLot?.id})
        if(response.status === 200) {
          const { data } = response;
          setParkingRules(data);
        }
      } catch(error) {
        console.log(error)
      } 
    };
    fetchCitationTicket();
  }, [selectedParkingLot])

  const activeRules = list?.filter((l) => l.status === true ).length

  const setState = useCallback((type, payload) => {
    dispatch({ type, payload })
  },[dispatch])

  const handleTabsChange = (tab) => {
    setListType(tab);
    history.push(`/dashboard/parking_lots/${parkingLotId}/${tab}`, {
      listType: tab,
    });
  };

  const handleToggleCollapse = useCallback((tab) => {
    setActiveTab((activeTab) => (activeTab === tab ? null : tab));
  },[setActiveTab])

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={
        <CollapseContent {...props} state={state} setState={setState} selectedParkingLot={selectedParkingLot}/>
      }
      backTab='/dashboard/parking_lots'
      className='d-flex justify-content-space-between'
    >
      <Col className='d-flex justify-content-start'>
        <Tabs list={tabs} className={styles.tabs} defaultTab={pageType ? pageType : listType} onClick={handleTabsChange} />
      </Col>
      <Col className='d-flex justify-content-start'>
        <Col className={`d-flex align-items-center`} style={{marginLeft: '10px', whiteSpace: 'nowrap'}} xs={4} >
          {listType === 'rules' && <div>{`Active Rules (${activeRules ? activeRules : 0})`}</div>}
        </Col>
      </Col>
      <Col className='d-flex justify-content-end p-0'>
        {listType === 'voi' && <Button
          onClick={() => handleToggleCollapse('voi_filters')}
          className={`${styles.btnFilter} ${activeTab === 'voi_filters' ? styles.activeTab : '' }`}
          icon={<FilterIcon className={`${activeTab === 'voi_filters' ? styles.activeIcon : ''}`}/>}
          size="md"
        />}
        <Button
          onClick={() => handleToggleCollapse('parking_lots')}
          className={`${styles.disputeButton} ${activeTab === 'parking_lots' ? styles.activeTab : '' }`}
          size="md"
          icon={<ParkingLotIcon className={`${activeTab === 'parking_lots' ? '' : styles.icon} ml-n4 ${styles['icon-fade']}`} />}
        >
          {`ID ${parkingLotId}`}
        </Button>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;