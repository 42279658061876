import { FieldType } from "components/helpers/form_fields";

const eventSettings = (attrs) => {
  const { customInputClass='', customLabelClass='',customIconInputClass,customDateInputClass, customDropdownStyle, renderLocationModal, icon, cost_to_attendees, cost_to_organizer,categories } = attrs;
  return ([
    [{
      name: 'event_type',
      label: 'Event Type',
      customInputClass,
      customLabelClass,
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: false,
      list: [
        { label: 'Private', value: 'pvt' },
        { label: 'City', value: 'city' }
      ]
    }],
    [{
      name: 'event_name',
      label: 'Event Name',
      customInputClass,
      customLabelClass,
      disabled: false,
    }],
    [{
      name: 'event_category_id',
      label: "Event Category",
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      disabled: false,
      options: categories,
      customInputClass: customDropdownStyle,
      customLabelClass,
    }],
    [{
      name: 'event_description',
      label: "Description",
      customInputClass,
      customLabelClass,
      disabled: false,
    }],
    
    [{
      name: 'contact_person_name',
      label: 'Organizer Name',
      customInputClass,
      customLabelClass,
      disabled: false
    }],
    [{
      name: 'organizer_phone_number',
      label: 'Organizer Phone Number',
      type: FieldType.PHONE_NUMBER_FIELD,
      customInputClass,
      customLabelClass,
      disabled: false,
      initialCountry: "us",
    }],
    [{
      name: 'organizer_email',
      label: 'Organizer Email',
      customInputClass,
      customLabelClass,
      disabled: false
    }],
    [{
      name: 'event_dates',
      label: "Event Dates",
      type: FieldType.MULTIPLE_DATE_FIELD,
      customInputClass: customDateInputClass,
      customLabelClass,
      disabled: false,
      min: new Date(+new Date() + 86400000)

    }],
    [{
      name: 'event_time',
      label: "Select Event Time",
      type: FieldType.RADIO_BUTTON_FIELD,
      mandatory: true,
      disabled: false,
      list: [
        { label: 'Same for all days', value: 'same' },
        { label: 'Custom', value: 'custom' }
      ],      
      customInputClass,
      customLabelClass,

    }],
    [{
      name: 'number_of_vehicles',
      label: 'Number of Attendees',
      type: FieldType.INCREASER_FIELD,
      step: 1,
      min: 1,
      mandatory: true,
      disabled: false,
      customInputClass,
      customLabelClass,
      renderValue: value => value
      
    }],

    [{
      name: 'location',
      label: 'Location',
      mandatory: true,
      render: renderLocationModal,
      disabled: false,
      customInputClass,
      customLabelClass,
      
    }],
    [{
      name: 'attendees_cost_type',
      label: "Cost to Attendees",
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      mandatory: true,
      disabled: false,
      options: [
        { label: 'Free', value: 'free' },
        { label: 'Paid', value: 'paid' }
      ],      
      customInputClass: customDropdownStyle,
      customLabelClass,      
    },
    {
      name: 'attendees_cost',
      label: "Cost Per Vehicle",
      type: FieldType.NUMBER_FIELD,
      mandatory: cost_to_attendees==='paid',
      disabled: cost_to_attendees==='free',     
      customInputClass: customIconInputClass,
      customLabelClass,
      icon: icon
    }],
    [{
      name: 'organizer_cost_type',
      label: "Cost to Organizer",
      mandatory: true,
      type: FieldType.DROPDOWN_SEARCH_SELECT_FIELD,
      disabled: false,
      options: [
        { label: 'Free', value: 'free' },
        { label: 'Paid', value: 'paid' }
      ],      
      customInputClass: customDropdownStyle,
      customLabelClass,
    },
    {
      name: 'organizer_cost',
      label: "Cost",
      type: FieldType.NUMBER_FIELD,
      mandatory: cost_to_organizer==='paid',
      disabled: cost_to_organizer==='free',     
      customInputClass: customIconInputClass,
      customLabelClass,
      icon: icon
    }],



   
  ])
}

export default eventSettings