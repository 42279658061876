import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { isEmpty } from "underscore";
/* API */
import {
  resetPasswordRequest,
  checkPasswordToken,
  sendResetPasswordInstructionsRequestValet,
} from "api/valetApp/valet";
/* Base */
import CardLayout from "components/base/layout/card";
/* Helpers */
import { btnSpinner } from "components/helpers";
import { setErrorsMessages } from "components/helpers/messages";
import { AlertMessagesContext } from "components/helpers/alert_messages";
import Password from "components/helpers/form_fields/password";
/* Modules */
import OtpForm from "components/modules/otp_form";
import styles from "./index.module.sass";
import Countdown, { zeroPad } from "react-countdown";

const ValetResetPassword = ({ history }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [messages, setMessages] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [passwordTokenInvalid, setPasswordTokenInvalid] = useState(true);
  const [code, setCode] = useState("");
  const [isCountingDown, setIsCountingDown] = useState(false);

  const COUNTDOWN_TIME = 2 * 60 * 1000;
  const getInitialEndTime = () => {
    const storedEndTime = localStorage.getItem("countdownEndTime");
    return storedEndTime ? parseInt(storedEndTime, 10) : null;
  };
  const [endTime, setEndTime] = useState(getInitialEndTime);

  useEffect(() => {
    if (endTime && endTime > Date.now()) {
      setIsCountingDown(true);
    } else {
      localStorage.removeItem("countdownEndTime");
    }
  }, [endTime]);

  const handleResendClick = () => {
    const newEndTime = Date.now() + COUNTDOWN_TIME;
    setEndTime(newEndTime);
    localStorage.setItem("countdownEndTime", newEndTime);
    setIsCountingDown(true);
  };

  const handleCountdownComplete = () => {
    setIsCountingDown(false);
    localStorage.removeItem("countdownEndTime");
  };

  const alertContext = useContext(AlertMessagesContext);

  const username = history?.location?.state?.username;

  const redirectToLogin = (text) => {
    alertContext.addAlertMessages([{ type: "Success", text }]);
    history.push("/valet_login");
  };

  const verifyToken = async () => {
    try {
      const res = await checkPasswordToken({
        username: username,
        reset_password_code: code,
      });
      if (res.status === 201) {
        setPasswordTokenInvalid(false);
        setMessages(null);
      } else {
        setMessages(
          setErrorsMessages(
            "Invalid Verification Code. Please check the verification code entered!"
          )
        );
      }
    } catch (err) {
      console.log("errrrr", err);
      setMessages(
        setErrorsMessages(
          "Invalid Verification Code. Please check the verification code entered!"
        )
      );
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    if (password === passwordConfirmation) {
      setIsFetching(true);
      try {
        await resetPasswordRequest({
          username: username,
          password: password,
          reset_password_code: code,
          password_confirmation: passwordConfirmation,
        });
        localStorage.removeItem("countdownEndTime");
        redirectToLogin("Your password was successfully changed");
      } catch (error) {
        setIsFetching(false);
        setMessages(setErrorsMessages(error));
      }
    } else {
      setMessages(
        setErrorsMessages(
          "Your password and confirmation password do not match"
        )
      );
    }
  };

  const validInputs = () => {
    if (passwordTokenInvalid) {
      return false;
    }
    return password ? passwordConfirmation === password : false;
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <span>
          {zeroPad(minutes)} : {zeroPad(seconds)}
        </span>
      );
    }
  };

  const resendToken = async () => {
    if (isCountingDown) return;
    try {
      const response = await sendResetPasswordInstructionsRequestValet(
        username
      );
      if (response.status === 201) {
        handleResendClick();
        alertContext.addAlertMessages([
          {
            text: "Verification Code Sent Successfully.",
            type: "Success",
          },
        ]);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <CardLayout
      title="Reset Your Password"
      isFetching={isFetching}
      messages={messages}
    >
      <div style={{ marginTop: "50%", width: "100%" }}>
        <h1 className="h1-title-primary mb-4 text-center">
          Reset Your Password
        </h1>
        {passwordTokenInvalid && (
          <div>
            <p className={styles.verificationTitle}>
              We've sent a 6-digit verification code to your email address
            </p>
            <p className={styles.verificationSubtitle}>
              Please enter the code below to reset your password.
            </p>
            <div className="mt-5 pt-1">
              <OtpForm value={code} setValue={setCode} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                disabled={isCountingDown && endTime}
                color={
                  !isCountingDown && !endTime ? "primary-lg" : "disabled-lg"
                }
                className={`${
                  !isCountingDown || !endTime
                    ? styles.button
                    : styles.disabledBg
                } mt-5 p-3 text-uppercase btn-lg btn-block mr-2`}
                onClick={() => resendToken()}
              >
                {isCountingDown && endTime ? (
                  <Countdown
                    date={endTime}
                    renderer={renderer}
                    onComplete={handleCountdownComplete}
                  />
                ) : (
                  "Resend Code"
                )}
              </Button>
              <Button
                disabled={!code}
                color={code ? "primary-lg" : "disabled-lg"}
                className={`${
                  code ? styles.button : styles.disabledBg
                } mt-5 p-3 text-uppercase btn-lg btn-block ml-2`}
                onClick={() => verifyToken()}
              >
                {isFetching
                  ? btnSpinner({ className: "spinner-border" })
                  : "Verify"}
              </Button>
            </div>
          </div>
        )}
        {!passwordTokenInvalid && (
          <form onSubmit={submitForm}>
            <h1 className="h1-title-black mb-4 text-center">
              Enter New password
            </h1>
            <div className="form-label-group">
              <label className="general-text-3" htmlFor="password">
                Password
              </label>
              <Password
                field={{
                  name: "password",
                }}
                customAttr={{
                  onChange: (e) => setPassword(e.target.value),
                  className: `position-relative form-control-lg form-control ${
                    !isEmpty(messages) ? "input-error" : ""
                  }`,
                  placeholder: "Enter your password",
                }}
              />
            </div>

            <div className="form-label-group mt-2">
              <label className="general-text-3" htmlFor="passwordConfirmation">
                Password Confirmation
              </label>
              <Password
                field={{
                  name: "passwordConfirmation",
                }}
                customAttr={{
                  onChange: (e) => setPasswordConfirmation(e.target.value),
                  className: `position-relative form-control-lg form-control ${
                    !isEmpty(messages) ? "input-error" : ""
                  }`,
                  placeholder: "Enter your password",
                }}
              />
            </div>

            <Button
              disabled={
                passwordTokenInvalid ||
                isFetching ||
                !password ||
                !passwordConfirmation
              }
              color={validInputs() ? "primary-lg" : "disabled-lg"}
              className={`${
                validInputs() ? styles.button : styles.disabledBg
              } mt-5 p-3 text-uppercase btn-lg btn-block`}
              type="submit"
            >
              {isFetching
                ? btnSpinner({ className: "spinner-border" })
                : "Save"}
            </Button>
          </form>
        )}
      </div>
    </CardLayout>
  );
};

ValetResetPassword.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ValetResetPassword;
