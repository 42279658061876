import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/clients";
import Loader from 'components/helpers/loader';
import { showFields } from 'components/helpers/fields/clients';
import Button from 'components/base/button';
import { isEmpty } from 'underscore';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { Link } from 'react-router-dom';
import { capitalize } from 'components/helpers';
import CheckBox from 'components/base/check_box';

const Setting = (props) => {
  const { closeModal, startFetching, selectedRecordId, isSaving, updateClient, errors, setErrors} = props
  const [isFetching, setIsFetching] = useState()
  const [client, setClient] = useState({})
  const [moduleList, setModuleList] = useState([])
  const [checkedState, setCheckedState] = useState();

  const fieldAttrs = {
    customInputClass: styles.input,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel1,
    icon: <Link className={styles.checkDetailsLink}>Check Details</Link>,
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3,
    events: {
      onChange: (_e) => setErrors({})
    }
  }

  const applicationData = useCallback((data) => {
    const { id, client, dashboard, user_mgmt, subscriber_mgmt, vehicle_mgmt, parking_lot
      , notification, payment, report, permit_mgmt, city_event, law_enforcement_agency, arrival_time
      , dispute, violation, parking_reward, citation, video, coupon, parking_rule, broadcast_message, parking_regulation, theme
      } = data
    
    return ({  
      id: id,
      name: client?.name,
      phone: client?.phone,
      email: client?.email,
      status: client?.status,
      dashboard: dashboard,
      user_mgmt: user_mgmt,
      subscriber_mgmt: subscriber_mgmt,
      vehicle_mgmt: vehicle_mgmt,
      parking_lot: parking_lot,
      notification: notification,
      payment: payment,
      report: report,
      permit_mgmt: permit_mgmt,
      city_event: city_event,
      law_enforcement_agency: law_enforcement_agency,
      arrival_time: arrival_time,
      dispute: dispute,
      violation: violation,
      parking_reward: parking_reward,
      citation: citation,
      video: video,
      coupon: coupon,
      parking_rule: parking_rule,
      broadcast_message: broadcast_message,
      parking_regulation: parking_regulation,
      theme: theme
    })
  },[])

  useEffect(() => {
    setCheckedState(moduleList?.reduce((acc, item) => {
      acc[item] = client[item] === 'undefined' ? false : client[item]
      return acc;
    }, {}))

  // eslint-disable-next-line 
  }, [moduleList])

  // Handle checkbox toggle
  const handleCheckboxChange = (item) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };


  useEffect(() => {
    setIsFetching(true)
    const fetchClient = async () => {
      if(!selectedRecordId)  return;
      try {
        const response = await show({id: selectedRecordId?.id})
        if(response.status === 200) {
          const { data } = response;
          setClient(applicationData(data));
          setModuleList(Object.keys(applicationData(data)).filter(key => !['id', 'name', 'phone', 'email', 'status'].includes(key)))
        }
      } catch(error) {
      } finally {
        setIsFetching(false)
      }
    };
    fetchClient();
  }, [selectedRecordId, applicationData, startFetching])

  const validateValues = (formState) => {
    const errors = {}
    const { name } = formState;
    if(!name) { errors['name'] = ['Name is required']}
    return errors
  }

  const submitValues = (values) => {
    setErrors({})
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    
    updateClient(checkedState)
  }

  const fields = () => {
    return showFields({...fieldAttrs})
  }

  const checkBoxLabel = (item) => {
    return item.split('_').map((word) => word === 'mgmt' ? 'Management' : capitalize(word)).join(' ')
  }

  const formatErrorMessages = (errors) => {
    const errorMessages = {}
    Object.keys(errors).forEach((key) => {
      errorMessages[key] = errors[key].join(', ')
    })
    return Object.keys(errorMessages).map((key) => {
      return <p>{`${capitalize(key)}: ${errorMessages[key]}`}</p> 
    })
  }

  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0'>
      <p className={`${styles.title} mx-0 mb-3 p-0`}>{client?.username}</p>
      <Form initialValues={client} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (            
            <Col>
              <Col>
              <div >
                {renderFields(newFields.slice(0, 2), {...singleFieldProps, errors})}
              </div>
            </Col>
            <hr className={styles.divider} />
            <Col>
              <div>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Modules</span>
                </div>
                
                {moduleList.map((item) => (
                    <div className={styles.checkBoxColumns} key={item} >
                      <div>
                        
                        <p className={styles.checkBoxLabel}>{checkBoxLabel(item)}</p>
                      </div>
                      <div>
                          <CheckBox
                            className={styles.checkBox}
                            
                            value={checkedState[item]}
                            onChange={() => handleCheckboxChange(item)}
                            disabled={['dashboard', 'user_mgmt', 'subscriber_mgmt', 'vehicle_mgmt', 'parking_lot'].includes(item)}
                          />
                      </div>
                    </div>
                ))}
                {errors && <p className={styles.alertMsg}>{formatErrorMessages(errors)}</p>}
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <Button 
                type='button'
                onClick={()=>  closeModal()}
                className={`${styles.deleteButton} mb-3`}
              >
                Cancel
              </Button>
              
              <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                Save
              </Button>
            </Col>
          </Col>
        )}}
      </Form>
    </Container> 
  )
}

export default (Setting);
