import React, { useEffect, useCallback, useRef, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Form } from 'informed'
import styles from './index.module.sass'
import Button from 'components/base/button'
import { renderFields } from 'components/base/forms/common_form'
import { schedulerFields } from 'components/helpers/fields/system_reports/scheduler_fields'
import { isEmpty, map } from 'underscore'
import { search as dropdownsSearch } from "api/dropdowns";
import { ReactComponent as TrashIcon } from "assets/trash_icon.svg";
import 'components/base/card.css'
import { schduledReports } from 'api/reports';
import Loader from 'components/helpers/loader';

const Schedule = ({closeModal, scheduleReport, isSaving, errors, setErrors, startFetching, reportName, deletedEmails, setDeletedEmails, openInnerModal, deletableEmails, setDeletableEmails, setNewData}) => {
  const [initialSchedulers, setInitialSchedulers] = useState(null);
  const [isFetching, setIsFetching] = useState(true)
  const [fieldsState, setFieldsState] = useState([{id: 0}]);
  const [existingSchedulers, setExistingSchedulers] = useState([])
  const [count, setCount] = useState(0)
  const [emails, setEmails] = useState([])
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch(`report_scheduler_admin_list?module_name=${reportName}`)
        ).then((response) => setEmails(response.data)),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const schedulerData = useCallback((data) => {
    const schedulers_attributes = {};
    map(data,(item, idx) =>{
      (schedulers_attributes[`${idx}`] = {
        email: item?.email,
        file_format: item?.file_format,
        schedule_type: item?.schedule_type,
        schedule_time: item?.schedule_time
      })
      setExistingSchedulers((prevState) => [...prevState, { id: item?.id, email:  item?.email}]);
    });
    return {
      schedulers_attributes: schedulers_attributes
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, scheduleReport])

  useEffect(() => {
    const fetchSchedulers = async () => {
      setIsFetching(true)
      try {
        const response = await schduledReports({module_name: reportName})
        if(response.status === 200 || response.status === 201) {
          // eslint-disable-next-line
          {map(response.data, (item, idx) => {
            setFieldsState((prevState) => [...prevState, { id: idx + 1 }]);
          })}
          setInitialSchedulers(schedulerData(response.data));
          setCount(response.data.length)
        }
      } catch(error) {
        console.log(error)
      } finally{
        setIsFetching(false)
      }
    };
    fetchSchedulers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportName, setInitialSchedulers])

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const fieldProps = {
    events: {
      onChange: (e) => setErrors({})
    }
  }

  const validateValues = (formState) => {
    const errors = {}
    const { schedulers_attributes } = formState;
    // eslint-disable-next-line
    {map(schedulers_attributes, (item, idx) => {
      if(item?.email){
        if(!item?.file_format) errors[`schedulers_attributes[${idx}][file_format]`] = ['Format is required']
        if(!item?.schedule_type) errors[`schedulers_attributes[${idx}][schedule_type]`] = ['Schedule Period is required']
        if(!item?.schedule_time || item.schedule_time === "NaN:NaN") {
          errors[`schedulers_attributes[${idx}][schedule_time]`] = ['Schedule time is required']
        }
      }
    })}
    return errors
   }

  const sortHash = (hash) => {
    if(hash){
      return Object.keys(hash)
        .sort()
        .reduce((acc, key) => ({ ...acc, [key]: hash[key] }), {});
    }
  };

  const findUnmatchedIDs = (schedulersHash, emailsWithId) => {
    const schedulerEmails = schedulersHash.schedulers_attributes.map(scheduler => scheduler.email);
    
    const unmatchedIDs = emailsWithId
      .filter(emailObj => !schedulerEmails.includes(emailObj.email))
      .map(emailObj => emailObj.id);

    setDeletedEmails(emailsWithId.filter(emailObj => !schedulerEmails.includes(emailObj.email)).map(emailObj => emailObj.email))
  
    return unmatchedIDs;
  };

  const save = useCallback(() => {
    setErrors({})
    const {values} = formApiRef?.current?.getState();
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    
    let vehicleDetails = []
    if(values?.schedulers_attributes){
      Object.keys(values?.schedulers_attributes).forEach((key) => {
        if(values?.schedulers_attributes[key]?.email){

          Object.keys(initialSchedulers?.schedulers_attributes).forEach((val) => {
            if(JSON.stringify(sortHash(values?.schedulers_attributes[key])) === JSON.stringify(sortHash(initialSchedulers?.schedulers_attributes[key]))){
              return 
            }else if((JSON.stringify(sortHash(values?.schedulers_attributes[key])) !== JSON.stringify(sortHash(initialSchedulers?.schedulers_attributes[val]))) && val === `${Object.keys(initialSchedulers?.schedulers_attributes).length -1}`){
              vehicleDetails.push({...values?.schedulers_attributes[key], module_name: reportName})
            }
          })
          if(isEmpty(initialSchedulers?.schedulers_attributes)){
            vehicleDetails.push({...values?.schedulers_attributes[key], module_name: reportName})
          }
        }
      });
    }
    let deleteEmails = findUnmatchedIDs(values,existingSchedulers)
    setDeletableEmails(deleteEmails)
    setNewData(vehicleDetails)
    if(!isEmpty(deleteEmails)){
      openInnerModal();
    }else if((!isEmpty(vehicleDetails) || isEmpty(initialSchedulers?.schedulers_attributes))){
      scheduleReport(vehicleDetails);
    }else{
      closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setErrors, scheduleReport, initialSchedulers, reportName, deletedEmails, existingSchedulers, openInnerModal, deletableEmails]);


  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const validateEmailValues = (formState) => {
    const errors = {}
    const { schedulers_attributes} = formState;
    if(!schedulers_attributes || !schedulers_attributes?.[`${count}`]?.['email']) {
      errors[`schedulers_attributes[${count}][email]`] = ['Email is required']
    }
    return errors
  }

  const onAdd = () => {
    setErrors({})
    const {values} = formApiRef?.current?.getState();
    const inputErrors = validateEmailValues(values)
    if(!isEmpty(inputErrors)){
      setErrors(Object.assign(inputErrors))
      return
    };
    setCount(count + 1)
    setFieldsState([...fieldsState, { id: fieldsState[fieldsState.length - 1].id + 1 }]);
    setEmails(emails.filter((email) => values?.schedulers_attributes[values?.schedulers_attributes?.length - 1]?.email !== email?.value))
  };

  const onRemove = (idx) => {
    const {values} = formApiRef?.current?.getState();
    setEmails([...emails, { value: values?.schedulers_attributes[idx]?.email, label: values?.schedulers_attributes[idx]?.email }]);
    setFieldsState(fieldsState.filter((field) => field.id !== idx))
  };

  if (isFetching) {
    return <Loader />;
  }
  
  return (
    <Container>
      <p className={`${styles.title}  p-0`}></p>
      <Form initialValues={initialSchedulers} getApi={setFormApi} className='m-auto' onSubmit={save}>
        {({ formState }) => {
          const fields = fieldsState
          return (
            <Col className='w-100 m-0' style={{padding: '10px'}}>
              {fields?.map((field, idx) => (
                <React.Fragment key={field.id}>
                  <Row className="m-0 align-items-center">
                    <Col xs={8}>
                      {renderFields(schedulerFields({id: field.id, mute: idx !== fields.length - 1, customLabelClass: styles.formLabel, emails: emails, customDropdownInputClass: styles.formDropdownInput})?.slice(0,1), {...fieldProps, lSize: 3, iSize: 9, errors})}
                    </Col>
                    <Col className={(idx === fields.length - 1) ? 'd-none' : 'pl-0 pr-4'} xs={4}>
                      {renderFields(schedulerFields({id: field.id, customLabelClass: styles.formLabel, customInputClass: styles.formInput})?.slice(1,2), {...fieldProps, lSize: 5, iSize: 7, errors})}
                    </Col>
                    <Col className={(idx !== fields.length - 1) ? 'd-none' : 'pl-0 pr-4'} xs={4}>
                      <Button
                        type="button"
                        onClick={() => onAdd()}
                        className={styles.addButtonCustom}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                  <Row className={(idx === fields.length - 1) ? 'd-none' : 'm-0 align-items-center'}>
                    <Col xs={6}>
                      {renderFields(schedulerFields({id: field.id, customLabelClass: styles.formLabel, customInputClass: styles.formInput}).slice(2,3), {...fieldProps, lSize: 4, iSize: 8, errors})}
                    </Col>
                    <Col xs={4}>
                      {renderFields(schedulerFields({id: field.id, customLabel1Class: styles.formLabel1, customInput1Class: styles.formInput1})?.slice(3,4), {...fieldProps, lSize: 2, iSize: 10, errors})}
                    </Col>
                    <Col xs={2}>
                      <TrashIcon
                        className={styles.setTrashIcon}
                        onClick={() => onRemove(field.id)}
                      />
                    </Col>
                  </Row>
                  {idx !== fields.length - 1 && <div className="border border-2 flex-grow-1 ml-2 mb-3"></div>}
                </React.Fragment>
                ))}
              <Col className="d-flex justify-content-center mb-4 mt-3" xs={12}>
                <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                  Cancel
                </Button>
                <Button type='submit' className={styles.button}  isLoading={isSaving}>
                  Save
                </Button>
              </Col>
            </Col>
          )
        }}
      </Form>
    </Container>
  )
}

export default Schedule;
