import React, { useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Show from '../show';
import RejectApplication from '../reject_application';
import OnHoldApplication from '../on_hold_application';
import Setting from '../setting'
/* Assets */
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
/* API */
import { approveEventApplication, rejectEventApplication, putOnHold, create } from 'api/event_parking_applications';
/* Helpers */
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import CreateApplication from '../create'
import Confirmation from '../create/confirmation';
/* Helpers */

const initialValues = {
  isSaving: false,
  errors: {},
  parkingLots: {},
  selectedParkingLots:[],
  totalSelectedSpaces: 0,
  disableCheckboxes: false,
}

const reducer = (state, action) => {
  const { event } = state;
  switch (action.type) {
    case 'errors':
      return { ...state, errors: action.payload };
    case 'parkingLots':
      return { ...state, event: action.payload };
    case 'fetching':
      return { ...state, fetching: action.payload, event: action.payload ? {} : event };
    case 'show_details':
      return { ...state, show_details: action.payload };
    case 'selectParkingLot':
      return {
        ...state,
        selectedParkingLots: action.payload.selectedParkingLots,
        totalSelectedSpaces: action.payload.totalSelectedSpaces,
        disableCheckboxes: action.payload.disableCheckboxes,
      };
    default:
      return { ...state };
  }
};

const EventModal = (props) => {
  const [values, dispatch] = useReducer(reducer, initialValues)
  
  const state = useMemo(() => values,[values])

  const [errors, setErrors] = useState({})
  
  const setState = useCallback((type, payload) => {
    dispatch({ type , payload })
  },[dispatch])

  const { addAlertMessages } = useContext(AlertMessagesContext)
  
  const { setListElement, modalType, openModal, record, cityEventRecord, listType } = props

  const [settingRecord, setSettingRecord] = useState({})

  const [eventType, setEventType] = useState('')

  const [alertMsg, setAlertMsg] = useState("")

  const closeModal = useCallback(() => {
    openModal()
  }, [openModal])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  const approveApplication = useCallback(async (attrs) => {
    const data = Object.assign(record, attrs)

    try {
      const response  = await approveEventApplication({data: {event_parking_application: data}, id : record.id})
      closeModal()
      const message = `The ${record.event_name} Event Applications is Approved successfully`
      addAlertMessages([{
        type: 'primary',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement, closeModal])


  const rejectApplication = useCallback(async (attrs) => {
    const data = Object.assign(record, attrs)

    try {
      const response  = await rejectEventApplication({data: {event_parking_application: data}, id : record.id})
      closeModal()
      const message = `The ${record.event_name} Event Applications is Rejected successfully`
      addAlertMessages([{
        type: 'danger',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement, closeModal])

  const holdApplication = useCallback(async (attrs) => {
    const data = Object.assign(record, attrs)
    try {
      const response  = await putOnHold({data: {event_parking_application: data}, id : record.id})
      closeModal()
      const dates = response.data.event_dates_and_times.map((d)=>{return{date:d.date}})
      const sorted = dates.sort((a, b) => {   if(new Date(a.date) < new Date(b.date)) {return -1;} return 1;}).slice(0,1);
      const earliest_date = sorted.reduce((acc, curr) => `${acc} ${curr.date}`,'')
      const message = `Application placed on hold. If no action is taken by ${earliest_date}, the application will automatically be rejected.`
      addAlertMessages([{
        type: 'notice',
        text: message,
        onlyMessage: true,
      }], 'center')
      setListElement(response.data)
    } catch (error) {
      console.log(error)
    }
  },[record, addAlertMessages, setListElement, closeModal])

  const createApplication = useCallback(async (data) => {
    try {
      const response  = await create({data: {event_parking_application: data, allow_save: 2}})
      closeModal()
      openModal('confirmation')
      setListElement(response.data)
    } catch (error) {
      setAlertMsg(error?.response?.data?.errors?.event_space)
      console.log(error)
    }
  },[setListElement, closeModal, openModal])

  return (
    <Modal
      isOpen={!!modalType} 
      centered 
      contentClassName={styles.applicationModal} 
      style={{maxWidth: '689px', width: '100%'}} 
    >
      { !!modalType && <ModalHeader close={closeBtn()} className='border-0 pb-0' /> }
      <ModalBody>
        {( modalType === 'show' || modalType === "approve") && <Show record={listType === 'city'? cityEventRecord : record} listType={listType} closeModal={closeModal} modalType={modalType} approveApplication={approveApplication} /> }
        {
          modalType === 'reject' && 
          <RejectApplication
            rejectApplication={rejectApplication}
            record={record}
            closeModal={closeModal}
            state={state}
            setState={setState}
          />
        }
        {
          modalType === 'on_hold' && 
          <OnHoldApplication
            holdApplication={holdApplication}
            record={record}
            closeModal={closeModal}
            state={state}
            setState={setState}
          />
        }
        {
          modalType === 'setting' && 
          <Setting 
            setSettingRecord={setSettingRecord}
            settingRecord={settingRecord}
            setErrors={setErrors}
            errors={errors}
            state={state}
            setState={setState}
            closeModal={closeModal}
            addAlertMessages={addAlertMessages}
          /> 
        }
        {
          modalType === 'create' && 
          <CreateApplication 
            record={record} 
            closeModal={closeModal} 
            modalType={modalType} 
            createApplication={createApplication} 
            setErrors={setErrors} 
            errors={errors} 
            state={state} 
            setState={setState}
            setEventType={setEventType}
            setAlertMsg={setAlertMsg}
            alertMsg={alertMsg}
          /> 
        }
        {
          modalType === 'confirmation' && 
          <Confirmation
            closeModal={closeModal}
            eventType={eventType}
          />
        }
      </ModalBody>
    </Modal>
  )
}

const  mapState = (state) => {
  return {
    record: state.event_parking_applications.records.record,
    cityEventRecord: state.city_event.records.record
  }
}
export default connect(mapState, null)(EventModal);

