import { ClientAction } from 'actions';
import { combineReducers } from 'redux';
import reduceEntity from './entities';

const { index, records, filters } = reduceEntity(ClientAction);

const ClientReducers = combineReducers({
  index,
  records,
  filters
});

export default ClientReducers;
