import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router';
import ValetHomepage from 'components/pages/valet_app/home/index'

const Routing = ({ match }) => (
  <React.Fragment>
    <Route exact path={`${match.path}`} component={ValetHomepage} />
  </React.Fragment>
);

Routing.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Routing);
