import React, { useCallback, useState } from 'react'
import { filterFields } from 'components/helpers/fields/reports'
import { permitFilterFields, permitTypeFilterFields, parkingSessionFilterFields,
         violationFilterFields, citationFilterFields, disputeFilterFields, 
         paymentFilterFields, userFilterFields, vehicleFilterFields,
         parkingLotFilterFields, eventFilterFields, bootingTowingFilterFields,
         agencyFilterFields} from 'components/helpers/fields/system_reports/permit_report_fields'
import { renderFields, renderFieldsWithGrid } from 'components/base/forms/common_form'
import { reportFilterFetcher } from 'api/reports';
import Button from 'components/base/button'
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
import styles from './index.module.sass'
import moment from "moment"
import DateModal from "components/base/date_modal";
import { list as selectList } from 'selectors/list'

const FilterForm = (props) => {
  const { setFilters, initialFilter, setInitialFilter, parkingLots, permitTypes, parkingSessionStatuses, permitTypeCategory, startFetching,
          setList, match, formApiRef, setFormApi, reportName, ticketStatuses, violationTypes, citationStatuses,
          disputeStatuses, disputeTypes, townManagers, parkingAdmins, agencyTypes, filters, dateRange, setDateRange } = props;
  const [dateModalOpen, setDateModalOpen] = useState(false)
  
  const datesToFilter= [
    {
      label: 'Today',
      value: 'Today',
      period: {from: moment(), to: moment()}
    },
    {
      label: 'Yesterday',
      value: 'Yesterday',
      period: {from: moment().subtract(1, "days"), to: moment().subtract(1, "days")},
    },
    {
      label: 'This week',
      value: 'This week',
      period: {from: moment().startOf("isoWeek"), to: moment().endOf("isoWeek")},
    },
    {
      label: 'Last week',
      value: 'Last week',
      period: {from: moment().subtract(1, 'weeks').startOf('week'), to: moment().subtract(1, 'weeks').endOf('week')},
    },
    {
      label: 'This month',
      value: 'This month',
      period: {from: moment().startOf("month"), to: moment().endOf("month")},
    },
    {
      label: 'Last month',
      value: 'Last month',
      period: {from: moment().subtract(1, 'months').startOf('month'), to: moment().subtract(1, 'months').endOf('month')},
    },
    {
      label: 'Custom Range',
      value: 'Custom Range',
    },
  ]

  const attrs = { 
    customInputClass: styles.formInput2,
    customLabelClass: styles.formLabel,
    customInput1Class: styles.formInput2,
    datesToFilter: datesToFilter,
    dateRange: dateRange,
    parkingLots: parkingLots,
    permitTypes: permitTypes,
    permitTypeCategory: permitTypeCategory,
    parkingSessionStatuses: parkingSessionStatuses,
    ticketStatuses: ticketStatuses,
    violationTypes: violationTypes,
    citationStatuses: citationStatuses,
    disputeStatuses: disputeStatuses,
    disputeTypes: disputeTypes,
    townManagers: townManagers,
    parkingAdmins: parkingAdmins,
    agencyTypes: agencyTypes
  }

  const timeFieldsProps = {
    lSize: 5,
    iSize: 7,
    events: {
      onChange: (e) => {setCustomDate(e)}
    }
  }

  const rangeFieldsProps = {
    lSize: 5,
    iSize: 7,
    events: {
      onChange: (e) => {setPeriodDate(e, 'from')}
    }
  }

  const range1FieldsProps = {
    lSize: 5,
    iSize: 7,
    events: {
      onChange: (e) => {setPeriodDate(e, 'to')}
    }
  }

  const setPeriodDate = (e, attr) => {
    const { values: filterValues } = formApiRef.current.getState();
    formApiRef.current.setValues({
      period: 'Custom Range'
    })
    setFilters({period: 'Custom Range', range: { ...filterValues?.range, [`${attr}`]: e}})
    setInitialFilter({...filterValues?.range, [`${attr}`]: e})
  }
  
  const filterViolations = useCallback(async (filters={}) => {
    const { range } = filters
    if(range && !range?.to) delete range.to
    if(range && !range?.from) delete range.from
    if(filters?.booting_towing_filter?.hasOwnProperty('status')){
      Object.assign(filters, {booting_towing_filter: {...filters?.booting_towing_filter, [filters?.booting_towing_filter?.status]: true}})
    }
    startFetching(reportFilterFetcher(Object.assign({ ...match.params }, { filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])

  const setCustomDate = (e) => {
    if(e.label === 'Custom Range'){
      setDateModalOpen(true)
    }else if(['Today', 'Yesterday', 'This week', 'Last week', 'This month', 'Last month'].includes(e.label)){
      formApiRef.current.setValues({
        range: {
          from: e?.period?.from?.format("YYYY-MM-DD"),
          to: e?.period?.to?.format("YYYY-MM-DD")
        }
      })
      setInitialFilter({from: e?.period?.from?.format("YYYY-MM-DD"),to: e?.period?.to?.format("YYYY-MM-DD")})
      console.log("FROMTO", `${e?.period?.from?.format("YYYY-MM-DD")} to ${e?.period?.to?.format("YYYY-MM-DD")}`)
      setDateRange(`${e?.period?.from?.format("YYYY-MM-DD")} to ${e?.period?.to?.format("YYYY-MM-DD")}`)
    }
  }

  const save = () => {
    const { values: filterValues } = formApiRef.current.getState();
    const updatedFilters = Object.keys(filterValues)
      .filter((objKey) => objKey !== 'period' && objKey !== 'range')
      .reduce((newObj, key) => {
        newObj[key] = filterValues[key];
        return newObj;
      }, {});

    const filters = {
      [`${setHeaders()[1]}`]: {
        ...updatedFilters,
      },
      range: initialFilter ? initialFilter : {},
      period: filterValues?.period
    }
    setFilters(filters);
    filterViolations(filters);
  }

  const handleApply = (from, to) => {
    console.log("FROM", from, "TO", to)
    formApiRef.current.setValues({
      range: {
        from: from,
        to: to
      }
    })
    setDateRange(`${from} to ${to}`)
    setDateModalOpen(false)
    setInitialFilter({from: from,to: to})
  };

  const resetFilters = () => {
    formApiRef.current.setValues({
      range: { 
        from: '',
        to: ''
      },
      period: '',
      permit_type_name: '',
      status: '',
      statuses: '',
      parking_lot_ids: '',
      parking_lot_id: '',
      permit_type_category: '',
      category: '',
      ticket_status: '',
      violation_type: '',
      violation_type_id: '',
      dispute_type: '',
      payment_method: '',
      query: {users: {email: ''}}, 
      plate_number: '',
      manufacturer_name: '',
      vehicle_type: '',
      parking_lot_type: '',
      town_managers: '',
      parking_admins: '',
      event_type: '',
      event_days: '',
      email: '',
      agency_type_id: ''
    })
    setDateRange(null)
    setFilters({})
    setInitialFilter({})
    filterViolations({});
  }

  const resetDates = () => {
    setFilters({...filters, range: { from: '', to: ''}, period: ''})
    setInitialFilter({})
    formApiRef.current.setValues({
      ...filters,
      range: { from: '', to: ''},
      period: ''
    })
  }


  const setHeaders = () => {
    switch (reportName) {
      case 'Permit':
        return [permitFilterFields, 'permit_filter']
      case 'PermitType':
        return [permitTypeFilterFields, 'permit_type_filter']
      case 'ParkingSession':
        return [parkingSessionFilterFields, 'parking_session_filter']
      case 'Violation':
        return [violationFilterFields, 'violation_filter']
      case 'CitationTicket':
        return [citationFilterFields, 'citation_filter']
      case 'Dispute':
        return [disputeFilterFields, 'dispute_filter']
      case 'Payment':
        return [paymentFilterFields, 'payment_filter']
      case 'User':
        return [userFilterFields, 'user_filter']
      case 'Vehicle':
        return [vehicleFilterFields, 'vehicle_filter']
      case 'ParkingLot':
        return [parkingLotFilterFields, 'parking_lot_filter']
      case 'EventParkingApplication':
        return [eventFilterFields, 'event_filter']
      case 'BootingTowing':
        return [bootingTowingFilterFields, 'booting_towing_filter']
      case 'Agency':
        return [agencyFilterFields, 'agency_filter']
      default:
        return []
    }
  }

  const getFields = () => filterFields({ ...attrs})

  const initialValues = {
    ...filters[`${setHeaders()[1]}`],
    period: filters?.period,
    range: {
      from: filters?.range?.from,
      to: filters?.range?.to
    }
  };

  return (
    <Form initialValues={initialValues}  getApi={setFormApi} className={styles.form} onSubmit={save}>
      {({formState}) => {
        const fields = getFields()
        const filterField = setHeaders()[0]
        return <Row className='m-auto'>
          <Row className='w-100'>
            <Col style={{marginLeft: '25px'}} xs={4}>
              {renderFields(fields.slice(0, 1), {...timeFieldsProps})}
            </Col>
            <Col style={{marginLeft: '-12px'}} xs={4}>
              {renderFields(fields.slice(1, 2), {...rangeFieldsProps})}
            </Col>
            <Col style={{marginLeft: '-13px'}} xs={4}>
              <span className={`${styles['fieldset-dateStroke']} position-absolute`} style={{marginLeft: '50px'}}></span>
              <div style={{marginLeft: '68px'}}>
              {renderFields(fields.slice(2, 3), {...range1FieldsProps})}
              </div>
              <span 
                role='button' 
                className={`${styles['fieldset-dateClear']} position-absolute`}
                onClick={resetDates}
              >
                Clear Date
              </span>
            </Col>
          </Row>
          <Col className={`m-0 ${styles.fieldset}`} xs={12}>
            {renderFieldsWithGrid(filterField({...attrs}),5,4,{...timeFieldsProps})}
          </Col>
          <Col className='d-flex justify-content-center pt-3 m-0' xs={12} >
            <Button
              className={`${styles.btn} ${styles['background-primary']} mr-5`}
              type='submit'
            >
              Filter
            </Button>
            <Button
              type='button'
              onClick={resetFilters}
              className={`${styles.btn} ${styles['background-secondary']}`}
            >
              Reset
            </Button>
          </Col>
          <DateModal
            maxDate={moment().toDate()}
            apply={handleApply}
            isOpen={dateModalOpen}
            toggleModal={() => setDateModalOpen(false)}
          />
        </Row>
      }}
    </Form>
  )
}

export default FilterForm;
