import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'informed'
import { Col, Container, Row } from 'reactstrap';
import { isEmpty, map } from 'underscore';
/* Base */
import { renderField, renderFields } from 'components/base/forms/common_form'
import Button from 'components/base/button';
/* Styles/Assets */
import styles from './index.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import withFetching from 'components/modules/with_fetching';
import { getPassFields } from 'components/helpers/fields/app/event_parking/getPassFields'
import GooglePayButton from '@google-pay/button-react';
import GooglePayDetails from 'components/modules/google_pay.js'
import { Spinner } from 'reactstrap';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import ErrorWrapper from "components/base/forms/common_form/error";
import withCurrentSubscriber from 'components/modules/with_current_subscriber';

const btnSpinner = (props = {}) => {
  return (
    <span>
      <Spinner {...props} size="sm" color="default"/>
    </span>
  )
};

const dollarIcon = () => <FontAwesomeIcon icon={faDollarSign} className={styles.dollarIcon} />

const GetPass = (props) => {
  const  {currentSubscriber, fetchedEvent,event, state , getEventParkingPass,setState, closeModal, errors, setErrors, isSaving, formApiRef, setFormApi, modalType, contextHolder, t } = props;
  const [step , setStep] = useState(1)
  const [cardNumber, setCardNumber] = useState('');
  const [name, setName] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [expiryM, setExpiryM] = useState('')
  const [expiryY, setExpiryY] = useState('')
  const creditCardNum = useRef('')
  const holder_name = useRef('')
  const cvv = useRef('')
  const [showGooglePay, setGooglePayShow] = useState(false);
  const [showCreditCardFields, setShowCreditCardFields] = useState(false)
  const [selectedParkingLot, setSelectedParkingLot] = useState()
  const [months, setMonths] = useState([1,2,3,4,5,6,7,8,9,10,12])
  const vehicles = currentSubscriber?.vehicles_attributes.map((v) => {return {value: v.plate_number, label: v.plate_number}})
  const [lotMsg, setLotMsg] = useState([''])

  const years = Array.from(
    { length: 20 },
    (_, index) => new Date().getFullYear() + index
  );

  const handleExpiryYearChange  = (e) => {
    setExpiryY(e) ;
    if(e > new Date().getFullYear()){
      setMonths([1,2,3,4,5,6,7,8,9,10,12])
    } else {
      setMonths(months.filter((m) => {return m > (new Date().getMonth() + 1)}))
    }
  }


  const validateFields = () => {
    const {full_name, vehicle_lpn, email, expiration_month, expiration_year } = formApiRef.current.getValues();
    const errors = {};
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (step ===  1) {
      if(!full_name){errors['full_name'] = ['Full Name is required!']}
      if(!vehicle_lpn){errors.vehicle_lpn = ['Vehicle LPN is Required !']}
      if(!email){errors.email = ['Email Address is Required !']}
      if(email && !(email?.match(isValidEmail))){errors['email'] = ['Invalid email address !']}
      if(!selectedParkingLot){setLotMsg(t("events.city_events.get_pass_form.parking_lot"))}
    }
    if (step ===  2) {
      if(showCreditCardFields && !creditCardNum?.current?.value){errors['credit_card_number'] = ['Credit Card Number is required!']}
      if(showCreditCardFields && !holder_name?.current?.value){errors['holder_name'] = ['Card Holder Name is required !']}
      if(showCreditCardFields && !cvv?.current?.value){errors['cvc'] = ['CVC is required !']}
      if(showCreditCardFields && !expiration_month){errors['expiration_month'] = ['Expiration Month is required !']}
      if(showCreditCardFields && !expiration_year){errors['expiration_year'] = ['Expiration Year is required!']} 

    }
   return errors 
  }

  const handleNext = () => {
    setErrors({})
    const validationErrors = validateFields()
   
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
      return
    }else if(step < 3) {
      setStep(step + 1);
    }
  };
  

  useEffect(() => {
    if(!selectedParkingLot) setSelectedParkingLot(fetchedEvent?.parking_lots[0]?.passes_sold_out === "NO"? fetchedEvent?.parking_lots[0] : undefined );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchedEvent])

  useEffect(() => {
    setErrors({})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handlePrevious = () => {
    if (step > 1) {
     setStep(step - 1);
    }
  };

  const initialValues = {
    full_name: `${currentSubscriber.first_name} ${currentSubscriber.last_name}`,
    event_name: event?.event_name,
    payment_amount: event?.attendees_cost,
    email: currentSubscriber?.email
  }

  const attrs = {
    customLabelClass: styles.formLabel,
    customInputClass: styles.formInput,
    customDropdownInputClass: styles.formDropdownInput,
    customExpiryInputClass: styles.cardInput,
    years: years,
    months: months
  }
  

  const getFields = () => {
    return getPassFields({
      ...attrs,
      customDropdownInputClass: styles.formDropdownInput,
      customExpiryDropdownInputClass:
        state.innerModal === "get_pass"
          ? (!isEmpty(errors)
            ? styles.expiryDropdownInnerModal2
            : styles.expiryDropdownInnerModal)
          : (!isEmpty(errors) ? styles.expiryDropdownInnerModal2 : styles.expiryDropdown),
      customExpiryLabel: styles.expiryDropdownLabel,
      customExpiryLabel2: styles.cardLabels2,
      vehicles: vehicles
    });
  };

  const cardMFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_month')
    }
  }
  const cardYFieldProps = {
    iSize: 12,
    lSize: 12,
    events:{
      onChange: (e) => handleCardFieldsChange(e, 'expiration_year'),
    }
  }

  const paymentFieldProps = {
    iSize: 6,
    lSize: 6,
    events: {
      onChange: (_e) => showPaymentOption()
    }
  }

  const singleFieldProps = {
    lSize: 4,
    iSize: 8, 
  }
  


  const showPaymentOption = () =>{
    if (formApiRef.current?.getValue('payment_methods')===0){
      setGooglePayShow(true)
      setShowCreditCardFields(false)
  
    }else if (formApiRef.current?.getValue('payment_methods')===1) {
      setShowCreditCardFields(true)
      setGooglePayShow(false)
    }
  }


  const submitValues = (values) => {
    const validationErrors = validateFields()
   
    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
      return
    }
    const data = { 
      event_parking_application_id: fetchedEvent?.id,
      parking_lot_id: selectedParkingLot?.id,
      full_name: values?.full_name,
      email: values?.email,
      lpn: values?.vehicle_lpn,
      gateway_params:{
        amount: values?.payment_amount?.toString(),
        credit_card_attributes:{
          number:  creditCardNum?.current?.value,
          cvv: cvv?.current?.value,
          holder_name: holder_name?.current?.value,
          expiration_month: values?.expiration_month,
          expiration_year: values?.expiration_year,
        },
        
      }
    }
    getEventParkingPass(data)
  }

  const submitValuesGPay = (paymentToken) => { 
    const validationErrors = validateFields()

    if(!isEmpty(validationErrors)){
      setErrors(validationErrors)
      return
    }
    const {values} = formApiRef.current.getState()
    const data = { 
      event_parking_application_id: fetchedEvent?.id,
      parking_lot_id: selectedParkingLot?.id,
      full_name: values?.full_name,
      email: values?.email,
      lpn: values?.vehicle_lpn,
      gateway_params:{
        amount: values?.payment_amount?.toString(),
        digital_wallet_attributes: {
          encryptionhandler: 'EC_GOOGLE_PAY',
          devicedata: paymentToken?.paymentMethodData?.tokenizationData?.token
        }
      }    
    }  
    getEventParkingPass(data)
  }

  const handleCheckboxChange = (parkingLot) => {
    const selectedParkingLots = state.selectedParkingLots.slice();
    const parkingLotIndex = selectedParkingLots.indexOf(parkingLot);
    if (parkingLotIndex === -1) {
      selectedParkingLots.push(parkingLot);
    } else {
      selectedParkingLots.splice(parkingLotIndex, 1);
    }
    const disableCheckboxes = (selectedParkingLots.length === 2);
    setState('selectParkingLot', {
      selectedParkingLots,
      disableCheckboxes,
    });
    setSelectedParkingLot(parkingLot)
  };

  const handleCardFieldsChange = (value, field) => {
    const updatedErrors = Object.keys(errors)
      .filter((objKey) => objKey !== field)
      .reduce((newObj, key) => {
        newObj[key] = errors[key];
        return newObj;
      }, {});
    setErrors(updatedErrors);
    if (field === "credit_card_number") {
      setCardNumber(value);
    } else if (field === "holder_name") {
      setName(value);
    } else if (field === "expiration_month") {
      setExpiryM(value);
      setFocus("expiry");
    } else if (field === "expiration_year") {
      handleExpiryYearChange(value);
    } else if (field === "cvc") {
      setCvc(value);
    }
  };

  return (
    <Container className='p-0' style={{marginRight: '-3%'}}>
      {contextHolder}
      <p className={`${styles.title}  p-0`}></p>
      <fieldset disabled={isSaving}>
        <Form initialValues={initialValues}  getApi={setFormApi} className={styles.form}  onSubmit={submitValues} >
          {({ formState }) => {
            const { payment_amount } = formState.values
            const newFields = getFields()
            return (
              <Row className='mx-auto w-100'>
                <Col className='flex-column w-100 text-center'>
                  <p className={styles.modalTitle}> Get Parking Pass </p>
                </Col>
  
                <Row className={step === 1 ? `m-0 p-0 ${styles['fieldset-double']}   transition-container fade-in pt-2` : 'd-none transition-container fade-out'}>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']} pl-1.7`} xs={12}>
                    { renderFields(newFields[0], { ...singleFieldProps, errors} )}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    { renderFields(newFields[1], { ...singleFieldProps, errors} )}
                  </Col>
                  <Col className={`m-0 p-0 ${styles['fieldset-double']}`} xs={12}>
                    { renderFields(newFields[2], { ...singleFieldProps, errors} )}
                  </Col>

                  <div className={styles.lotLabelSection}>
                    <label className={styles.formLabel} style={{marginLeft: "-10px", fontWeight: 1000, fontSize:"13px"}}>{t("events.city_events.get_pass_form.preferred_lot")}:</label>
                    <p className={styles.lotRequiredMsg}>{lotMsg}</p>
                  </div>
  
                  <Row className="mx-auto w-100">
                    <Col className="d-flex flex-wrap justify-content w-100">
                      <div className={styles.scrollableContainer}>
                        {fetchedEvent?.parking_lots?.map((parkingLot) => (
                          <div
                            key={parkingLot.name}
                            className={styles.parkingLotBox}
                            onClick={() => parkingLot.passes_sold_out === 'NO' ? handleCheckboxChange(parkingLot) : ''}
                          >
                            <span>
                              <div className={styles.parkingLotCell}>
                                <span className={modalType==='get_pass' ? styles.lotName1 : styles.lotName} style={{textAlign: 'left'}}>
                                  <span className={modalType==='get_pass' ? styles.lotName1 : styles.lotName} style={{fontSize:"15px", fontWeight:500, color:"grey", position:"relative"}}>{parkingLot?.name}</span>
                                </span>
                                {parkingLot.passes_sold_out ==='YES' ? <p className={modalType==="get_pass"? styles.alertMsg1 : styles.alertMsg}>Full</p> : 
                                  <input
                                    type="checkbox"
                                    checked={selectedParkingLot? selectedParkingLot===parkingLot : parkingLot === fetchedEvent?.parking_lots[0]}
                                    onChange={() => handleCheckboxChange(parkingLot)}
                                    label={parkingLot?.name}
                                    disabled={parkingLot.sold_out === 'YES'}
                                    className={styles.customCheckBoxStyle}
                                  />
                                }
                              </div>
                            </span>
                          </div>
                        ))}
                      </div>
                            
                    </Col>
                  </Row>
                </Row>

                <Row className={step === 2 && event?.attendees_cost_type === 'paid' ? `m-0 p-0 ${styles['fieldset-double']}   transition-container fade-in pt-2` : 'd-none transition-container fade-out'}>
                  { map(newFields[3], (field, idx) => {
                      if(idx === 0) {
                        field = {
                          ...field,
                          icon: dollarIcon(), 
                          customInputClass: attrs.customInputClass.concat(' ', styles.iconInput)
                        }
                      }
                      
                      return (
                        <React.Fragment>
                          { idx === 0 && 
                            <Col className={`m-0 pt-2 ${styles['fieldset-double']} pl-1`} xs={12}>
                              { renderField(field, { ...paymentFieldProps, errors} )}
                            </Col>
                          }
                          {idx === 1 &&
                            <Col className={`m-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                              { renderField(field, { ...paymentFieldProps, errors} )}
                            </Col> 
                          }
                          {idx === 2 && payment_amount !== '0.0' &&
                            <Col className={`m-0 ${styles['fieldset-double']} pl-1`} xs={12}>
                              { renderField(field, { ...paymentFieldProps, errors} )}
                            </Col> 
                          }
                        </React.Fragment>
                      )
                  })}
              
                  <Col>
                    <Row className="flex-column w-100 text-center p-1">  
                      {showCreditCardFields && !showGooglePay &&
                        <Col>
                          <div className={styles.cardContainer}>
                            <div className={state.innerModal==='get_pass' ? styles.cardPreviewInnerModal : styles.cardPreview}>
                              <Cards
                                number={cardNumber}
                                name={name}
                                expiry={`${expiryM}${expiryY}`}
                                cvc={cvc}
                                focused={focus}
                              />
                            </div>
                          </div>
                          <div>
                            <div className={state.innerModal==='get_pass' ? styles.cardInputs : styles.cardInputs2}>
                              <label className={styles.cardLabels}>Card Number</label>
                              <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'credit_card_number'}}>
                                <input
                                  type="tel"
                                  id="credit_card_number"
                                  name="credit_card_number"
                                  placeholder="Card Number"
                                  maxLength={16}
                                  value={cardNumber}
                                  onChange={(e) => handleCardFieldsChange(e.target.value, 'credit_card_number')}
                                  onFocus={() => setFocus('number')}
                                  className={styles.cardInput}
                                  ref={creditCardNum}
                                />
                              </ErrorWrapper>
                              <label className={styles.cardLabels}>Holder Name</label>
                              <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'holder_name'}}>
                                <input
                                  type="text"
                                  id="holder_name"
                                  name="holder_name"
                                  placeholder="Holder Name"
                                  value={name}
                                  onChange={(e) => handleCardFieldsChange(e.target.value, 'holder_name')}
                                  onFocus={() => setFocus('name')}
                                  className={styles.cardInput}
                                  ref={holder_name}
                                />
                              </ErrorWrapper>
  
                              { map(newFields[4], (field, idx) => {
                                return (
                                  <React.Fragment>
                                    {idx===0 &&
                                      renderField(field, { ...cardYFieldProps, errors} )
                                    }
                                    {idx=== 1 &&
                                      renderField(field, { ...cardMFieldProps, errors} )
                                    }
                                  </React.Fragment>
                                )
                              })}
                              <label htmlFor="cvc" className={styles.cvcLabel}>CVC</label>
                              <ErrorWrapper className={styles.ccNumField} errors={errors} field={{name:'cvc'}}>
                                <input
                                  type="tel"
                                  id="cvc"
                                  name="cvc"
                                  placeholder="CCV"
                                  value={cvc}
                                  onChange={(e) => handleCardFieldsChange(e.target.value, 'cvc')}
                                  onFocus={() => setFocus('cvc')}
                                  className={styles.cardInput}
                                  maxLength={3}
                                  ref={cvv}
                                />
                              </ErrorWrapper>
                            </div>
                              
                          </div>
                        </Col>
                           
                          
                      }
                    </Row>
                  </Col>
                  <div centered>
                    {showGooglePay && <GooglePayButton
                      environment="TEST"
                      paymentRequest={GooglePayDetails(payment_amount)}
                      onLoadPaymentData={paymentRequest => {
                        submitValuesGPay(paymentRequest)
                      }}
                      buttonType='pay'
                      className={state.innerModal==="get_pass"? styles.gpayBtnInnerModal : styles.gpayBtn}
                    />}
                  </div>
                </Row>
                <Col className="d-flex justify-content-center mb-1 mt-3" xs={12}>
                  {step > 1 &&
                  <Button onClick={handlePrevious} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-6`}>
                    Previous
                  </Button>
                  }
                  { step === 1 &&
                    <Button onClick={closeModal} type='button' className={`${styles.button} ${styles['button-bg-secondary']} mr-4`}>
                      Cancel
                    </Button>
                  }
                  {step < 2 && event?.attendees_cost_type === 'paid'  && 
                    <Button type='button' className={styles.button}  onClick={handleNext}>
                    Next
                    </Button>
                  }
                  {((showCreditCardFields && step === 2) || (step === 2 && payment_amount===0.0) || (step === 1 && event?.attendees_cost_type==='free')) &&
                    <Button type='submit' className={styles.button}>
                      {isSaving ? btnSpinner({ className: 'spinner-border' }) : "Submit"}
                    </Button>
                  }
                </Col>
              </Row>
            )
          }}
        </Form>
      </fieldset>
    </Container>
  )
}

export default withFetching(withCurrentSubscriber(GetPass));