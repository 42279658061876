import React, { useCallback, useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
/* Styles/Assets */
import styles from './index.module.sass';
import { ReactComponent as SettingIcon } from 'assets/setting-gear-icon.svg'
import { ReactComponent as SearchIcon } from 'assets/search_icon.svg'
import { ReactComponent as SecondaryGearIcon } from 'assets/secondary-gear.svg'
import { ReactComponent as FileIcon } from 'assets/file-icon.svg'
import { ReactComponent as ArrownDownToBracketIcon } from 'assets/arrow-down-to-bracket.svg'
import { ReactComponent as ClockIcon } from 'assets/file-clock-icon.svg'
import { ReactComponent as EyeIcon } from 'assets/eye-icon.svg'
/* API */
import { search as dropdownsSearch } from "api/dropdowns";
import { filterFetcher } from "api/permits";
/* Base */
import SlideButton from 'components/base/slide_button';
import Tabs from 'components/base/tabs';
import NavigationToolbar from 'components/base/navigationToolbar';
/* Component */
import FilterForm from './filterForm';
import { list as selectList } from 'selectors/list';

const tabs = [
  { value: 'permitted', label: 'Permitted'},
  { value: 'active', label: 'Active'},
  { value: 'cancelled', label: 'Cancelled'},
  { value: 'revoked', label: 'Revoked'},
]

const CollapseContent = ({startFetching,filters, setList, setFilters, match, resource, currentUser, listType, setListType}) => {
  const [dropdowns, setDropdowns] = useState({
    parkingLots: [],
    permitTypes: [],
    permitTypeCategory: [],
  })
  
  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("parking_lot_list")).then((response) =>
          setDropdowns(state => ({...state, parkingLots: response.data }))),
        startFetching(
          dropdownsSearch("permit_type_list")
        ).then((response) => setDropdowns(state => ({...state, permitTypes: response.data }))),
        startFetching(dropdownsSearch("permit_type_category")).then((response) =>
          setDropdowns(state => ({...state, permitTypeCategory: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Row className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`} sm="12">
      <FilterForm 
        {...dropdowns}
        startFetching={startFetching} 
        setList={setList} 
        match={match} 
        resource={resource} 
        setFilters={setFilters}
        filters={filters}
        listType={listType}
        setListType={setListType}
      />
    </Row>
  )
};

const ToolBar = (props) => {
  const { openModal, activeTab, setActiveTab, modalType, openCollapse, listType, setListType, startFetching, setFilters, match, setList} = props
 
  const filterPermits = useCallback(async (filters) => {
    startFetching(filterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters })))
      .then((res) => {
        setList(selectList(res));
      })
      .catch(error => console.log(error))
  },[setList, startFetching, match.params])


  const handleTabsFilter = () => {
    const filters = { status: listType === 'permitted' ? '' : listType };
    if(listType==='permitted' && activeTab!=='filters'){
      setFilters({})
      filterPermits({})
    }else {
      setFilters(filters)
      filterPermits(filters)
    }    
  }

  useEffect(() => {
    if (listType) {
      handleTabsFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFilters, setListType, listType]);

  const handleToggleCollapse = useCallback((tab) => {
    setActiveTab(activeTab => activeTab === tab ? null : tab)
  },[setActiveTab])

  return (
    <NavigationToolbar 
      className='justify-content-between align-items-center'
      collapseContent={<CollapseContent
        activeTab={activeTab}
          {...props}
           />}
      openCollapse={openCollapse}
    >
      <Col className='d-flex justify-content-start pl-0'>
      <Tabs list={tabs} defaultTab={listType} onClick={setListType}/>
        <Link to='permit_waiting_lists' className={`${styles.navButton} btn position-relative ml-5`}>
          <FileIcon />
          <ClockIcon  style={{ position: 'absolute', top: '50%', left: '20px' }}/>
          <span className="ml-2">Waitlist</span>
        </Link>
      </Col>
      <Col className='d-flex justify-content-end p-0'>
        <SlideButton
          icon={<EyeIcon />}
        >
          <Link to='permit_applications' className={`${styles.navButton} mr-3 d-inline-block text-decoration-none`} style={{width: '176px'}}>View Permit Appilcations</Link>
        </SlideButton>
        <SlideButton
          icon={<SettingIcon />}
        >
          <Link to='permit_types' className={`${styles.navButton} mr-3 d-inline-block text-decoration-none`} style={{width: '156px'}}>Manage Permit Types</Link>
        </SlideButton>
        <SlideButton
          icon={<ArrownDownToBracketIcon />}
          isActive={
            ['import', 'import_issued_permits', 'import_permit_types'].includes(modalType)
          }
          onClick={() => {
            handleToggleCollapse('import');
            openModal('import');
          }}
        >
          <span className='mr-3 d-inline-block' style={{width: '136px'}}>Import Permit Data</span>
        </SlideButton>
        <SlideButton
          icon={<FileIcon />}
        >
          <Link to='permits/reports' className={`${styles.navButton} mr-3 d-inline-block text-decoration-none`} style={{width: '55px'}}>Reports</Link>
        </SlideButton>
        <SlideButton
          icon={<SecondaryGearIcon style={{ width: '18px', height: '18px'}}/>}
        >
          <Link to='permit_type_codes' className={`${styles.navButton} mr-3 d-inline-block text-decoration-none`} style={{width: '130px'}}>Permit Type Code</Link>
        </SlideButton>
        <SlideButton
          isActive={activeTab === 'filters'}
          status={`${activeTab === 'filters' ? '' : 'secondary'}`}
          className={`${activeTab === 'filters' ? styles.activeFilterTab : styles.initialTab}`}
          icon={<SearchIcon className={`${activeTab === 'filters' ? styles.activeFilterIcon : ''}`}/>}
          onClick={() => handleToggleCollapse('filters')}
        >
          <span className='mr-3 d-inline-block' style={{width: '130px'}}>Advanced Search</span>
        </SlideButton>
      </Col>
    </NavigationToolbar>
  )
}

export default ToolBar;