import React, { useCallback, useState } from "react";
import { Container, Row } from "reactstrap";
import { ROLES } from "config/permissions";
import PrivateEvents from "./private_events";
import CityEvents from "./city_events";
/* Modules */
import withCurrentUser from "components/modules/with_current_user";
/* Helpers */
import AccessControl from "components/helpers/access-control";
import ToolBar from "./toolbar";

const Index = (props) => {
  const { currentUser } = props;
  const [listType, setListType] = useState("city");
  const [showFilters, setShowFilters] = useState(false);
  const [updatedProps, setUpdatedProps] = useState();
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState("");
  const [search, setSearch] = useState("");

  const openModal = useCallback(
    (modalType) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case modalType === "show":
          val = "show";
          break;
        case modalType === "approve":
          val = "approve";
          break;
        case modalType === "reject":
          val = "reject";
          break;
        case modalType === "on_hold":
          val = "on_hold";
          break;
        case modalType === "setting":
          val = "setting";
          break;
        case modalType === "create":
          val = "create";
          break;
        case modalType === "confirmation":
          val = "confirmation";
          break;
        default:
          break;
      }
      setModalType(val);
    },
    [setModalType]
  );

  const toggleShowFilters = useCallback(() => {
    setShowFilters((val) => !val);
  }, [setShowFilters]);

  const commmonProps = {
    ...props,
    openModal,
    modalType,
    activeTab,
    setActiveTab,
    search,
    setSearch,
    listType,
  };

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className="mw-100 p-0">
        <ToolBar
          listType={listType}
          setListType={setListType}
          showFilters={showFilters}
          toggleShowFilters={toggleShowFilters}
          setFilters={updatedProps?.setFilters}
          filters={updatedProps?.filters}
          startFetching={updatedProps?.startFetching}
          setList={updatedProps?.setList}
          list={updatedProps?.list}
          match={updatedProps?.match}
          {...commmonProps}
        />
        <Row className="m-auto p-1">
          {listType === "city" && (
            <CityEvents {...commmonProps} setUpdatedProps={setUpdatedProps} />
          )}
          {listType === "private" && (
            <PrivateEvents
              {...commmonProps}
              setUpdatedProps={setUpdatedProps}
            />
          )}
        </Row>
      </Container>
    </AccessControl>
  );
};

export default withCurrentUser(Index);
