import React, { useCallback } from 'react'
import { Col } from 'reactstrap';
/* Component */
import TableRow from './tableRow';
/* Modules */
import { filterFetcher } from 'api/user_permits';
/* Helpers */
import { filterFields } from 'components/helpers/fields/permits/index';
/* Styles/Assets */
import styles from './index.module.sass';
/* Base */
import { IndexTable } from 'components/base/table';

const TableData = (props) => {
  const { isResourceFetching,filters,  list, setRecord, openModal, modalType, openCollapse } = props

  const renderRecords = useCallback(() => {
    return list.map((record) => 
      <TableRow  
        key={record.id} 
        openModal={openModal} 
        record={record} 
        setRecord={setRecord}
        modalType={modalType}    
      />
    );
  },[list, openModal, setRecord, modalType])

  return (
    <Col xs={12} className='p-1'>
      <IndexTable
        {...props}
        className={`${openCollapse ? styles.tableActive : '' } ${styles.table}`}
        isFetching={() => isResourceFetching }
        filterFields={filterFields()}
        filterFetcher={filterFetcher}
        paginationQuery={filters}
        columns={
          <React.Fragment>
            <th attr="permit_number" style={{width: '15%', minWidth: '150px' }} >Permit Number</th>
            <th attr="permit_types.name" style={{ width: '25%', minWidth: '210px' }} >Permit Type</th>
            <th attr="issue_date" style={{ width: '25%', minWidth: '210px' }} >Issue Date</th>
            <th attr="vehicles.plate_number" style={{ width: '25%', minWidth: '210px' }} >Vehicle Plate Number</th>
            <th attr="user_name" style={{ width: '25%', minWidth: '210px' }} >
              Vehicle Owner Name
            </th>
            <th attr="vehicles.model" style={{ width: '25%', minWidth: '210px' }} >
              Vehicle Model
            </th>
            <th attr="expired_status" style={{ width: '15%', minWidth: '150px' }} >
              Permit Expired
            </th>
            <th attr="expiry_date" style={{ width: '25%', minWidth: '210px' }} >
              Expiry Date
            </th>
          </React.Fragment>
        }
        renderRecords={renderRecords}
        entityName="Permits"
      >
      </IndexTable>
    </Col>
  )
}

export default TableData;