import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ROLES } from "config/permissions";
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, ADD_LIST_ELEMENT } from 'actions/clients';
import { filterFetcher } from 'api/clients';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from "components/helpers/access-control";
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import ClientModal from '../client_modal';
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Clients = props => {
  const { isResourceFetching, currentUser, filters } = props;
  const [modalType, setModalType] = useState(null)
  const [selectedRecordId, setSelectedRecordId] = useState(null)
  const [activeTab, setActiveTab] = useState(null);
  const [tags, setTags] = useState(Object.keys(filters) || []);

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  useEffect(() => {
    setTags(Object.keys(filters))
  }, [filters]);

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              resource={resource}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isFetching={isResourceFetching}
              listType="clients"
              tags={tags}
              setTags={setTags}
              openModal={openModal}
              modalType={modalType}
              total={props.list.length}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              selectedRecordId={selectedRecordId}
              setSelectedRecordId={setSelectedRecordId}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
              list={props.list}
            />
          </Col>
        </Row>
        <ClientModal
          {...props}
          openModal={openModal}
          modalType={modalType}
          setActiveTab={setActiveTab}
          selectedRecordId={selectedRecordId}
        />
      </Container>
    </AccessControl>
  )
  
};

Clients.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'clients';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS),
      addListElement: invoke(ADD_LIST_ELEMENT), 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.clients.filters,
    record: state.clients.records
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(filterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Clients))),
  SET_FILTERS
);