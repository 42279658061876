import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { Form } from 'informed';
import { show } from "api/subscribers";
import Loader from 'components/helpers/loader';
import { showFields } from 'components/helpers/fields/subscribers';
import Button from 'components/base/button';
import { renderFields } from 'components/base/forms/common_form';
import styles from './index.module.sass'
import { searchV1 as dropdownsSearch } from "api/dropdowns";
import { Link } from 'react-router-dom';
import { displayUnixTimestamp } from 'components/helpers';
import { isEmpty } from 'underscore';
import 'components/base/card.css'

const Show = ({ startFetching, closeModal , record, isSaving, updateRecord, errors, setErrors}) => {
  const [isFetching, setIsFetching] = useState()
  const [vehicle, setVehicle] = useState({})
  const [countryCode, setCountryCode] = useState("us");
  const [stateCode, setStateCode] = useState({});
  const [dropdowns, setDropdowns] = useState({
    accountTypes: [],
    countryList: [],
    stateList: [],
  })

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(dropdownsSearch("account_types_list")).then((response) =>
          setDropdowns(state => ({...state, accountTypes: response.data }))),
        startFetching(dropdownsSearch("countries_list")).then((response) =>
          setDropdowns(state => ({...state, countryList: response.data }))),
        startFetching(dropdownsSearch("states_list", {country_code: countryCode})).then((response) =>
          setDropdowns(state => ({...state, stateList: response.data }))),
      ])
    }
    fetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode])

  const fieldAttrs = {
    customInputClass: styles.input,
    customInput1Class: styles.input1,
    customInput2Class: styles.input2,
    customLabelClass: styles.inputLabel,
    customLabelClass1: styles.inputLabel2,
    customRadioLabelClass: styles.inputLabel1,
    account_types: dropdowns.accountTypes,
    countryList: dropdowns.countryList,
    stateList: dropdowns.stateList
  }

  const singleFieldProps = {
    iSize: 9,
    lSize: 3
  }

  const doubleFieldProps = {
    iSize: 6,
    lSize: 6
  }

  const countryFieldProps = {
    iSize: 6,
    lSize: 6,
    events: {
      onChange: (e) => setCountryCode(e)
    }
  }

  const stateFieldProps = {
    iSize: 6,
    lSize: 5,
    events: {
      onChange: (e) => setStateCode(e)
    }
  }

  const applicationData = useCallback((data) => {
    const { id, created_at, updated_at, first_name, last_name, email, phone, confirmed_at, vehicles_owned, account_type, home_address={}, status } = data
    setCountryCode(home_address?.country_code)
    setStateCode(home_address?.state_code)
    return ({  
      id: id,
      created_at: displayUnixTimestamp(created_at),
      updated_at: displayUnixTimestamp(updated_at),
      confirmed_at: displayUnixTimestamp(confirmed_at),
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      vehicles_owned: vehicles_owned,
      account_type_id: account_type?.id,
      status: status,
      home_address_attributes: {
        address1: home_address.address1,
        address2: home_address.address2,
        city: home_address.city,
        country: home_address.country,
        state_code: home_address.state,
        zip_code: home_address.postal_code
      }
  })
  },[setCountryCode, setStateCode])

  useEffect(() => {
    setIsFetching(true)
    const fetchCitationTicket = async () => {
      if(!record)  return;
      try {
        const response = await show({id: record.id})
        if(response.status === 200) {
          const { data } = response;
          setVehicle(applicationData(data));
        }
      } catch(error) {
        console.log(error)
      } finally {
        setIsFetching(false)
      }
    };
    fetchCitationTicket();

  }, [record, applicationData, startFetching])

  const validateValues = (formState) => {
    const errors = {}
    const {first_name, last_name, phone, home_address_attributes={}, status} = formState
    if(!first_name) { errors['first_name'] = ['First Name is required!']}
    if(!last_name) { errors['last_name'] = ['Last Name is required!']}
    if(!status) { errors['status'] = ['Status is required!']}
    if(!phone) { errors['phone'] = ['Phone Number is required!']}
    if(!home_address_attributes.address1) { errors['home_address_attributes[address1]']= ['Address Line 1 is required!']}
    if(!home_address_attributes.city) { errors['home_address_attributes[city]']= ['City is required!']}
    if(!home_address_attributes.state_code) { errors['home_address_attributes[state_code]']= ['State is required!']}
    if(!home_address_attributes.country) { errors['home_address_attributes[country]']= ['Country is required!']}
    if(!home_address_attributes.zip_code) { errors['home_address_attributes[zip_code]']= ['Zip Code is required!']}
    return errors
  }

  const submitValues = useCallback(() => {
    setErrors({})
    const {values} = formApiRef.current.getState();
    
    const inputErrors = validateValues(values)
    if(!isEmpty(inputErrors)){ 
      setErrors(inputErrors)
      return
    };
    const {first_name, last_name, phone, home_address_attributes={}, account_type_id, status} = formApiRef.current.getValues();

    const data = {
      first_name: first_name,
      last_name: last_name,
      phone: phone,
      home_address_attributes: {
        address1: home_address_attributes.address1,
        address2: home_address_attributes.address2,
        city: home_address_attributes.city,
        country_code: countryCode,
        state_code: stateCode,
        postal_code: home_address_attributes.zip_code
      },
      account_type_id: account_type_id,
      status: status
    }
    updateRecord(data)
  },[setErrors, updateRecord, countryCode, stateCode]);

  const fields = () => {
    return showFields({...fieldAttrs})
  }

  const renderUserField = (fields, text, idx) => {
    fields[idx] = {
      ...fields[idx], 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput),
      icon: <Link className={text === 'Check the List' ? styles.checkDetailsLink1 : styles.checkDetailsLink} to={{ pathname: `/dashboard/subscribers/${record?.id}/permits`, state: { user_id: record?.id } }}>{text}</Link>
    }

    return(
      <Col style={{marginLeft: '-4px'}}>
        {renderFields(fields.slice(idx,idx+1), {...singleFieldProps})}
      </Col>
    )
  }

  const renderVehicleField = (fields, text, idx) => {
    fields[idx] = {
      ...fields[idx], 
      customInputClass: fieldAttrs.customInputClass.concat(' ', styles.iconInput),
      icon: <Link className={text === 'Check the List' ? styles.checkDetailsLink1 : styles.checkDetailsLink} to={{ pathname: `/dashboard/subscribers/${record?.id}/vehicles`, state: { record } }}>{text}</Link>
    }

    return(
      <Col style={{marginLeft: '-4px'}}>
        {renderFields(fields.slice(idx,idx+1), {...singleFieldProps})}
      </Col>
    )
  }


  if(isFetching) { return <Loader /> }

  return (
    <Container className='p-0'>
      <Form getApi={setFormApi} initialValues={vehicle} className={styles.form} onSubmit={submitValues}>
        {({ formState }) => {
          const newFields = fields()
          return (
            <Col>
              <Col>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Subscription Details</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                  {renderUserField(newFields, "Check Details", 0)}
                <div style={{ margin: '10px'}}>
                  {renderFields(newFields.slice(1, 10), {...singleFieldProps, errors})}
                </div>
              </Col>
              <Col>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Address Details</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                <div style={{ margin: '10px'}}>
                  {renderFields(newFields.slice(10, 12), {...singleFieldProps, errors})}
                  <Row className={styles.doubleFileds}>
                    <div className={styles.fieldset}>
                      {renderFields(newFields.slice(12,13), {...doubleFieldProps, errors})}
                      {renderFields(newFields.slice(13,14), {...countryFieldProps, errors})}
                    </div>
                    <div className={`${styles.fieldset} ${styles['fieldset-right-label']}`}>
                      {renderFields(newFields.slice(14,15), {...stateFieldProps, errors})}
                      {renderFields(newFields.slice(15,16), {...doubleFieldProps, errors})}
                    </div>
                  </Row>
                </div>
              </Col>
              <Col>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Permit Owned</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                {renderUserField(newFields, "Check Details", 16)}
              </Col>
              <Col>
                <div className="d-flex align-items-center my-3">
                  <span className={styles.detailsLabel}>Subscriber Vehicles</span>
                  <span className="border border-2 flex-grow-1 ml-2"></span>
                </div>
                {renderVehicleField(newFields, "Check the List", 17)}
              </Col>
              <Col className='d-flex justify-content-center'>
                <Button 
                  onClick={()=>  closeModal()}
                  className={`${styles.cancelButton} mb-2`}
                >
                  Cancel
                </Button>
                <Button type='submit' className={styles.submitButton} isLoading={isSaving}>
                  Save
                </Button>
              </Col>
            </Col>
          )
        }}
      </Form>
    </Container> 
  )
}

export default (Show);