import React from 'react'
import { isEmpty } from 'underscore';
import { Col, Row, Spinner } from 'reactstrap'
/* Helpers */
import { displayDate } from 'components/helpers';
/* Styles */
import styles from './index.module.sass';
import { capitalize } from 'components/helpers';
import { ReactComponent as EditIcon } from 'assets/edit_icon.svg';
import { Link } from 'react-router-dom';
import { Descriptions, Button } from 'antd';
import { useTranslation } from 'react-i18next';

const Details = (props) => {
  const {match, event, openModal} = props
  const { t } = useTranslation() 
  const parkingLotPath = `${match.path.substr(0,4)}/events/${event?.event_id}`
  if(isEmpty(event)) return <span style={{marginTop:"-20%"}}>Not Found</span>
  
  const items = [
    {
      label: "Event Name",
      children: `${capitalize(event?.event_name)}`,
    },
   
    {
      label: "Total Spaces",
      children: `${event?.total_spaces}`,
    }, 
    
    {
      label: "Cost",
      children: (
        <React.Fragment>
          <Row className="d-flex">
            <Col className="d-flex justify-content-flex-start text-left" xs={6}>
              ${event?.organizer_cost ? event?.organizer_cost : event?.total_cost}
            </Col>
            <Col className={styles.colBtn}  xs={6}>
              <span
                className="text-center"
                style={
                  event?.payment_status === "paid"
                    ? { background: "#42f05f", borderRadius: "10px" }
                    : { background: "#fd5353", borderRadius: "1rem" }
                }
              >
                {event?.payment_status === "paid" ? (
                  <span style={{ margin: "1rem" }}>
                    {t("events.private_events.paid")}
                  </span>
                ) : (
                  <span style={{ margin: "0.8rem" }}>
                    {t("events.private_events.unpaid")}
                  </span>
                )}
              </span>
            </Col>
          </Row>
          
        </React.Fragment>
      ),
    },
      
    {
      label: "Event Dates & Times",
      span: 1,
      children: (
        <React.Fragment>
          {event?.event_dates_and_times?.map((dt, idx) => (
            <div>
              {displayDate(dt.date, "ddd, MMM D YYYY")}
              <span style={{ position: "relative" }}>{" -> "}</span>
              {new Date("2023-02-26T" + dt.start_time).toLocaleString("en-in", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
              -
              {new Date("2023-02-26T" + dt.end_time).toLocaleString("en-in", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </div>
          ))}
        </React.Fragment>
      ),
    },
    {
      label: "Organizer Details",
      span: 2,
      children: (
        <Row className="d-flex">
          <Col className="d-flex justify-content-flex-start" xs={6}>
            Contact Person: {capitalize( event?.contact_person_name)}
            <br />
            Email: {event?.organizer_email}
            <br />
            Phone: {event?.organizer_phone_number}
            <br />
          </Col>
          <Col className={styles.colBtn} xs={6}>
            <Button type='primary' icon={<EditIcon />} className={styles.editBtn} onClick={() => openModal("edit")}></Button>
          </Col>
        </Row>
      ),
    },   
    {
      label: <Link style={{ textDecorationLine: "underline", color: "#242E42", opacity: "0.8" }} to={parkingLotPath}> Parking Lots </Link>,
      span: 3,
      children: (
        <React.Fragment>
          {event?.parking_lots?.map((lot, id) => (
            <div className="pt-1">
              {lot?.name}, {lot?.location?.full_address}
              <span className={styles.spacesTag}>
                <span style={{ margin: "1rem" }}>
                  {lot?.alloted_spaces} Spaces
                </span>
              </span>
            </div>
          ))}
        </React.Fragment>
      ),
    },
    
   
  ];
  return (
    <Descriptions
      bordered
      labelStyle={{ fontFamily: "Roboto, sans-serif", fontWeight: "bold", color: "rgba(#242E42, 0.6)" }}
      extra={
        <Row className='d-flex'>
          <Button type="text" className={`${styles.btn} ${styles['btn-txt']}`}>
            {`Status: ${capitalize(event?.status)}`}
          </Button>

          <Button
            type="primary"
            danger
            onClick={() =>
              event?.status !== "expired" &&
              event?.status !== "cancelled" &&
              event?.status !== "rejected" &&
              openModal("cancel")
            }
            className={styles.btn}
          >
            Cancel Event Parking
          </Button>
          
        </Row>
      }
      items={items}
      
      layout='vertical'
      className={styles.description}
    />
  );
}

const EventDetails = (props) => {
  const {fetching, event, openModal, modalType, match, history,contextHolder} = props
  return (
    <Row className={styles.detailsRow}>
        { 
          fetching 
            ? <Spinner {...props} size="sm" color="#FFFFFF" />
            : <Details event={event} openModal={openModal} modalType={modalType} match={match} history={history} />
        }
      
      {contextHolder}
    </Row>
  )
}


export default EventDetails