import React from "react";
import { connect } from "react-redux";
/* Base */
import { Card, CardBody, Row, Col, Container } from "reactstrap";
/* Helpers */
import styles from "./index.module.sass";
import { ReactComponent as DisputesIcon } from "assets/menu_icons/disputes_icon.svg";
import { ReactComponent as SessionsIcon } from "assets/valet_app_icons/session_icon.svg";
import { ReactComponent as BookingIcon } from "assets/valet_app_icons/booking_icon.svg";
import { ReactComponent as PerformanceIcon } from "assets/valet_app_icons/performance_icon.svg";
import { ReactComponent as NotificationIcon } from "assets/valet_app_icons/notification_icon.svg";
import { ReactComponent as AttendantIcon } from "assets/valet_app_icons/attendant_icon.svg";
import { ReactComponent as ProfileIcon } from "assets/valet_app_icons/profile_icon.svg";
import { ReactComponent as DamageClaimIcon } from "assets/valet_app_icons/damage_claim_icon.svg";
/* Modules */
import RedirectIfAuthorized from "components/modules/redirect_if_authorized";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import withCurrentValetStaff from "components/modules/with_current_valet_staff";

const ValetHomepage = (props) => {
  const MAINMENUS = {
    valet_manager: [
      { title: "Requests", icon: <DisputesIcon className={styles.icon} /> },
      { title: "Sessions", icon: <SessionsIcon className={styles.icon} /> },
      { title: "Bookings", icon: <BookingIcon className={styles.icon} /> },
      {
        title: "Damage Claims",
        icon: <DamageClaimIcon className={styles.icon} />,
      },
      {
        title: "Performance",
        icon: <PerformanceIcon className={styles.icon} />,
      },
      { title: "Attendants", icon: <AttendantIcon className={styles.icon} /> },
      {
        title: "Notifications",
        icon: <NotificationIcon className={styles.icon} />,
      },
      {
        title: "Profile",
        icon: props.currentValetStaff?.avatar ? (
          <img
            src={props.currentValetStaff.avatar}
            alt="profile"
            className="rounded-circle"
            width="80"
            height="80"
          />
        ) : (
          <ProfileIcon className={styles.icon} />
        ),
      },
    ],
    valet_attendant: [
      { title: "Requests", icon: <DisputesIcon className={styles.icon} /> },
      { title: "Sessions", icon: <SessionsIcon className={styles.icon} /> },
      {
        title: "Notifications",
        icon: <NotificationIcon className={styles.icon} />,
      },
      { title: "Profile", icon: <ProfileIcon className={styles.icon} /> },
      {
        title: "Performance",
        icon: <PerformanceIcon className={styles.icon} />,
      },
    ],
  };

  return (
    <Container className="m-0 p-0 mw-100">
      <div
        className={`${styles.helpText} d-flex justify-content-center mt-5 mb-3`}
      >
        How can we help you?
      </div>
      <Row className="m-0 p-0 d-flex justify-content-center w-90 pt-4">
        {MAINMENUS[props.currentValetStaff?.role_type]?.map((menu, idx) => {
          return (
            <Col
              className={`m-0 p-0 ${styles.cardCol}`}
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
            >
              <Card className={`${styles.card} hover-shadow rounded-4`}>
                <CardBody className={styles.cardbodyicon}>{menu.icon}</CardBody>
                <CardBody className={styles.cardbody}>{menu?.title}</CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClicks
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Container>
  );
};

export default connect(null)(
  RedirectIfAuthorized(withCurrentValetStaff(ValetHomepage))
);
