import React, { useCallback, useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { citationFilterFetcher } from 'api/vehicles';
import withCurrentUser from 'components/modules/with_current_user';
import withFetching from 'components/modules/with_fetching';
import connectList from 'components/modules/connect_list';
import AccessControl from 'components/helpers/access-control';
import { connect } from 'react-redux';
import resourceFetcher from 'components/modules/resource_fetcher';
import { bindActionCreators } from 'redux';
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS } from 'actions/vehicle_citation_tickets';
import { invoke } from 'actions';
import ToolBar from './toolbar';
import VehicleModal from '../../vehicle_modal';
import TableData from './table_data';
import { list as selectList } from "selectors/list";

const ParkingCitationTickets = (props) => {
  const { currentUser, setList, location, list } = props
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState()
  const [createCitation, setCreateCitation] = useState(false)
  const [restrictApplication, setRestrictApplication] = useState(false)
  const [allowApplication, setAllowApplication] = useState(false)
  const [setting, setSetting] = useState('')

  const openModal = useCallback((modalType) => {
    setModalType(modalType)
  },[setModalType])

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await citationFilterFetcher({id: location?.state?.record?.id})
        if(response.status === 200) {
          setList(selectList(response));
        }
      } catch(error) {
        console.log(error)
      } 
    };
    if(location?.state?.record?.id !== list[0]?.user_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const commonProps = {
    openModal: openModal,
    modalType: modalType,
    setActiveTab: setActiveTab,
    createCitation: createCitation,
    setCreateCitation: setCreateCitation,
    restrictApplication: restrictApplication,
    allowApplication: allowApplication,
    setSetting: setSetting
  }
  
  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN, ROLES.AGENCY_MANAGER, ROLES.AGENCY_OFFICER, ROLES.PARKING_ADMIN, ROLES.TOWN_MANAGER, ROLES.PARKING_OPERATOR]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              {...commonProps}
              activeTab={activeTab} 
              total={props.total}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData {...props} openModal={openModal} modalType={modalType} activeTab={activeTab} />
          </Col>
        </Row>
        <VehicleModal
          {...props}
          {...commonProps}
          setRestrictApplication={setRestrictApplication}
          setAllowApplication={setAllowApplication}
          setting={setting}
        />
      </Container>
    </AccessControl>
  )
}

const resource = 'vehicle_citation_tickets';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS), 
    }, 
    dispatch
  );
}

const  mapState = (state) => {
  return { 
    filters: state.vehicle_citation_tickets.filters,
    selectedRecord: state.vehicle_citation_tickets.records.record

  }
}


export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(citationFilterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(ParkingCitationTickets))),
);
