import React, { Component } from 'react';
import Toolbar from './toolbar'
import CitationAndAppealCard from './citation_and_appeals'
import PermitCard from './permits'
import PermitTypeCard from './permit_types'
import ActiveUserCard from './active_users'
import DormantUserCard from './dormant_users'
import CurrentlyParkedCard from './currently_parked'
import PreviouslyParkedCard from './previously_parked'
import ViolationReportCard from './violation_reports'
import VolumeCard from './volume'
import { index } from 'api/parking_lots';
import { Row, Col } from 'reactstrap';
import styles from './dashboard.module.sass';
import moment from 'moment';
import ViolationTypeBarGraphCard from './violation_types/violation_type_bargraph';

moment.updateLocale("en", { week: {
  dow: 1,
  doy: 4,
}});

const defaultDateFilters = [
  {
    from: moment(),
    to: null,
    label: 'Today',
    text: `Today (${moment().format('L')})`,
    since: 'since yesterday'
  },
  {
    from: moment().startOf('week'),
    to: moment().endOf('week'),
    label: 'This week',
    text: `This week (${moment().startOf('week').format('MM/DD')}-${moment().endOf('week').format('MM/DD')})`,
    since: 'since last week'
  },
  {
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    label: 'This month',
    text: `This month (${moment().startOf('month').format('MMM')})`,
    since: 'since last month'
  }
]

const allParkingLots = {
  label: 'All Parking Lots',
  value: 0
};

class Dashboard extends Component {
  state = {
    parkingLots: [],
    dateRange: {},
    types: [
      {
        name: 'vehicles_parked',
        reportType: 'vehicles_parked',
        display: true,
        reload: false,
        info: 'Historical number of vehicles that parked on the covered parking lots.',
        maxDate: moment().subtract(1, 'days'),
        displayMore: true,
        searchName: 'vehicles perviosly parked'
      },
      {
        name: 'vehicles_currently_parked',
        reportType: 'vehicles_currently_parked',
        display: true,
        reload: false,
        info: 'Number of vehicles parked as of this time.',
        displayMore: true,
        searchName: 'vehicles currently parked'
      },
      {
        name: 'violation_reports_opened',
        reportType: 'violations',
        display: true,
        reload: false,
        info: 'Number of Violation Reports that have not been reviewed yet from the covered parking lots.',
        datesToFilter: defaultDateFilters,
        displayMore: true,
        searchName: 'violation reports opened'
      },
      {
        name: 'violation_reports_rejected',
        reportType: 'violations',
        display: true,
        reload: false,
        info: 'Number of Violation reports that have been reviewed but were deemed invalid.',
        datesToFilter: defaultDateFilters,
        displayMore:true,
        searchName: 'violation reports rejected'
      },
      {
        name: 'voi_matches',
        reportType: 'voi_matches',
        display: true,
        reload: false,
        info: 'Number of vehicles in the Vehicle of Interest(VOI) that are detected inside covered parking lots.',
        datesToFilter: defaultDateFilters,
        displayMore:true,
        searchName: 'vehicle of  interest match'
      },
      {
        name: 'revenue',
        reportType: 'revenues',
        display: true,
        reload: false,
        info: 'Total amount of parking fees collected from the covered parking lots.',
        datesToFilter: defaultDateFilters,
        displayMore: true,
        searchName: 'revenue earned'
      },
      {
        name: 'citation_tickets_opened',
        reportType: 'citation_tickets_opened',
        display: true,
        reload: false,
        info: 'Number of citation tickets that are not yet resolved/settled.',
        datesToFilter: defaultDateFilters,
        searchName: 'citation_tickets_opened',
        displayMore: true,
      },
      {
        name: 'citation_tickets_canceled',
        reportType: 'citation_tickets_canceled',
        display: true,
        reload: false,
        info: 'Number of citation tickets that are cancelled.',
        datesToFilter: defaultDateFilters,
        searchName: 'citation_tickets_canceled',
        displayMore: true,
      }, 
    ],
    citation_and_appeals: {
        name: 'citation_and_appeals',
        reportType: 'Citation and Appeals',
        display: true,
        reload: false,
        info: 'An overview of citation tickets and appeals.',
        datesToFilter: defaultDateFilters,
        searchName: 'citation_and_appeals',
        displayMore: true,
      },
    permits: {
      name: 'permits',
      reportType: 'Permits',
      display: true,
      reload: false,
      info: 'Overview of issued permits.',
      datesToFilter: defaultDateFilters,
      searchName: 'permits',
      displayMore: true,
    },
    permit_types: {
      name: 'permit_types',
      reportType: 'Permit Types',
      display: true,
      reload: false,
      info: 'Overview of issued permit types.',
      datesToFilter: defaultDateFilters,
      searchName: 'permit_types',
      displayMore: true,
    },
    violation_types: {
      name: 'violation_types',
      reportType: 'Violation Types',
      display: true,
      reload: false,
      info: 'Volume of committed violations.',
      datesToFilter: defaultDateFilters,
      searchName: 'violation_types',
      displayMore: true,
    },
    previously_parked: {
      name: 'previously_parked',
      reportType: 'previously_parked',
      display: true,
      reload: false,
      info: 'Historical number of vehicles that parked on the covered parking lots.',
      maxDate: moment().subtract(1, 'days'),
      displayMore: true,
      searchName: 'vehicles perviosly parked'
    },
    violation_reports: {
      name: 'violation_reports',
      reportType: 'Violation Reports',
      display: true,
      reload: false,
      info: 'A representation of the number of open violation reports compared to closed violation reports.',
      datesToFilter: defaultDateFilters,
      searchName: 'violation_reports',
      displayMore: true,
    },
    volume: {
      name: 'volume',
      reportType: 'Volume',
      display: true,
      reload: false,
      info: 'Revenue Earned',
      datesToFilter: defaultDateFilters,
      searchName: 'volume',
      displayMore: true,
    },
  }

  filter = (from, to) => {
    const { types } = this.state
    this.setState({
      dateRange: {
        from,
        to
      },
      types: types.map(element => {
        return {
          ...element,
          name: element.name,
          display: element.display,
          reload: true
        }
      })
    });
  }

  resetFilter = (from, to) => {
    const { types } = this.state
    this.setState({
      dateRange: {},
      types: types.map(element => {
        return {
          ...element,
          name: element.name,
          display: element.display,
          reset: true,
          reload: true
        }
      })
    });
  }

  refresh = () => {
    const { types } = this.state
    this.setState({
      types: types.map(element => {
        return {
          ...element,
          name: element.name,
          reload: true,
          display: element.display
        }
      })
    });
  }

  stopRefreshing = () => {
    const { types } = this.state
    this.setState({
      types: types.map(element => {
        return {
          ...element,
          dateRange: {},
          name: element.name,
          display: element.display,
          reload: false
        }
      })
    });
  }

  search = (value) => {
    const { types } = this.state

    this.setState({
      types: types.map((type) => {
        var display = true
        if (value) {
          const searchValue = value.split(' ').join('_').toLocaleLowerCase()
          display = type.searchName.includes(searchValue)
        }

        return {
          ...type,
          display
        }
      })
    })

  }

  componentWillUnmount () {
    document.querySelector('.frame-container').classList.remove(styles.frameContainer);
  }

  componentDidMount () {
    document.querySelector('.frame-container').classList.add(styles.frameContainer);

    index({ perPage: 50})
      .then(res => {
        this.setState({
          parkingLots: [allParkingLots].concat(res.data.map((parking_lot) => ({label: parking_lot.name, value: parking_lot.id})))
        })
      })
  }

  render() {
    const { dateRange, parkingLots, citation_and_appeals, permit_types, permits, previously_parked, violation_reports, volume } = this.state
    const commonProps = {
      parkingLots,
      defaultParkingLot: allParkingLots,
    };

    const timeCommonProps = {
      type: permits.name,
      maxDate: previously_parked.maxDate
    };
  
    return (
      <div className={styles.container}>
        <Row>
          <Col xs="12">
            <Toolbar className={styles.toolBarCard} dateRange={dateRange} filter={this.filter} resetFilter={this.resetFilter} search={this.search} refresh={this.refresh} />
          </Col>
          <Col className={`pt-0 ${styles.listDataCard}`} xs="12">
            <Row className='m-0 mb-2' xs={12}>
              <Col style={{maxWidth: '15%'}} className='p-0'><ActiveUserCard/></Col>
              <Col style={{maxWidth: '15%'}} className='p-0'><DormantUserCard/></Col>
              <Col style={{maxWidth: '15%'}} className='p-0'><CurrentlyParkedCard/></Col>
              <Col style={{maxWidth: '15%'}} className='p-0'>
                <PreviouslyParkedCard
                  {...this.props}
                  maxDate={previously_parked.maxDate}
                />
              </Col>
              <Col  style={{maxWidth: '40%'}} className='p-0'>
                <VolumeCard
                  {...commonProps}
                  {...timeCommonProps}
                  type={volume.name}
                  />
              </Col>
            </Row>
            <Row className='mx-0 mb-2' style={{ marginTop: '14px'}} xs={12}>
              <Col style={{maxWidth: '30%'}} className='p-0'>
                <ViolationReportCard
                  type={violation_reports.name}
                  info={violation_reports.info}
                  maxDate={previously_parked.maxDate}
                />
              </Col>
              <Col style={{maxWidth: '30%'}} className='p-0'>
                <ViolationTypeBarGraphCard className={styles.borderPrimary}/>
              </Col>
              <Col style={{maxWidth: '40%'}} className='p-0'>
                <CitationAndAppealCard
                  {...commonProps}
                  type={citation_and_appeals.name}
                />
              </Col>
            </Row>
            <Row className='mx-0 mb-2' style={{ marginTop: '-8px'}} xs={12}>
              <Col style={{maxWidth: '40%'}} className='p-0'>
              <PermitCard
                {...commonProps}
                type={permits.name}
              />
              </Col>
              <Col style={{maxWidth: '60%'}} className='p-0'>
                <PermitTypeCard
                  {...commonProps}
                  type={permit_types.name}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Dashboard;