import React, { useCallback, useState } from "react";
import { Row, Spinner } from "reactstrap";
import PropTypes from "prop-types";
/* API */
import { getDetails } from "api/events";
/* Base */
/* Helpers */
import EventDetails from "./eventDetails";
import EventModal from "./event_modal";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const Event = (props) => {
  const { event, match, history } = props;
  const [updatedEvent, setUpdatedEvent] = useState(event);
  const [modalType, setModalType] = useState();
  const [activeTab, setActiveTab] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation()

  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: message,
      style: {
        width: 400,
      },
    });
  };

  const openModal = useCallback(
    (modalType) => {
      let val;
      // Evaluate modalType
      switch (true) {
        case modalType === "edit":
          val = "edit";
          break;
        case modalType === "cancel":
          val = "cancel";
          break;
        default:
          break;
      }
      setModalType(val);
    },
    [setModalType]
  );

  const btnSpinner = (props = {}) => {
    return (
      <span>
        <Spinner {...props} size="sm" color="#FFFFFF" />
      </span>
    );
  };

  const fetchEventDetails = useCallback(async () => {
    setIsFetching(true);
    try {
      const response = await getDetails({ event_id: event?.event_id });
      setUpdatedEvent(response.data);
    } catch (_error) {
    } finally {
      setIsFetching(false);
    }
  }, [setIsFetching, event]);

  return (
    <Row className="mx-auto">
      {isFetching && btnSpinner({ className: 'spinner-border' })}
      <EventDetails
        contextHolder={contextHolder}
        match={match}
        history={history}
        fetching={isFetching}
        event={updatedEvent}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        openModal={openModal}
        modalType={modalType}
      />
      <EventModal
        openNotificationWithIcon={openNotificationWithIcon}
        event={event}
        modalType={modalType}
        openModal={openModal}
        fetchEventDetails={fetchEventDetails}
        t={t}
      />
    </Row>
  );
};

Event.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClickFilter: PropTypes.func,
  label: PropTypes.string,
};

export default Event;
