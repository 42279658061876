import React from 'react'
/* Helpers */
import { camelize, displayDate } from 'components/helpers';
/* Base */
/* Styles/Assets */
import styles from './index.module.sass';
import SlideButton from 'components/base/slide_button';
import { ReactComponent as ShareIcon } from 'assets/share.svg';

const TableRow = ({record, setRecord}) => {

  return (
    <tr onClick={() => setRecord(record)}>
      <td className=''>{record.permit_number}</td>
      <td >
        <div className='d-flex no-wrap align-items-center'>
          <span>{camelize(record.permit_type)}</span>
          { record.shared && (
            <SlideButton icon={<ShareIcon />} className={` ${styles.badge} ml-2`} status='orange-light'>
              <span className='mr-3 d-inline-block' style={{width: '34px'}}>Shared</span>
            </SlideButton>
          )}
        </div>
      </td>
      <td className=''>{displayDate(record.issue_date, 'MMMM Do YYYY')}</td>
      <td className='text-uppercase'>{record.vehicle?.plate_number}</td>
      <td className='text-capitalize'>{record.vehicle?.owner_name || record.owner_name}</td>
      <td className='text-capitalize'>{record.vehicle?.model}</td>
      <td className='text-capitalize'>{record.permit_expired ? 'Yes' : 'No'}</td>
      <td className=''>{record.expiry_date === 'Non-Expiring'? 'Non-Expiring' : displayDate(record.expiry_date, 'MMMM Do YYYY')}</td>
    </tr>

  )
}

export default TableRow;
