export function getErrorMsg(errorObj) {
  if (!errorObj) return ''
  if (typeof errorObj === 'string') return errorObj
  if (!(errorObj instanceof Error)) return ''
  if (errorObj.message === 'Network Error') return 'No connection'
  const { response } = errorObj
  if (!response || !response.data) return ''
  const { error, errors } = response.data
  if (error) return error
  if (!errors) return ''
  for (const key in errors) {
    const errorValue = errors[key]
    if (Array.isArray(errorValue) && errorValue.length > 0) {
      return errorValue[0]
    }
  }
  return ''
}
