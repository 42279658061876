import React from "react";
/* API */
import { filterFetcher } from "api/clients";
/* Base */
import IndexTable from "components/base/table";
/* Modules */
import TableRow from "./table_row";
/* Styles and Assets */
import styles from './index.module.sass'

const TableData = (props) => {
  const { list, match, history, selectedRecordId, openModal, modalType, setSelectedRecordId, activeTab, filters } = props;

  const renderRecords = () => {
    return list.map((record) => {
      return (
        <TableRow
          key={record.id} 
          record={record} 
          history={history}
          selectedRecordId={selectedRecordId}
          match={match}
          openModal={openModal}
          modalType={modalType}
          setSelectedRecordId={setSelectedRecordId}
        />
      );
    });
  }

  return (
    <IndexTable
      className={`${styles.table} ${activeTab ? styles['table-active'] : ''}`}
      {...props}
      filterFetcher={filterFetcher}
      paginationQuery={filters}
      showModalFilter={false}
      columns={
        <React.Fragment>
          <th attr="id" style={{width: '30%', minWidth: 200}}>Id</th>
          <th attr="clients.name" style={{width: '30%', minWidth: 300}}>Name</th>
          <th attr="clients.email" style={{width: '30%', minWidth: 300}}>Email</th>
          <th attr="clients.status" style={{width: '30%', minWidth: 100}}>Status</th>
          <th 
            attr="actions"
            style={{ width: '20%', minWidth: '210px', display: 'flex', justifyContent: 'center' }}
            disableSort
          >
            Action
          </th>
        </React.Fragment>
      }
      renderRecords={renderRecords}
      entityName='Clients'
    />
  )
}

export default TableData;