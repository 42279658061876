import { PermitReportActions } from "actions";
import { combineReducers } from "redux";
import reduceEntity from "../entities";

const { index, records, filters } = reduceEntity(PermitReportActions);

const PermitReportsReducers = combineReducers({
  index,
  records,
  filters
});

export default PermitReportsReducers;
