import React, { useRef, useState } from 'react'
import { filterFields } from 'components/helpers/fields/reports'
import { renderFields } from 'components/base/forms/common_form'
import { Form } from 'informed'
import { Col, Row } from 'reactstrap'
import styles from './index.module.sass'
import moment from "moment"
import DateModal from "components/base/date_modal";

const FilterForm = (props) => {
  const { setFilters, setInitialFilter, dateRange, setDateRange } = props;
  const [dateModalOpen, setDateModalOpen] = useState(false)

  const datesToFilter= [
    {
      label: 'Today',
      value: 'Today',
      period: {from: moment(), to: moment()}
    },
    {
      label: 'Yesterday',
      value: 'Yesterday',
      period: {from: moment().subtract(1, "days"), to: moment().subtract(1, "days")},
    },
    {
      label: 'This week',
      value: 'This week',
      period: {from: moment().startOf("isoWeek"), to: moment().endOf("isoWeek")},
    },
    {
      label: 'Last week',
      value: 'Last week',
      period: {from: moment().subtract(1, 'weeks').startOf('week'), to: moment().subtract(1, 'weeks').endOf('week')},
    },
    {
      label: 'This month',
      value: 'This month',
      period: {from: moment().startOf("month"), to: moment().endOf("month")},
    },
    {
      label: 'Last month',
      value: 'Last month',
      period: {from: moment().subtract(1, 'months').startOf('month'), to: moment().subtract(1, 'months').endOf('month')},
    },
    {
      label: 'Custom Range',
      value: 'Custom Range',
    },
  ]

  const attrs = { 
    customInputClass: styles.formInput,
    customLabelClass: styles.formLabel,
    customInput1Class: styles.formInput2,
    datesToFilter: datesToFilter,
    dateRange: dateRange
  }

  const initialValues = {
    period: 'Today',
    range: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD")
    }
  };

  const timeFieldsProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {setCustomDate(e)}
    }
  }

  const rangeFieldsProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {setPeriodDate(e, 'from')}
    }
  }

  const range1FieldsProps = {
    lSize: 4,
    iSize: 8,
    events: {
      onChange: (e) => {setPeriodDate(e, 'to')}
    }
  }

  const setPeriodDate = (e, attr) => {
    const { values: filterValues } = formApiRef.current.getState();
    formApiRef.current.setValues({
      period: 'Custom Range'
    })
    setFilters({period: 'Custom Range', range: { ...filterValues?.range, [`${attr}`]: e}})
  }

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }

  const setCustomDate = (e) => {
    if(e.label === 'Custom Range'){
      setDateModalOpen(true)
    }else{
      formApiRef.current.setValues({
        range: {
          from: e?.period?.from?.format("YYYY-MM-DD"),
          to: e?.period?.to?.format("YYYY-MM-DD")
        }
      })
      setFilters({
        period: e?.label,
        range: { 
          from: e?.period?.from?.format("YYYY-MM-DD"),
          to: e?.period?.to?.format("YYYY-MM-DD")
        },
      })
      setInitialFilter({from: e?.period?.from?.format("YYYY-MM-DD"),to: e?.period?.to?.format("YYYY-MM-DD")})
      setDateRange(`${e?.period?.from?.format("YYYY-MM-DD")} to ${e?.period?.to?.format("YYYY-MM-DD")}`)
    }
  }

  const handleApply = (from, to) => {
    setDateRange(`${from} to ${to}`)
    setDateModalOpen(false)
    formApiRef.current.setValues({
      range: {
        from: from,
        to: to
      }
    })
    setInitialFilter({from: from,to: to})
  };

  const resetDates = () => {
    setFilters({
      range: { 
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      },
      period: 'Today'
    })
    formApiRef.current.setValues({
      range: { 
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      },
      period: 'Today'
    })
  }
  
  const getFields = (fromDate, toDate) => filterFields({ ...attrs, fromDate, toDate})

  return (
    <Form initialValues={initialValues} getApi={setFormApi} className={styles.form} >
      {({formState}) => {
        const dateFrom = formState.values.range?.from || ''
        const dateTo = formState.values.range?.to || ''
        
        const fields = getFields(dateFrom, dateTo)
        return <Row className='m-auto'>
          <Col className={`m-0 ${styles.fieldset}`} xs={4}>
            {renderFields(fields.slice(0, 1), {...timeFieldsProps})}
          </Col>
          <Col className={`m-0 ${styles.fieldset}`} xs={4}>
            {renderFields(fields.slice(1, 2), {...rangeFieldsProps})}
          </Col>
          <Col className={styles.rightColumn} xs={4}>
            <span className={`${styles['fieldset-dateStroke']} position-absolute`}></span>
            {renderFields(fields.slice(2, 3), {...range1FieldsProps})}
            <span 
              role='button' 
              className={`${styles['fieldset-dateClear']} position-absolute`}
              onClick={resetDates}
            >
              Reset Date
            </span>
          </Col>
          <DateModal
            maxDate={moment().toDate()}
            apply={handleApply}
            isOpen={dateModalOpen}
            toggleModal={() => setDateModalOpen(false)}
          />
        </Row>
      }}
    </Form>
  )
}

export default FilterForm;
