import { FieldType } from "components/helpers/form_fields";

const reportsField =({parkingLots=[], customLabelClass, customInputClass='', startDate='', endDate='', checkboxClass='', popperPlacement='bottom-end', permitTypes=[], customInputDateClass='', expiryStartDate, expiryEndDate}) => {
  return ([
    [
      {
        name: "permit_types['category']",
        label: 'Category',
        type: FieldType.SELECT_FIELD,
        customLabelClass,
        customInputClass,
        options: [
          {
            value: 'both',
            label: 'Both'
          },
          {
            value: 'permanent',
            label: 'Permanent'
          },
          {
            value: 'temporary',
            label: 'Temporary'
          },
        ],
      },
      {
        name: "permit_types['payment_type']",
        label: 'Paid/Free',
        type: FieldType.SELECT_FIELD,
        customLabelClass,
        customInputClass,
        options: [
          {
            value: 'both',
            label: 'Both'
          },
          {
            value: 'free',
            label: 'Free'
          },
          {
            value: 'paid',
            label: 'Paid'
          },
        ],
      },
      {
        name: "application_date['from']",
        dateFormat: 'MMMM dd, yyyy',
        max: endDate || new Date() ,
        showIcon: false,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        popperPlacement,
        placeholder: 'From'
      },
      {
        name: "application_date['to']",
        dateFormat: 'MMMM dd, yyyy',
        min: startDate,
        max: new Date(),
        showIcon: false,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass: customInputDateClass,
        popperPlacement,
        placeholder: 'To'
      },
      {
        name: "special_permits",
        label: 'Special / Ordinary',
        type: FieldType.SELECT_FIELD,
        customLabelClass,
        customInputClass,
        options: [
          {
            value: 'both',
            label: 'Both'
          },
          {
            value: 'no',
            label: 'Ordinary'
          },
          {
            value: 'yes',
            label: 'Special'
          },
        ],
      },
      {
        name: 'permit_type_ids',
        label: 'Permit Types',
        type: FieldType.MULTISELECT_FIELD,
        customLabelClass,
        customInputClass,
        showToolTip: false,
        placeholder: 'Choose permit types',
        options: permitTypes
      },
      {
        name: "expiry_date['from']",
        dateFormat: 'MMMM dd, yyyy',
        max: expiryEndDate,
        showIcon: false,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        popperPlacement,
        placeholder: 'From'
      },
      {
        name: "expiry_date['to']",
        dateFormat: 'MMMM dd, yyyy',
        min: expiryStartDate,
        showIcon: false,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass: customInputDateClass,
        popperPlacement,
        placeholder: 'To'
      },
      {
        name: "expired",
        label: 'Expiration',
        type: FieldType.SELECT_FIELD,
        customLabelClass,
        customInputClass,
        options: [
          {
            value: 'both',
            label: 'Both'
          },
          {
            value: 'yes',
            label: 'Expired'
          },
          {
            value: 'no',
            label: 'Unexpired'
          },
        ],
      },
      {
        name: 'day_pass_permits',
        label: 'Day Pass Permits',
        type: FieldType.CHECKBOX_FIELD,
        customLabelClass,
        customInputClass: checkboxClass
      },
    ],
    [
      {
        name: 'parking_lot_ids',
        label: 'Parking Lots',
        type: FieldType.MULTISELECT_FIELD,
        customLabelClass,
        customInputClass,
        showToolTip: false,
        placeholder: 'Choose a parking lot',
        options: [{label: 'All parking lots', value: 0 }].concat(parkingLots)
      },
      {
        name: "total_cost['minimum']",
        type: FieldType.NUMBER_FIELD,
        customLabelClass,
        customInputClass,
        placeholder: 'Min',
        min: 0,
      },
      {
        name: "total_cost['maximum']",
        type: FieldType.NUMBER_FIELD,
        customLabelClass,
        customInputClass,
        placeholder: 'Max',
        min: 0,
      },
      {
        name: "issue_date['from']",
        dateFormat: 'MMMM dd, yyyy',
        max: endDate || new Date() ,
        showIcon: false,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        popperPlacement,
        placeholder: 'From'
      },
      {
        name: "issue_date['to']",
        dateFormat: 'MMMM dd, yyyy',
        min: startDate,
        max: new Date(),
        showIcon: false,
        type: FieldType.SINGLE_DATE_FIELD,
        customLabelClass,
        customInputClass,
        popperPlacement,
        placeholder: 'To'
      },
    ]
  ]);
}

export default reportsField;
