import React, { useCallback, useMemo, useReducer, useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import Create from '../create';
import Show from '../show';
import Settings from '../view/parkingLotDetails/settings/index'
import { update, create } from 'api/parking_lots';
import styles from './index.module.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AlertMessagesContext } from 'components/helpers/alert_messages';
import { message } from 'antd'

const initialValues = {
  isSaving: false,
  errors: {},
  addedDays: [
    {
      label: 'monday',
      count: [1],
    },
    {
      label: 'tuesday',
      count: [1],
    },
    {
      label: 'wednesday',
      count: [1],
    },
    {
      label: 'thursday',
      count: [1],
    },
    {
      label: 'friday',
      count: [1],
    },
    {
      label: 'saturday',
      count: [1],
    },
    {
      label: 'sunday',
      count: [1],
    },
  ]
}

const reducer = (state, action) => {
  const { type, payload } = action;
  const { errors } = state;
  switch (type) {
    case 'isSaving':
      return { ...state, isSaving: payload, errors: payload ? {} : errors }
    case 'errors':
      return { ...state, errors: payload }
    default:
      return { ...state };
  }
}

const setList = (list, data, perPage, page) => {
  const listLength = list.length;
  switch (true) {
    case listLength < perPage:
      return [data].concat(list);
    case listLength === perPage && page === 1:
      return [data].concat(list).slice(0, perPage);
    default:
      return list;
  }
}

const ParkingModal = (props) => {
  const { addListElement, modalType, openModal, record, parkingAdmins, townManagers, agencies, selectedRecord, setRecord } = props
  const [values, dispatch] = useReducer(reducer, initialValues)
  const { addAlertMessages } = useContext(AlertMessagesContext)
  const state = useMemo(() => values,[values])
  const [messageApi, contextHolder] = message.useMessage();

  const [errors, setErrors] = useState({})
  
  const setState = useCallback((type, payload) => {
    dispatch({ type , payload })
  },[dispatch])


  const closeModal = useCallback(() => {
    setErrors({})
    openModal()
  }, [openModal, setErrors])

  const updateParkingLot = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await update({data: {parking_lot: data}, id : modalType === "settings" ? selectedRecord?.id : record.id})
      if(modalType !== 'settings') setRecord(response.data)
      if(response.status === 200){
        openModal()
        addAlertMessages([{
            type: 'primary',
            text:  "Changes Saved",
            onlyMessage: true,
          }], 'center')
         
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
      if (error?.response?.data?.errors?.parking_days_parking_days_settings){
        messageApi.open({
          type: "error",
          content: error?.response?.data?.errors?.parking_days_parking_days_settings
            ? error?.response?.data?.errors?.parking_days_parking_days_settings
            : error?.response?.data?.errors?.parking_days?.[0]? error?.response?.data?.errors?.parking_days?.[0] : '',
        });
      }
    }finally {
      setState('isSaving', false)
    }
  },[addAlertMessages, setState, record, selectedRecord, modalType, setRecord, openModal,messageApi])

  const createParkingLot = useCallback(async (data) => {
    setState('isSaving', true)
    try {
      const response  = await create({data: {parking_lot: data}})
      addListElement({setList: setList, data: response.data})
      setRecord(response.data)
      if(response.status === 201){
        openModal()
        addAlertMessages([{
            type: 'primary',
            text:  `${response.data.name} parking lot was successfully created`,
            onlyMessage: true,
          }], 'center')
         
      }
    } catch (error) {
      setErrors(error.response?.data?.errors)
    }finally {
      setState('isSaving', false)
    }
  },[addAlertMessages, setState, setRecord, openModal, addListElement])

  const closeBtn = useCallback(() => {
    return <FontAwesomeIcon
      icon={faTimes} 
      className={styles.closeIcon} 
      onClick={closeModal}
    />
  },[closeModal]);

  return (
    <Modal
      isOpen={!!modalType}
      centered
      contentClassName={styles.applicationModal}
      style={{ maxWidth: "689px", width: "100%" }}
    >
      {!!modalType && (
        <ModalHeader close={closeBtn()} className="border-0 pb-0" />
      )}
      <ModalBody>
        {modalType === "view" && (
          <Show
            contextHolder={contextHolder}
            updateParkingLot={updateParkingLot}
            parkingAdmins={parkingAdmins}
            addAlertMessages={addAlertMessages}
            townManagers={townManagers}
            agencies={agencies}
            state={state}
            setState={setState}
            record={record}
            closeModal={closeModal}
            modalType={modalType}
            errors={errors}
            setErrors={setErrors}
          />
        )}
        {modalType === "settings" && (
          <Settings
            updateParkingLot={updateParkingLot}
            parkingAdmins={parkingAdmins}
            addAlertMessages={addAlertMessages}
            townManagers={townManagers}
            agencies={agencies}
            state={state}
            setState={setState}
            record={selectedRecord}
            closeModal={closeModal}
            modalType={modalType}
            errors={errors}
            setErrors={setErrors}
          />
        )}
      </ModalBody>
      <ModalBody>
        {( modalType === 'create') && 
          <Create 
            createParkingLot={createParkingLot}
            parkingAdmins={parkingAdmins}
            townManagers={townManagers}
            agencies={agencies}
            state={state}
            setState={setState}
            record={record}
            closeModal={closeModal}
            modalType={modalType}
            errors={errors}
            setErrors={setErrors}
          />
        }
      </ModalBody>
    </Modal>
  );
}

const  mapState = (state) => {
  return {
    record: state.parking_lot.records.record
  }
}
export default connect(mapState, null)(ParkingModal);

