import resourceApi from "components/modules/resource_api";
import fetchApi from 'components/modules/fetch_api';
import { generatePath } from 'react-router';

const { index } = resourceApi("form_fields");

const filterFetcher = (params = {}) => {
    const { page, perPage = 10000, filters = {}, query } = params;

    return index({
        page,
        perPage,
        query: {
            ...query,
            ...filters,
        },
    });
};

const addFormFields = (params = {}) => {
    const { data = {} } = params;
    return fetchApi(generatePath(`dashboard/form_fields/add_fields`), {
        method: 'PUT',
        params: data,
    })
};

const updateFormFields = (params = {}) => {
    const { id, data = {} } = params;
    return fetchApi(generatePath(`dashboard/form_fields/${id}`), {
        method: 'PUT',
        params: data,
    })
};

const updateFieldsPosition = (params = {}) => {
    // const { id, data = {} } = params;
    return fetchApi(generatePath(`dashboard/form_fields/reposition`), {
        method: 'PUT',
        params: params,
    })
};

export { filterFetcher, addFormFields, updateFormFields, updateFieldsPosition };