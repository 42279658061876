import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap';
import { ROLES } from "config/permissions";
import { SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, SET_FILTERS } from 'actions/permits';
import { invoke } from 'actions';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/* Modules */
import connectList from 'components/modules/connect_list';
import resourceFetcher from 'components/modules/resource_fetcher';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* API */
import { filterFetcher } from 'api/user_permits';
/* Helpers */
import AccessControl from 'components/helpers/access-control';
/* Components */
import ToolBar from "../../toolbar";
import TableData from './tableData';

const Index = (props) => {
  const { location, match } = props;
  const listType = location?.state?.listType;
  const [activeTab, setActiveTab] = useState();
  const userId = match?.params?.id;

  return (
    <AccessControl
      currentRole={props.currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              userId={userId}
              match={match}
              pageType="permits"
              {...props}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData 
              {...props}
              listType={listType}
              userId={userId}
              activeTab={activeTab}
            />
          </Col>
        </Row>
      </Container>
    </AccessControl>
  )
}

const resource = 'permit';

const mapDispatch = (dispatch) => {
  return bindActionCreators(
    {
      setListElement: invoke(SET_LIST_ELEMENT),
      setFilters: invoke(SET_FILTERS),
      setRecord: invoke(SET_SINGLE_RECORD),
    },
    dispatch
  );
};

const  mapState = (state) => {
  return { 
    filters: state.permit.filters,
    selectedRecord: state.permit.records.record
  }
}

export default withRouter(
  connectList(
    resource,
    SET_LIST,
    resourceFetcher(filterFetcher, resource),
    connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index))),
    { fetchCondition: () => true }
  )
);
