import React, { useMemo } from "react";
import { map } from "underscore";
/* Styles and Assets */
import styles from "./index.module.sass";
import { ReactComponent as EyeIcon } from "assets/eye-icon.svg";
import { ReactComponent as SettingIcon } from 'assets/menu_icons/setting-gear-icon.svg'
import Action from "./action";

const TableRow = ({
  record,
  selectedRecordId,
  openModal,
  modalType,
  setSelectedRecordId,
}) => {
  const { status } = record;

  const statusColor = useMemo(() => {
    const colorMapping = {
      active: 'success',
      suspended: 'danger',
    }
    return colorMapping[status] || 'default'
  },[status])

  const actions = [
    {
      icon: <EyeIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => openModal('show'),
      text: 'View',
      isActive: modalType === 'show' && selectedRecordId === record.id,
      width: '50px'
    },
    {
      icon: <SettingIcon className={`${styles['icon-eye']} mx-3`} />,
      highlightedClass: `${styles['background-primary']} mx-3 text-white`,
      onClick: () => openModal('setting'),
      text: 'Setting',
      isActive: modalType === 'setting' && selectedRecordId === record.id,
      width: '50px'
    },
  ];

  return (
    <tr onClick={() => setSelectedRecordId(record)}>
      <td>{record?.id}</td>
      <td>{record?.name}</td>
      <td>{record?.email || `${record?.name?.toLowerCase()}@gamil.com`}</td>
      <td>
        <div className='d-flex'>
          <span className={`${styles.icon} ${styles[`icon-${statusColor}`]} mr-2`}> </span>
          <span >{status}</span>
        </div>
      </td>  
      <td>
        <div className={`${styles.actions} d-flex justify-content-center `}>
          {map(actions, (action, idx) => (
            <Action key={idx} {...action} idx={idx} />
          ))}
        </div>
      </td>
    </tr>
  );
};

export default TableRow;