import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import styles from "./index.module.sass";
import Button from "components/base/button";
import SlideButton from "components/base/slide_button";
import NavigationToolbar from "components/base/navigationToolbar";
import { ReactComponent as FilterIcon } from "assets/filter_icon.svg";
import FilterForm from "./filterForm";
/* API */
import { search as dropdownsSearch } from "api/dropdowns";
import { ReactComponent as PenToSquareSvg } from "assets/pen-to-square.svg";

const CollapseContent = ({
  startFetching,
  setList,
  setFilters,
  match,
  resource,
  currentUser,
}) => {
  const [dropdowns, setDropdowns] = useState({
    roles: [],
  });

  useEffect(() => {
    const fetch = () => {
      Promise.all([
        startFetching(
          dropdownsSearch("role_names_filter", { admin_id: currentUser.id })
        ).then((response) =>
          setDropdowns((state) => ({ ...state, roles: response.data }))
        ),
      ]);
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row
      className={`${styles.filters} w-100 align-items-center justify-content-between px-0 pt-3 pb-2 mx-0`}
      sm="12"
    >
      <FilterForm
        {...dropdowns}
        startFetching={startFetching}
        setList={setList}
        match={match}
        resource={resource}
        setFilters={setFilters}
      />
    </Row>
  );
};

const ToolBar = (props) => {
  const { isFetching, activeTab, setActiveTab, openModal } = props;

  const handleToggleCollapse = useCallback(
    (tab) => {
      if (isFetching) return;
      setActiveTab(activeTab === tab ? null : tab);
    },
    [activeTab, setActiveTab, isFetching]
  );

  return (
    <NavigationToolbar
      openCollapse={!!activeTab}
      collapseContent={<CollapseContent activeTab={activeTab} {...props} />}
      className="d-flex justify-content-space-between"
    >
      <Col className="d-flex justify-content-start align-items-center p-0">
        <span className={`${styles.title} general-text-1`}>
          {`Client (${props.total})`}
        </span>
      </Col>
      <Col className="d-flex justify-content-end pr-0">
        <SlideButton
          className={styles.createButton}
          onClick={() => openModal("create")}
          isActive={true}
          icon={<PenToSquareSvg />}
        >
          {" "}
          <span
            className="mr-3 d-inline-block"
            style={{
              width: "103px",
              fontWeight: 400,
              marginRight: "70px",
              marginTop: "2px",
              fontSize: "15px",
            }}
          >
            Create Client
          </span>
        </SlideButton>
        <Button
          onClick={() => handleToggleCollapse("filters")}
          className={`${styles.btnFilter} ${
            activeTab === "filters" ? styles.activeTab : ""
          }`}
          icon={
            <FilterIcon
              className={`${activeTab === "filters" ? styles.activeIcon : ""}`}
            />
          }
          size="md"
        />
      </Col>
    </NavigationToolbar>
  );
};

export default ToolBar;
