import faker from "faker";
import { FieldType } from "components/helpers/form_fields";
import permissions from "config/permissions";
import fieldsWithPermission from "./fields_with_permission";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const fieldsNew = () =>{
  return [
    { name: "name", label: "Name", mandatory: true },
    { name: "email", label: "Email", mandatory: true },
    { name: "phone", label: "Phone" },
    {
      name: "status",
      label: "Current status",
      mandatory: true,
      type: FieldType.SELECT_FIELD,
      options: [
        { value: "active", label: "Active" },
        { value: "suspended", label: "Suspended" },
      ],
    },
  ]
};

const fieldsShow = (roles, agencies, parkingLots, roleType, userPermissions) =>
  fieldsWithPermission(
    fieldsNew(roles, agencies, parkingLots, roleType),
    userPermissions,
    permissions.UPDATE_ADMIN
  );

  const filterFields = (attrs) => {
    const {customInputClass, customLabelClass} = attrs
    return [
      {
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        customInputClass,
        customLabelClass,
      },
      {
        name: "status",
        label: "Status",
        type: FieldType.SELECT_FIELD,
        options: [
          { value: "active", label: "Active" },
          { value: "suspended", label: "Suspended" },
        ],
        customInputClass,
        customLabelClass,
      },
    ];
  };

const exampleData = () =>
  process.env.NODE_ENV !== "production"
    ? {
        email: faker.internet.email(),
        name: faker.internet.name(),
        phone: "+13583767678",
        status: "suspended",
      }
    : {
        status: "suspended",
      }; // These are defaults values for each field

const showFields = (attrs) => {
  const { customInputClass='', customLabelClass='' } = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      suspended: 'red',
      active: 'green'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'id',
      label: 'Client ID',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'name',
      label: 'Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'email',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'status',
      label: 'Current Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass,
      customLabelClass,
      mandatory: true,
    }
  ]
}

const newFields = (attrs) => {
  const { customInputClass='', customLabelClass=''} = attrs;
  const statusColor = (label) => {
    const colorMapping = {
      suspended: 'red',
      active: 'green'
    }
    return colorMapping[label?.toLowerCase()] || 'default'
  }

  return [
    {
      name: 'name',
      label: 'Name',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      customInputClass,
      customLabelClass,
    },
    {
      name: 'email',
      label: 'Email Address',
      customInputClass,
      customLabelClass,
      mandatory: true,
    },
    {
      name: 'status',
      label: 'Current Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      mandatory: true,
      options: [{ value: 'active', label: 'Active' }, { value: 'suspended', label: 'Suspended' }].map(({value, label}) => ({
        value,
        label: <React.Fragment>
          <FontAwesomeIcon 
            className='mr-2' 
            icon={faCircle}
            style={{ 
              color: statusColor(label),
              width: 10
            }}
            /> 
          {label}
        </React.Fragment>
      })),
      customInputClass,
      customLabelClass,
    }
  ]
}

export { fieldsNew, fieldsShow, exampleData, filterFields, showFields, newFields };
      