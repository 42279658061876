import React, { useCallback, useState, useEffect } from 'react';
import { ROLES } from "config/permissions";
/* Actions */
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD} from 'actions/subscriber_vehicles';
/* API */
import { vehicleFilterFetcher } from 'api/subscribers';
/* Modules */
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
/* Helpers */
import AccessControl from "components/helpers/access-control";
/* Components */
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { list as selectList } from "selectors/list";
import VehicleModal from 'components/pages/vehicles/vehicle_modal';

const Index = props => {
  const { isResourceFetching, currentUser, setList, list, match } = props;
  const [modalType, setModalType] = useState(null)
  const [activeTab, setActiveTab] = useState(null);

  const openModal = useCallback((val) => {
    setModalType(val)
  },[])

  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await vehicleFilterFetcher({id: match?.params?.id})
        if(response.status === 200) {
          setList(selectList(response));
        }
      } catch(error) {
        console.log(error)
      } 
    };
    if(match?.params?.id !== list[0]?.user_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match])

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              resource={resource}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isFetching={isResourceFetching}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
            />
          </Col>
        </Row>
        <VehicleModal 
          {...props}
          modalType={modalType} 
          openModal={openModal} 
        />
      </Container>
    </AccessControl>
  )
  
};

const resource = 'subscriber_vehicle';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS), 
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.subscriber_vehicle.filters,
    record: state.subscriber_vehicle.records.record
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(vehicleFilterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Index)))
);


