import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Col, Nav, Row } from 'reactstrap';
import DropdownNavigation from './dropdown_navigation';
import { ReactComponent as DashboardIcon } from 'assets/menu_icons/dashboard_icon.svg';
import { ReactComponent as SelectedDashboardIcon } from 'assets/menu_icons/selected_dashboard_icon.svg';
import { ReactComponent as AgenciesIcon } from 'assets/menu_icons/law_enf_icon.svg';
import { ReactComponent as SelectedAgenciesIcon } from 'assets/menu_icons/selected_law_enf_icon.svg';
import { ReactComponent as CameraIcon } from 'assets/menu_icons/stream_footages_icon.svg';
import { ReactComponent as SelectedCameraIcon } from 'assets/menu_icons/selected_stream_footages_icon.svg';
import { ReactComponent as ParkingLotIcon } from 'assets/menu_icons/parking_lot_icon.svg';
import { ReactComponent as SelectedParkingLotIcon } from 'assets/menu_icons/selected_parking_lot_icon.svg';
import { ReactComponent as ReportIcon } from 'assets/menu_icons/reports_icon.svg';
import { ReactComponent as SelectedReportIcon } from 'assets/menu_icons/selected_reports_icon.svg';
import { ReactComponent as SubscribersIcon } from 'assets/menu_icons/subscribers_management_icon.svg';
import { ReactComponent as SelectedSubscribersIcon } from 'assets/menu_icons/selected_subscribers_management_icon.svg';
import { ReactComponent as SelectedVehiclesIcon } from 'assets/menu_icons/selected_vehicles_management_icon.svg';
import { ReactComponent as VehiclesIcon } from 'assets/menu_icons/vehicles_management_icon.svg';
import { ReactComponent as PaymentsIcon } from 'assets/menu_icons/payments_transaction_icon.svg';
import { ReactComponent as SelectedPaymentsIcon } from 'assets/menu_icons/selected_payments_transaction_icon.svg';
import { ReactComponent as ViolationsIcon } from 'assets/menu_icons/violations_icon.svg';
import { ReactComponent as SelectedViolationsIcon } from 'assets/menu_icons/selected_violations_icon.svg';
import { ReactComponent as DisputesIcon } from 'assets/menu_icons/disputes_icon.svg';
import { ReactComponent as SelectedDisputesIcon } from 'assets/menu_icons/selected_disputes_icon.svg';
import { ReactComponent as NotificationsIcon } from 'assets/menu_icons/notifications_icon.svg';
import { ReactComponent as SelectedNotificationsIcon } from 'assets/menu_icons/selected_notifications_icon.svg';
import { ReactComponent as ClipboardIcon } from 'assets/menu_icons/clipboard.svg';
import { ReactComponent as SelectedClipboardIcon } from 'assets/menu_icons/selected_clipboard.svg';
import { ReactComponent as CalendarIcon } from 'assets/menu_icons/calendar_icon.svg';
import { ReactComponent as SelectedCalendarIcon } from 'assets/menu_icons/selected_calendar_icon.svg';
import { ReactComponent as StarIcon } from 'assets/menu_icons/star_icon.svg';
import { ReactComponent as SelectedStarIcon } from 'assets/menu_icons/selected_star_icon-com.svg';
import { ReactComponent as CouponIcon } from 'assets/menu_icons/coupon_icon.svg';
import { ReactComponent as SelectedCouponIcon } from 'assets/menu_icons/selected_coupon_icon.svg';
import { ReactComponent as BootingAndTowingIcon } from 'assets/menu_icons/booting_and_towing.svg';
import { ReactComponent as SelectedBootingAndTowingIcon } from 'assets/menu_icons/selected_booting_and_towing.svg';
import { ReactComponent as SettingIcon } from 'assets/menu_icons/setting-gear-icon.svg';
import { ReactComponent as SelectedSettingIcon } from 'assets/menu_icons/selected_setting-gear-icon.svg';
import { ReactComponent as ConfigurationIcon } from 'assets/menu_icons/configuration-icon.svg';
import { ReactComponent as SelectedConfigurationIcon } from 'assets/menu_icons/selected_configuration-icon.svg';
import { ReactComponent as ConnectionIcon } from 'assets/menu_icons/connection-signal-wifi-svgrepo-com.svg';
import { ReactComponent as SelectedConnectionIcon } from 'assets/menu_icons/selected_connection-signal-wifi-svgrepo-com.svg';
import { ReactComponent as RulesIcon } from 'assets/menu_icons/rules-svgrepo-com.svg';
import { ReactComponent as SelectedRulesIcon } from 'assets/menu_icons/selected_rules-svgrepo-com.svg';
import { ReactComponent as ChargingIcon } from 'assets/menu_icons/battery-bolt-svgrepo-com.svg';
import { ReactComponent as SelectedChargingIcon } from 'assets/menu_icons/selected_battery-bolt-svgrepo-com.svg';
import { ReactComponent as FileSearchIcon } from 'assets/menu_icons/file-search-svgrepo-com.svg';
import { ReactComponent as SelectedFileSearchIcon } from 'assets/menu_icons/selected_file-search-svgrepo-com.svg';
import { ReactComponent as AdminsIcon } from 'assets/menu_icons/admin_icon.svg';
import { ReactComponent as SelectedAdminsIcon } from 'assets/menu_icons/selected_admin_icon.svg';
import { ReactComponent as ArrivalIcon } from 'assets/menu_icons/arrival_time_record.svg';
import { ReactComponent as SelectedArrivalIcon } from 'assets/menu_icons/selected_arrival_time_record.svg';
import { ReactComponent as ValetIcon } from 'assets/menu_icons/valet_icon.svg';
import styles from './side-navigation.module.sass';
import permissions from 'config/permissions';
import withCurrentUser from 'components/modules/with_current_user';
import PermissibleRender from 'components/modules/permissible_render';
import doesUserHasPermission from 'components/modules/does_user_has_permission';

const routes = {
  dashboard: '/dashboard',
  admins: '/dashboard/admins',
  agencyTypes: '/dashboard/agency_types',
  agencies: '/dashboard/agencies',
  parkingLots: '/dashboard/parking_lots',
  parkingLotsCamera: '/dashboard/live/parking_lots',
  reports: '/dashboard/reports',
  roles: '/dashboard/roles',
  subscribers: '/dashboard/subscribers',
  vehicles: '/dashboard/vehicles',
  payments: '/dashboard/payments',
  monitor_vehicles: '/dashboard/monitor_vehicles',
  event_parking_applications: '/dashboard/event_parking_applications',
  coupons: '/dashboard/coupons',
  disputes: '/dashboard/disputes',
  citationTickets: '/dashboard/parking/citation_tickets',
  violations: '/dashboard/violations',
  booting_and_towings: '/dashboard/bootings',
  notifications: '/dashboard/notifications',
  reward_types: '/dashboard/reward_types',
  permits: '/dashboard/permits',
  integrations: '/dashboard/integrations',
  archive: '',
  new_dashboard: '/dashboard/new_dashboard',
  select_lists: '/dashboard/select_lists',
  report_configuration: '/dashboard/report_configuration',
  wallet_and_payment_configuration: '/dashboard/wallet_and_payment_configuration',
  themes: '/dashboard/themes',
  broadcast_messages: '/dashboard/broadcast_messages',
  parking_regulations: '/dashboard/parking_regulations',
  charging_sessions: '/dashboard/charging_sessions',
  system_logs:  '/dashboard/system_logs',
  valet: '/dashboard/value_added_services',
  form_configurations: '/dashboard/form_configurations',
  clients: '/dashboard/clients',
};

const isActive = (location, path) => (
  location ? (
    location.pathname === path ? 'selected-nav-point' : 'menu-points'
  ) : 'menu-points'
);

const isNavActive = (location, path) => {
  let isLocationActive = false;
  if (location && Array.isArray(path)) {
    isLocationActive = path.includes(location.pathname);
  } else if (location && typeof path === 'string') {
    isLocationActive = location.pathname === path;
  }
  return isLocationActive;
};

function SideNavigation ({ currentUserPermissions, currentUser, location }) {
  const { openedTitle } = useSelector(({ side_nav }) => side_nav);
  const { client } = currentUser;

  return (
    <Nav vertical pills className={`${styles.sideNavigation} ${openedTitle && styles.noScroll} shadow-sm bg-white`}>
      <li>
        <Link className={`nav-link ${isActive(location, routes.dashboard)}`} to={routes.dashboard}>
          <Row className='p-0 w-100'>
            <Col className='pr-0' xs={3}>
              {isNavActive(location, routes.dashboard) ? <SelectedDashboardIcon/> : <DashboardIcon/>}
            </Col>
            <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
              Dashboard
            </Col>
          </Row>
        </Link>
      </li>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_ADMIN}
        modulePermission={client?.user_mgmt}
      >
        <li>
          <Link
            className={`nav-link ${
              location.pathname === routes.admins ||
              location.pathname === routes.roles
                ? "selected-nav-point"
                : "menu-points"
            }`}
            to={routes.admins}
          >
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, [routes.admins, routes.roles]) ? <SelectedAdminsIcon/> : <AdminsIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Users Management
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_USER}
        modulePermission={client?.subscriber_mgmt}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.subscribers)}`} to={routes.subscribers}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.subscribers) ? <SelectedSubscribersIcon/> : <SubscribersIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Subscribers Management
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.permit_mgmt}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.permits)}`} to={routes.permits}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.permits) ? <SelectedReportIcon/> : <ReportIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Permit Management
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_VEHICLE}
        modulePermission={client?.vehicle_mgmt}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.vehicles)}`} to={routes.vehicles}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.vehicles) ? <SelectedVehiclesIcon/> : <VehiclesIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Vehicles Management
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_PARKINGLOT}
        modulePermission={client?.parking_lot}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.parkingLots)}`} to={routes.parkingLots}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.parkingLots) ? <SelectedParkingLotIcon/> : <ParkingLotIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Parking Lots Management
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.broadcast_message}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.broadcast_messages)}`} to={routes.broadcast_messages}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3} style={{paddingLeft: '11px'}}>
                {isNavActive(location, routes.broadcast_messages) ? <SelectedConnectionIcon/> : <ConnectionIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Broadcast Messages
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_CITYEVENTS}
        modulePermission={client?.city_event}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.event_parking_applications)}`} to={routes.event_parking_applications}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.event_parking_applications) ? <SelectedCalendarIcon/> : <CalendarIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                City Events
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_AGENCY}
        modulePermission={client?.law_enforcement_agency}
      >
        <li>
          <DropdownNavigation title="Law Enforcement Agencies"
            icon={isNavActive(location, [routes.agencyTypes, routes.agencies]) ? <SelectedAgenciesIcon className='float-left mr-4'/> : <AgenciesIcon className='float-left mr-4'/>}>
            {doesUserHasPermission(currentUserPermissions, permissions.READ_AGENCYTYPE) && (
              <Link className={`nav-link ${isActive(location, routes.agencyTypes)}`} to={routes.agencyTypes}>
                Law Enforcement Agency Types
              </Link>
            )}
            <Link className={`nav-link ${isActive(location, routes.agencies)}`} to={routes.agencies}>Law Enforcement Agencies Management</Link>
          </DropdownNavigation>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_PAYMENT}
        modulePermission={client?.payment}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.payments)}`} to={routes.payments}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.payments) ? <SelectedPaymentsIcon/> : <PaymentsIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Payment Transactions
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>

      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.report}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.system_logs)}`} to={routes.system_logs}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.system_logs) ? <SelectedFileSearchIcon/> : <FileSearchIcon/>}
                {/* <FileSearchIcon /> */}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9} style={{paddingLeft: '11px'}}>
                Audit Logs
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>


      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.arrival_time}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.monitor_vehicles)}`} to={routes.monitor_vehicles}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.monitor_vehicles) ? <SelectedArrivalIcon/> : <ArrivalIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Arrival Time Records
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>


      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_DISPUTE}
        modulePermission={client?.dispute}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.disputes)}`} to={routes.disputes}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.disputes) ? <SelectedDisputesIcon/> : <DisputesIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9} style={{paddingLeft: '11px'}}>
                Disputes
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions['READ_Parking::CitationTicket']}
        modulePermission={client?.citation}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.citationTickets)}`} to={routes.citationTickets}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.citationTickets) ? <SelectedClipboardIcon/> : <ClipboardIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Citation Tickets
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_VIOLATION}
        modulePermission={client?.violation}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.violations)}`} to={routes.violations}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.violations) ? <SelectedViolationsIcon/> : <ViolationsIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Violations
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>

      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_BOOTING_AND_TOWINGS}
        modulePermission={client?.citation}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.booting_and_towings)}`} to={routes.booting_and_towings}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.booting_and_towings) ? <SelectedBootingAndTowingIcon/> : <BootingAndTowingIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Booting And Towings
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.valet}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.valet)}`} to={routes.valet}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.valet) ? <ValetIcon className='selected-valet-parking'/> : <ValetIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Valet parking
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_NOTIFICATION}
        modulePermission={client?.notification}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.notifications)}`} to={routes.notifications}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.notifications) ? <SelectedNotificationsIcon/> : <NotificationsIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Notifications Configurations
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>

      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.theme}
      >
        <li>
          <DropdownNavigation title="Customization"
            icon={isNavActive(location, [routes.select_lists, routes.themes])
              ? <SelectedSettingIcon className='float-left mr-3' style={{ marginLeft: '-8px' }}/>
              : <SettingIcon className='float-left mr-3' style={{ marginLeft: '-8px' }}/>}>
            <Link className={`nav-link ${isActive(location, routes.select_lists)}`} to={routes.select_lists}>Select Lists</Link>
            <Link className={`nav-link ${isActive(location, routes.themes)}`} to={routes.themes}>Themes</Link>
          </DropdownNavigation>
        </li>
      </PermissibleRender>

      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_CITYEVENTS}
        modulePermission={client?.parking_reward}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.reward_types)}`} to={routes.reward_types}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.reward_types) ? <SelectedStarIcon/> : <StarIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Parking Rewards
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>


      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_CAMERA}
        modulePermission={client?.video}
      >
        <li>
          <DropdownNavigation title="Videos Footage"
            icon={isNavActive(location, [routes.parkingLotsCamera, routes.archive]) ? <SelectedCameraIcon className='float-left mr-4'/> : <CameraIcon className='float-left mr-4'/>}>
            <Link className={`nav-link ${isActive(location, routes.parkingLotsCamera)}`} to={routes.parkingLotsCamera}>Live Streaming</Link>
            <Link className={`nav-link ${isActive(location, routes.archive)}`} to={routes.archive}>Archived Videos</Link>
          </DropdownNavigation>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_REPORT}
        modulePermission={client?.report}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.reports)}`} to={routes.reports}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.reports) ? <SelectedReportIcon/> : <ReportIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                System Reports
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>

      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_REPORT}
        modulePermission={client?.report}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.charging_sessions)}`} to={routes.charging_sessions}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.charging_sessions) ? <SelectedChargingIcon/> : <ChargingIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                EV Charging
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        requiredPermission={permissions.READ_CITYEVENTS}
        modulePermission={client?.coupon}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.coupons)}`} to={routes.coupons}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.coupons) ? <SelectedCouponIcon/> : <CouponIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Coupons
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.city_event}
      >
        <li>
          <DropdownNavigation title='System Configurations'
            icon={isNavActive(location, [routes.integrations, routes.report_configuration, routes.wallet_and_payment_configuration, routes.form_configurations])
              ? <SelectedConfigurationIcon className='float-left mr-3'/> : <ConfigurationIcon className='float-left mr-3'/>}>
            <Link className={`nav-link ${isActive(location, routes.integrations)}`} to={routes.integrations}>Integrations</Link>
            <Link className={`nav-link ${isActive(location, routes.report_configuration)}`} to={routes.report_configuration}>Report Configurations</Link>
            <Link className={`nav-link ${isActive(location, routes.wallet_and_payment_configuration)}`} to={routes.wallet_and_payment_configuration}> Wallet and payment configurations</Link>
            <Link className={`nav-link ${isActive(location, routes.form_configurations)}`} to={routes.form_configurations}>Form Configurations</Link>
          </DropdownNavigation>
        </li>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={currentUserPermissions}
        modulePermission={client?.parking_regulation}
      >
        <li>
          <Link className={`nav-link ${isActive(location, routes.parking_regulations)}`} to={routes.parking_regulations}>
            <Row className='p-0 w-100'>
              <Col className='pr-0' xs={3}>
                {isNavActive(location, routes.parking_regulations) ? <SelectedRulesIcon/> : <RulesIcon/>}
              </Col>
              <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
                Parking Regulations
              </Col>
            </Row>
          </Link>
        </li>
      </PermissibleRender>
      <li>
        <Link className={`nav-link ${isActive(location, routes.new_dashboard)}`} to={routes.new_dashboard}>
          <Row className='p-0 w-100'>
            <Col className='pr-0' xs={3}>
              {isNavActive(location, routes.new_dashboard) ? <SelectedDashboardIcon/> : <DashboardIcon/>}
            </Col>
            <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
              New Dashboard
            </Col>
          </Row>
        </Link>
      </li>
      <li>
        <Link className={`nav-link ${isActive(location, routes.clients)}`} to={routes.clients}>
          <Row className='p-0 w-100'>
            <Col className='pr-0' xs={3}>
              {isNavActive(location, routes.clients) ? <SelectedDashboardIcon/> : <DashboardIcon/>}
            </Col>
            <Col className='pl-2 pr-0 align-content-center d-none d-xl-block' xs={9}>
               Clients
            </Col>
          </Row>
        </Link>
      </li>
    </Nav>
  );
}

SideNavigation.propTypes = {
  location: PropTypes.object.isRequired,
  currentUserPermissions: PropTypes.array.isRequired
};

export default withRouter(withCurrentUser(SideNavigation));
