import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Input, Container } from 'reactstrap';
import { isEmpty } from 'underscore';
/* Actions */
import { setValetToken, setCurrentValetData } from 'actions/users';
/* API */
import { auth } from 'api/valetApp/valet';
/* Base */
import CardLayout from 'components/base/layout/card';
/* Helpers */
import { btnSpinner } from 'components/helpers';
import { setErrorsMessages } from 'components/helpers/messages';
import Password from 'components/helpers/form_fields/password'
import styles from './index.module.sass'
/* Modules */
import RedirectIfAuthorized from 'components/modules/redirect_if_authorized';
import AppModal from '../app/app_modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { message } from 'antd';

const ValetLogin = (props) => {
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [messages, setMessages] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [modalType, setModalType] = useState('')
  const [messageApi, contextHolder] = message.useMessage();
  const [messagePop, setMessagePop] = useState(false)
  const openModal = (modalType) => {
    let val
    // Evaluate modalType
    switch (true) {
      case modalType === 'apply_permit':
        val = 'apply_permit'
        break;
      case modalType === 'find_parking':
        val = 'find_parking'
        break;
      case modalType === 'confirmation':
        val = 'confirmation'
        break;
      default:
        break;
    }
    setModalType(val)
  }

  const success = () => {
    messageApi.open({
      type: 'success',
      duration: 2,
      content: 'Verification Successful! Please login to access your account',
      className: 'custom-class',
      style: {
        marginTop: '20vh',
      },
    });
    setMessagePop(true)
  };

  const submitForm = (event) => {
    event.preventDefault();
    setIsFetching(true)
    auth(username, password)
      .then(res => {
        setValetToken(res.data)
        localStorage.removeItem('TOKEN')
        // localStorage.setItem('SUB_TOKEN', res.data.token)
        localStorage.setItem('VALET_TOKEN', res.data.token)
      })

      .catch(error => {
        const errorMessage = !error.request?.status ? 'Could not connect to the server now. Please try again later.' : error
        setIsFetching(false)
        setMessages(setErrorsMessages(errorMessage))
      });
  };

  const setCurrentValetData = () => {
    props.setCurrentValetData()
      .catch(error => {
        console.log(error)
        setIsFetching(false)
        setMessages(setErrorsMessages(error))
      });
  }


  const setValetToken = (data) => {
    localStorage.setItem('VALET_TOKEN', data)
    setIsFetching(false)
    setMessages([])
    props.setValetToken(data.token);
    setCurrentValetData();
    props.history.push('/valet/home');
  }

  const validInputs = () => {
    return username && password
  }

  useEffect(() => {
    if (localStorage.getItem('firstLoadDone') === null && !messagePop && props?.location?.state?.showMessage) {
      localStorage.setItem('firstLoadDone', 1);
      success();
      setMessagePop(true);
    }
    // eslint-disable-next-line
  }, [messagePop, props])



  return (
    <Container>
      {contextHolder}
      <CardLayout isFetching={isFetching} messages={messages}>
        <form style={{ marginTop: "50%" }} onSubmit={submitForm}>
          <h1 className="h1-title-primary mb-4 text-center">Valet Staff Login</h1>

          <div className="form-label-group">
            <label className="general-text-3" htmlFor="email">Username</label>
            <Input
              id="email"
              type="text"
              value={username}
              name="username"
              onChange={e => setUserName(e.target.value)}
              className={`form-control-lg ${!isEmpty(messages) ? 'input-error' : ''}`}
              placeholder="Enter your email or username"
              required
              autoFocus
            />
          </div>

          <div className="form-label-group mt-2">
            <label className="general-text-3" htmlFor="password">Password</label>
            <Password
              field={{ name: "password", filled: false }}
              customAttr={{
                onChange: e => setPassword(e.target.value),
                className: `position-relative form-control-lg form-control ${styles.passwordField} ${!isEmpty(messages) ? 'input-error' : ''}`,
                placeholder: "Enter your password"
              }}
              className
            />
          </div>

          <Link to='/forgot_password?valet=true' className={`mr-1 mt-3 mb-5 d-block ${styles.forgetPassword}`}>Forgot Password?</Link>
          <Button color={validInputs() ? 'primary-lg' : 'disabled-lg'} className={`${validInputs() ? styles.button : styles.disabledBg} mt-4 p-3 text-uppercase btn-lg btn-block`} type="submit" disabled={isFetching}>
            {isFetching ? btnSpinner({ className: 'spinner-border' }) : "LOGIN"}
          </Button>
        </form>
      </CardLayout>
      <AppModal openModal={openModal} modalType={modalType} />
      <ToastContainer position="top-center" autoClose={1500} />
    </Container>

  );
}

function mapDispatch(dispatch) {
  return { ...bindActionCreators({ setValetToken, setCurrentValetData }, dispatch) };
}

ValetLogin.propTypes = {
  setValetToken: PropTypes.func.isRequired,
  setCurrentValetData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default connect(
  null,
  mapDispatch
)(RedirectIfAuthorized(ValetLogin));