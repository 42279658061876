import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ROLES } from "config/permissions";
import { SET_FILTERS, SET_LIST, SET_LIST_ELEMENT, SET_SINGLE_RECORD, ADD_LIST_ELEMENT, POP_LIST_ELEMENT } from 'actions/reports';
import { reportFilterFetcher } from 'api/reports';
import resourceFetcher from 'components/modules/resource_fetcher';
import connectList from 'components/modules/connect_list';
import withFetching from 'components/modules/with_fetching';
import withCurrentUser from 'components/modules/with_current_user';
import AccessControl from "components/helpers/access-control";
import TableData from "./table_data";
import { Col, Container, Row } from "reactstrap";
import ToolBar from "./toolbar";
import { invoke } from 'actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { list as selectList } from 'selectors/list';
import { isEmpty } from 'underscore';
import SystemReportModal from '../system_report_modal';
import Loader from 'components/helpers/loader';

const Show = props => {
  const { isResourceFetching, currentUser, setList, startFetching, filters, location, match } = props;
  const [modalType, setModalType] = useState(null)
  const [isResponseFetching, setIsResponseFetching] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [activeTab, setActiveTab] = useState('filters');
  const [initialFilter, setInitialFilter] = useState(filters?.range)
  // eslint-disable-next-line
  const [reportName, setReportName] = useState(location?.state?.reportName ? location?.state?.reportName?.module_name : location?.pathname.split('/').pop())
  const [tableHeaders, setTableHeaders] = useState([])
  const [currentFilters, setCurrentFilters] = useState(null)
  const [dateRange, setDateRange] = useState(location?.state?.dateRange ? location?.state?.dateRange : `${filters?.range?.from} to ${filters?.range?.to}`)
  const [titleName, setTitleName] = useState()

  const formApiRef = useRef();

  const setFormApi = (formApi) => {
    formApiRef.current = formApi
  }
  const openModal = useCallback((val) => {
    setModalType(val)
  },[])
  
  const isFetching = useCallback(() => {
    return isResourceFetching;
  },[isResourceFetching])

  useEffect(() => {
    const fetchPermitReport = async () => {
      setIsResponseFetching(true)
      try{
      startFetching(reportFilterFetcher(Object.assign({ ...match.params }, {filters: filters}, { query: filters }, {module_name: reportName}, {page: location?.search?.split('=')[1]})))
      .then((res) => {
        setList(selectList(res));
        localStorage.removeItem('initialCall')
      }) } catch(error) {
        console.log(error)
      } finally{
        setIsResponseFetching(false)
      }
    }
    if(localStorage.getItem('initialCall') === 'true') fetchPermitReport()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setList, startFetching, match.params, filters])

  useEffect(() => {
    const setHeaders = () => {
      switch (reportName) {
        case 'Permit':
          setTableHeaders(['Permit Number', 'Permit Type', 'Issue Date', 'Expiry Date', 'Status',
            'Vehicle Plate Number', 'Vehicle Owner Name', 'Vehicle Model',
            'Commuters Count', 'Parking Lot', 'Cost'])
          setTitleName('Permit')
          break;
        case 'PermitType':
          setTableHeaders(['Permit Type Name', 'Created At', 'Time (From - To)', 'Paid/Free',
            'Category', 'Status', 'Period', 'Space Sharing'])
          setTitleName('Permit Type')
          break;
        case 'ParkingSession':
          setTableHeaders(['Parking Session Id', 'Check In Time', 'Check Out Time', 'Parking Slot',
           'Vehicle Plate Number', 'Status', 'Parking Lot', 'Vehicle Parked At',
           'Vehicle Left At', 'Vehicle Entered At', 'Vehicle Exit At'])
          setTitleName('Parking Session')
          break;
        case 'Violation':
          setTableHeaders(['Violation Id', 'Created At', 'Parking Lot', 'Agency',
           'Vehicle Plate Number', 'Officer', 'Status', 'Violation Type'])
          setTitleName('Violation')
          break;
        case 'CitationTicket':
          setTableHeaders(['Citation Ticket Id', 'Created At', 'Status', 'Vehicle Plate Number',
           'Violation Type', 'Amount', 'Parking Lot', 'Dispute Type'])
          setTitleName('Citation Ticket')
          break;
        case 'Dispute':
          setTableHeaders(['Dispute Id', 'Created At', 'Dispute Type', 'Status',
           'Username', 'Email', 'Parking Lot'])
          setTitleName('Dispute')
          break;
        case 'Payment':
          setTableHeaders(['Transaction Id', 'Created At', 'Transaction Amount', 'Status',
           'Transaction Type', 'Vehicle Owner Email', 'Vehicle Plate Number', 'Parking Lot Name'])
          setTitleName('Payment')
          break;
        case 'User':
          setTableHeaders(['User Id', 'First Name', 'Last Name', 'Confirmed At', 'Created At',
           'Email', 'Status', 'Account Type', 'Vehicle Owned', 'Phone'])
          setTitleName('Subscriber')
          break;
        case 'Vehicle':
          setTableHeaders(['Vehicle Id', 'Vehicle Registered On', 'Vehicle Plate Number', 'Owner Email',
           'Owner First Name', 'Owner Last Name', 'Registration State', 'Vehicle Category',
           'Vehicle Type', 'Vehicle Manufacturer', 'Vehicle Status', 'Vehicle Model',
           'Vehicle Color', 'Agency', 'Vehicle Class', 'Vehicle Subclass'])
          setTitleName('Vehicle')
          break;
        case 'ParkingLot':
          setTableHeaders(['Parking Lot Id', 'Name', 'Created At', 'Email', 'Phone', 'Status',
           'Availability', 'Lot Type', 'Parking Operator', 'Town Manager',
            'Total Spaces', 'Location', 'Agency'])
          setTitleName('Parking Lot')
          break;
        case 'EventParkingApplication':
          setTableHeaders(['Event Id', 'Event Type', 'Event Name', 'Status', 'Start Date',
           'End Date', 'Organizer Email', 'Organizer Phone', 'Event Days',
           'Event Spaces', 'Payment Status', 'Cost'])
          setTitleName('Event')
          break;
        case 'Agency':
          setTableHeaders(['Agency Id', 'Agency Name', 'Created At', 'Email',
           'Status', 'Manager', 'Agency Type', 'Location'])
          setTitleName('Agency')
          break;
        case 'BootingTowing':
          setTableHeaders(['Vehicle Id', 'Status', 'Vehicle Plate Number', 'Booted On',
           'Booted By', 'Impounded On', 'Impounded By', 'Vehicle Manufacturer',
           'Vehicle Model', 'Vehicle Color', 'Owner Email', 'Owner First Name', 'Owner Last Name'])
          setTitleName('Booting & Towing')
          break;
        default:
          break;
      }
    }
    if(isEmpty(tableHeaders)) setHeaders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setTableHeaders, reportName])

  if (isResponseFetching) {
    return <Loader />;
  }

  return (
    <AccessControl
      currentRole={currentUser?.role_type}
      allowedRoles={[ROLES.SUPER_ADMIN]}
    >
      <Container className='mw-100 p-0'>
        <Row className='m-auto'>
          <Col xs={12} className='p-0'>
            <ToolBar
              {...props}
              resource={resource}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isFetching={isResourceFetching}
              openModal={openModal}
              modalType={modalType}
              setInitialFilter={setInitialFilter}
              initialFilter={initialFilter}
              formApiRef={formApiRef}
              setFormApi={setFormApi}
              reportName={reportName}
              dateRange={dateRange}
              setDateRange={setDateRange}
              titleName={titleName}
            />
          </Col>
          <Col xs={12} className='p-0'>
            <TableData
              {...props}
              isFetching={isFetching}
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
              resource={resource}
              modalType={modalType}
              openModal={openModal}
              activeTab={activeTab}
              tableHeaders={tableHeaders}
              setCurrentFilters={setCurrentFilters}
              currentFilters={currentFilters}
              titleName={titleName}
            />
          </Col>
        </Row>
        <SystemReportModal
          {...props}
          openModal={openModal}
          modalType={modalType}
          setActiveTab={setActiveTab}
          selectedRecord={selectedRecord}
          reportName={reportName}
        />
      </Container>
    </AccessControl>
  )
  
};

Show.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  isResourceFetching: PropTypes.bool.isRequired,
  startFetching: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const resource = 'report';

const  mapDispatch = (dispatch) => {
  return bindActionCreators(
    { 
      setListElement: invoke(SET_LIST_ELEMENT), 
      setRecord: invoke(SET_SINGLE_RECORD),
      setFilters: invoke(SET_FILTERS),
      addListElement: invoke(ADD_LIST_ELEMENT),
      popListElement: invoke(POP_LIST_ELEMENT),
    }, 
    dispatch);
}

const  mapState = (state) => {
  return { 
    filters: state.report.filters,
    record: state.report.records
  }
}

export default connectList(
  resource,
  SET_LIST,
  resourceFetcher(reportFilterFetcher, resource),
  connect(mapState, mapDispatch)(withFetching(withCurrentUser(Show))),
  SET_FILTERS
);
