const { FieldType } = require("components/helpers/form_fields");

const viewFields = (attrs, disabled) => {
  const { customInputClass='', customLabelClass='', statuses=[], defaultStatus } = attrs;
  return [
    {
      name: 'date',
      label: 'Date',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'user_name',
      label: 'Full Name',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'email',
      label: 'Subscriber Email',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'parking_lot',
      label: 'Parking Lot',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'dispute_type',
      label: 'Dispute Type',
      customInputClass,
      customLabelClass,
      disabled: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: FieldType.DROPDOWN_SELECT_FIELD,
      options: defaultStatus === 'in_progress' ? [{value: "in_progress", label: "In Progress"},{value:"rejected", label:"Rejected"},{value: "solved", label: "Solved"}] : statuses ,
      customInputClass,
      customLabelClass,
      disabled:defaultStatus === "rejected" || defaultStatus === "solved",
  },
 
  ]
  
}




export { viewFields };
